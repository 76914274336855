import { Period } from './Period.model'
import { BehaviorSubject } from 'rxjs'
import { subMonths } from 'date-fns'
import { PeriodType } from '../../public_api'
import { DateHelper } from '@eliq/util'

export class Resolution {
	// this class is a mess.
	// good idea but bad knowledge of tools available ahead of time

	// the class is used to keep track of the selected period and resolution in the header of usage and reports views.
	// Idea: parent component would have a Resolution member and passes down the Period as an observable to the chart for example, and gives
	// the header the ability to update the Period by of course clicking next or previous month and so on
	// . it works but not ideal.

	private periodType: PeriodType
	private period!: BehaviorSubject<Period>
	private currentPeriodValue!: Period
	private previousPeriod!: Period
	private currentDate: Date
	private currentTime!: Date
	private dateCalc: DateHelper

	private minDates: Map<PeriodType, Date>
	private maxDates: Map<PeriodType, Date>
	constructor(
		pt?: PeriodType,
		dateInPeriod?: Date,
		minDates?: Map<PeriodType, Date>,
		maxDates?: Map<PeriodType, Date>,
	) {
		this.dateCalc = DateHelper.getInstance()

		this.minDates = minDates ? minDates : new Map<PeriodType, Date>()
		this.maxDates = maxDates ? maxDates : new Map<PeriodType, Date>()

		// note that the term resolution here is incorrect. should be ConsumptionPeriod instead..
		this.periodType = pt ? pt : PeriodType.Month
		// if we received a parameter dateinperiod then use that, otherwise grab the current date via new Date()
		this.currentDate = this.getDateWithinBoundaries(
			dateInPeriod ? dateInPeriod : new Date(),
			this.periodType,
		)

		this.initPeriod()
	}

	private initPeriod() {
		// we set the default resolution to... uhm... month.
		this.currentPeriodValue = new Period(this.periodType, this.currentDate)
		this.period = new BehaviorSubject(this.currentPeriodValue)
		this.previousPeriod = new Period(
			this.periodType,
			subMonths(new Date(this.currentDate.getTime()), 1),
		)
	}

	/**
	 * Updates the boundary dates of the object and then emits a new period which is allowed within the new boundaries
	 * @param newMinDates
	 * @param newMaxDates
	 */
	public updateBoundaryDates(
		newMinDates: Map<PeriodType, Date>,
		newMaxDates: Map<PeriodType, Date>,
	) {
		this.minDates = newMinDates
		this.maxDates = newMaxDates
		this.currentDate = this.getDateWithinBoundaries(
			this.currentPeriodValue.getFirstDate(),
			this.periodType,
		)
		const newPeriod = new Period(this.periodType, this.currentDate)
		this.previousPeriod = new Period(
			this.periodType,
			this.dateCalc.subOfPeriod(this.periodType, 1, this.currentDate),
		)
		this.period.next(newPeriod)
	}

	public setNewPeriodType(newPeriodType: PeriodType) {
		if (this.periodType === newPeriodType) {
			return
		}
		this.periodType = newPeriodType
		if (newPeriodType !== PeriodType.Minute) {
			this.currentDate = this.getDateWithinBoundaries(
				this.currentDate,
				this.periodType,
			)
		} else {
			this.currentTime = this.getTimeWithinBoundaries(this.periodType)
		}

		this.previousPeriod = this.getPreviousPeriodDontMove()
		this.currentPeriodValue = this.getNewCurrentPeriod()
		this.period.next(this.currentPeriodValue)
	}

	public setNewPeriod(period: Period) {
		this.periodType = period.getPeriodType()
		this.currentDate = period.getFirstDate()
		this.previousPeriod = new Period(
			this.periodType,
			this.dateCalc.subOfPeriod(this.periodType, 1, this.currentDate),
		)

		this.currentPeriodValue = period
		this.period.next(this.currentPeriodValue)
	}

	private getPeriodMoveForwards() {
		this.currentDate = this.dateCalc.addOfPeriod(
			this.periodType,
			1,
			this.currentDate,
		)
		this.currentPeriodValue = new Period(this.periodType, this.currentDate)
		return this.currentPeriodValue
	}

	// always call before getNewPrevious or getNewNext
	private getNewCurrentPeriod() {
		return new Period(this.periodType, this.currentDate)
	}

	private getPeriodMoveBackwards() {
		this.currentDate = this.dateCalc.subOfPeriod(
			this.periodType,
			1,
			this.currentDate,
		)
		this.currentPeriodValue = new Period(this.periodType, this.currentDate)
		return this.currentPeriodValue
	}

	private getPreviousPeriodDontMove() {
		let dateClone: Date
		if (this.periodType !== PeriodType.Minute) {
			dateClone = new Date(this.currentDate.getTime())
		} else {
			dateClone = new Date(this.currentTime.getTime())
		}
		dateClone = this.dateCalc.subOfPeriod(this.periodType, 1, dateClone)
		return new Period(this.periodType, dateClone)
	}

	public getCurrentPeriodValue(): Period {
		return this.currentPeriodValue
	}

	public getPeriod(): BehaviorSubject<Period> {
		return this.period
	}

	public getPreviousPeriod(): Period {
		return this.previousPeriod
	}

	public goToNextPeriod() {
		const newPeriod = this.getPeriodMoveForwards()
		this.previousPeriod = this.getPreviousPeriodDontMove()
		this.period.next(newPeriod)
	}

	public goToPreviousPeriod() {
		const newPeriod = this.getPeriodMoveBackwards()
		this.previousPeriod = this.getPreviousPeriodDontMove()
		this.period.next(newPeriod)
	}

	/**
	 * uses the class' min and maxdate maps in order to see that we're compliant with them.
	 * if we are compliant, return the date that's passed in. if we're not compliant, return either the
	 * max or min date so that we're on the edge of compliancy.
	 * Also, if the first date of the to-be period would be after the current date, we set it back.
	 *
	 * @param date the date to check if its within boundaries
	 * @param periodType the periodtype we're using to look within
	 */
	private getDateWithinBoundaries(date: Date, periodType: PeriodType): Date {
		const tempPeriod = new Period(periodType, date)
		date = new Date()
		const minDate = this.minDates.get(periodType)
		if (minDate) {
			if (tempPeriod.getLastDate() < minDate) {
				return minDate
			}
		}

		const maxDate = this.maxDates.get(periodType)
		if (maxDate) {
			if (tempPeriod.getFirstDate() > maxDate) {
				return maxDate
			}
		}

		return date
	}

	/**
	 * In case we need to use the period min and maxtime maps in order to see that we're compliant with them.
	 * No bugs found yet so just returns the current time
	 * @param time the time to check if its within boundaries
	 * @param periodType the periodtype we're using to look within
	 */
	private getTimeWithinBoundaries(periodType: PeriodType): Date {
		const time = new Date()
		return time
	}
}
