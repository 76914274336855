import { Component, OnInit, EventEmitter, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
	NotificationData,
	NotificationHandlerService,
} from '@eliq/feature/notifications'
import { EliqDatePipe } from '@eliq/core/pipes/date.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui'
import { NotificationItemComponent } from '@eliq/feature/notifications'
import { NgFor } from '@angular/common'

@Component({
	selector: 'eliq-notifications-popper',
	templateUrl: './notifications-popper.component.html',
	styleUrls: ['./notifications-popper.component.css'],
	standalone: true,
	imports: [
		NgFor,
		NotificationItemComponent,
		BasicButtonComponent,
		TranslateModule,
		EliqDatePipe,
	],
})
export class NotificationsPopperComponent implements OnInit {
	@Output() showAllClickEmitter = new EventEmitter<any>()
	public notifications?: NotificationData[]
	public nShowingNotifications = 3
	public nTotalNotifications?: number

	constructor(
		private notificationHandler: NotificationHandlerService,
		private router: Router,
	) {}

	ngOnInit() {
		this.notificationHandler.notifications.subscribe((notifications) => {
			if (notifications) {
				// null check
				this.notifications = notifications.slice(0, this.nShowingNotifications)
				this.nTotalNotifications = notifications.length
				if (this.nShowingNotifications > this.nTotalNotifications) {
					// showing notifications is first set as 3, but what if there only is 1 notification, for example?
					// then we set showing notifications to be equal to total notifications.
					this.nShowingNotifications = this.nTotalNotifications
				}
			}
		})
	}

	showAllClicked() {
		this.showAllClickEmitter.emit()
		this.router.navigate(['/notifications'])
	}
}
