<div
	style="height: 100%; display: flex; justify-content: center; align-items: center;
width: 100%; max-height: calc(100vh - 90px); flex-direction: column;"
>
	<div *ngIf="loading">
		<eliq-spinner size="medium"></eliq-spinner>
	</div>
	<div *ngIf="ticketState !== null && !loading">
		<div *ngIf="ticketState === TicketValidationState.invalid">
			<div class="ticket-info-box">
				<h1 translate>validate_ticket.incorrect_url</h1>
				<p translate>validate_ticket.incorrect_url_desc</p>
			</div>
		</div>

		<div
			*ngIf="ticketState === TicketValidationState.expired || ticketState === TicketValidationState.used"
		>
			<div class="ticket-info-box">
				<h1 translate>validate_ticket.link_expired</h1>
				<p translate>validate_ticket.for_security_reasons_inctive</p>
				<p translate>validate_ticket.please_go_back_to_the_app</p>
			</div>
		</div>

		<div
			*ngIf="ticketState === TicketValidationState.completed && (!type || type==='magic_link_login' || type === 'set_email')"
		>
			<div class="ticket-info-box">
				<h1 translate>validate_ticket.email_address_verified</h1>
				<p translate>validate_ticket.you_can_now_return_to_the_app</p>
			</div>
		</div>
	</div>
	<div
		class="ticket-info-box"
		*ngIf="ticketState === null && !loading && buttonToVerifyTicket"
	>
		<h2 class="primary-h1" style="margin: 0rem auto;" translate>
			sign_in_verify_magic_link.verify_title
		</h2>
		<p class="primary-p1" translate>sign_in_verify_magic_link.verify_text</p>

		<eliq-basic-button
			[buttonStyle]="{margin: '0.5rem auto'}"
			[submit]="false"
			(click)="verifyTicketClicked()"
			[label]="'sign_in_verify_magic_link.verify_button'"
			[useEntireWidth]="false"
			*ngIf="!loading && buttonToVerifyTicket"
		></eliq-basic-button>
	</div>
</div>
