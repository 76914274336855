<div class="container">
	<div class="verificationArea">
		<p
			class="primary-p2"
			[innerHTML]="'sign_in_verify_magic_link.please_open_email_inbox' | translate: {'email': '<b>'+userEmail+'</b>'}"
		></p>
		<p class="primary-p2" translate>
			sign_in_verify_magic_link.link_expires_in_30_min
		</p>
		<img
			class="emailIcon"
			[src]="'/assets/images/Icon_email.svg' | imgSrc"
			alt=""
		/>
	</div>
	<div class="footer">
		<eliq-link
			label="sign_in_verify_magic_link.having_trouble_logging_in"
			(clicked)="havingTroubleClicked.emit()"
		></eliq-link>
	</div>
</div>
