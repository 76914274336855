<div class="container">
	<div class="inner-max-w-container">
		<h6 class="header primary-h6">
			{{'user_consents.' + consents[index].name + '_title_first_time' }}
		</h6>
		<p class="description secondary-p2">
			{{'user_consents.' + consents[index].name + '_description_first_time' }}
		</p>
		<div class="learn-more-container">
			<eliq-link
				label="high_resolution_insights_data.learn_more"
				(clicked)="learnMoreClicked()"
			></eliq-link>
		</div>

		<div class="consent-buttons">
			<eliq-basic-button
				[variant]="'primary'"
				(clicked)="yesClicked()"
				label="high_resolution_insights_data.accept"
				[useEntireWidth]="true"
				[loading]="loading"
			></eliq-basic-button>

			<div style="margin-top: 12px;">
				<eliq-basic-button
					[variant]="'secondary'"
					(clicked)="noClicked()"
					label="high_resolution_insights_data.decline"
					[useEntireWidth]="true"
					[disabled]="loading"
				></eliq-basic-button>
			</div>
		</div>

		<!--<p class="additional-info-bottom secondary-p3" translate>high_resolution_insights_data.additional_info</p>-->
	</div>
</div>
