<div class="icons">
	<eliq-property-icon
		[imgPath]="'/assets/images/' + property.$key + '.svg' | imgSrc"
		[altText]="property.$key"
	></eliq-property-icon>
</div>
<p class="question secondary-p2">
	{{'home_profile_prompt.question_' + property.$key | translate}}
</p>
<!--<p class="subtext" translate>{{'settings_location_properties.'+title}}</p>-->
<div class="valueArea">
	<eliq-number-spinner-compact
		class="secondary-p2"
		[value]="property.getValue()"
		[min]="property.$limits.min"
		[max]="property.$limits.max"
		(valueChange)="propertyValChange($event)"
	></eliq-number-spinner-compact>
</div>
