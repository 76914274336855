<div class="container">
	<h6 class="header primary-h6">
		{{'contract_renewal_home_card.renew_your_contract' | translate}}
	</h6>
	<span class="days-left contract-renewal-home-card-days-left">
		{{'contract_renewal_home_card.days_left' | translate: {'days': expiryDate | daysLeft} }}
	</span>
	<p class="secondary-p1 description">
		{{'contract_renewal_home_card.there_are_new_offers_available' | translate: {'days': expiryDate | daysLeft} }}
	</p>
	<a
		routerLink="/account"
		[queryParams]="{setting: 'my_agreements', renew: agreementId}"
	>
		<eliq-link
			[label]="'contract_renewal_home_card.show_offers' | translate"
		></eliq-link>
	</a>
</div>
