import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { InlineSVGModule } from 'ng-inline-svg-2'

@Component({
	selector: 'eliq-info-button',
	templateUrl: './info-button.component.html',
	styleUrls: ['./info-button.component.scss'],
	standalone: true,
	imports: [ImgSrcPipe, InlineSVGModule],
})
export class InfoButtonComponent {
	@Output() clicked = new EventEmitter()
	@Input() name = "Default Name"
}
