import { Injectable } from '@angular/core'
import { EliqApiHttpClient, JsonGetterService } from '@eliq/data-access'
import {
	ConsumptionHandlerService,
	EliqNumberPipe,
	PeriodType,
	UnitType,
} from '@eliq/core'

//import { Location } from '@eliq/models'

import { Period, Location } from '@eliq/core'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { APIBudget } from '../models/api-budget.model'
import { ConfigBudget } from '@eliq/data-access/services/config/models/config-budget.model'

@Injectable({
	providedIn: 'root',
})
export class BudgetApiService {
	constructor(
		private jsonGetter: JsonGetterService,
		private http: EliqApiHttpClient,
		private consHandler: ConsumptionHandlerService,
		private numberPipe: EliqNumberPipe,
	) {}

	getBudgets(locId: number, unit: string) {
		return this.http.get<APIBudget[]>(
			`/v3/locations/${locId}/budgets?unit=${unit}`,
		)
	}

	getSuggestedLimitString(budget: APIBudget): string {
		let limit = budget.suggested_limit
		if (budget.unit === 'energy') {
			limit /= 1000
		}

		return this.numberPipe.transform(limit, budget.unit)
	}

	getUnitToUse(location: Location | undefined): Observable<UnitType> {
		//const possibleUnits = ['energy', 'cost']

		return this.jsonGetter.getBudgetConfig().pipe(
			take(1),
			map<ConfigBudget | undefined, UnitType>(
				(budgetConfig: ConfigBudget | undefined) => {
					if (!budgetConfig?.defaultUnit) {
						return 'cost'
					}

					return budgetConfig.defaultUnit
				},
			),
			switchMap<UnitType, Observable<UnitType>>(
				(configUnit: UnitType): Observable<UnitType> => {
					if (!location || configUnit === 'energy') {
						return of('energy')
					}

					const unit =
						configUnit === 'cost' &&
						location.fuels.some((f) => {
							return !!f?.consumption?.data_to
						})
							? 'cost'
							: 'energy'

					if (unit !== 'cost') {
						return of(unit)
					}

					return this.getBudgets(location.id, configUnit).pipe(
						take(1),
						map((budgets) =>
							budgets[0].suggested_limit
								? ('cost' as const)
								: ('energy' as const),
						),
						catchError(() => of('energy' as const)),
					)
				},
			),
			map((configUnit) => {
				if (!location || configUnit === 'energy') {
					return 'energy' as const
				}
				return location?.fuels.some((f) => {
					return !!f?.consumption?.data_to
				})
					? ('cost' as const)
					: ('energy' as const)
			}),
			tap((unit) => {}),
		)

		//return of(possibleUnits).pipe()
		//return of('cost')
		/*	return this.jsonGetter.getBudgetConfig().pipe(
			map((budgetConfig: any) => {
				let defaultUnit: UnitType = 'cost' as UnitType

				if (budgetConfig?.defaultUnit) {
					defaultUnit = budgetConfig.defaultUnit as UnitType
				}

				if (defaultUnit === 'cost') {
					return budgetConfig.suggested_limit ? 'cost' : 'energy'
				} else {
					return defaultUnit as unknown as UnitType
				}
			}),
			catchError(() => of('energy')),
		)*/
	}

	getValueUnitSymbol(budgets: APIBudget[]): string {
		return budgets[0].unit === 'energy'
			? 'kWh' // energy
			: budgets[0].unit === 'cost'
			? this.numberPipe._getCurrencySymbol('narrow') // cost
			: 'm³' // m3
	}

	createBudget(locId: number, budget: APIBudget, selectedLimit: number) {
		return this.http.put(
			`/v3/locations/${locId}/budgets?fuel=${budget.fuel}&unit=${budget.unit}&resolution=${budget.resolution}`,
			{
				enabled: true,
				limit: selectedLimit,
			},
		)
	}

	editBudget(locId: number, budget: APIBudget, selectedLimit: number) {
		return this.createBudget(locId, budget, selectedLimit)
	}

	deleteBudget(locId: number, budget: APIBudget) {
		return this.http.put(
			`/v3/locations/${locId}/budgets?fuel=${budget.fuel}&unit=${budget.unit}&resolution=${budget.resolution}`,
			{
				enabled: false,
			},
		)
	}

	getConsumptionAndForecastForBudget(
		locId: number,
		budget: APIBudget,
		fromDate: Date,
		toDate: Date,
	): Observable<{ cons: number[]; fore: number[] }> {
		return forkJoin({
			cons: this.getConsumptionForBudget(locId, budget, fromDate, toDate)
				.pipe(
					take(1),
					map((num) => num ?? ([] as number[])),
				)
				.pipe(
					catchError((err) => {
						const tempPeriod = new Period(
							<PeriodType>budget.resolution,
							fromDate,
						)
						return of(tempPeriod.getDates().map((date) => 0))
					}),
				),
			fore: this.getForecastForBudget(locId, budget, fromDate, toDate).pipe(
				take(1),
				map((num) => num ?? ([] as number[])),
			),
		})
	}

	getForecastForBudget(locId: number, budget: APIBudget, from: Date, to: Date) {
		return this.consHandler
			.getForecastForFuels(locId, [budget.fuel], budget.unit, 'day', from, to)
			.pipe(map((res) => res.get(budget.fuel)))
	}

	getConsumptionForBudget(
		locId: number,
		budget: APIBudget,
		from: Date,
		to: Date,
	) {
		return this.consHandler
			.getConsumptionForFuels(
				locId,
				[budget.fuel],
				budget.unit,
				'day',
				from,
				to,
			)
			.pipe(map((res) => res.get(budget.fuel)))
	}
}
