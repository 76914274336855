import { Component, Inject, OnInit } from '@angular/core'
import { AuthService, LoginService } from '@eliq/feature/auth'
import {
	CoreDataStoreService,
	HomeProfile,
	LocationHttpService,
	Property,
	UserHttpService,
	PropertyGroup,
	APIConsent,
} from '@eliq/core'
import { ConsentService } from '@eliq/feature/consents/consent.service'

import { HeaderConfigService } from '@eliq/core/config'
import { HomedetailsSetupModalContainerComponent } from '@eliq/feature/homeprofile'
import { ModalService } from '@eliq/ui/modal'
import {
	NotificationData,
	NotificationHandlerService,
} from '@eliq/feature/notifications'
import { SnackbarService } from '@eliq/ui/snackbar'
import { forkJoin, of } from 'rxjs'
import { catchError, switchMap, take, tap } from 'rxjs/operators'
import { EnvironmentService } from '@eliq/data-access'
import { AnnualCardAddModalComponent } from '@eliq/ui/layout/home/cards/annual-card/annual-card-add-modal/annual-card-add-modal.component'
import { AnnualCardSuccessModalComponent } from '@eliq/ui/layout/home/cards/annual-card/annual-card-success-modal/annual-card-success-modal.component'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-testing-helper',
	templateUrl: './testing-helper.component.html',
	styleUrls: ['./testing-helper.component.css'],
	standalone: true,
	imports: [NgIf],
})
export class TestingHelperComponent implements OnInit {
	public localStorageAuth: boolean

	constructor(
		private env: EnvironmentService,
		private coreds: CoreDataStoreService,
		private locHttp: LocationHttpService,
		private notificationHandler: NotificationHandlerService,
		private userHttp: UserHttpService,
		private consentsService: ConsentService,
		private snackbar: SnackbarService,
		private modal: ModalService,
		private auth: AuthService,
		private login: LoginService,
		private headerConfig: HeaderConfigService,
		@Inject('LOCAL_STORAGE_AUTH') localStorageAuth: boolean,
	) {
		this.localStorageAuth = localStorageAuth
	}

	private props!: Property[]
	private propGroups!: PropertyGroup[]
	private locid!: number
	private userId!: number | null
	public nots!: NotificationData[]
	private consents!: APIConsent[]

	// TODO Handle no location
	ngOnInit(): void {
		this.coreds.getActiveLocation().subscribe((loc) => {
			this.locid = loc?.id as number
			forkJoin({
				hpReq: this.locHttp.getLocationHomeProfile(loc?.id as number),
			}).subscribe(({ hpReq }) => {
				const hp = new HomeProfile(this.env, hpReq)
				this.props = hp.$properties
				this.propGroups = hp.$propertyGroups
			})
		})

		this.consentsService
			.getConsents()
			.subscribe((consents) => (this.consents = consents))

		this.coreds.user.subscribe((user) => {
			this.userId = user.id

			this.headerConfig.getShowNotifications().pipe(
				take(1),
				tap((showNots) => {
					if (showNots) {
						this.notificationHandler.notifications.subscribe((res) => {
							this.nots = res
						})
					}
				}),
			)
		})
	}

	resetProperties() {
		this.locHttp
			.resetProperties(this.locid, this.props, this.propGroups)
			.subscribe(
				(res) => {
					this.snackbar.doTextToast('Reset homeprofile 😀🏡')
				},
				(err) => {
					this.snackbar.doTextToast('Failed to reset homeprofile 😥')
				},
			)
	}

	resetTerms() {
		this.userHttp.patchUserTerms(this.userId, true).subscribe(
			(res) => {
				this.snackbar.doTextToast('Reset terms! 📗😀')
			},
			(err) => {
				this.snackbar.doTextToast('Failed to reset terms. What happened?! 😥')
			},
		)
	}

	unreadNotifications() {
		this.notificationHandler
			.patchNotificationReads(this.userId, this.nots.slice(0, 6), false)
			.subscribe(
				(res) => {
					this.snackbar.doTextToast('6 notifications now unread 😎🔔')
				},
				(err) => {
					this.snackbar.doTextToast(
						'Failed to mark notifications as unread. Must be a backend thing 😜',
					)
				},
			)
	}

	removeConsents() {
		const changedConsents: APIConsent[] = this.consents.map((consent) => {
			consent.given_consent = false
			return consent
		})

		this.consentsService.putConsents(changedConsents).subscribe(
			(res) => {
				this.snackbar.doTextToast(
					'You no longer consent to... well, anything 🤷 Why are you even here?',
				)
			},
			(err) => {
				this.snackbar.doTextToast('Failed to remove consents. Hmm... 🕵️‍♂️')
			},
		)
	}

	tryHomeProfileSetupWizard() {
		const dialogRef = this.modal.openModal(
			HomedetailsSetupModalContainerComponent,
		)
		const instance = <HomedetailsSetupModalContainerComponent>(
			dialogRef.componentInstance
		)
		instance.propertyGroups = this.propGroups
		instance.properties = this.props
		instance.locationId = this.locid
	}

	invalidateSession() {
		this.auth.invalidateSession$().subscribe()
		this.snackbar.doTextToast('Seems to have worked.')
	}

	refreshCookieAuthTokens() {
		this.auth
			.refreshAccessToken()
			.pipe(
				take(1),
				tap(() => this.snackbar.doTextToast('Seems to have worked.')),
				catchError(() => {
					this.snackbar.doTextToast('Seems to have went wrong.')
					return of(undefined)
				}),
			)
			.subscribe()
	}

	invalidateCookieAccessToken() {
		this.auth
			.disableCookieAccessToken(this.userId)
			.pipe(
				take(1),
				tap(() => this.snackbar.doTextToast('Seems to have worked.')),
				catchError(() => {
					this.snackbar.doTextToast('Seems to have went wrong.')
					return of(undefined)
				}),
			)
			.subscribe()
	}

	invalidateCookieTokens() {
		this.auth
			.disableCookiesBeforeALogout(this.userId)
			.pipe(
				take(1),
				tap(() => this.snackbar.doTextToast('Seems to have worked.')),
				catchError(() => {
					this.snackbar.doTextToast('Seems to have went wrong.')
					return of(undefined)
				}),
			)
			.subscribe()
	}

	setReasonableHomeProfile() {
		const wantedSettings: Map<string, any> = new Map()
		wantedSettings.set('house_type', 'house')
		wantedSettings.set('living_area', 150)
		wantedSettings.set('persons', 3)
		wantedSettings.set('heating_type_primary', 'district_heating')

		const propsToSet = this.props.filter((prop) =>
			wantedSettings.has(prop.$key),
		)
		propsToSet.forEach((p) => (p.$placeholder = wantedSettings.get(p.$key)))

		this.locHttp
			.resetProperties(this.locid, this.props, this.propGroups)
			.pipe(
				switchMap(() => {
					return this.locHttp.patchProperties(this.locid, propsToSet)
				}),
				take(1),
			)
			.subscribe(
				(res) => {
					this.snackbar.doTextToast(
						'Your home profile is now very reasonable 🧐👌',
					)
				},
				(err) => {
					this.snackbar.doTextToast('Oops! Something happened... 🤬🤕')
				},
			)
	}

	setAllowPVData(allowed: boolean) {
		localStorage.setItem('PV_DATA_ALLOWED', allowed ? 'ALLOWED' : 'NOT_ALLOWED')
		sessionStorage.clear()
		if (allowed) {
			this.snackbar.doTextToast(
				'🌞 A wild solar cell apeared. Please refresh the page.',
			)
		} else {
			this.snackbar.doTextToast('☁️ No more PV data. Please refresh the page.')
		}
	}

	addAnnualElectricity() {
		const dialogRef = this.modal.openModal(AnnualCardAddModalComponent)
		const instance = <AnnualCardAddModalComponent>dialogRef.componentInstance
		const lastYear = new Date().getFullYear() - 1
		instance.lastYear = lastYear
		instance.locId = this.locid
		let greyBorder = true
		if (this.env.isKBC()) {
			greyBorder = false
		}
		instance.greyBorder = greyBorder

		instance.energy.subscribe((value) => {
			if (value > -1) {
				const dialogRef = this.modal.openModal(AnnualCardSuccessModalComponent)
				const instance = <AnnualCardSuccessModalComponent>(
					dialogRef.componentInstance
				)
				instance.lastYear = lastYear
				instance.greyBorder = greyBorder
				instance.estimatedEnergy = value
			}
		})
	}

	removePreviousYearAnnualElectricity() {
		this.locHttp.deletePreviousYearEnergy(this.locid).subscribe(
			(res) => {
				this.snackbar.doTextToast(
					`Reset energy for year: ${
						new Date().getFullYear() - 1
					} 😀⚡. Refresh please`,
				)
			},
			(err) => {
				this.snackbar.doTextToast('Oops! Something happened... 🤬🤕')
			},
		)
	}
}
