<div class="container" *ngIf="!showViewHistory">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<div class="inline">
		<h6 class="primary-h6 header">
			{{ titleKey | translate }}
		</h6>
		<eliq-info-button
			(clicked)="infoButtonClicked()"
			class="info-icon"
		></eliq-info-button>
	</div>
	<eliq-smr-submit-modal
		(titleChanged)="setTitleKey($event)"
		[meter]="data && data.meter"
		[meters]="data && data.meters"
	></eliq-smr-submit-modal>
	<div class="button">
		<eliq-link
			(clicked)="viewHistoryButtonClicked(true)"
			label="{{ 'submit_meter_read.view_read_history' | translate }}"
		></eliq-link>
	</div>
</div>
<!--
<ng-container *ngIf="showViewHistory">
	<eliq-view-history-modal-container
		[meter]="data &&  data.meter"
		[meters]="data &&  data.meters"
	></eliq-view-history-modal-container>
</ng-container> -->
