// @ts-nocheck

import {
	APIMonitor,
	APIMonitorStatus,
	APIAnomalyStatusCustom,
} from './api-models/api-monitor.model'

export class MonitorData {
	private id: number
	private active: boolean
	private creator: string
	private measuringType: string
	private limitUpper: number
	private limitLower: number
	private unit: string
	private intervalPeriod: string
	private intervalTicks: number
	private sourceType: string
	private sourceIsForecast: boolean
	private status: MonitorStatusData
	private consumption: number[]

	constructor(apiMonitor: APIMonitor) {
		this.id = apiMonitor.id
		this.active = apiMonitor.active
		this.creator = apiMonitor.creator
		this.measuringType = apiMonitor.measuring_type
		this.limitUpper = apiMonitor.limit_upper
		this.limitLower = apiMonitor.limit_lower
		this.unit = apiMonitor.unit
		this.intervalPeriod = apiMonitor.interval_period
		this.intervalTicks = apiMonitor.interval_ticks
		this.sourceType = apiMonitor.source_fuel
		this.sourceIsForecast = apiMonitor.source_is_forecast
		this.status = new MonitorStatusData(apiMonitor.status)
	}

	public setConsumption(consumption: number[]) {
		this.consumption = consumption
	}

	public getConsumption(): number[] {
		return this.consumption
	}

	/**
	 * Getter $id
	 * @return {number}
	 */
	public get $id(): number {
		return this.id
	}

	/**
	 * Getter $active
	 * @return {boolean}
	 */
	public get $active(): boolean {
		return this.active
	}

	/**
	 * Getter $creator
	 * @return {string}
	 */
	public get $creator(): string {
		return this.creator
	}

	/**
	 * Getter $measuringType
	 * @return {string}
	 */
	public get $measuringType(): string {
		return this.measuringType
	}

	/**
	 * Getter $limitUpper
	 * @return {number}
	 */
	public get $limitUpper(): number {
		return this.limitUpper
	}

	/**
	 * Getter $limitLower
	 * @return {number}
	 */
	public get $limitLower(): number {
		return this.limitLower
	}

	/**
	 * Getter $unit
	 * @return {string}
	 */
	public get $unit(): string {
		return this.unit
	}

	/**
	 * Getter $intervalPeriod
	 * @return {string}
	 */
	public get $intervalPeriod(): string {
		return this.intervalPeriod
	}

	/**
	 * Getter $intervalTicks
	 * @return {number}
	 */
	public get $intervalTicks(): number {
		return this.intervalTicks
	}

	/**
	 * Getter $sourceType
	 * @return {string}
	 */
	public get $sourceType(): string {
		return this.sourceType
	}

	/**
	 * Getter $sourceIsForecast
	 * @return {boolean}
	 */
	public get $sourceIsForecast(): boolean {
		return this.sourceIsForecast
	}

	/**
	 * Getter $status
	 * @return {MonitorStatusData}
	 */
	public get $status(): MonitorStatusData {
		return this.status
	}

	/**
	 * Setter $id
	 * @param {number} value
	 */
	public set $id(value: number) {
		this.id = value
	}

	/**
	 * Setter $active
	 * @param {boolean} value
	 */
	public set $active(value: boolean) {
		this.active = value
	}

	/**
	 * Setter $creator
	 * @param {string} value
	 */
	public set $creator(value: string) {
		this.creator = value
	}

	/**
	 * Setter $measuringType
	 * @param {string} value
	 */
	public set $measuringType(value: string) {
		this.measuringType = value
	}

	/**
	 * Setter $limitUpper
	 * @param {number} value
	 */
	public set $limitUpper(value: number) {
		this.limitUpper = value
	}

	/**
	 * Setter $limitLower
	 * @param {number} value
	 */
	public set $limitLower(value: number) {
		this.limitLower = value
	}

	/**
	 * Setter $unit
	 * @param {string} value
	 */
	public set $unit(value: string) {
		this.unit = value
	}

	/**
	 * Setter $intervalPeriod
	 * @param {string} value
	 */
	public set $intervalPeriod(value: string) {
		this.intervalPeriod = value
	}

	/**
	 * Setter $intervalTicks
	 * @param {number} value
	 */
	public set $intervalTicks(value: number) {
		this.intervalTicks = value
	}

	/**
	 * Setter $sourceType
	 * @param {string} value
	 */
	public set $sourceType(value: string) {
		this.sourceType = value
	}

	/**
	 * Setter $sourceIsForecast
	 * @param {boolean} value
	 */
	public set $sourceIsForecast(value: boolean) {
		this.sourceIsForecast = value
	}

	/**
	 * Setter $status
	 * @param {MonitorStatusData} value
	 */
	public set $status(value: MonitorStatusData) {
		this.status = value
	}
}

export class MonitorStatusData {
	private triggered: boolean
	private checked: string
	private value: number
	private custom: AnomalyStatusCustom

	constructor(apiMonitorStatus: APIMonitorStatus) {
		this.triggered = apiMonitorStatus.triggered
		this.checked = apiMonitorStatus.checked
		this.value = parseInt(apiMonitorStatus.value)
		this.custom = apiMonitorStatus.custom
			? new AnomalyStatusCustom(apiMonitorStatus.custom)
			: undefined
	}

	/**
	 * Getter $triggered
	 * @return {boolean}
	 */
	public get $triggered(): boolean {
		return this.triggered
	}

	/**
	 * Getter $checked
	 * @return {string}
	 */
	public get $checked(): string {
		return this.checked
	}

	/**
	 * Getter $value
	 * @return {number}
	 */
	public get $value(): number {
		return this.value
	}

	/**
	 * Getter $custom
	 * @return {AnomalyStatusCustom}
	 */
	public get $custom(): AnomalyStatusCustom {
		return this.custom
	}

	/**
	 * Setter $triggered
	 * @param {boolean} value
	 */
	public set $triggered(value: boolean) {
		this.triggered = value
	}

	/**
	 * Setter $checked
	 * @param {string} value
	 */
	public set $checked(value: string) {
		this.checked = value
	}

	/**
	 * Setter $value
	 * @param {number} value
	 */
	public set $value(value: number) {
		this.value = value
	}

	/**
	 * Setter $custom
	 * @param {AnomalyStatusCustom} value
	 */
	public set $custom(value: AnomalyStatusCustom) {
		this.custom = value
	}
}

export class AnomalyStatusCustom {
	private status: string
	private weight: number

	constructor(apiAnomaly: APIAnomalyStatusCustom) {
		if (apiAnomaly) {
			this.status = apiAnomaly.status
			this.weight = apiAnomaly.weight
		} else {
			this.status = undefined
			this.weight = undefined
		}
	}

	/**
	 * Getter $status
	 * @return {string}
	 */
	public get $status(): string {
		return this.status
	}

	/**
	 * Getter $weight
	 * @return {number}
	 */
	public get $weight(): number {
		return this.weight
	}

	/**
	 * Setter $status
	 * @param {string} value
	 */
	public set $status(value: string) {
		this.status = value
	}

	/**
	 * Setter $weight
	 * @param {number} value
	 */
	public set $weight(value: number) {
		this.weight = value
	}
}
