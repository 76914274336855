<div class="icons">
	<eliq-property-icon
		[imgPath]="'/assets/images/' +propertyGroup.$type+ '.svg' | imgSrc"
		[altText]="'settings_location_properties.'+propertyGroup.$type | translate"
	></eliq-property-icon>
</div>
<p class="question secondary-p2">
	{{'home_profile_prompt.question_' + propertyGroup.$type | translate}}
</p>
<table>
	<tbody>
		<tr class="my-tr" *ngFor="let property of propertyGroup.$properties">
			<!--<td>
                <mat-checkbox [disableRipple]="true" [checked]="property.getCurrentSelection() !== undefined"
                    (change)="checkChange(property, $event.checked)"></mat-checkbox>
            </td>-->
			<td class="middle-text primary-p2">
				{{'home_profile_options.' + property.$key | translate}}
			</td>
			<td>
				<eliq-number-spinner-compact
					class="secondary-p2"
					[value]="property.$value"
					[min]="property.$limits.min"
					[max]="property.$limits.max"
					(valueChange)="propertyValChange(property, $event)"
				></eliq-number-spinner-compact>
			</td>
		</tr>
	</tbody>
</table>
