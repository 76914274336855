import {
	AfterViewInit,
	Component,
	Directive,
	OnInit,
	ViewChild,
	ViewContainerRef,
	inject,
} from '@angular/core'
import {
	ActivatedRoute,
	NavigationEnd,
	Router,
	RouterOutlet,
} from '@angular/router'
import { AuthService } from '@eliq/feature/auth'
import {
	CoreDataStoreService,
	JsonGetterService,
	LanguageService,
	Location,
} from '@eliq/core'
import {
	Category,
	HeaderComponent,
	HeaderHelperService,
	HeaderObject,
} from '@eliq/feature/header'
import { ModalService } from '@eliq/ui/modal'
import { EliqTicketResultEvent, EliqTrackingService } from '@eliq/core/tracking'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { forkJoin, Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { EmbeddedService } from '@eliq/feature/embedded/embedded.service'
import { EnvironmentService } from '@eliq/data-access'
import { AsyncPipe, NgIf } from '@angular/common'
import {
	FooterComponent,
	JerseyFooterComponent,
	JerseyB2BFooterComponent,
} from '@eliq/ui/layout'
import { SpinnerComponent } from '@eliq/ui'
import { IlRegisterEmailBannerComponent } from '@eliq/feature/integrationless/il-register-email-banner/il-register-email-banner.component'
import { OnscreenConsoleContainerComponent } from '@eliq/feature/onscreen-console/onscreen-console-container/onscreen-console-container.component'
import { FullpageLinearLoadingBarComponent } from '@eliq/ui/common'
import { supportsBudget } from '@eliq/core/models/src/Location.utils'
@Component({
	selector: 'eliq-app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [
		AsyncPipe,
		NgIf,
		SpinnerComponent,
		HeaderComponent,
		TranslateModule,
		FooterComponent,
		JerseyFooterComponent,
		JerseyB2BFooterComponent,
		RouterOutlet,
		FullpageLinearLoadingBarComponent,
		IlRegisterEmailBannerComponent,
		OnscreenConsoleContainerComponent,
	],
})
export class AppComponent implements OnInit {
	public enableHiddenConsole = false //this.env.getFeatureFlag('onscreen-console')
	public _isLoadingDone = false
	public showHeader = false
	public showMainFooter = true
	public showMenuOptions = true
	public categories!: Category[]
	public headers!: HeaderObject[]
	public isIntegrationless = false

	public isServer = !this.env.isBrowser()
	public clientName = this.env.getClientName()

	public transLoaded = false
	public headerLoaded = false
	public notBrowser = !this.env.isBrowser()

	public locations!: Location[]
	public activeLocation!: Location

	private viewsWithoutHeader: string[] = [
		'/renewal*',
		'/chat*',
		'/',
		'/login*',
		'/flow*',
		'/paypoint-ticket*',
	]

	private viewsWithoutFooter: string[] = [
		'/tickets*',
		'/renewal*',
		'/chat*',
		'/flow*',
		'/',
		'/login*',
		'/paypoint-ticket*',
	]

	private viewsWithoutMenuOptions: string[] = ['/tickets*']

	constructor(
		public env: EnvironmentService,
		private languageService: LanguageService,
		private translate: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private headerHelper: HeaderHelperService,
		private config: JsonGetterService,
		private coreds: CoreDataStoreService,
		private modalService: ModalService,
		private authService: AuthService,
		private tracking: EliqTrackingService,
		public embeddedService: EmbeddedService,
	) {
		this.transLoaded = Object.keys(this.translate.translations).length > 0

		this.translate.onLangChange.subscribe((res) => {
			this.transLoaded = Object.keys(this.translate.translations).length > 0
		})

		this.isIntegrationless = this.env?.integrationless?.enabled || false
	}

	ngOnInit(): void {
		// Write git revision tag to console
		console.log('GIT_REVISION_TAG:', this.env.gitRevisionTag())

		// Sending logs to https://console.re/eliqwebclient
		// TODO self host console.re so this works, currently it only works in the local development server
		// because of their restrictions on the API. This will be immensely useful to debug bugs that occur in the apps.
		// It's possible that there's some alternative to console.re that's better because I haven't checked so much,
		// but we definitively need something like this.

		/*;(window as any).consolere = {
			channel: 'eliqerror',
			api: '/assets/console.js',
			ready: function (c) {
				const d = document,
					s = d.createElement('script')
				let l = false
				s.src = this.api
				s.id = 'consolerescript'
				;(s as any).onreadystatechange = s.onload = function () {
					if (!l) {
						c()
					}
					l = true
				}
				d.getElementsByTagName('head')[0].appendChild(s)
			},
		}
		;(window as any).consolere.ready(() => {
			;(window as any).console.re.log('remote log test')
			window?.console.log = (window?.console as any).re.log
			window?.console.error = (window?.console as any).re.error
		})*/

		if (
			typeof (window as any) !== 'undefined' &&
			window?.console &&
			this.env.isProd()
		) {
			;(window as any).console.log = () => {
				/**/
			}
			//;(window as any).console.warn = () => {
			/*
			 */
			//}
		}

		// Add postmessage listener
		this.embeddedService.addListener()

		this.route.queryParams.subscribe((newParams) => {
			this.embeddedService.newParams(newParams)
			if (
				Object.keys(newParams).includes('locale') &&
				newParams['locale']?.length > 0
			) {
				this.languageService.setCurrentLanguage(newParams['locale']).subscribe()
			}
		})

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.showHeader = !this.viewsWithoutHeader.some(
					(path) =>
						event.url === path ||
						(path.includes('*') && event.url.startsWith(path.replace('*', ''))),
				)

				this.showMainFooter = !this.viewsWithoutFooter.some(
					(path) =>
						event.url === path ||
						(path.includes('*') && event.url.startsWith(path.replace('*', ''))),
				)

				this.showMenuOptions = !this.viewsWithoutMenuOptions.some(
					(path) =>
						event.url === path ||
						(path.includes('*') && event.url.startsWith(path.replace('*', ''))),
				)

				if (this.showHeader && !this.headers) {
					forkJoin({
						headers: this.config.getHeaderItems().pipe(take(1)),
						cats: this.headerHelper.getCategories().pipe(take(1)),
						locations: this.coreds.locations.pipe(take(1)),
						activeLocation: this.coreds.getActiveLocation().pipe(take(1)),
					}).subscribe(
						(res) => {
							this.activeLocation = res.activeLocation as Location
							this.locations = res.locations

							this.headers = res.headers.map(
								(h) => new HeaderObject(h.link, h.title, h.icon),
							)

							const elecFuel = this.activeLocation.fuels.find(
								(fuel) => fuel.type === 'elec',
							)
							let hasProduction = false
							if (elecFuel) {
								hasProduction = !!elecFuel.production
							}

							if (
								!hasProduction ||
								localStorage.getItem('PV_DATA_ALLOWED') === 'NOT_ALLOWED'
							) {
								this.headers = this.headers.filter(
									(v) => v.title !== 'menu_options.pv',
								)
							}

							if (!supportsBudget(this.activeLocation)) {
								this.headers = this.headers.filter((v) => v.link !== 'budget')
							}

							this.categories = res.cats
							this.headerLoaded = true

							// now the initial setup is complete, let's start watching for
							// location changes that calls for us to update our locations variable after intitial config
							this.coreds.locations.subscribe((locs) => (this.locations = locs))
						},
						(err) => {
							this.headerLoaded = true
						},
					)

					// we also need to stay subscribed to the active location,
					// the above observable is only taken once and the removed.
					this.coreds.getActiveLocation().subscribe((res) => {
						if (this.activeLocation !== res) {
							this.activeLocation = res as Location
						}
					})
				}
				this.tracking.trackScreenOnRouterEvent(
					this.router.url,
					this.route.snapshot.queryParams,
				)
			}
		})
	}

	isLoggedIn(): boolean {
		return this.authService.isLoggedIn()
	}

	isLoggedInAndNotOnFlow(): boolean {
		return (
			this.isLoggedIn() &&
			!this.router.url.includes('/flow') &&
			this.router.url !== '/'
		)
	}

	logoutClicked() {
		this.modalService
			.openConfirmActionDialog(
				'sign_out_prompt.are_you_sure_you_want_to_logout',
				'sign_out_prompt.cancel',
				'sign_out_prompt.yes_logout',
			)
			.subscribe((result) => {
				if (result) {
					this.authService.logout()
				} else {
					// doesnt wanna logout so do nothing
				}
			})
	}
}
