<div
	class="signin-background"
	[class.signin-background-image]="!signinCardsShouldExpand"
	id="signin-card-123"
	(window:resize)="windowResized($event)"
>
	<eliq-company-logo
		[link]="'/'"
		*ngIf="expandLogin"
		padding="0"
		height="100%"
		type="contrast"
		class="logo-top-left"
	></eliq-company-logo>

	<ng-container *ngIf="loginTypes && loginState === 'logging_in'">
		<eliq-signin-magiclink
			class="signin-card-sizing"
			*ngIf="currentLoginType.type === 'magiclink'"
			(logInDone)="logInDone()"
			[signUpUrl]="signUpUrl"
		>
			<eliq-signin-types-footer
				*ngIf="loginTypes.length > 1"
				[loginTypes]="loginTypes"
				signin-types-footer
			></eliq-signin-types-footer>
			<div
				*ngIf="showHelpButtonOnInitialState$ | async"
				class="help-button-container"
				having-trouble
			>
				<eliq-link
					label="login_help.title"
					(clicked)="loginHelpClicked()"
				></eliq-link>
			</div>
		</eliq-signin-magiclink>

		<eliq-signin-magiclink-accountnr
			class="signin-card-sizing"
			*ngIf="currentLoginType.type === 'magiclink_accountnr'"
			(logInDone)="logInDone()"
		>
			<eliq-signin-types-footer
				*ngIf="loginTypes.length > 1"
				[loginTypes]="loginTypes"
				signin-types-footer
			></eliq-signin-types-footer>
			<div
				*ngIf="showHelpButtonOnInitialState$ | async"
				class="help-button-container"
				having-trouble
			>
				<eliq-link
					label="login_help.title"
					(clicked)="loginHelpClicked()"
				></eliq-link>
			</div>
		</eliq-signin-magiclink-accountnr>
	</ng-container>

	<ng-container *ngIf="loginState === 'setting_up'">
		<eliq-signin-setup-wizard
			class="signin-card-sizing"
			(done)="setupWizardDone()"
		></eliq-signin-setup-wizard>
	</ng-container>
</div>
