import { Injectable } from '@angular/core'
import { ValueOf } from '@eliq/core'
import { Observable, Subject, AsyncSubject, BehaviorSubject, take } from 'rxjs'
import { CoreDataStoreService } from '@eliq/core'
import { ActivatedRouteSnapshot, NavigationEnd, Params } from '@angular/router'
import { PostMessageApi } from './post-message-api/postmessage-api.service'
import { AuthService } from '@eliq/feature/auth'
import { Router } from '@angular/router'

interface HideParam<T> {
	readonly hideFooter: T
	readonly hideHeader: T
	readonly hideTitle: T
}

type EmbeddedParams = HideParam<boolean> & { location?: number }

@Injectable({
	providedIn: 'root',
})
export class EmbeddedService extends PostMessageApi {
	constructor(
		protected override authService: AuthService,
		protected override coreDS: CoreDataStoreService,
		protected override router: Router,
	) {
		super(authService, coreDS, router)
	}

	public params$: BehaviorSubject<EmbeddedParams> = new BehaviorSubject({
		hideHeader: false,
		hideFooter: false,
		hideTitle: false,
	} as EmbeddedParams)

	parseBoolean<D = null>(
		str: string,
		_default: D = null as unknown as D,
	): boolean | D {
		switch (str) {
			case 'false':
			case '0':
				return false
			case 'true':
			case '1':
				return true
		}
		return _default
	}

	parseFlagValue<D = false>(
		key: string,
		value: string,
		_default: D = null as unknown as D,
	): boolean | D {
		const [_key, _value] = this.decodedNoSpaces(
			key,
			(value ?? '').toLowerCase(),
		)

		if (_key[0] === '!') {
			return false
		}
		if (!_value?.length) {
			return true
		}

		const parsed = this.parseBoolean(_value)
		if (parsed !== null) {
			return parsed
		}

		return _default
	}

	decodedNoSpaces(str: string): string
	decodedNoSpaces(...strings: string[]): string[]
	decodedNoSpaces(...strings: string[]): string[] | string | null {
		const output: string[] = []
		for (const str of strings) {
			let outputStr = ''
			// This is a bit over-engineered but has better performance than .replace(/ /g, "")
			for (const o of decodeURI(str)) {
				if (o !== ' ') {
					outputStr += o
				}
			}
			output.push(outputStr)
		}
		if (output.length > 1) {
			return output
		}
		if (output.length === 1) {
			return output[0]
		}
		return null
	}

	/**
	 * @param mapping - The mapping between short flag keys and the long flag keys
	 * @param queryParams
	 * @param shortFlagsQueryParamKey - The name of the query param that contains all these flags in short format.
	 * @param defaultValue
	 * @param defaultIfShortFlagsPresent - Default the shortFlagsQueryParamKey is present (even if it
	 * contains an empty string)
	 * @param localStorageKey - Persists output object in localStorage if it's a string,
	 * and prepends the value of this param to the localStorage key.
	 * @param useShortKeyInOutput - Use the short flag keys in the return value of this function and in
	 * the localStorage JSON.
	 * @param defaultSpecific - Override defaults for certain *output flag key names* (i.e. one of the values
	 * of the mapping record).
	 *
	 * @returns a string to boolean record
	 *
	 * # Long vs short flag keys
	 *
	 * Short format is like this for example:
	 *
	 * `/?hide=title:true,header:false,footer:1`
	 *
	 * ... or even shorter:
	 *
	 * `/?hide=title,!header,footer`
	 *
	 * Long format is like this:
	 *
	 * `/?hideTitle=true&hideHeader=false&hideFooter=true`
	 *
	 * But does not need include the `shortFlagsQueryParamKey` like in this example.
	 */
	parseUrlFlags<MappingType extends Readonly<Record<string, string>>>(
		mapping: MappingType,
		queryParams: Record<string, string> = {},
		shortFlagsQueryParamKey: string | null = null,
		defaultValue = false,
		defaultIfShortFlagsPresent = true,
		localStorageKey: string | null | false = null,
		useShortKeyInOutput = false,
		defaultSpecific: Partial<MappingType> | null = null,
	): { [P in ValueOf<Readonly<MappingType>>]: boolean } {
		const output = {} as Record<string, boolean>

		const mappingEntries = Object.entries(mapping)
		const mappingKeys = Object.keys(mapping)

		const reversedMapping = {}
		for (const entry of mappingEntries) {
			;(reversedMapping as any)[entry[1]] = entry[0]
		}

		const outputMapping = useShortKeyInOutput ? mapping : reversedMapping

		const outputKeys = Object.keys(outputMapping)

		const setOutputVal = (key: string, val?: string) => {
			const parsedVal = this.parseFlagValue<null>(key, val ?? '', null)
			const trueKey = key[0] === '!' ? key.slice(1) : key

			if (parsedVal !== null) {
				if (outputKeys.includes(trueKey)) {
					output[trueKey] = parsedVal
				} else if (mappingKeys.includes(trueKey)) {
					output[mapping[trueKey]] = parsedVal
				}
			} else {
				console.error(
					'parsedVal was null for key',
					key,
					JSON.stringify(
						{ queryParams, key, val, parsedVal, outputKeys, mapping },
						null,
						2,
					),
				)
			}
		}

		const shortFlagsPresent =
			queryParams && (shortFlagsQueryParamKey ?? '') in queryParams

		// Set default for all of the mapping values
		for (const key of outputKeys) {
			if (shortFlagsPresent) {
				output[key] = defaultIfShortFlagsPresent
			} else {
				output[key] = defaultValue
			}
			if (defaultSpecific && key in defaultSpecific) {
				const parsed = this.parseBoolean((defaultSpecific as any)[key])
				if (parsed !== null) {
					output[key] = parsed
				}
			}
		}

		// Override defaults with localStorage values
		const inStorage: Partial<HideParam<boolean>> = {}

		if (localStorageKey) {
			let storage: Partial<HideParam<boolean>> = {}
			try {
				storage = JSON.parse(localStorage.getItem(localStorageKey) ?? '{}')
			} catch (e) {
				console.error('Error parsing localStorage', e)
			}

			for (const key of outputKeys) {
				if (storage === null) {
					break
				}

				;(inStorage as any)[key] = this.parseBoolean((storage as any)[key])

				// If it's set and not same as the default
				if (
					(inStorage as any)[key] !== null &&
					(inStorage as any)[key] !== defaultValue
				) {
					output[key] = (inStorage as any)[key]
				}
			}
		}

		// Override localStorage options with the short flags
		if (shortFlagsPresent) {
			let i = 0
			for (const opt of queryParams[shortFlagsQueryParamKey ?? ''].split(',')) {
				i += 1
				const [key, val] = opt.split(':')
				setOutputVal(key, val)
			}
		}

		// Override the short param options with the long params options
		if (queryParams) {
			for (const key of outputKeys) {
				if (key in queryParams) {
					setOutputVal(key, queryParams[key])
				}
			}
		}

		// Save non-default or changed items to localStorage
		if (localStorageKey) {
			const toStore = { ...inStorage }

			for (const key of outputKeys) {
				if (output[key] !== defaultValue) {
					;(toStore as any)[key] = output[key] ? 'true' : 'false'
				} else {
					delete (toStore as any)[key]
				}
			}

			if (Object.keys(toStore).length) {
				localStorage.setItem(localStorageKey, JSON.stringify(toStore))
			} else {
				localStorage.removeItem(localStorageKey)
			}
		}

		return output as { [P in keyof MappingType]: boolean }
	}

	/**
	 * parses hide url params in these formats:
	 *
	 *  / - Hides all of them by default, unless saved in local storage.
	 *
	 *  /?hide=title:1,header:1,footer:0 - Hides title and header only
	 *
	 *  /?hide=title:true,header:false,footer:true - Hides title and footer
	 *
	 *  /?hide=title:true,header:0,footer:true - Hides title and footer
	 *
	 *  /?hideTitle=false&hide=header:0,footer:1 - only hides footer
	 *
	 *  /?hideTitle=false&hide=header:0,footer:1&location=123 - there's also location option
	 *
	 * Also saves things in localStorage
	 * @param queryParams
	 */
	parseEmbeddedParams(
		queryParams: Params,
	): HideParam<boolean> & { location?: number } {
		const maybeLocation = parseInt(queryParams['location'])
		const location = isNaN(maybeLocation) ? undefined : maybeLocation

		if (location) {
			this.coreDS.locations.pipe(take(1)).subscribe((_locations) => {
				this.coreDS.setActiveLocation(location)
			})
		}

		const flagsMapping = {
			header: 'hideHeader',
			footer: 'hideFooter',
			title: 'hideTitle',
		} as const

		const flags = this.parseUrlFlags(
			flagsMapping,
			queryParams,
			'hide',
			false,
			false,
			'EmbeddedParams',
		)
		return { location, ...flags }
	}

	newParams(newParams: Params) {
		const queryParams = this.parseEmbeddedParams(newParams)
		this.params$.next(queryParams)
	}
}
