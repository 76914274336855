<a *ngIf="link && fullLink" [href]="link" target="_blank">
	<eliq-info-card-content
		[title]="title"
		[text]="text"
		[imgSrc]="imgSrc"
		[paddingBottom]="paddingBottom"
		[link]="link"
		[linkText]="linkText"
		[cardStyle]="cardStyle"
		[imageStyle]="imageStyle"
		[imageLongShadow]="imageLongShadow"
		[linkStyle]="linkStyle"
		[kbcPostMessageLink]="kbcPostMessageLink"
		[fullLink]="fullLink"
	></eliq-info-card-content>
</a>
<eliq-info-card-content
	*ngIf="!fullLink || !link"
	[title]="title"
	[text]="text"
	[imgSrc]="imgSrc"
	[paddingBottom]="paddingBottom"
	[link]="link"
	[linkText]="linkText"
	[cardStyle]="cardStyle"
	[imageStyle]="imageStyle"
	[kbcPostMessageLink]="kbcPostMessageLink"
	[imageLongShadow]="imageLongShadow"
	[linkStyle]="linkStyle"
	[fullLink]="fullLink"
></eliq-info-card-content>
