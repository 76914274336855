import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { LinkComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-signin-magiclink-verification',
	templateUrl: './signin-magiclink-verification.component.html',
	styleUrls: ['./signin-magiclink-verification.component.scss'],
	standalone: true,
	imports: [TranslateModule, LinkComponent, ImgSrcPipe],
})
export class SigninMagiclinkVerificationComponent implements OnInit {
	@Input() userEmail: string
	@Output() havingTroubleClicked = new EventEmitter()

	constructor() {}

	ngOnInit(): void {}
}
