<div class="container" *ngIf="(isLoaded && showSubmitView) else loadingBlock">
	<div
		*ngIf="!showConfirmView && this.parentForm && this.parentForm.controls['meterId']!"
	>
		<div class="meter-read">
			<eliq-smr-select-meter-dropdown
				[options]="meterIds"
				[labels]="meterLabels"
				[label]="'Select meter'"
				[selectedCtrl]="parentForm.controls.meterId"
			></eliq-smr-select-meter-dropdown>
			<div class="secondary-p3" style="margin-top: 5px; font-size: 16px;">
				{{ 'submit_meter_read.id' | translate }}
				{{ meter.display_name }}
			</div>
		</div>
		<form [formGroup]="parentForm" (ngSubmit)="preSubmit($event)">
			<div class="meter-read">
				<div class="label">
					<span>{{ 'submit_meter_read.select_date' | translate }}</span>
				</div>
				<eliq-supply-dates-dropdown
					[meter]="meter"
					[supplyDateCtrl]="parentForm.controls.supplyDate"
				></eliq-supply-dates-dropdown>
				<!-- (selectedDate)="supplyDateSelected($event)" -->

				<!-- [supplyDateCtrl]="parentForm?.controls?.supplyDate" -->
			</div>
			<div class="meter-read submit-modal-input-container">
				<eliq-submit-modal-input
					[registersForm]="parentForm.controls.registersForm"
					[registers]="registers"
					[meter]="meter"
				></eliq-submit-modal-input>
			</div>
			<div class="meter-read">
				<eliq-basic-button
					label="{{ 'submit_meter_read.submit' | translate }}"
					[disabled]="!formIsValid"
					[submit]="true"
				></eliq-basic-button>
			</div>
		</form>
	</div>
</div>

<!-- <ng-container *ngIf="toggleForm" class="container">
	<div class="meter-read">
		<eliq-smr-select-meter-dropdown
			[options]="meterIds"
			(selection)="printVal($event)"
		></eliq-smr-select-meter-dropdown>
		<div class="secondary-p3" style="margin-top: 5px; font-size: 16px;">
			{{ 'submit_meter_read.id' | translate }} {{ selectedVal }}
		</div>
	</div>
	<form [formGroup]="parentForm" (ngSubmit)="submitRead()">
		<div class="meter-read">
			<div class="label">
				<span>{{ 'submit_meter_read.select_date' | translate }}</span>
			</div>
			</!--<eliq-submit-read-dropdown
				[meterID]="selectedVal"
				[supplyDatesForm]="parentForm"
			></eliq-submit-read-dropdown>-/->
		</div>
		<div class="meter-read">
			<eliq-submit-modal-input
				[registers]="registers"
				[registersForm]="parentForm"
				[meterID]="selectedVal"
			></eliq-submit-modal-input>
		</div>
		<div class="meter-read">
			<eliq-basic-button
				label="{{ 'submit_meter_read.submit' | translate }}"
				(clicked)="submitButtonClicked(true)"
				[disabled]="!parentForm.valid"
			></eliq-basic-button>
		</div>
	</form>
</ng-container> -->

<ng-container *ngIf="showConfirmView && !showSuccessView">
	<div class="regConfirmation">
		<div>
			{{ 'submit_meter_read.confirmation_dialog_description' | translate }}
			<table class="regConfirmationTable">
				<thead>
					<tr>
						<th>Meter</th>
						<th>Type</th>
						<th>Reading</th>
					</tr>
				</thead>
				<tbody>
					<tr
						*ngFor="let reg of parentForm?.controls?.registersForm?.controls | keyvalue"
					>
						<td class="regName" translate>{{'common.'+this.meter.type}}</td>
						<td class="regType">{{findRegisterById(reg.key)?.type}}</td>
						<td class="regValue">{{reg.value.value}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="buttons">
			<eliq-basic-button
				label="{{ 'submit_meter_read.confirmation_dialog_update_button' | translate }}"
				(clicked)="updateButtonClicked(true)"
			></eliq-basic-button>
			<eliq-basic-button
				label="{{ 'submit_meter_read.confirmation_dialog_confirm_button' | translate }}"
				(clicked)="confirmButtonClicked(true)"
			></eliq-basic-button>
		</div>
	</div>
</ng-container>

<ng-container *ngIf="showSuccessView">
	<div style="text-align: center; margin: 1rem auto;">
		<div>{{ 'submit_meter_read.success_dialog_description' | translate }}</div>
	</div>
</ng-container>

<ng-template #loadingBlock>
	<div class="loading-container" *ngIf="!isLoaded && !showSubmitView">
		<eliq-spinner size="medium"></eliq-spinner>
	</div>
</ng-template>
