<p class="primary-p2">{{notification.$content}}</p>

<div>
	<a
		routerLink="/budget"
		(click)="targetClicked()"
		[queryParams]="routerParams"
	>
		<eliq-link
			[label]="'notifications_feed_actions.view_budget' | translate"
		></eliq-link>
	</a>
</div>
