import { Component, OnDestroy, OnInit, inject } from '@angular/core'
import {
	AsyncPipe,
	CommonModule,
	NgClass,
	NgIf,
	NgStyle,
} from '@angular/common'
import { MatFormFieldModule } from '@angular/material/form-field'
import { BasicButtonComponent } from '@eliq/ui'
import { ModalService } from '@eliq/ui/modal'
import { IlRegisterEmailBannerModalComponent } from '../il-register-email-banner-modal/il-register-email-banner-modal.component'
import {
	FormGroup,
	FormBuilder,
	FormControl,
	FormGroupDirective,
	NgForm,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core'
import { LinkComponent } from '@eliq/ui'
import { MatInputModule } from '@angular/material/input'
import { UserHttpService } from '@eliq/core'
import { AuthService } from '@eliq/feature/auth'
import {
	BehaviorSubject,
	Observable,
	Subject,
	interval,
	map,
	mergeMap,
	of,
	switchMap,
	take,
	takeUntil,
	takeWhile,
	tap,
	timer,
} from 'rxjs'

@Component({
	selector: 'eliq-luna-register-email-banner',
	standalone: true,
	imports: [
		CommonModule,
		NgStyle,
		NgClass,
		NgIf,
		AsyncPipe,
		//NgForm,
		//FormGroup,
		//FormBuilder,
		//FormControl,
		//FormGroupDirective,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		LinkComponent,
		//ErrorStateMatcher,
		MatFormFieldModule,
		BasicButtonComponent,
	],
	templateUrl: './il-register-email-banner.component.html',
	styleUrls: ['./il-register-email-banner.component.scss'],
})
export class IlRegisterEmailBannerComponent implements OnInit, OnDestroy {
	email = new FormControl('', [Validators.required, Validators.email])

	registerEmailForm!: FormGroup
	modalService = inject(ModalService)
	userService = inject(UserHttpService)
	authService = inject(AuthService)

	private destroyed$ = new Subject<void | boolean>()
	public emailIsSet = false

	emailIsVerified$ = new BehaviorSubject<boolean>(false)

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.registerEmailForm = this.formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		})
		this.emailIsVerified().subscribe((verified) =>
			this.emailIsVerified$.next(verified),
		)
	}

	ngOnDestroy() {
		this.destroyed$.next(true)
	}

	getEmail() {
		return this.registerEmailForm.get('email')
	}

	emailIsVerified(): Observable<boolean> {
		const userId = this.authService.getUserId()
		if (!userId) {
			return of(false)
		}
		return this.userService.getUser(userId).pipe(
			take(1),
			map((user) => {
				if (user.email) {
					this.emailIsSet = true
					return true
				} else {
					return false
				}
			}),
		)
	}

	getErrorMessage() {
		return this.email.hasError('required')
			? 'You must enter a value'
			: this.email.hasError('email')
			? 'Not a valid email'
			: ''
	}

	onEmailSubmit() {
		if (this.emailIsSet) {
			return
		}

		// pass on the user-supplied value to the parent component.
		if (this.getErrorMessage() !== '') {
			//this.showErrors = true
			console.warn('invalid email:', this.email.value)
		} else {
			//this.showErrors = false
			this.modalService.openModal(IlRegisterEmailBannerModalComponent, {
				data: {
					email: this.email.value,
					emailIsVerified$: this.emailIsVerified$.asObservable(),
				},
			})

			const emailIsVerifiedObs$ = timer(0, 3000).pipe(
				takeWhile(() => this.emailIsSet === false),
				mergeMap(() => this.emailIsVerified()),
				tap((emailIsVerified) => {
					if (emailIsVerified) {
						this.emailIsVerified$.next(emailIsVerified)
					}
				}),
				takeUntil(this.destroyed$),
			)

			emailIsVerifiedObs$.subscribe()
		}
	}
}
