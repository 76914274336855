// Feature flags are available in production only if "manuallyTested" is true and
// "enabledFor" includes the client it should be shown for.
//
// The "enabledFor" can also be "all".
//
// If the client is included by "enabledFor" but manually tested is false then it
// will still be enabled in UAT.

export interface FeatureFlag {
	manuallyTested: boolean
	enabledFor: 'all' | Array<string>
	/// If the feature should be enabled for all clients, clients mentioned here will be excluded.
	exceptions?: Array<string>
}

export interface FeatureFlags {
	[key: string]: FeatureFlag
}

export default {
	'onscreen-console': {
		manuallyTested: false,
		enabledFor: ['all'],
	},
	'annual-card': {
		manuallyTested: false,
		enabledFor: ['kbc'],
	},
	'dynamic-capacity-tariffs': {
		manuallyTested: false,
		enabledFor: ['eliq', 'kbc'],
	},
	m3: {
		manuallyTested: true,
		enabledFor: ['oap', 'kbc', 'mega', 'MySmartE'],
	},
	'tickets-button-to-verify': {
		manuallyTested: true,
		enabledFor: 'all',
	},
	'insights-home-profile-wizard': {
		manuallyTested: true,
		enabledFor: 'all',
		exceptions: ['sms']
	},
} as FeatureFlags
