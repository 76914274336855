import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConfigLoginType } from '../../models/config-login-type.model'

export interface LoginConfig {
	signUpUrl: string
	loginTypes: ConfigLoginType[]
	wizardSteps
	options: {
		show_having_trouble_before_initiated_login: boolean
	}
}

@Injectable({
	providedIn: 'root',
})
export class LoginConfigService {
	constructor(private config: JsonGetterService) {}

	getLoginTypes(): Observable<ConfigLoginType[]> {
		return this.loginConfig().pipe(map((loginConfig) => loginConfig.loginTypes))
	}

	getSignUpUrl(): Observable<string> {
		return this.loginConfig().pipe(map((loginConfig) => loginConfig.signUpUrl))
	}

	showHelpButtonOnInitialState(): Observable<boolean> {
		return this.loginConfig().pipe(
			map((config) => {
				if (!config.options) return false
				// default to false if it doesnt exist.
				else return config.options.show_having_trouble_before_initiated_login
			}),
		)
	}

	private loginConfig(): Observable<LoginConfig> {
		return this.config.getConfig('login')
	}
}
