<h1 class="primary-h1" style="text-align: center;">We use cookies</h1>
<blockquote class="primary-p1">
	Without cookies you would get logged out and lose all your
	preferences&mdash;every time the page refreshes.
</blockquote>
<p class="primary-p2">
	By using this site you agree to the use of these strictly necessary cookies,
	and optionally also some other cookies that improve our website.
</p>
<strong class="primary-p1">Please check the cookies you consent to</strong>
<form class="primary-p2 cookieConsentForm" #cookieConsentForm="ngForm">
	<mat-checkbox [checked]="true" [disabled]="true" [name]="'necessary'">
		<span style="font-weight: bold;">Necessary</span>
	</mat-checkbox>
	<mat-checkbox
		*ngFor="let key of optionalConsentKeys"
		[(ngModel)]="cookieConsentFormData[key]"
	>
		<span style="font-weight: bold;">
			{{key[0].toUpperCase() + key.slice(1)}}
		</span>
	</mat-checkbox>
	<div class="bottomButtons">
		<eliq-basic-button
			[submit]="false"
			(click)="accepted('all')"
			[label]="'Allow all'"
			[useEntireWidth]="true"
			(mouseenter)="mouseEnter()"
			(mouseleave)="mouseLeave()"
		></eliq-basic-button>
		<eliq-basic-button
			[submit]="false"
			(click)="accepted('some')"
			[label]="'Only selected'"
			[useEntireWidth]="true"
		></eliq-basic-button>
	</div>
</form>
