import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { Observable } from 'rxjs'
import { BillsConfig } from '../models/config-models/bills-config-model'

@Injectable({
	providedIn: 'root',
})
export class BillsConfigService {
	constructor(private config: JsonGetterService) {}

	getBillsConfig(): Observable<BillsConfig> {
		return this.config.getConfig('bills')
	}
}
