import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BasicButtonComponent, XButtonComponent } from '@eliq/ui'
import { ModalService } from '@eliq/ui/modal'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-exit-modal',
	templateUrl: './exit-modal.component.html',
	styleUrls: ['./exit-modal.component.scss'],
	standalone: true,
	imports: [XButtonComponent, TranslateModule, BasicButtonComponent],
})
export class ExitModalComponent {
	@Output() clicked = new EventEmitter()

	constructor(
		public dialogRef: MatDialogRef<ExitModalComponent>,
		private modal: ModalService,
	) {}

	closeClicked() {
		this.dialogRef.close()
	}

	exitButtonClicked() {
		this.clicked.emit(true)
		this.closeClicked()
	}
}
