import { Component, OnInit } from '@angular/core'
import { JsonGetterService, LanguageService } from '@eliq/core'
import { ActivatedRoute } from '@angular/router'
import { SigninContainerComponent } from '@eliq/feature/login/components/signin-container/signin-container.component'
import { LoginGreetingComponent } from '@eliq/feature/login/components/login-greeting/login-greeting.component'
import { TranslationPickerComponent } from '@eliq/ui/common/components/translation-picker/translation-picker.component'
import { NgIf, AsyncPipe } from '@angular/common'
import { EnvironmentService } from '@eliq/data-access'

@Component({
	selector: 'eliq-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		TranslationPickerComponent,
		LoginGreetingComponent,
		SigninContainerComponent,
		AsyncPipe,
	],
})
export class LoginComponent implements OnInit {
	public expandLogin = false

	public translationPicker = true

	public languages = this.jsonGetter.getLanguages()
	public selectedLang = ''
	public integrationless = false

	constructor(
		private env: EnvironmentService,
		private route: ActivatedRoute,
		private jsonGetter: JsonGetterService,
		private langService: LanguageService,
	) {}

	ngOnInit() {
		this.integrationless = this.env?.integrationless?.enabled ?? false

		this.route.queryParams.subscribe((params) => {
			const ssoTokenKBC = params['ssoTokenKBC']
			let language = params['language']

			if (ssoTokenKBC) {
				;(async () => {
					if (!language) {
						const langs = await this.jsonGetter.getLanguages().toPromise()
						language = langs ? langs[0].code : 'en-GB'
					}
					this.langSelected(language)
				})()
			}
		})
		this.selectedLang = this.langService.getCurrentLanguage()
	}

	langSelected(lang: string) {
		this.langService.setCurrentLanguage(lang).subscribe()
	}

	maximizeLogin(event: boolean) {
		this.expandLogin = event
		this.translationPicker = false
	}
}
