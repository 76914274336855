import { Component, Input, OnInit } from '@angular/core'
import { EliqNumberPipe } from '@eliq/core'
import { MonthlyComparison } from '../pv-home-card-container/pv-home-card-container.component'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { NgIf, NgClass } from '@angular/common'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { EliqThemeService } from '@eliq/theme'
@Component({
	selector: 'eliq-pv-trend-item',
	templateUrl: './pv-trend-item.component.html',
	styleUrls: ['./pv-trend-item.component.scss'],
	standalone: true,
	imports: [NgIf, NgClass, TranslateModule, ImgSrcPipe, InlineSVGModule],
})
export class PvTrendItemComponent implements OnInit {
	@Input() value!: number
	@Input() diff!: number
	@Input() type!: 'consumption' | 'production' | 'import' | 'export'
	@Input() unit!: 'cost' | 'energy' | 'm3'

	public titleKey = ''
	public iconKey = ''
	public shownPercentage!: number
	public get colorKey() {
		return this.themeService.getPropVal(`chart-column-elec-${this.type}-color`)
	}

	public shownValue = ''
	constructor(
		public eliqNumberPipe: EliqNumberPipe,
		private themeService: EliqThemeService,
	) {}

	ngOnInit(): void {
		this.titleKey = this.type
		if (this.type === 'consumption') {
			this.iconKey = 'flash'
		} else if (this.type === 'production') {
			this.iconKey = 'sunny'
		} else if (this.type === 'import') {
			this.iconKey = 'homecard_arrow_down'
		} else if (this.type === 'export') {
			this.iconKey = 'homecard_arrow_up'
		}

		if (this.diff) {
			this.shownPercentage = Math.abs(this.diff)
		}

		this.shownValue = this.eliqNumberPipe
			.transform(this.value, this.unit)
			.replace(' kWh', '')
			.replace(this.eliqNumberPipe._getCurrencySymbol('narrow'), '')
	}
}
