import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { PeriodType, ResolutionType } from '@eliq/core'
import { ChartHelperService } from '@eliq/core'
import {
	DateTranslatorService,
	EliqNumberPipe,
	Resolution,
	VariableTranslatorService,
} from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { DozenalYear, SelectableYear } from '../../models/dozenal-year.model'
import { APIDynamicCapacityTariffsData } from '../../models/dynamic-capacity-tariffs-data.model'
import { Observable } from 'rxjs'
import { DynamicCapacityTariffsApiService } from '../../services/dynamic-capacity-tariffs-api.service'
import { map } from 'rxjs/operators'
import { PostmessageExternalSender } from '@eliq/external/kbc-post-message-api'
import { NgIf, NgFor, DecimalPipe } from '@angular/common'
import { XButtonComponent } from '@eliq/ui'
import { NgxPopperjsModule } from 'ngx-popperjs'
import { InfoLinkCardTrackingService } from '@eliq/ui/layout/home/cards/info-link-card/tracking/info-link-card-tracking.service'

@Component({
	selector: 'eliq-peak-tariffs-details-modal',
	templateUrl: './peak-tariffs-details-modal.component.html',
	styleUrls: ['./peak-tariffs-details-modal.component.scss'],
	standalone: true,
	imports: [
		NgxPopperjsModule,
		XButtonComponent,
		NgIf,
		NgFor,
		DecimalPipe,
		TranslateModule,
	],
})
export class PeakTariffsDetailsModalComponent implements OnInit {
	@Input() data: number[]
	@Input() locId: number
	@Input() startYear: number
	@Input() pv: boolean
	public janToDec: DozenalYear
	public selectedYear: SelectableYear
	public selectableYears: SelectableYear[]
	private currentYear: SelectableYear
	@Output() newYearSelected = new EventEmitter<number>()

	public resolutionType = ResolutionType.Month

	@Output() pointClicked = new EventEmitter<number>()

	public now: Date
	public resolution: Resolution
	public headerKey = ''
	public average: number

	private Highcharts
	//for rendering highcharts graph
	private myChart

	private chartOptions
	private canClickTooltip: boolean

	public popperShowing = false
	public updateFlag = false

	private initialized = false

	public noData = false
	public message = ''
	public link = ''
	public linkPrefix = ''
	public linkSuffix = ''
	constructor(
		private dialogRef: MatDialogRef<PeakTariffsDetailsModalComponent>,
		private chartHelper: ChartHelperService,
		private element: ElementRef,
		private translator: TranslateService,
		private numberPipe: EliqNumberPipe,
		private dateTranslator: DateTranslatorService,
		private api: DynamicCapacityTariffsApiService,
		private env: EnvironmentService,
		private tracking: InfoLinkCardTrackingService,
		private varTrans: VariableTranslatorService,
	) {}

	ngOnInit() {
		this.now = new Date()
		this.setup()
		this.initialized = true
	}

	private setup() {
		if (!this.initialized) {
			this.linkPrefix = this.translator.instant(
				'screen_dynamic_capacity_tariffs.your_peak_tariff_link_prefix',
			)
			if (this.linkPrefix.charAt(this.linkPrefix.length - 1) !== ' ') {
				this.linkPrefix += ' '
			}
			this.link = this.translator.instant(
				'screen_dynamic_capacity_tariffs.your_peak_tariff_link_direct',
			)
			this.linkSuffix = this.translator.instant(
				'screen_dynamic_capacity_tariffs.your_peak_tariff_link_hyper',
			)
			this.headerKey = this.translator.instant(
				'screen_dynamic_capacity_tariffs.capacity_tariff_text',
			)
			this.message = this.translator.instant(
				'screen_dynamic_capacity_tariffs.missing_data',
			)
		}
		const dates: Date[] = []
		for (let i = 0; i <= 11; i++) {
			dates.push(new Date(this.now.getFullYear(), i))
		}
		this.janToDec = {
			dates: dates,
			periodType: PeriodType.Year,
		}
		if (!this.initialized) {
			const currentPeriodYearStart = this.now.getFullYear()
			this.selectableYears = []
			const startYear = isNaN(this.startYear)
				? currentPeriodYearStart - 4
				: currentPeriodYearStart - this.startYear < 8
				? this.startYear
				: currentPeriodYearStart - 7
			for (let i = 0; i >= startYear - currentPeriodYearStart; i--) {
				this.selectableYears.push(
					new SelectableYear(currentPeriodYearStart + i),
				)
			}
			this.currentYear = new SelectableYear(currentPeriodYearStart)
			this.selectedYear = this.currentYear
			this.canClickTooltip = false
		}
		if (this.data && this.data?.length) {
			this.chartOptions = this.getOptions()
			// push this operation to back of js queue so the highcharts containing div can render first
			this.getHighchartsAndDrawChart()
		}
	}

	private getOptions() {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const myThis = this
		const janToDecMonths = this.dateTranslator.getMonths() //jan ... dec
		const cats = janToDecMonths.map(
			(m) => m.charAt(0).toUpperCase() + m.charAt(1) + m.charAt(2),
		)
		const series = this.getSeries()
		const graphMax = this.getMaxAndSetAverage() * 1.1
		const plotLines = this.getPlotLines()
		return {
			chart: {
				height: 300,
				type: 'column',
				style: {
					fontFamily: 'inherit',
					fontSize: '14px',
					color: 'inherit',
				},
				scrollablePlotArea: {
					scrollPositionX: 0,
					opacity: 0.7,
				},
				reflow: true,
				marginTop: 50,
			},
			plotOptions: {
				column: {
					stacking: 'normal',
					pointRange: 0,
					maxPointWidth: 10,
				},
				series: {
					stickyTracking: true,
					states: {
						inactive: {
							opacity: 1,
						},
					},
				},
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			tooltip: {
				style: {
					fontFamily: 'inherit',
					pointerEvents: 'auto',
					fontSize: '14px',
				},
				borderWidth: 0,
				borderColor: '#E3E3E3',
				backgroundColor: 'rgba(255, 255, 255, 0)',
				borderRadius: 0,
				followTouchMove: false,
				shadow: false,
				useHTML: true,
				headerFormat: '',
				formatter: function () {
					const background = `<div class="eliq-tooltip-bg">`
					let lines: string =
						background +
						`<div id="dynamiccapacitytariffschart" class="primary-p3" style="margin: 4px 10px 0px 10px;">`

					let dateString = (this as any).point.custom.dateString

					if (myThis.canClickTooltip) {
						dateString = `<button type="button" class="primary-p3 link dynamic-capacity-tariffs-highcharts-link" style="padding: 0 !important" (clicked)="dynamicCapacityTariffsTooltipClicked()">${dateString}</button>`
					}

					lines = lines.concat(
						'<p style="margin: 0; margin-bottom: 6px; color: ' +
							myThis.getColor('tooltipdate') +
							'; padding-bottom: 4px; border-bottom: 1px solid var(--eliq-card-border, #e9e9e9);"><span>' +
							dateString +
							'</span></p>',
					)

					const value = (this as any).point.custom.missingData
						? myThis.translator.instant(
								'screen_dynamic_capacity_tariffs.missing_data',
						  )
						: myThis.formatNumber((this as any).y)

					lines = lines.concat(
						'<p style="margin: 0; margin-bottom: 4px;">' +
							'<span class="primary-p3" style="font-weight: 600;">' +
							value +
							'</span>' +
							'</span></p>',
					)

					return lines.concat('</div></div>')
				},
				footerFormat: '',
			},
			xAxis: {
				className: 'secondary-p3 eliq-text-color',
				labels: {
					style: {
						color: this.getColor('axis-labels'),
						fontSize: 'inherit',
					},
				},
				lineWidth: 1,
				lineColor: this.getColor('axis-labels'),
				gridLineWidth: 0,
				categories: cats,
				startOnTick: false,
				endOnTick: false,
				min: 0,
				max: cats.length - 1,
				maxPadding: 0,
				minPadding: 0,
			},
			yAxis: {
				endOnTick: false,
				title: {
					text: 'kW',
					align: 'high',
					offset: 0,
					rotation: 0,
					style: {
						color: this.getColor('axis-labels'),
						fontSize: '12px',
					},
					x: -10,
					y: -10,
				},
				labels: {
					enabled: true,
					style: {
						fontSize: 'inherit',
					},
				},
				gridLineWidth: 1,
				lineWidth: 1,
				lineColor: this.getColor('axis-labels'),
				min: 0,
				max: graphMax,
				plotLines: plotLines,
			},
			series: series,
		}
	}

	private formatNumber(value: number) {
		return (
			(this as any).numberPipe
				.getTransformParts(value, 'energy')
				.find((part) => part.type == 'number')
				.text.trim() + ' kW'
		)
	}

	private getSeries() {
		// distinguish between being 0 and null
		// null -> y: 0 + custom.missing_data = true,
		// 0 -> y: 0 + custom.missing_data = false

		const regularSeriesData: any[] = []
		const nullSeriesData: any[] = []

		this.data.forEach((value, i) => {
			const isMissingData = value == null

			const result = {
				y: isMissingData ? 0 : value,
				custom: {
					dateString: this.chartHelper.getDateString(
						this.janToDec.dates[i],
						this.janToDec.periodType,
						this.resolutionType,
					),
					missingData: isMissingData,
				},
			}

			if (isMissingData) {
				nullSeriesData.push(result)
				regularSeriesData.push(null)
			} else {
				nullSeriesData.push(null)
				regularSeriesData.push(result)
			}
		})
		return [
			{
				borderColor: 'var(--eliq-dynamic-capacity-tariffs-graph-primary)',
				borderRadiusTopLeft: 2,
				borderRadiusTopRight: 2,
				borderWidth: 1,
				color: 'var(--eliq-dynamic-capacity-tariffs-graph-primary)',
				dashStyle: 'Solid',
				data: regularSeriesData,
				stacking: 'normal',
				type: 'column',
				zIndex: 5,
			},
			{
				borderRadiusTopLeft: 5,
				borderRadiusTopRight: 5,
				borderWidth: 1,
				cursor: 'pointer',
				data: nullSeriesData,
				stacking: 'normal',
				type: 'column',
				zIndex: 5,
			},
		]
	}

	private getMaxAndSetAverage(): number {
		if (this.data.length < 0) {
			this.noData = true
			return 0
		}
		let maxAmount = this.data[0]
		let total = maxAmount
		for (let i = 1; i < this.data.length; i++) {
			total += this.data[i]
			if (this.data[i] > maxAmount) {
				maxAmount = this.data[i]
			}
		}
		this.average = total / this.data.length
		if (this.average <= 0) {
			this.noData = true
		}
		return maxAmount
	}

	private getPlotLines() {
		return [
			{
				value: this.average,
				color: 'red',
				dashStyle: 'longdash',
				label: {
					useHTML: true,
					formatter: () => {
						return (
							`<div style="background-color: var(--eliq-card-background, #fff); font-family: 'Quicksand'; padding: 6px; border-radius: var(--eliq-card-border-radius); box-shadow: 0 4px 1rem 0 rgba(0,0,0,0.1); opacity: 0.9; border: 1px solid #e3e3e3">` +
							'<p style="margin: 0px; font-weight: 600;">' +
							this.translator.instant('common.average') +
							': ' +
							this.formatNumber(this.average) +
							'</p>' +
							'</div>'
						)
					},
					x: 4,
					y: -5,
				},
			},
		]
	}

	private async getHighchartsAndDrawChart() {
		this.chartHelper.getHighcharts().then((hc) => {
			this.Highcharts = hc
			this.Highcharts._modules['Core/Renderer/HTML/AST.js']
			this.myChart = this.Highcharts.chart(
				this.getChartContainerElement(),
				this.chartOptions,
			)
		})
	}

	private getChartContainerElement() {
		return this.element.nativeElement.querySelector(
			'#peak-tariffs-details-modal-chart',
		)
	}

	private getColor(key: string): string {
		const cssVarKey = `--eliq-dynamic-capacity-tariffs-${key}`

		const toReturn =
			(window as any)
				?.getComputedStyle(document.body)
				.getPropertyValue(cssVarKey)
				.trim() || ''
		return toReturn
	}

	public closeClicked() {
		this.dialogRef.close()
	}

	public setSelectedYear(clickedYear: SelectableYear) {
		if (this.selectedYear.year !== clickedYear.year) {
			this.selectedYear = clickedYear
			this.newYearSelected.emit(clickedYear.year)
			this.noData = false
			this.getData().subscribe(
				(res) => {
					(this as any).data = res.consumption
					this.updateFlag = true
					this.setup()
				},
				(err) => {
					/**/
				},
			)
		}
	}

	private getData(): Observable<APIDynamicCapacityTariffsData> {
		const now = new Date()
		const fromDate = new Date(this.selectedYear.year, 1)
		let toDate = new Date(this.selectedYear.nextYear, 1)
		if (now < toDate) {
			toDate = now
		}
		const d = this.api.getDynamicCapacityTariffsData(
			this.locId,
			'month',
			fromDate,
			toDate,
			this.pv,
		)
		return d.pipe(
			map(
				(result) =>
					({
						...result,
						consumption: (result as any)?.consumption.map((value) =>
							value === null ? value : value / 1000,
						),
					} as APIDynamicCapacityTariffsData),
			), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
		)
	}

	public onLinkClick(event, url) {
		event.preventDefault()

		if (this.env.isKBC()) {
			const kbcSender = new PostmessageExternalSender()
			kbcSender.openUrl(url)
		} else {
			window?.open(url, '_blank')
		}

		this.tracking.cardOpened(url)
	}
}
