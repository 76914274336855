import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'
import { XButtonComponent } from '@eliq/ui'
import { TranslatePreWrapDirective } from '@eliq/core'

@Component({
	selector: 'eliq-pv-home-card-info-modal',
	templateUrl: './pv-home-card-info-modal.component.html',
	styleUrls: ['./pv-home-card-info-modal.component.scss'],
	standalone: true,
	imports: [XButtonComponent, TranslateModule, TranslatePreWrapDirective],
})
export class PvHomeCardInfoModalComponent {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { activeDataStream: false | 'consumption-production' | 'import-export' },
		private dialogRef: MatDialogRef<PvHomeCardInfoModalComponent>,
	) {}


	closeClicked() {
		this.dialogRef.close()
	}
}
