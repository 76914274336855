<div class="background-holder">
	<div class="greeting-container">
		<div style="height: 4rem; margin-bottom: 2rem;">
			<eliq-company-logo
				[link]="'/'"
				height="4rem"
				padding="0"
				type="primary"
			></eliq-company-logo>
		</div>
		<h2 class="greeting-header primary-h2" translate>
			web_login_greeting.welcome_to_web
		</h2>
		<p class="greeting-text secondary-p2" translate>
			web_login_greeting.greeting_long
		</p>
	</div>
</div>
