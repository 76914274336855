import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { ToggleButton } from '../../../models/ToggleButton.model'
import { NgStyle, NgClass, NgIf } from '@angular/common'

@Component({
	selector: 'eliq-toggle-button',
	templateUrl: './toggle-button.component.html',
	styleUrls: ['./toggle-button.component.scss'],
	standalone: true,
	imports: [NgStyle, NgClass, NgIf],
})
export class ToggleButtonComponent implements OnInit {
	@Input() toggleButton!: ToggleButton
	@Input() translatorPath = ''
	@Output() clickEmitter = new EventEmitter<any>()
	@Input() fontClass = 'secondary-p3'
	@Input() noBg = false
	@Input() buttonStyle: Record<string, string> = {}
	@Input() buttonSelectedStyle: Record<string, string> = {}

	public translatedName = ''

	constructor(private translator: TranslateService) {}

	ngOnInit() {
		// this.translatorpath can be undefined, which is ok: its handled properly by the translatorpathservice.
		this.translatedName = this.translator.instant(
			this.checkAndGetTranslatorPath(this.translatorPath) +
				this.toggleButton.$label,
		)
	}

	private checkAndGetTranslatorPath(path: string): string {
		if (path === undefined) return ''
		else {
			// there is a path specified. check if it ends with a dot. if it does not, add a dot, then proceed.
			if (path.slice(-1) !== '.') {
				path = path + '.'
			}
			return path
		}
	}

	onButtonClick() {
		this.clickEmitter.emit(this.toggleButton.$id)
	}
}
