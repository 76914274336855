import { Invoice } from '@eliq/feature/bills'
import { NotificationDataSource } from './NotificationDataSources.model'

export class NotificationDataSourceCustom extends NotificationDataSource {
	public action? = ''
	public action_button_label? = ''
	public action_payload?: {
		target?: string
		url?: string
	}
	constructor(source: any) {
		super(source)
		this.action = source.action
		this.action_button_label = source.action_button_label
		this.action_payload = source.action_payload
	}
}
