export class NotificationDataSource {
	private id: number
	private locationId: number

	constructor(source: any) {
		this.id = source.id
		this.locationId = source.location_id
	}

	/**
	 * Getter $id
	 * @return {number}
	 */
	public get $id(): number {
		return this.id
	}

	/**
	 * Getter $locationId
	 * @return {number}
	 */
	public get $locationId(): number {
		return this.locationId
	}

	/**
	 * Setter $id
	 * @param {number} value
	 */
	public set $id(value: number) {
		this.id = value
	}

	/**
	 * Setter $locationId
	 * @param {number} value
	 */
	public set $locationId(value: number) {
		this.locationId = value
	}
}
