<div class="container" (window:resize)="onContainerResized($event)">
	<div class="pager-button-container">
		<div *ngFor="let i of pages">
			<button
				*ngIf="i!==undefined; else nullBlock"
				class="pager-button"
				[class.pager-button--selected]="currentPage === i"
				(click)="clickPage(i)"
				[style.width.px]="width"
				[style.height.px]="height"
			>
				<span>{{i}}</span>
			</button>
		</div>
	</div>
	<div class="small-version-buttons-container">
		<div
			class="pager-move-button back secondary-p2"
			[class.pager-move-button-disabled]="currentPage === 1"
			(click)="backClick()"
		>
			<img
				class="arrow arrow-back"
				[src]="'/assets/images/Icon_arrow.svg' | imgSrc"
				alt=""
			/>
			<p class="primary-p2 back" translate>common.back</p>
		</div>
		<div
			class="pager-move-button next secondary-p2"
			[class.pager-move-button-disabled]="currentPage === nPages"
			(click)="nextClick()"
		>
			<p class="primary-p2 next" translate>common.next</p>
			<img
				class="arrow"
				[src]="'/assets/images/Icon_arrow.svg' | imgSrc"
				alt=""
			/>
		</div>
	</div>

	<ng-template #nullBlock>
		<div class="dots-container">
			<span class="pager-dot"></span>
			<span class="pager-dot" style="margin: 0px 3px;"></span>
			<span class="pager-dot"></span>
		</div>
	</ng-template>
</div>
