import { Injectable } from '@angular/core'
import {
	EliqTrackingService,
	TrackingEventName,
	EliqInfoCardOpenEvent,
} from '@eliq/core/tracking'

@Injectable({
	providedIn: 'root',
})
export class InfoLinkCardTrackingService {
	constructor(private tracking: EliqTrackingService) {}

	cardOpened(url: string) {
		const data: EliqInfoCardOpenEvent = {
			v: 1,
			url: url,
		}
		this.tracking.logEvent(TrackingEventName.EliqInfoCardOpen, data)
	}
}
