<div class="container" [ngClass]="{'hasYearlyEnergy': hasYearlyEnergy}">
		<div class="top-area">
			<eliq-x-button
				[ngStyle]="{'visibility': noX ? 'hidden' : 'visible'}"
				(clicked)="skipClick()"
			></eliq-x-button>
		</div>
		<div class="progressarea">
			<mat-progress-bar
				[color]="'#33427E'"
				[mode]="'determinate'"
				[value]="progressMade"
			></mat-progress-bar>
		</div>

	<div class="headers">
		<h5 class="home-profile-header primary-h5">{{header}}</h5>
		<p class="location-header primary-h6">{{locationName}}</p>
	</div>

	<div class="setup-area" *ngIf="propertyType === 'property'">
		<eliq-homedetails-radiobuttons
			*ngIf="property.$dataType === 'single_select'"
			[property]="property"
			(formValueChange)="setNewValue($event)"
			(formStatusChange)="validityChange($event)"
		></eliq-homedetails-radiobuttons>

		<eliq-homedetails-multiple-select
			*ngIf="property.$dataType === 'multiple_select'"
			[property]="property"
			(formValueChange)="setNewValue($event)"
			(formStatusChange)="validityChange($event)"
		></eliq-homedetails-multiple-select>

		<eliq-homedetails-homesize
			*ngIf="property.$dataType === 'number' && shouldNumberPropHaveSlider(property)"
			(formValueChanged)="setNewValue($event)"
			(formStatusChanged)="validityChange($event)"
			[property]="property"
		></eliq-homedetails-homesize>

		<eliq-homedetails-amountof
			*ngIf="property.$dataType === 'number' && !shouldNumberPropHaveSlider(property)"
			[property]="property"
			(valueChange)="setNewValue($event)"
			(statusChange)="validityChange($event)"
		></eliq-homedetails-amountof>
	</div>

	<div class="setup-area" *ngIf="propertyType === 'propertyGroup'">
		<eliq-homedetails-appliances
			[propertyGroup]="property"
		></eliq-homedetails-appliances>
	</div>

	<div class="footer">
		<div
			*ngIf="!(currentIndex === 0); else elseBlock"
			class="footerback"
			(click)="backClick()"
		>
			<eliq-link label="common.back"></eliq-link>
		</div>
		<ng-template #elseBlock>
			<span></span>
		</ng-template>
		<div class="footerbutton">
			<eliq-basic-button
				(clicked)="continueClick()"
				[label]="continueString | translate"
				[disabled]="!currentlyValid"
			></eliq-basic-button>
		</div>
	</div>
</div>
