// @ts-nocheck

import {
	Component,
	OnInit,
	Output,
	Input,
	EventEmitter,
	OnChanges,
	AfterViewInit,
} from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { NgFor, NgIf } from '@angular/common'

@Component({
	selector: 'eliq-pager',
	templateUrl: './pager.component.html',
	styleUrls: ['./pager.component.css'],
	standalone: true,
	imports: [NgFor, NgIf, TranslateModule, ImgSrcPipe],
})
export class PagerComponent implements OnInit, OnChanges, AfterViewInit {
	@Input() nPages: number
	@Output() selectedPageEmitter = new EventEmitter<number>()
	@Input() currentPage = 1

	public pages: number[]

	public isBig = true

	public width: number
	public height: number
	private bigWidth = 40
	private bigHeight = 38
	private smallWidth = 32
	private smallHeight = 30

	constructor() {}

	ngOnInit() {
		// assume we're showing the big boys
		if (this.isBig) {
			this.width = this.bigWidth
			this.height = this.bigHeight
		}
	}

	ngAfterViewInit() {
		// check what the size of the container is once its drawn
	}

	ngOnChanges() {
		this.setupPages()
	}

	private setupPages() {
		this.pages = []
		const maxPageNr = this.nPages

		let newPages = []
		// start off by always pushing 1
		newPages.push(1)
		if (this.currentPage !== 1) newPages.push(this.currentPage - 1)
		newPages.push(this.currentPage)
		if (this.currentPage + 1 < maxPageNr) newPages.push(this.currentPage + 1)
		newPages.push(maxPageNr)

		newPages = this.removeDups(newPages)
		newPages = this.addNulls(newPages)

		this.pages = newPages
	}

	public clickPage(page: number) {
		this.currentPage = page
		this.setupPages()
		this.selectedPageEmitter.emit(this.currentPage)
	}

	public backClick() {
		if (this.currentPage !== 1) {
			this.clickPage(this.currentPage - 1)
		}
	}

	public nextClick() {
		if (this.currentPage !== this.nPages) {
			this.clickPage(this.currentPage + 1)
		}
	}

	private removeDups(arr: number[]): number[] {
		const newArr = []
		let pastN = undefined
		arr.forEach((n) => {
			if (pastN !== n) newArr.push(n)
			pastN = n
		})

		return newArr
	}

	private addNulls(arr: number[]): number[] {
		const newArr = []
		let prevN = 0
		arr.forEach((n) => {
			if (prevN !== n - 1) newArr.push(undefined)
			newArr.push(n)
			prevN = n
		})

		return newArr
	}

	private smallify() {
		if (this.isBig) {
			this.height = this.smallHeight
			this.width = this.smallWidth
			this.isBig = false
		}
	}

	private bigify() {
		if (!this.isBig) {
			this.height = this.bigHeight
			this.width = this.bigWidth
			this.isBig = true
		}
	}

	// listens to changes on when the container of the pager is resized. if it goes below a certain threshold,
	// we need to show the smaller version of the pager in order to keep it looking good
	public onContainerResized(event) {
		event.target.innerWidth < 330 ? this.smallify() : this.bigify()
	}
}
