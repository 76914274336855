<eliq-x-button (clicked)="closeModal()"></eliq-x-button>

<div class="container" *ngIf="!emailIsVerified">
	<h1 class="primary-h2">Verify your email address</h1>
	<p class="primary-p2" style="margin-bottom: 0;">
		We just sent an email to
		<strong>{{data.email}}</strong>
		that contains a link to verify your email address. Once you have verified
		your addres, your energy data will be saved into your new account.
	</p>
	<div class="divider"></div>
	<h1 class="primary-p1">Didn't receive the email?</h1>
	<p class="primary-p2">
		Check that you've spelled correctly and that the email didn't end up in your
		spam folder. If it still doesn't work, request a new email below.
	</p>
	<eliq-basic-button
		[variant]="'primary'"
		[useEntireWidth]="false"
		[disabled]="!data.email || emailJustSent"
		[label]="'Resend email'"
		(click)="sendEmail()"
	></eliq-basic-button>

	<div
		class="secondary-p2 edit-email-link"
		(click)="closeModal()"
		target="_blank"
	>
		<eliq-link label="Change email address"></eliq-link>
	</div>
</div>

<div class="container" *ngIf="emailIsVerified">
	<h1 class="primary-h2">Email address verified</h1>
	<p class="primary-p2">
		Your energy data is now saved to your new account and you can access it
		anytime by logging in with your email address.
	</p>

	<div style="position: relative; display: block; width: 9rem;">
		<eliq-basic-button
			[variant]="'primary'"
			[useEntireWidth]="true"
			[label]="'OK'"
			(click)="closeModal()"
		></eliq-basic-button>
	</div>
</div>
