<eliq-homechart
	*ngIf="loaded"
	[unit]="unit"
	[consumption]="consumption"
	[forecast]="forecast"
	[production]="production"
	[fontFamily]="inherit"
	[period]="period"
	[resolutionType]="resolutionType"
	[categoryType]="categoryType"
	[temperatures]="temperatures"
	[importData]="importData"
	[exportData]="exportData"
></eliq-homechart>

<div class="message-card" *ngIf="waitingForPVDisagg">
	<span style="position: relative; z-index: 1;">
		<h2 class="primary-p2">
			{{'home_consumption_summary_graph.cons_prod_coming_soon_header' | translateOrFallback:'insights_comparison.cons_prod_coming_soon_header'}}
		</h2>
		<div
			style="margin: 0px 0;"
			class="secondary-p2"
			[innerHTML]="'home_consumption_summary_graph.cons_prod_coming_soon_description' | translateOrFallback:'insights_comparison.cons_prod_coming_soon_description'"
		></div>

		<div style="width: 100%; display: flex; justify-content: center;">
			<ng-lottie
				width="100px"
				height="auto"
				[options]="{'path': '/assets/images/animated_graph.json', 'background': 'transparent', 'speed': 1, 'loop': true, 'autoplay': true}"
			></ng-lottie>
		</div>
	</span>
</div>
