import { Injectable } from '@angular/core'
import { JsonGetterService } from '../json-getter.service'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { HeaderConfig } from './header-config.model'

@Injectable({
	providedIn: 'root',
})
export class HeaderConfigService {
	constructor(private config: JsonGetterService) {}

	private headerConfig(): Observable<HeaderConfig> {
		return this.config.getConfig('header_options')
	}

	/**
	 * Returns a boolean representing whether or not we should be showing/"doing" notifications.
	 * Defaults to true if the option is not specified in the config file.
	 */
	getShowNotifications(): Observable<boolean> {
		return this.headerConfig().pipe(
			map((config) => config.showNotifications ?? true),
			catchError(() => of(true)), // default to true in the case the option isnt specified.
		)
	}

	getShowAccount(): Observable<boolean> {
		return this.headerConfig().pipe(
			map((config) => config.showAccount ?? true),
			catchError(() => of(true)), // default to true in the case the option isnt specified.
		)
	}

	getNoLogoWhenHamburger(): Observable<boolean> {
		return this.headerConfig().pipe(
			map((config) => config.noLogoWhenHamburger ?? false),
			catchError(() => of(false)),
		)
	}

	getCompanyLogoUrl(): Observable<string> {
		return this.headerConfig().pipe(
			map((config) => (config.companyLogoUrl ? config.companyLogoUrl : '/')),
		)
	}

	getCompanyLogoStyle(): Observable<Record<string, string>> {
		return this.headerConfig().pipe(
			map((config) => (config.companyLogoStyle ? config.companyLogoStyle : {})),
		)
	}
}
