import { Injectable } from '@angular/core'
import { APIYearlyConsumption } from '@eliq/models'
import { EliqApiHttpClient } from '@eliq/data-access'
import { APIMeter } from '@eliq/core/models/src/api-models/api-meter.model'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { YearlyEstimateSuggestion } from '../models/yearly-estimate-suggestion.model'

@Injectable({
	providedIn: 'root',
})
export class AnnualHttpService {
	getYearlyEstimateSuggestion(
		locId: number,
		confidenceLevelPercentage: number,
	) {
		return this.http.get<YearlyEstimateSuggestion>(
			`/v3/locations/${locId}/yearly_estimates/suggestion?confidence_level_percentage=${confidenceLevelPercentage}`,
		)
	}

	constructor(private http: EliqApiHttpClient) {}

	getAnnualEnergyFrom(locId: number, year: number) {
		return this.http.get<APIYearlyConsumption[]>(
			`/v3/locations/${locId}/yearly_estimates?fuel=elec&type=consumption&year_from=${year}&year_to=${
				year + 1
			}`,
		)
	}

	addAnnualEnergy(
		locId: number,
		energy: number,
	): Observable<APIYearlyConsumption[]> {
		return this.http.post(`/v3/locations/${locId}/yearly_estimates`, {
			fuel: 'elec',
			type: 'consumption',
			source: 'user',
			year: new Date().getFullYear() - 1,
			energy_wh: 1000 * energy,
		})
	}

	//For testing purposes only
	getMeters(locId: number) {
		return this.http
			.get<APIMeter[]>(`/v3/locations/${locId}/meters`)
			.pipe(
				tap((meters) => {
					if (meters.length > 0) {
					} else {
					}
				}),
			)
			.subscribe(
				(res) => {},
				(err) => {},
			)
	}
}
