import { ComponentType } from '@angular/cdk/portal'
import { Injectable, TemplateRef } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { ConfirmActionDialogComponent } from '../../components/confirm-action-dialog/confirm-action-dialog.component'
import { ErrorModalComponent } from '../../components/error-modal/error-modal.component'
import { LoadingModalComponent } from '../../components/loading-modal/loading-modal.component'
export interface OpenModalOptions extends MatDialogConfig {
	disableClose?: boolean
	autoFocus?: boolean
	width?: string
	data?: any // data to be passed into the modal component (https://material.angular.io/components/dialog/overview#sharing-data-with-the-dialog-component)
}

export interface ErrorData {
	message?: string
	error?: Error
	title?: string
	code?: string
	debugData?: any
}

@Injectable({ providedIn: 'root' })
export class ModalService {
	// a service components can use to open different types of modals. this service right here is just the messenger, so to say.
	// passes data to the component that is to live in the modal, then returns the response from the opened modal to the caller

	private defaultModalWidthNumber = 580
	private defaultModalMaxWidthNumber = 90
	private defaultModalWidth = this.defaultModalWidthNumber.toString() + 'px'
	private defaultModalMaxWidth =
		this.defaultModalMaxWidthNumber.toString() + 'vw'
	public options: MatDialogConfig = {
		width: this.defaultModalWidth,
		maxWidth: this.defaultModalMaxWidth,
		panelClass: 'eliq-modal',
		disableClose: false,
		maxHeight: '90vh',
		restoreFocus: false,
		autoFocus: false,
		ariaModal: true,
	}

	constructor(
		private dialog: MatDialog,
		private translator: TranslateService,
	) {}

	/**
	 * Opens an arbitrary modal component
	 * @param component
	 * @param options Some options of type OpenModalOptions (defined in modal.service.ts)
	 */

	openModal<T>(
		component: ComponentType<T> | TemplateRef<T>,
		openModalOptions?: OpenModalOptions,
	) {
		const options = Object.assign({ ...this.options }, openModalOptions) // shallow copy
		return this.dialog.open(component, options)
	}

	openErrorModal(errorData?: ErrorData | string) {
		const dialogRef = this.dialog.open(ErrorModalComponent, {
			...this.options,
			...{ panelClass: 'eliq-error-modal' },
		})
		let _errorData = errorData
		if (typeof errorData === 'undefined' || errorData === null) {
			_errorData = { message: this.translator.instant('common.general_error') }
		} else if (typeof errorData === 'string') {
			_errorData = { message: errorData }
		} else if (typeof errorData === 'object' && !errorData.message) {
			;(_errorData as ErrorData)['message'] = this.translator.instant(
				'common.general_error',
			)
		}
		dialogRef.componentInstance.errorData = _errorData as ErrorData
		return dialogRef.afterClosed()
	}

	/**
	 * None of these passed strings need to be translated, I'll handle it :)
	 * @param toConfirm
	 * @param noString
	 * @param yesString
	 * @param header
	 * @param width a string such as "520px"
	 * @param smaller multiplies the defaultModalWith by 0.9 to make it slightly smaller if nested modals are opened for example
	 * width parameter overrides the smaller parameter, so you should only provide one of these two options
	 */
	public openConfirmActionDialog(
		toConfirm: string,
		noString?: string,
		yesString?: string,
		header?: string,
		width?: string,
		smaller = false,
	) {
		const options = Object.assign({}, this.options)
		options.autoFocus = false

		if (width) {
			options.width = width
		} else if (smaller) {
			options.width = (this.defaultModalWidthNumber * 0.9).toString() + 'px'
			options.maxWidth =
				(this.defaultModalMaxWidthNumber * 0.9).toString() + 'vw'
		}

		const dialogRef = this.dialog.open(ConfirmActionDialogComponent, options)
		dialogRef.componentInstance.header = header
		dialogRef.componentInstance.question = this.translator.instant(toConfirm)
		dialogRef.componentInstance.optionNo = noString
		dialogRef.componentInstance.optionYes = yesString

		// returns an observable which can be subscribed to in order to see the outcome of the user's actions
		return dialogRef.afterClosed()
	}

	/**
	 * Opens a loading modal. Places over everything and blocks the website while loading. Cannot be closed until completed.
	 * @param loadingMessage The message to be shown. If not provided, using general "Loading..."-string. This string should be translated.
	 * @return Returns the dialogref which should be used to close the modal with .close().
	 */
	public openLoadingModal(loadingMessage?: string) {
		const dialogRef = this.dialog.open(LoadingModalComponent, {
			disableClose: true,
		})

		if (loadingMessage === undefined) {
			loadingMessage = this.translator.instant('common.please_wait')
		}

		if (dialogRef?.componentInstance?.loadingMessage) {
			;(dialogRef as any).componentInstance.loadingMessage = loadingMessage
		}
		return dialogRef
	}
}
