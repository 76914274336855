import {
	Component,
	ElementRef,
	OnChanges,
	OnInit,
	ViewEncapsulation,
} from '@angular/core'
import { Input } from '@angular/core'
import { Output } from '@angular/core'
import { EventEmitter } from '@angular/core'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CommonConfigService } from '@eliq/core/config'
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { NgStyle, NgIf, AsyncPipe } from '@angular/common'
import { EliqThemeService } from '@eliq/theme'
import { RouterLink } from '@angular/router'
import { NgTemplateOutlet } from '@angular/common'
import { defer, of } from 'rxjs'
@Component({
	selector: 'eliq-link',
	templateUrl: './link.component.html',
	styleUrls: ['./link.component.scss'],
	standalone: true,
	imports: [
		NgStyle,
		NgIf,
		InlineSVGModule,
		AsyncPipe,
		TranslateModule,
		ImgSrcPipe,
		RouterLink,
		NgTemplateOutlet,
	],
	//encapsulation: ViewEncapsulation.ShadowDom,
})
export class LinkComponent implements OnInit, OnChanges {
	// implements OnInit {
	@Input() label!: string
	@Input() disabled = false
	@Input() type = 'button'

	@Input() buttonStyle: Record<string, string> = {}
	@Input() colorKey = 'link'
	@Input() target: '_self' | '_blank' = '_blank'
	@Input() leftIcon: string | undefined | false = undefined

	@Output() clicked = new EventEmitter<any>()
	@Input() href = ''

	public isExternalLink = false

	public leftIconPath$ = defer(() => this.leftIcon === false ? of(undefined) : this.commonConfig.underlineButtonLeftIconPath())
	public leftIconSvgAttributes = {
		height: '18',
	}

	constructor(
		private elRef: ElementRef,
		private themeService: EliqThemeService,
		private commonConfig: CommonConfigService,
	) {}

	ngOnChanges() {
		this.isExternalLink =
			this.href.startsWith('http') || this.href.startsWith('//')
	}

	ngOnInit() {
		/**
		 * TODO I want a better way to do something similar with this, so you can easily
		 * overload the styles in the _colors.scss file or have it default to some
		 * other variable.
		 */
		if (Object.keys(this.buttonStyle).includes("color")) {
			return
		}
		const linkEvents = ['', 'visited', 'hover', 'active', 'focus']

		const linkColors = linkEvents.reduce((colors, event) => {
			let colorKeyFallback = this.colorKey
			if (colorKeyFallback.includes("-link")) {
				colorKeyFallback = colorKeyFallback.replace("-link", "")
			}
			colors[`link-component${event && '--' + event}`] = this.themeService.getProp(...[
				`${this.colorKey}${event && '--' + event}`,
				...((this.colorKey !== "link") ? [
					colorKeyFallback,
					`link${event && '--' + event}`
				] : [])
			])
			return colors
		}, {})
		this.themeService.setProps(this.elRef.nativeElement, linkColors)
	}

	handleClick(event: any) {
		if (this.disabled) return false
		this.clicked.emit(event)
		return true
	}
}
