import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core'
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { SnackbarService } from '@eliq/ui/snackbar'
import {
	EliqCurrencyPipe,
	EliqNumberPipe,
	InputHelperService,
} from '@eliq/core'
import { take } from 'rxjs/operators'
import { APIBudget } from '../../models/api-budget.model'
import { BudgetApiService } from '../../services/budget-api.service'
import { BudgetTrackingService } from '../../tracking/budget-tracking.service'
import { PeriodType } from '@eliq/core'
import { BasicButtonComponent } from '@eliq/ui'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { NgIf, getCurrencySymbol, getLocaleCurrencyCode } from '@angular/common'
import { MatRadioModule } from '@angular/material/radio'
import { TranslateModule } from '@ngx-translate/core'
import { XButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-budget-create-modal',
	templateUrl: './budget-create-modal.component.html',
	styleUrls: ['./budget-create-modal.component.scss'],
	standalone: true,
	imports: [
		XButtonComponent,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		MatRadioModule,
		NgIf,
		MatFormFieldModule,
		MatInputModule,
		BasicButtonComponent,
	],
})
export class BudgetCreateModalComponent implements OnInit {
	@Input() locId: number
	@Input() budgets: APIBudget[]

	public form: FormGroup

	public unitSymbol
	public prefixUnitSymbol = true

	public selectedResolutionSuggestedLimit = ''
	public selectedResolutionTranslationKey = ''
	private currentlySelectedBudget: APIBudget

	// random id used to stop the browser from autosuggesting
	public randomId = ''
	public createLoading = false

	public weekSelectable = false
	public monthSelectable = false

	constructor(
		@Inject(LOCALE_ID) private myLocale,
		private currencyPipe: EliqCurrencyPipe,
		private dialogRef: MatDialogRef<BudgetCreateModalComponent>,
		private fb: FormBuilder,
		private numberPipe: EliqNumberPipe,
		private inputHelper: InputHelperService,
		private budgetApi: BudgetApiService,
		private snackbar: SnackbarService,
		private tracking: BudgetTrackingService,
	) {}

	ngOnInit(): void {
		this.randomId = this.inputHelper.makeid(10)
		this.unitSymbol = this.budgetApi.getValueUnitSymbol(this.budgets)
		this.prefixUnitSymbol =
			this.unitSymbol ===
				getCurrencySymbol(
					getLocaleCurrencyCode(this.myLocale) || '',
					'narrow',
				) && this.currencyPipe.isCurrencySymbolSuffix()
				? false
				: true

		this.dialogRef
			.backdropClick()
			.pipe(take(1))
			.subscribe((_) => this.closeClicked())

		this.weekSelectable = !this.budgets?.find((b) => b?.resolution === 'week')
			?.enabled
		this.monthSelectable = !this.budgets?.find((b) => b?.resolution === 'month')
			?.enabled

		let preSelectedPeriod = 'month'
		const selectableBudgets = this.budgets.filter((b) => !b.enabled)
		if (selectableBudgets.length >= 1) {
			// TODO before it was '=== 1', i.e. 'if there is specifically 1 non-enabled'
			preSelectedPeriod = selectableBudgets[0].resolution
			this.currentlySelectedBudget = selectableBudgets[0]
			this.selectedResolutionTranslationKey =
				selectableBudgets[0].resolution + 'ly'
			this.selectedResolutionSuggestedLimit =
				this.budgetApi.getSuggestedLimitString(this.currentlySelectedBudget)
			if (this.selectedResolutionSuggestedLimit.includes('null ')) {
				this.selectedResolutionSuggestedLimit = ''
			}
		}

		this.form = this.fb.group({
			resolution: new FormControl(preSelectedPeriod, [Validators.required]),
			limit: new FormControl(undefined, [
				Validators.required,
				Validators.pattern('^[1-9]\\d*$'),
			]),
		})

		this.form.valueChanges.subscribe((changes) => {
			if (changes.resolution) {
				this.selectedResolutionTranslationKey = changes.resolution + 'ly'
				this.currentlySelectedBudget = this.budgets.find(
					(b) => b.resolution === changes.resolution,
				) as APIBudget
				this.selectedResolutionSuggestedLimit =
					this.budgetApi.getSuggestedLimitString(this.currentlySelectedBudget)
			}
		})
	}

	createBudgetClicked() {
		this.tracking.createBudgetCreated(
			<PeriodType>this.currentlySelectedBudget.resolution,
		)
		this.createLoading = true
		let formVal = this.form.get('limit')?.value
		if (this.currentlySelectedBudget.unit === 'energy') {
			formVal *= 1000
		}
		this.budgetApi
			.createBudget(this.locId, this.currentlySelectedBudget, formVal)
			.subscribe(
				(res) => {
					this.createLoading = false
					this.dialogRef.close(res)
				},
				(err) => {
					this.createLoading = false
					this.snackbar.doErrorToast()
				},
			)
	}

	closeClicked() {
		this.tracking.createBudgetCancelled()
		this.dialogRef.close()
	}
}
