export class APIBudget {
	enabled: boolean
	fuel: string
	resolution: string
	suggested_limit: number
	type: string
	unit: string
	limit: number
	status: {
		code: string
		data_until: string
		forecast: number
		period_start: string
		period_end: string
		result: string
		value: number
	}
}
/**
 * Checks if these are the same budgets or not.
 * Remember that a budget does not have an ID, it's uniqueness is
 * a 3-tuple (fuel, unit, resolution)
 * @param a a budget
 * @param b the other budget
 * @returns a boolean
 */
export function areBudgetsEqual(a: APIBudget, b: APIBudget): boolean {
	return a.fuel === b.fuel && a.unit === b.unit && a.resolution === b.resolution
}
