import { Renderer2, Component, OnInit, Inject } from '@angular/core'
import { DOCUMENT, NgIf } from '@angular/common'
import { ActivatedRoute } from '@angular/router'
import { ChatTrackerService } from '../../tracking/chat-tracker.service'
//import { WindowRef } from '@eliq/core/WindowRef'
import { ChatConfigService } from '../../services/chat-config.service'
import { ChatOptions } from '../../models/chat-config.model'
import { EnvironmentService } from '@eliq/data-access'

type ChatProvider = 'maxcontact' | 'hubspot' | 'drift' | 'kundo'

@Component({
	selector: 'eliq-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css'],
	standalone: true,
	imports: [NgIf],
})
export class ChatComponent implements OnInit {
	public options: ChatOptions
	public errorObject = null
	public params: any = {}
	public chatProvider: ChatProvider = 'drift'
	public chatKey = ''

	constructor(
		private environment: EnvironmentService,
		private configService: ChatConfigService,
		private r2: Renderer2,
		private activatedRoute: ActivatedRoute,
		private tracking: ChatTrackerService,
		@Inject(DOCUMENT) private _document: Document,
	) {}

	setup() {
		this.chatProvider = this.options.provider as ChatProvider
		this.chatKey = this.options.chatKey
		if (!this.environment.isUAT() && this.options.chatKeyProd) {
			this.chatKey = this.options.chatKeyProd
		}

		switch (this.options.provider) {
			case 'hubspot':
				if (!this.chatKey) {
					this.chatKey = '5212812'
				}
				this.setupHubspot()
				break
			case 'drift':
				this.setupDrift()
				break
			case 'maxcontact':
				this.setupMaxcontact()
				break
			case 'kundo':
				this.setupKundo()
				break
		}
	}

	setupHubspot() {
		const script = this.r2.createElement('script')
		script.type = `application/javascript`
		script.src = '//js.hs-scripts.com/' + this.chatKey + '.js'
		script.defer = true
		script.async = true

		script.addEventListener('load', () => {
			const wnd = this._document.defaultView ?? (window as any)

			wnd.hsConversationsSettings = {
				loadImmediately: true,
				inlineEmbedSelector: 'body',
				enableWidgetCookieBanner: true,
			}
			if (wnd.HubSpotConversations) {
				wnd.onConversationsAPIReady()
			} else {
				wnd.hsConversationsOnReady = [wnd.onConversationsAPIReady]
			}
			wnd.hsConversationsOnReady = [
				() => {
					wnd.HubSpotConversations.widget.load()

					const style = this._document.createElement('style')
					style.innerHTML = `
          body, html {
            overflow: hidden !important;
          }
          #hubspot-conversations-inline-iframe {
            position: absolute;
            top: 0;
            left: 0;
            overflow: hidden;
            height: 100%;
            width: 100%;
          }`
					this._document.getElementsByTagName('head')[0].appendChild(style)
				},
			]
		})
		script.id = 'hs-script-loader'
		this.r2.appendChild(this._document.body, script)
	}

	setupDrift() {
		const script = this.r2.createElement('script')
		script.type = `application/javascript`

		script.text = `
      "use strict";
      !function() {
        window?.chatLoading = true;
        var t = window?.driftt = window?.drift = window?.driftt || [];
        if (!t.init) {
          if (t.invoked) return void (window?.console && console.error && console.error("Drift snippet included twice."));
          t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
          t.factory = function(e) {
            return function() {
              var n = Array.prototype.slice.call(arguments);
              return n.unshift(e), t.push(n), t;
            };
          }, t.methods.forEach(function(e) {
            t[e] = t.factory(e);
          }), t.load = function(t) {
            var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
            o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
            var i = document.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(o, i);
          };
        }
      }();

      drift.SNIPPET_VERSION = '0.3.1';
      drift.load('${this.chatKey}');
      drift.on("ready", () => {

        drift.api.widget.hide();
        let driftContainer = document.getElementById("drift-frame-chat");

        function fixStyles() {
          if (driftContainer.style.width !== "100%") {
            if (driftContainer) {

              driftContainer.style.width = "100%";
              driftContainer.style.height = "100%";
              driftContainer.style.maxHeight = "100%";
              driftContainer.style.bottom = "0";
              driftContainer.style.right = "0";
              let frameChat = driftContainer.getElementsByClassName("drift-frame-chat")[0];
              frameChat.style.width = "100%";
              frameChat.style.height = "100%";
              frameChat.style.maxWidth = "100%";
            } else {
              driftContainer = document.getElementById("drift-frame-chat");
            }
            window?.chatLoading = false;
          }
          setTimeout(fixStyles, 50);
        }

        setTimeout(fixStyles, 0);

        drift.api.openChat();

        drift.identify("${this.params['name']}", {
          email: "${this.params['email']}",
          name: "${this.params['name']}"
        });
      })
    `
		this.r2.appendChild(this._document.body, script)
	}

	setupMaxcontact() {
		const theInterval = setInterval(() => {
			const maxForm = this._document.querySelector('#form') as HTMLDivElement
			if (maxForm?.dataset && maxForm.dataset['channelKey']) {
				maxForm.dataset['channelKey'] = this.chatKey
				const script = this.r2.createElement('script')
				script.type = `application/javascript`
				script.src =
					'https://chat.maxcontact.com/bes-uat-chat/max.chat.application.min.js'
				this.r2.appendChild(this._document.body, script)

				const style = this._document.createElement('style')
				style.innerHTML = `
          body, html {
            overflow: hidden !important;
          }
          .mainview .max-full-application .max-chat-header {
            width: 100% !important;
          }
          .mainview .max-full-application .max-close-button {
            display: none !important;
          }`
				this._document.getElementsByTagName('head')[0].appendChild(style)

				let clickN = 0
				const theSecondInterval = setInterval(() => {
					const closeBtn = this._document.querySelector(
						'.mainview .max-full-application .max-chat-header',
					) as HTMLDivElement
					if (closeBtn) {
						closeBtn.click()
						clickN += 1
					}
					if (clickN > 5) {
						clearInterval(theSecondInterval)
					}
				}, 50)

				clearInterval(theInterval)
			}
		}, 50)
	}

	public lookForKundo = 0 as any

	setupKundo() {
		{
			/*
      [KUNDO-HACK] Look for this in the web app to find related code

      This is a hack for Kundo chat which doesn't load using the script below in the component (though works in non-Angular apps).
      However, if we add the below chat script to index.html and if we redirect to the root '/?showChat=true' page, then chat is visible.
    */
			//window.location.href = '?showChat=true'
		}

		// If Kundo chat worked as other chat scripts we've added before, the below code would be enough and the hack above wouldn't be needed.
		const externalScript = this.r2.createElement('script')
		externalScript.type = 'application/javascript'
		externalScript.src = `https://static-chat.kundo.se/chat-js/org/${this.chatKey}/widget.js`
		externalScript.defer = false
		externalScript.async = false

		if (!this.options.flowKey)
			console.error('Missing Kundo Chat Flow Key');

		externalScript.addEventListener('load', () => {
			const w = this._document.defaultView ?? (window as any)
			w.$kundo_chat = w.$kundo_chat || {}
			w.$kundo_chat.custom_texts = {
				START_TEXT: 'Chatta med oss',
				CLOSE_TEXT: 'Stäng',
				CONTINUE_TEXT: 'Fortsätt',
				LOAD_TEXT_DEFAULT: '',
				START_TEXT_DEFAULT: 'Chatta med oss',
			}
			this.lookForKundo = setInterval(() => {
				const kundoChat = this._document.querySelector(
					'#kundo-chat',
				) as HTMLDivElement
				if (kundoChat) {
					w.$kundo_chat.start_chat(this.options.flowKey, true)
					//w.$kundo_chat.onChatEnd()
					//w.$kundo_chat.show_widget()
					//w.$kundo_chat.hide_widget()
					//w.$kundo_chat.start_widget()
					kundoChat.style.display = 'block'
					clearInterval(this.lookForKundo)
				}
			}, 100)
		})

		this.r2.appendChild(this._document.body, externalScript)
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.params = params

			this.configService.getChatConfig().subscribe((options) => {
				this.options = options
				this.setup()
			})
		})
	}
}
