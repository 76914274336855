<div class="container" [style.color]=" greyBorder ? 'black' : '#003665'">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<h5 class="primary-h5 header">{{successTitleText}}</h5>
	<div class="div_rounded_picture">
		<img
			class="bolt-image"
			[src]="'/assets/images/bolt.svg'"
			alt=""
			height="50"
		/>
	</div>

	<div class="text-area">
		<p class="secondary-p3 long-text">
			{{thanks}} {{forWhat}} {{whatHappened}}
			<b>{{estimatedEnergy}} {{valueSuffix}}</b>
		</p>
		<p></p>
	</div>

	<div
		class="button-area"
		style="display: flex; align-items: center; flex-wrap: wrap; justify-content: center;"
	>
		<div class="button-container">
			<eliq-basic-button
				class="closeBtn"
				(clicked)="saveClicked()"
				[submit]="true"
				[useEntireWidth]="true"
			>
				Close
			</eliq-basic-button>
		</div>
	</div>
</div>
