// @ts-nocheck

import { APIAgreement, APIRenewal } from './api-models/api-agreement.model'

export class Agreement {
	private fuels: AgreementFuel[]
	private name: string
	public renewal: APIRenewal
	public id = ''
	public from: Date
	public to: Date

	constructor(apiAgreement: APIAgreement) {
		// map the api object to an actual object
		this.fuels = apiAgreement.fuels.map((fuel) => {
			return new AgreementFuel(
				fuel.fuel,
				fuel.details.map((apiDetail) => {
					return new AgreementFuelDetail(
						apiDetail.name,
						apiDetail.type,
						apiDetail.value,
					)
				}),
				fuel.devices.map((apiDevice) => {
					return new AgreementFuelDevice(
						apiDevice.id,
						apiDevice.ext_ref,
						apiDevice.user_ref,
					)
				}),
			)
		})
		this.name = apiAgreement.name
		this.renewal = apiAgreement.renewal
		this.id = apiAgreement.id
		this.from = apiAgreement.from_date
			? new Date(apiAgreement.from_date)
			: undefined
		this.to = apiAgreement.to_date ? new Date(apiAgreement.to_date) : undefined
	}

	/**
	 * Getter $fuels
	 * @return {AgreementFuel[]}
	 */
	public get $fuels(): AgreementFuel[] {
		return this.fuels
	}

	/**
	 * Getter $name
	 * @return {string}
	 */
	public get $name(): string {
		return this.name
	}

	/**
	 * Setter $fuels
	 * @param {AgreementFuel[]} value
	 */
	public set $fuels(value: AgreementFuel[]) {
		this.fuels = value
	}

	/**
	 * Setter $name
	 * @param {string} value
	 */
	public set $name(value: string) {
		this.name = value
	}
}

export class AgreementFuel {
	private fuel: string
	private details: AgreementFuelDetail[]
	private devices: AgreementFuelDevice[]

	constructor(
		$fuel: string,
		$details: AgreementFuelDetail[],
		$devices: AgreementFuelDevice[],
	) {
		this.fuel = $fuel
		this.details = $details
		this.devices = $devices
	}

	/**
	 * Getter $fuel
	 * @return {string}
	 */
	public get $fuel(): string {
		return this.fuel
	}

	/**
	 * Getter $details
	 * @return {AgreementFuelDetail[]}
	 */
	public get $details(): AgreementFuelDetail[] {
		return this.details
	}

	/**
	 * Getter $devices
	 * @return {AgreementFuelDevice[]}
	 */
	public get $devices(): AgreementFuelDevice[] {
		return this.devices
	}

	/**
	 * Setter $fuel
	 * @param {string} value
	 */
	public set $fuel(value: string) {
		this.fuel = value
	}

	/**
	 * Setter $details
	 * @param {AgreementFuelDetail[]} value
	 */
	public set $details(value: AgreementFuelDetail[]) {
		this.details = value
	}

	/**
	 * Setter $devices
	 * @param {AgreementFuelDevice[]} value
	 */
	public set $devices(value: AgreementFuelDevice[]) {
		this.devices = value
	}
}

export class AgreementFuelDetail {
	private name: string
	private type: string
	private value: string

	constructor($name: string, $type: string, $value: string) {
		this.name = $name
		this.type = $type
		this.value = $value
	}

	/**
	 * Getter $name
	 * @return {string}
	 */
	public get $name(): string {
		return this.name
	}

	/**
	 * Getter $type
	 * @return {string}
	 */
	public get $type(): string {
		return this.type
	}

	/**
	 * Getter $value
	 * @return {string}
	 */
	public get $value(): string {
		return this.value
	}

	/**
	 * Setter $name
	 * @param {string} value
	 */
	public set $name(value: string) {
		this.name = value
	}

	/**
	 * Setter $type
	 * @param {string} value
	 */
	public set $type(value: string) {
		this.type = value
	}

	/**
	 * Setter $value
	 * @param {string} value
	 */
	public set $value(value: string) {
		this.value = value
	}
}

export class AgreementFuelDevice {
	private id: string
	private extRef: string
	private userRef: string

	constructor($id: string, $extRef: string, $userRef: string) {
		this.id = $id
		this.extRef = $extRef
		this.userRef = $userRef
	}

	/**
	 * Getter $id
	 * @return {string}
	 */
	public get $id(): string {
		return this.id
	}

	/**
	 * Getter $extRef
	 * @return {string}
	 */
	public get $extRef(): string {
		return this.extRef
	}

	/**
	 * Getter $userRef
	 * @return {string}
	 */
	public get $userRef(): string {
		return this.userRef
	}

	/**
	 * Setter $id
	 * @param {string} value
	 */
	public set $id(value: string) {
		this.id = value
	}

	/**
	 * Setter $extRef
	 * @param {string} value
	 */
	public set $extRef(value: string) {
		this.extRef = value
	}

	/**
	 * Setter $userRef
	 * @param {string} value
	 */
	public set $userRef(value: string) {
		this.userRef = value
	}
}
