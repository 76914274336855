<div class="container">
	<h6 class="primary-h6 header">
		{{ 'submit_meter_read.info_dialog_title' | translate }}
	</h6>
	<p class="secondary-p2">
		{{ 'submit_meter_read.info_dialog_description' | translate }}
	</p>
	<div class="button">
		<eliq-basic-button
			(clicked)="close()"
			[label]="'common.ok' | translate"
			[variant]="'secondary'"
		></eliq-basic-button>
	</div>
</div>
