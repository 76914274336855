import { Injectable } from '@angular/core'
import { forkJoin, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { APIData } from '../../models/src/api-models/api-data.model'
import { LocationHttpService } from '../http-services/location-http.service'

@Injectable({
	providedIn: 'root',
})
export class DataGetterService {
	constructor(private locationHttp: LocationHttpService) {}

	public getData(
		locationId: number,
		dataType: string,
		fuel: string,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APIData> {
		return this.locationHttp.getLocationData(
			locationId,
			dataType,
			fuel,
			unit,
			resolution,
			fromDt,
			toDt,
		)
	}

	public getDatas(
		locationId: number,
		dataTypes: string[],
		fuel: string,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<Map<string, APIData>> {
		return forkJoin(
			dataTypes.map((type) =>
				this.getData(locationId, type, fuel, unit, resolution, fromDt, toDt),
			),
		).pipe(
			map((result) => {
				const myMap: Map<string, APIData> = new Map()
				result.forEach((apiData) => myMap.set(apiData.dataType, apiData))
				return myMap
			}),
		)
	}
}
