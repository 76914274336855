import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
} from '@angular/core'
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms'
import { SmrApiService } from '@eliq/feature/smr-card/services/smr-api.service'
import { DatePipe, NgFor, NgForOf, NgIf } from '@angular/common'
import { EliqDatePipe, ImgSrcPipe } from '@eliq/core'
import { NgxPopperjsModule } from 'ngx-popperjs'
import { SmrMeter } from '@eliq/feature/smr-card/models/SmrMeter.model'
import { MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { TranslateModule } from '@ngx-translate/core'
import { MatLabel } from '@angular/material/form-field'

@Component({
	selector: 'eliq-supply-dates-dropdown',
	templateUrl: './supply-dates-dropdown.component.html',
	styleUrls: ['./supply-dates-dropdown.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		EliqDatePipe,
		NgxPopperjsModule,
		NgIf,
		DatePipe,
		ImgSrcPipe,
		ReactiveFormsModule,
		NgFor,
		NgForOf,
		MatOptionModule,
		MatSelectModule,
		TranslateModule,
	],
})
export class SupplyDatesDropdownComponent implements OnInit {
	@Input() meter: SmrMeter
	@Input() supplyDateCtrl = new FormControl<Date>(new Date(), [])

	@Output() selectedDate = new EventEmitter<Date>()

	public dates: string[] = []
	public supplyDates: Date[] = []
	public loaded: boolean

	currentDate: string
	translationDate: string
	translationString: string

	constructor(private api: SmrApiService, private datePipe: EliqDatePipe) {}

	ngOnInit(): void {
		this.getAvailableSubmitDates()
	}
	/*
	private setupBefore = false
	ngOnChanges(changes): void {



		if (
			changes['supplyDates'] &&
			changes['supplyDates']['currentValue'] &&
			this.supplyDateCtrl &&
			this.supplyDates?.length > 0
		) {
			const latestSupplyDate = this.supplyDates[0]

			this.supplyDateCtrl.setValue(latestSupplyDate)
			this.selectedDate.emit(latestSupplyDate)
			this.setupBefore = true
		}
	}*/

	public onSelected(value: Date) {
		this.selectedDate.emit(value)
		this.supplyDateCtrl.setValue(value)
	}

	getAvailableSubmitDates() {
		this.api.getDetailsOfMeter(this.meter.id).subscribe({
			next: (result) => {
				this.dates = result.available_submit_dates

				this.currentDate = this.datePipe.transform(
					new Date(),
					'short',
				) as string

				if (!result.supply_end_date || result.supply_end_date == undefined) {
					const startDate = this.datePipe.transform(
						new Date(result.supply_start_date),
						'short',
					)
					this.translationString = 'submit_meter_read.supply_start_date_message'
					this.translationDate = startDate as string
				} else if (result.supply_end_date) {
					const endDate = this.datePipe.transform(
						new Date(result.supply_end_date),
						'short',
					)
					if (new Date(result.supply_end_date) < new Date()) {
						this.translationString =
							'submit_meter_read.supply_end_date_in_past_message'
						this.translationDate = endDate as string
					} else {
						this.translationString =
							'submit_meter_read.supply_end_date_in_future_message'
						this.translationDate = endDate as string
					}
				}

				if (this.dates) {
					for (let i = 0; i < this.dates.length; i++) {
						this.supplyDates[i] = new Date(this.dates[i])
						this.loaded = true
					}
				}

				const latestSupplyDate = this.supplyDates[0]
				this.onSelected(latestSupplyDate)
			},
			error: (err) => console.error('ERROR HERE (68)', err),
		})
	}
}
