import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { PropertyMultipleSelect } from '@eliq/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { NgFor } from '@angular/common'
import { PropertyIconComponent } from '@eliq/ui/common/components/property-icon/property-icon.component'

@Component({
	selector: 'eliq-homedetails-multiple-select',
	templateUrl: './homedetails-multiple-select.component.html',
	styleUrls: ['./homedetails-multiple-select.component.css'],
	standalone: true,
	imports: [
		PropertyIconComponent,
		NgFor,
		MatCheckboxModule,
		TranslateModule,
		ImgSrcPipe,
	],
})
export class HomedetailsMultipleSelectComponent implements OnInit {
	@Input() property: PropertyMultipleSelect

	@Output() formValueChange = new EventEmitter<any>()
	@Output() formStatusChange = new EventEmitter<boolean>()

	constructor() {}

	ngOnInit() {}

	valueChange(option: string, checked: boolean) {
		if (this.property.$placeholder === undefined) {
			this.property.$placeholder = Object.assign([], this.property.$value)
		}

		let arr: string[] = Object.assign([], this.property.$placeholder)
		if (checked) {
			// we are to add something to the placeholder array
			arr.push(option)
		} else {
			// we are to remove something from the placeholder array
			arr = arr.filter((o) => {
				return o !== option
			})
		}

		this.formValueChange.emit(arr)
		this.formStatusChange.emit(this.property.hasValidSelection())
	}

	isKeySelected(key: string): boolean {
		if (this.property.getCurrentSelection() === undefined) return false
		return this.property.getCurrentSelection().some((selectedKey) => {
			return selectedKey === key
		})
	}
}
