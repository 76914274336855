<div>
	<button
		(click)="resetProperties()"
		title="Reset properties: nulls all home properties. Refresh page after click."
	>
		Reset homeprofile
	</button>
	<button
		(click)="resetTerms()"
		title="Reset terms: nulls t&c's acceptance. Refresh page after click."
	>
		Reset terms
	</button>
	<button
		*ngIf="nots"
		(click)="unreadNotifications()"
		title="Patch nots: Sets a few of the top notifications to unread status. (see top right)"
	>
		Patch notifications
	</button>
	<button
		(click)="setReasonableHomeProfile()"
		title="Sets the home profile to something that will belong to a similar homes group."
	>
		Set good home profile
	</button>
	<button
		(click)="tryHomeProfileSetupWizard()"
		title="Try the home profile setup wizard without logging in and out over and over.... Combine this with reseting the home profile for example in the other button."
	>
		Try home profile setup wizard
	</button>
	<button
		(click)="removeConsents()"
		title="Remove consents: sets all consents to false. Refresh the page afterwards."
	>
		Remove consents
	</button>

	<ng-container *ngIf="!localStorageAuth">
		<button
			(click)="refreshCookieAuthTokens()"
			title="Attempts to get a new pair of (accessToken, refreshToken) using your refreshToken. Nothing will happen, but you can examine the network tab to see if it worked."
		>
			Refresh auth tokens
		</button>
		<button
			(click)="invalidateCookieAccessToken()"
			title="Invalidates the access token. Check out the network tab. After invalidation, try to do something requiring a request. You can see how the invalid AT gets rejected and then refreshed before proceeding with the paused request."
		>
			Invalidate access token
		</button>
		<button
			(click)="invalidateCookieTokens()"
			title="Invalidates both tokens. This simulates the situation where a user visits the site but both tokens are outdated. Upon the first failed request, a refresh attempt will be made, which will also fail, and lastly you will be logged out. "
		>
			Invalidate both tokens
		</button>
	</ng-container>

	<button
		(click)="setAllowPVData(true)"
		title="Click to allow PV data. Note that a user that doesn't initially have any PV data won't magically get it."
	>
		Allow PV Data
	</button>
	<button
		(click)="setAllowPVData(false)"
		title="Click to disallow PV data. This means that only consumption data will be available."
	>
		Disallow PV Data
	</button>

	<button
		(click)="addAnnualElectricity()"
		title="Click to show update annual electricity consumption simulating a no-meter user during new year clicking to add annual."
	>
		No-meter new year
	</button>

	<button
		(click)="removePreviousYearAnnualElectricity()"
		title="Click to remove previous annual electricity consumption simulating an old no-meter user who didn't fill in previous year data."
	>
		Reset no-meter
	</button>
</div>
