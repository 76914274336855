import { Component, Input, OnInit } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import { NotificationDataSourceReport } from '../../models/NotificationDataSourceReport.model'
import { DateHelper } from '@eliq/util'
import { TranslateModule } from '@ngx-translate/core'
import { LinkComponent } from '@eliq/ui'
import { RouterLink } from '@angular/router'

@Component({
	selector: 'eliq-notification-item-monthly-insights',
	templateUrl: './notification-item-monthly-insights.component.html',
	styleUrls: ['./notification-item-monthly-insights.component.scss'],
	standalone: true,
	imports: [RouterLink, LinkComponent, TranslateModule],
})
export class NotificationItemMonthlyInsightsComponent implements OnInit {
	@Input() notification: NotificationData

	private dateCalculator: DateHelper

	public routerParams: any

	constructor() {}

	ngOnInit(): void {
		this.dateCalculator = DateHelper.getInstance()
		this.routerParams = this.getRouterParams()
	}

	private getRouterParams(): any {
		const source = <NotificationDataSourceReport>this.notification.$source

		let date: Date | null = source && source.$date ? source.$date : null
		const period: string = source && source.$period ? source.$period : 'month'

		if (!date) {
			date = new Date()
			date = new Date(date.getFullYear(), date.getMonth() - 1, 1) // Using previous month's first day as date.
		}

		const params = {
			date: this.dateCalculator.getApiCompliantSingleDate(date),
			period: period,
		}

		return params
	}
}
