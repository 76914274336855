import { Component, Input, OnInit } from '@angular/core'
import { DaysLeftPipe } from '../../pipes/days-left.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { LinkComponent } from '@eliq/ui'
import { RouterLink } from '@angular/router'

@Component({
	selector: 'eliq-contract-renewal-home-card',
	templateUrl: './contract-renewal-home-card.component.html',
	styleUrls: ['./contract-renewal-home-card.component.css'],
	standalone: true,
	imports: [RouterLink, LinkComponent, TranslateModule, DaysLeftPipe],
})
export class ContractRenewalHomeCardComponent implements OnInit {
	@Input() expiryDate: Date
	@Input() agreementId: string

	constructor() {}

	ngOnInit(): void {}
}
