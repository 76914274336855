import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	Input,
	inject,
} from '@angular/core'
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms'
import {
	CoreDataStoreService,
	EliqNumberPipe,
	LocationHttpService,
	PropertyNumber,
} from '@eliq/core'
import { InputHelperService } from '@eliq/core'
import { TranslateModule } from '@ngx-translate/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { MatSliderModule } from '@angular/material/slider'
import { DOCUMENT, NgIf } from '@angular/common'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { PropertyIconComponent } from '@eliq/ui/common/components/property-icon/property-icon.component'
import {
	catchError,
	delay,
	map,
	of,
	retry,
	retryWhen,
	switchMap,
	take,
	throwError,
	timer,
} from 'rxjs'
import { SpinnerComponent } from '@eliq/ui'
import { EnvironmentService } from '@eliq/data-access'

@Component({
	selector: 'eliq-homedetails-homesize',
	templateUrl: './homedetails-homesize.component.html',
	styleUrls: ['./homedetails-homesize.component.css'],
	standalone: true,
	imports: [
		SpinnerComponent,
		EliqNumberPipe,
		PropertyIconComponent,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		NgIf,
		MatSliderModule,
		ImgSrcPipe,
		TranslateModule,
	],
})
export class HomedetailsHomesizeComponent implements OnInit {
	//@Input() sliderValue = 50;
	//@Input() title: string;
	//@Input() min: number;
	//@Input() max: number;

	@Input() property!: PropertyNumber

	@Output() formStatusChanged: EventEmitter<boolean> =
		new EventEmitter<boolean>()
	@Output() formValueChanged: EventEmitter<number> = new EventEmitter<number>()
	public form!: FormGroup
	//public tickInterval = 5;

	public randomId = ''
	public suffix = 'm^2'

	public estimationKwh: number | '-' = '-'

	constructor(
		private inputHelper: InputHelperService,
		private fb: FormBuilder,
		private locHttp: LocationHttpService,
		private coreDS: CoreDataStoreService,
		private env: EnvironmentService,
	) {}

	public isIntegrationless = this.env.isIntegrationless()

	ngOnInit() {
		if (this.property.$key === 'yearlyenergy') {
			this.suffix = 'energy'

			this.coreDS
				.getActiveLocation(true)
				.pipe(
					take(1),
					switchMap((location) => {
						return this.locHttp
							.getYearlyEstimateSuggestion(location?.id ?? 0, 90)
							.pipe(take(1))
					}),
					map((suggestion) => {
						this.estimationKwh = suggestion.yearly_consumption.estimate / 1000
						return suggestion.yearly_consumption
					}),
					catchError((error) => {
						console.error(error)
						if (
							error?.error?.code ===
							'LOCATION_PROPERTIES_SETTINGS_IS_NOT_COMPLETE'
						) {
							console.error(
								'LOCATION_PROPERTIES_SETTINGS_IS_NOT_COMPLETE',
							)
							return throwError(() => error)
						}

						return of(null)
					}),
					//retryWhen(errors.fixedDelay(3, Duration.ofSeconds(10)).filter(e -> e instanceof ConnectTimeoutException))
					retry({
						count: 6,
						delay: () => timer(500),
						resetOnSuccess: false,
					}),
					take(1),
				)
				.subscribe({
					next: (est_cons) => {
						if (est_cons && (est_cons?.estimate ?? 0) > 0) {
							this.property.$placeholder =
								this.property.$value ||
								this.property.$placeholder ||
								est_cons.estimate / 1000

							this.property.$limits.min = Math.round(
								est_cons.lower_bound / 1000,
							)
							this.property.$limits.max = Math.round(
								est_cons.upper_bound / 1000,
							)
						} else {
							this.property.$placeholder =
								this.property.$value || this.property.$placeholder || 9001
						}

						if (!this.property.$value) {
							//this.property.savePlaceholder()
							this.sliderTempValue = this.property.$placeholder
							this.property.savePlaceholder()
							this.property.$placeholder = this.sliderTempValue
							this.property.savePlaceholder()
							this.sliderMovedUpdate()
						}
					},
					error: () => {
						//this.sliderTempValue = this.property.$placeholder
						//this.property.savePlaceholder()
						//this.property.$placeholder = this.sliderTempValue
						//this.sliderMovedUpdate()
					},
				})
		} else {
			this.property.$placeholder =
				this.property.$value || this.property.$placeholder || 0
		}

		this.randomId = this.inputHelper.makeid(5)
		this.form = this.fb.group({
			homesize: [this.property.getCurrentSelection(), Validators.required],
		})
		this.form.statusChanges.subscribe(() => {
			this.formStatusChanged.emit(this.property.$placeholder !== undefined)
		})

		this.form.valueChanges.subscribe(() => {
			this.formValueChanged.emit(+this.form.value.homesize)
		})
	}

	private sliderLastMoveTime: number | undefined
	private sliderTempValue: number | undefined
	private sliderDebounceTimer: NodeJS.Timeout
	sliderMovedUpdate = () => {
		if (typeof this.sliderTempValue === 'undefined') {
			return
		}
		this.property.$placeholder =
			Math.round(this.sliderTempValue / this.property.$limits.step) *
			this.property.$limits.step
		this.form.setValue({ homesize: this.property.$placeholder })
	}

	sliderMoved(event: any) {
		this.sliderTempValue = event.value

		// Do one throttled update so that the slider keeps moving while user is dragging
		const delay = 50
		if (
			this.sliderLastMoveTime &&
			Date.now() - this.sliderLastMoveTime < delay
		) {
			return
		}

		this.sliderMovedUpdate()

		// Do one debounced update so that the final location when user lets go of knob is correct
		clearTimeout(this.sliderDebounceTimer)
		this.sliderDebounceTimer = setTimeout(this.sliderMovedUpdate, delay * 2)

		this.sliderLastMoveTime = Date.now()
	}

	private _document = inject(DOCUMENT)
	onBlurInput(_event: FocusEvent) {
		const sel = this._document.defaultView?.getSelection()
		sel?.removeAllRanges()
	}

	onFocusInput(event: FocusEvent) {
		const sel = this._document.defaultView?.getSelection()
		sel?.removeAllRanges()
		const target = event.target as HTMLInputElement
		target.setSelectionRange(0, target?.value?.length)
	}

	sizeKeydown(event: any) {
		if (
			['delete', 'backspace', 'tab', 'arrowleft', 'arrowright'].includes(
				event.key.toLowerCase(),
			)
		) {
			return true
		}

		if (isNaN(parseInt(event.key)) || isNaN(parseInt(event.keyCode))) {
			event.preventDefault()
			return false
		}

		return true

		if (
			this.inputHelper.isOKNumberKey(event.key) ||
			this.inputHelper.isOKNumberKey(event.keyCode)
		) {
			return true
		}
		return false

		if (event.target.value.length > 4) {
			// if the input field is full, as in it has 3 characters in it, only accept characters
			// like backspace, delete, tab, arrows, and such
			if (!this.inputHelper.isOKFullKey(event.keyCode)) {
				event.preventDefault()
				return false
			}
		} else {
			// here we the input field is not full, so we accept all characters (movement chars and numbers)
			if (
				!this.inputHelper.isOKFullKey(event.keyCode) &&
				!this.inputHelper.isOKNumberKey(event.keyCode)
			) {
				event.preventDefault()
				return false
			}
		}
		return false
	}

	sizeKeyup(event: any) {
		if (isNaN(parseInt(event.key))) {
			event.preventDefault()
			return false
		}

		const size = event.target.value

		if (size === '') {
			this.property.$placeholder = this.property.$limits.min
		} else if (size > this.property.$limits.max) {
			// Remove last digit
			const digitsCount = size.length
			const adjustedSize = size.slice(0, digitsCount - 1)

			event.target.value = adjustedSize
			this.property.$placeholder = adjustedSize

			return true
		} else if (size >= this.property.$limits.min) {
			this.property.$placeholder =
				Math.round(event.target.value / this.property.$limits.step) *
				this.property.$limits.step
			return true
		}

		return false
	}
}
