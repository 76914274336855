import { Inject, Injectable, inject } from '@angular/core'
//import { Level } from 'level'
import { TransferState, StateKey, makeStateKey } from '@angular/core'
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common'
import { PLATFORM_ID } from '@angular/core'
import { Observable } from 'rxjs'
import * as CryptoJS from 'crypto-js'
import { get, set, del, clear } from 'idb-keyval'
import { HttpHeaders, HttpRequest } from '@angular/common/http'
import { ModalService } from '@eliq/ui/modal'
import { CookieConsentModalComponent } from './cookie-consent-modal-component/cookie-consent-modal-component.component'
import {
	CookieDict,
	CookieOptions,
	CookieService as NgxCookieService,
} from 'ngx-cookie'
import { EnvironmentService } from '@eliq/data-access'

export const COOKIE_CONSENT_STATUS_KEYS = [
	'necessary',
	'preferences',
	'statistics',
	'marketing',
] as const

export type CookieConsentStatusKeyType =
	(typeof COOKIE_CONSENT_STATUS_KEYS)[number]

export type CookieConsentStatus = {
	[key in CookieConsentStatusKeyType]: boolean
}

export type CookieConsentStatusGeneric<T> = {
	[key in CookieConsentStatusKeyType]: Readonly<T>
}

/**
 * @description Mapping of cookie consent status to cookie names.
 */
export type CookieConsentMapping = {
	[key in CookieConsentStatusKeyType]?: string[]
}

const COOKIE_CONSENT_MAPPING = 'c_c_m'
const COOKIE_CONSENT_STATUS = 'c_c_s'

@Injectable({
	providedIn: 'root',
})
export class CookieService {
	public ngxCookieService = inject(NgxCookieService)

	private cookies: Record<string, string> = {}
	private necessaryCookies: string[] = []
	private document = inject(DOCUMENT)
	private modalService = inject(ModalService)
	private env = inject(EnvironmentService)

	// Stores all the cookies that have been blocked and potentially re-adds them later.
	//private blockedCookies: Set<string> = new Set()

	private eliqBlockedCookies = new Set<string>()
	constructor() {
		if (this.env.isBrowser()) {
			//this.blockCookies()
			//this.showCookieConsentIfNeeded()
		}
	}

	/**
	 * @description
	 * Returns if the given cookie key exists or not.
	 *
	 * @param key Id to use for lookup.
	 * @returns true if key exists, otherwise false.
	 */
	hasKey(key: string): boolean {
		return this.ngxCookieService.hasKey(key)
	}
	/**
	 * @description
	 * Returns the value of given cookie key.
	 *
	 * @param key Id to use for lookup.
	 * @returns Raw cookie value.
	 */
	get(key: string): string | undefined {
		return this.ngxCookieService.get(key)
	}
	/**
	 * @description
	 * Returns the deserialized value of given cookie key.
	 *
	 * @param key Id to use for lookup.
	 * @returns Deserialized cookie value.
	 */
	getObject(key: string): object | undefined {
		return this.ngxCookieService.getObject(key)
	}
	/**
	 * @description
	 * Returns a key value object with all the cookies.
	 *
	 * @returns All cookies
	 */
	getAll(): CookieDict {
		return this.ngxCookieService.getAll()
	}

	/**
	 * @description
	 * Sets a value for given cookie key.
	 *
	 * @param key Id for the `value`.
	 * @param value Raw value to be stored.
	 * @param options (Optional) Options object.
	 */
	put(
		key: string,
		value: string | undefined,
		cookieConsentKey: CookieConsentStatusKeyType,
		options?: CookieOptions,
		skipConsentCheck?: boolean,
	): void {
		/*if (this.env.isBrowser()) {
			if (cookieConsentKey && !skipConsentCheck) {
				this.setCookieConsentMapping(key, cookieConsentKey)
				this.showCookieConsentIfNeeded()
			}
		} else if ('necessary' === cookieConsentKey) {
			this.necessaryCookies.push(key)
		}*/
		this.ngxCookieService.put(key, value, options)
	}
	/**
	 * @description
	 * Serializes and sets a value for given cookie key.
	 *
	 * @param key Id for the `value`.
	 * @param value Value to be stored.
	 * @param options (Optional) Options object.
	 */
	putObject(
		key: string,
		value: object,
		cookieConsentKey: CookieConsentStatusKeyType,
		options?: CookieOptions,
		skipConsentCheck?: boolean,
	): void {
		/*if (this.env.isBrowser()) {
			if (cookieConsentKey && !skipConsentCheck) {
				this.setCookieConsentMapping(key, cookieConsentKey)
				this.showCookieConsentIfNeeded()
			}
		} else if ('necessary' === cookieConsentKey) {
			this.necessaryCookies.push(key)
		}*/
		this.ngxCookieService.putObject(key, value, options)
	}
	/**
	 * @description
	 * Remove given cookie.
	 *
	 * @param key Id of the key-value pair to delete.
	 * @param options (Optional) Options object.
	 */
	remove(key: string, options?: CookieOptions): void {
		this.ngxCookieService.remove(key, options)
	}
	/**
	 * @description
	 * Remove all cookies.
	 */
	removeAll(options?: CookieOptions): void {
		this.ngxCookieService.removeAll(options)
	}

	public simpleParseCookieString = (
		str: string | Record<string, string>,
	): Record<string, string> => {
		if (typeof str === 'object') {
			return structuredClone(str)
		} else if (typeof str === 'string') {
			return str
				.split(';')
				.map((v) => v.split('='))
				.reduce((acc, v) => {
					acc[decodeURIComponent(v[0]?.trim())] = decodeURIComponent(
						v[1]?.trim(),
					)
					return acc
				}, {})
		} else {
			return {}
		}
	}

	public simpleGetCookieStringValue = (cookies: string, key: string) => {
		const rawCookies = cookies.split(';')
		return (
			rawCookies.find(
				(c) => decodeURIComponent(c.split('=')[0]).trim() === key,
			) ?? null
		)
	}

	public simpleEncodeCookieRecord = (obj: Record<string, string>) =>
		Object.entries(obj)
			.map(
				([key, value]) =>
					`${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
			)
			.join('; ')

	private cookieHandledEvent(cookie: string, blocked: boolean) {
		if (blocked) {
			this.eliqBlockedCookies.add(cookie)
		}
	}

	private blockCookies() {
		const document = window?.document as any
		const documentProto: any =
			Document?.prototype ?? document.prototype ?? HTMLDocument?.prototype

		if (
			documentProto.eliqSavedCookieGetter ||
			documentProto.eliqSavedCookieSetter
		) {
			return
		}

		/**
		 * From my experiments I've found this:
		  		const ref = document.__lookupSetter__('cookie')
					const testC = 'test2=helloworld'
					ref.call(document, testC)
					document.cookie.search(testC)
		 * to work...
		 */

		const defaultCookieConsentKey = 'marketing'
		const defaultAllowed =
			this.getCookieConsentStatus()[defaultCookieConsentKey] === true

		documentProto.eliqSavedCookieGetter = (document as any).__lookupGetter__(
			'cookie',
		)
		documentProto.eliqSavedCookieSetter = (document as any).__lookupSetter__(
			'cookie',
		)

		documentProto.eliqConsentDefaultAllowed = defaultAllowed
		documentProto.eliqCookieHandledEvent = this.cookieHandledEvent.bind(this)
		documentProto.eliqIsCookieAllowed = this.isCookieAllowed.bind(this)
		documentProto.eliqSetCookieConsentMapping =
			this.setCookieConsentMapping.bind(this)
		// This is crazy, reason I added this was because there might be a possibility
		// that I need to override the getter also because I override the setter,
		// even though I want the getter to stay the same functionally.
		document.__defineGetter__(
			'cookie',
			function cookie() {
				return document.eliqSavedCookieGetter.call(document, cookie)
			}.bind(document),
		)

		// This is also crazy, but it works
		document.__defineSetter__(
			'cookie',
			function cookie(cookie: string) {
				const __document = document as Document & {
					eliqSavedCookieSetter: (_cookie: string) => void
					eliqCookieHandledEvent: (_cookie: string, blocked: boolean) => any
					eliqIsCookieAllowed: (cookieKey: string) => {
						allowed: boolean
						mapped: boolean
					}
					eliqSetCookieConsentMapping: (...args: any[]) => any
					eliqConsentDefaultAllowed: boolean
				}

				if (cookie) {
					const key = cookie.split('=')[0].trim()
					const { allowed, mapped } = __document.eliqIsCookieAllowed(key)

					const is_deletion =
						cookie.includes('expires=Thu, 01 Jan 1970') &&
						cookie.indexOf('expires=Thu, 01 Jan 1970') ===
							cookie.lastIndexOf('expires=')

					const defaultAllowed = __document.eliqConsentDefaultAllowed

					if (!mapped && defaultAllowed) {
						__document.eliqSetCookieConsentMapping(key, defaultAllowed)
					}

					if (
						// Always allow deletion of cookies
						is_deletion ||
						(!mapped && defaultAllowed) ||
						(allowed && mapped)
					) {
						__document.eliqSavedCookieSetter.call(__document, cookie)
						__document.eliqCookieHandledEvent(cookie, is_deletion || true)
					} else {
						__document.eliqCookieHandledEvent(cookie, !is_deletion || false)
						return
					}
				}
			}.bind(document),
		)
	}

	public getCookieConsentStatus(): CookieConsentStatus {
		const cookieConsentStatus = this.getObject(COOKIE_CONSENT_STATUS)
		let result = {
			necessary: true,
			preferences: false,
			statistics: false,
			marketing: false,
		}
		if (cookieConsentStatus) {
			result = Object.assign(result, cookieConsentStatus ?? {})
		}
		return result
	}

	public setCookieConsentStatus(
		cookieConsentStatus: CookieConsentStatus,
	): void {
		const newStatus = Object.assign(
			this.getCookieConsentStatus() ?? {},
			this.getCookieConsentStatus(),
			cookieConsentStatus,
		)
		const expires = new Date()
		expires.setFullYear(new Date().getFullYear() + 1)
		this.putObject(COOKIE_CONSENT_STATUS, newStatus, 'necessary', {
			expires: expires,
			path: '/',
		})

		const currentMapping = this.getCookieConsentMapping()
		if (
			!currentMapping?.necessary ||
			!currentMapping.necessary.includes(COOKIE_CONSENT_STATUS)
		) {
			this.setCookieConsentMapping(COOKIE_CONSENT_STATUS, 'necessary')
		}
		if (!currentMapping?.necessary?.includes(COOKIE_CONSENT_MAPPING)) {
			this.setCookieConsentMapping(COOKIE_CONSENT_MAPPING, 'necessary')
		}

		this.unblockCookiesIfAllowed()
	}

	public getCookieConsentMapping() {
		const stored: Partial<CookieConsentMapping> =
			this.getObject(COOKIE_CONSENT_MAPPING) ?? {}
		return {
			...(stored ?? {}),
			necessary: [
				...new Set([
					...(stored?.necessary ?? []),
					COOKIE_CONSENT_MAPPING,
					COOKIE_CONSENT_STATUS,
				]),
			],
			preferences: stored?.preferences ?? [],
			marketing: stored?.marketing ?? [],
			statistics: stored?.statistics ?? [],
		}
	}

	public setCookieConsentMapping(
		cookieName: string,
		cookieStatusKey: CookieConsentStatusKeyType,
	) {
		const oldMapping = this.getCookieConsentMapping()

		const isValidMapping =
			cookieStatusKey in oldMapping &&
			Array.isArray(oldMapping[cookieStatusKey])

		const newMapping = Object.assign(oldMapping, {
			[cookieStatusKey]: [
				...(isValidMapping ? (oldMapping[cookieStatusKey] as string[]) : []),
				cookieName,
			],
		})
		// Remove duplicates
		Object.keys(newMapping).map(
			(key) => (newMapping[key] = [...new Set(newMapping[key])]),
		)

		const expires = new Date()
		expires.setFullYear(new Date().getFullYear() + 1)
		this.ngxCookieService.putObject(COOKIE_CONSENT_MAPPING, newMapping, {
			path: '/',
			expires: expires,
		})
	}

	private getAnyMappedCookies() {
		const cookieConsentMapping = this.getCookieConsentMapping()

		const res = Object.values(cookieConsentMapping).reduce((acc, val) => {
			if (Array.isArray(val)) {
				return [...acc, ...val]
			}
			return acc
		})
		return res
	}

	private getAllowedCookies() {
		const cookieConsentStatus = this.getCookieConsentStatus()
		const cookieConsentMapping = this.getCookieConsentMapping()

		const allowedCookies = [] as string[]
		for (const consentKey in cookieConsentStatus) {
			if (
				cookieConsentStatus[consentKey] === true &&
				consentKey in cookieConsentMapping
			) {
				allowedCookies.push(...cookieConsentMapping[consentKey])
			}
		}

		console.groupEnd()

		return [
			...new Set([
				...allowedCookies,
				COOKIE_CONSENT_MAPPING,
				COOKIE_CONSENT_STATUS,
			]),
		]
	}

	private isCookieAllowed(cookieKey: string): {
		allowed: boolean
		mapped: boolean
	} {
		const allAllowed = Object.values(this.getCookieConsentStatus()).every(
			(x) => x === true,
		)
		return {
			allowed: allAllowed || this.getAllowedCookies().includes(cookieKey),
			mapped: this.getAnyMappedCookies().includes(cookieKey),
		}
	}

	private removeDisallowedCookiesFrom(
		cookieStringOrObj: string | Record<string, string>,
		defaultCookieConsentKey: CookieConsentStatusKeyType = 'marketing',
	): {
		result: Record<string, string>
		removed: string[]
		altered: string[]
		allowed: string[]
	} {
		const cookies = this.simpleParseCookieString(cookieStringOrObj)
		if (!this.getAllowedCookies().length) {
			return {
				result: cookies,
				removed: [],
				altered: [],
				allowed: Object.keys(cookies),
			}
		}

		const defaultAlllowed =
			this.getCookieConsentStatus()[defaultCookieConsentKey] === true

		const removed = [] as string[]
		const altered = [] as string[]
		const _allowed = [] as string[]

		Object.keys(cookies).forEach((key) => {
			const { allowed, mapped } = this.isCookieAllowed(key)
			if (!mapped && defaultAlllowed) {
				this.setCookieConsentMapping(key, defaultCookieConsentKey)
				altered.push(key)
				_allowed.push(key)
			} else if (!allowed && mapped) {
				delete cookies[key]
				removed.push(key)
			} else if (allowed && mapped) {
				_allowed.push(key)
			}
		})

		return { result: cookies, removed, altered, allowed: _allowed }
	}

	private removeDisallowedCookies(
		defaultCookieConsentKey: CookieConsentStatusKeyType = 'marketing',
	) {
		if (!this.getAllowedCookies().length) {
			return
		}
		this.removeDisallowedCookiesFrom(
			this.getAll(),
			defaultCookieConsentKey,
		).removed.forEach((key) => {
			this.remove(key)
		})
	}

	private unblockCookiesIfAllowed() {
		structuredClone(this.eliqBlockedCookies).forEach((cookie) => {
			if (this.isCookieAllowed(cookie).allowed) {
				if ((document as any).eliqSavedCookieSetter) {
					;(document as any).eliqSavedCookieSetter.call(document, cookie)
					this.eliqBlockedCookies.delete(cookie)
				} else {
					console.warn('document.eliqSavedCookieSetter is undefined')
				}
			}
		})
	}

	private consentModalIsOpen = false
	public showCookieConsent() {
		if (this.consentModalIsOpen || this.hasKey(COOKIE_CONSENT_STATUS)) {
			return
		}
		this.consentModalIsOpen = true

		this.modalService.openModal(CookieConsentModalComponent, {
			disableClose: true,
			data: {
				cookieService: this,
				cookieAcceptedEvent: (status: CookieConsentStatus) => {
					this.setCookieConsentStatus(status)

					this.removeDisallowedCookies()

					this.consentModalIsOpen = false

					this.unblockCookiesIfAllowed()

				},
			},
		})
	}

	public showCookieConsentIfNeeded() {
		if (this.env.isBrowser()) {
			this.setCookieConsentMapping(COOKIE_CONSENT_STATUS, 'necessary')
			this.setCookieConsentMapping(COOKIE_CONSENT_MAPPING, 'necessary')

			this.removeDisallowedCookies()

			setTimeout(this.showCookieConsent.bind(this), 1000)
		}
	}
}
