import { Component, Input, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { BillsConfig } from '../../models/config-models/bills-config-model'
import { BillsConfigService } from '../../services/bills-config.service'
import { BillsContainerTwoComponent } from '../bills-container-two/bills-container-two.component'
import { NgIf, AsyncPipe } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-bills-page',
	templateUrl: './bills-page.component.html',
	styleUrls: ['./bills-page.component.scss'],
	standalone: true,
	imports: [TranslateModule, NgIf, BillsContainerTwoComponent, AsyncPipe],
})
export class BillsPageComponent implements OnInit {
	@Input() testing = false
	@Input() showHeading = true

	public $billsConfig: Observable<BillsConfig> =
		this.billsConfigGetter.getBillsConfig()

	constructor(private billsConfigGetter: BillsConfigService) {}

	ngOnInit() {}
}
