import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { NgFor } from '@angular/common'

@Component({
	selector: 'eliq-translation-picker',
	templateUrl: './translation-picker.component.html',
	styleUrls: ['./translation-picker.component.scss'],
	standalone: true,
	imports: [NgFor, ImgSrcPipe],
})
export class TranslationPickerComponent {
	@Input() languages!: { code: string; short: string }[]
	@Input() selectedLang = '' // code
	@Output() langSelected = new EventEmitter<string>()

	public langClicked(lang: string) {
		this.selectedLang = lang
		this.langSelected.emit(lang)
	}
}
