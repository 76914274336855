import { ResolutionType } from './ResolutionType.model'

export enum PeriodType {
	Second = 'second', // not possible so I removed it
	Minute = 'minute',
	Hour = 'hour',
	Day = 'day',
	Week = 'week',
	Month = 'month',
	Year = 'year',
	THIRTEENMONTH = 'thirteenmonth',
}

/** The most likely scenario is the first index of the returned array */
export const possibleResolutionForPeriod = (
	periodType: PeriodType,
): ResolutionType[] => {
	switch (periodType) {
		case PeriodType.Year:
			return [ResolutionType.Month]
		case PeriodType.THIRTEENMONTH:
			return [ResolutionType.Month]
		case PeriodType.Month:
			return [ResolutionType.Day]
		case PeriodType.Week:
			return [ResolutionType.Day]
		case PeriodType.Day:
			return [ResolutionType.Hour, ResolutionType.ThirtyMin]
		case PeriodType.Hour:
			return [ResolutionType.SixMin, ResolutionType.ThirtyMin]
		case PeriodType.Minute:
			return [ResolutionType.TenSec]
		//case PeriodType.Second:
		//	return [ResolutionType.]
	}

	return []
}
