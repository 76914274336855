import {
	ViewChild,
	ElementRef,
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	inject,
} from '@angular/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { DOCUMENT, NgIf } from '@angular/common'

@Component({
	selector: 'eliq-number-spinner-compact',
	templateUrl: './number-spinner-compact.component.html',
	styleUrls: ['./number-spinner-compact.component.scss'],
	standalone: true,
	imports: [NgIf, ImgSrcPipe],
})
export class NumberSpinnerCompactComponent {
	@ViewChild('inputEl') input!: ElementRef<HTMLDivElement>

	private _document = inject(DOCUMENT)

	public _value: number | null = null

	public get value(): number | null {
		if (isNaN(parseInt(this._value + ''))) {
			return null
		}
		return this._value
	}

	@Input() set value(v: string | null | number | null) {
		if (v === undefined) {
			v = null
		}
		if (v === null && this._value === null) {
			return
		}

		const vNum = parseInt(v + '')
		if (isNaN(vNum)) {
			//this.input.nativeElement.innerText = '-'
			this._value = null
			this.valueChange.emit(null)
		} else {
			this._value = vNum
			this.valueChange.emit(this._value)
		}
	}
	// on value change

	@Input() min = 0
	@Input() max = 5 // just a random default

	@Output() valueChange: EventEmitter<number | null> = new EventEmitter<
		number | null
	>()

	increment() {
		if (isNaN(parseInt(this.value + ''))) {
			this.value = this.min + 1
		} else if (typeof this.value === 'number' && this.value < this.max) {
			this.value++
		}
	}

	decrement() {
		if (isNaN(parseInt(this.value + ''))) {
			this.value = this.min
		} else if (typeof this.value === 'number' && this.value > this.min) {
			;(this.value as number)--
		}
	}

	saveSelection() {
		if (
			this._document?.defaultView &&
			this._document?.defaultView['getSelection']
		) {
			const sel = this._document.defaultView?.getSelection()

			if (sel && sel.getRangeAt && sel.rangeCount) {
				return sel.getRangeAt(0)
			}
		} else if (
			this._document['selection'] &&
			this._document['selection'].createRange
		) {
			return this._document['selection'].createRange()
		}
		return null
	}

	restoreSelection(range) {
		if (range) {
			if (
				this._document?.defaultView &&
				this._document?.defaultView['getSelection']
			) {
				const sel = this._document.defaultView?.getSelection()
				if (sel) {
					sel.removeAllRanges()
					sel.addRange(range)
				}
			} else if (this._document['selection'] && range.select) {
				range.select()
			}
		}
	}

	onBlurInput(event: Event) {
		const sel = this._document.defaultView?.getSelection()
		sel?.removeAllRanges()
		if (this.input.nativeElement.innerText === '') {
			this.input.nativeElement.innerText = '-'
			this._value = null
		}
	}

	selectAllText() {
		const range = this._document?.createRange()
		range?.setStart(
			this.input?.nativeElement?.firstChild ?? this.input?.nativeElement,
			0,
		)
		range?.setEnd(
			this.input?.nativeElement?.firstChild ?? this.input?.nativeElement,
			this.input?.nativeElement?.textContent?.length ?? 0,
		)
		const sel = this._document?.defaultView?.getSelection()
		sel?.removeAllRanges()
		sel?.addRange(range)
	}

	onFocusInput(event: FocusEvent) {
		this.selectAllText()
	}

	inputEvent(event: Event) {
		const savedSel = this.saveSelection()
		const val = (event.target as HTMLInputElement)?.innerText || ''
		this.value = val
		this.restoreSelection(savedSel)
	}
}
