import { Pipe, PipeTransform } from '@angular/core'
import { TranslateModule, TranslatePipe } from '@ngx-translate/core'

export interface EliqTranslatePipeArgs {
	fallbackKey?: string
	fallbackString?: string
	values?: { [key: string]: any }
	pipes?: ({ pipe: PipeTransform; args: any } | PipeTransform)[]
}

/// TranslatePipe that has fallback support:
@Pipe({
	standalone: true,
	name: 'translateOrFallback',
})
export class TranslateOrFallbackPipe implements PipeTransform {
	constructor(private translate: TranslatePipe) {}

	private _innerTransform(value: string, args: EliqTranslatePipeArgs) {
		const firstTry = this.translate.transform(value, args.values)
		if (firstTry === value) {
			if (args.fallbackKey) {
				return this.translate.transform(args.fallbackKey, args.values)
			} else if (args.fallbackString) {
				return args.fallbackString
			}
		}
		return firstTry
	}
	transform(value: string, args: EliqTranslatePipeArgs) {
		let result = this._innerTransform(value, args)
		if (Array.isArray(args.pipes)) {
			for (let pipe of args.pipes) {
				if (pipe['transform'] instanceof Function) {
					result = (pipe as PipeTransform).transform(result)
				} else if (
					(pipe as { pipe: PipeTransform; args: any }).pipe[
						'transform'
					] instanceof Function
				) {
					pipe = pipe as { pipe: PipeTransform; args: any }
					result = pipe.pipe.transform(result, pipe.args)
				} else {
					console.error(
						"TranslateOrFallback.pipe.ts - Invalid pipe in 'pipes' array",
						{ pipe, args },
					)
				}
			}
		}
		return result
	}
}
