<div class="parent">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button class="xbutton"></eliq-x-button>
	</div>
	<h6 class="primary-h6 header">{{headerKey | translate}}</h6>
	<div
		*ngIf="selectableYears.length > 1; else single"
		class="switch-background container round-grey-border"
	>
		<div
			class="popper"
			[popper]="dropdownPopper"
			[popperTrigger]="'click'"
			[popperPlacement]="'top'"
			[popperApplyClass]="'myPopper'"
			[popperApplyArrowClass]="'popperArrow'"
			(popperOnShown)="popperShowing=true"
			(popperOnHidden)="popperShowing=false"
		>
			<span class="primary-p2">{{selectedYear.year}}</span>
			<span class="pyramid down switch-arrow"></span>
		</div>
	</div>
	<popper-content #dropdownPopper>
		<ul class="list">
			<li
				class="dropdown-item"
				*ngFor="let sY of selectableYears"
				(click)="setSelectedYear(sY); dropdownPopper.hide();"
			>
				<p
					class="dropdown-text primary-p2"
					[class.dropdown-text--selected]="sY.year === selectedYear.year"
				>
					{{sY.year}}
				</p>
			</li>
		</ul>
	</popper-content>
	<div class="container value" *ngIf="!noData">
		<h2 class="primary-h2 value">
			<b>{{average | number:'1.2-2'}} kW</b>
		</h2>
	</div>

	<ng-template #single>
		<span class="primary-p2">{{selectedYear.year}}</span>
	</ng-template>

	<ng-container>
		<div class="chart-area">
			<div id="peak-tariffs-details-modal-chart" (update)="updateFlag"></div>
			<div class="message-card" *ngIf="noData">
				<div
					style="margin: 0px 0;"
					*ngIf="message"
					class="secondary-p2"
					[innerHTML]="message"
				></div>
			</div>
		</div>
	</ng-container>

	<div class="container">
		<h6 class="primary-h6 header subheader">
			<b>
				{{'screen_dynamic_capacity_tariffs.your_peak_tariff_title' | translate}}
			</b>
		</h6>
	</div>
	<div class="container">
		<h5 class="normal primary-p2">
			{{'screen_dynamic_capacity_tariffs.your_peak_tariff' | translate}}
			{{linkPrefix}}
			<a (click)="onLinkClick($event, link)" [href]="link" target="_blank">
				<span>{{linkSuffix}}</span>
			</a>
			.
		</h5>
	</div>
</div>
