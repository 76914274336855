import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '@eliq/ui/modal'
import { Location } from '@eliq/core'
import { DateTranslatorService } from '@eliq/core'
import { isYesterday } from 'date-fns'
import isToday from 'date-fns/isToday'
import {
	ConsProdDailyComparison,
	ConsProdMonthlyComparison,
} from '../pv-home-card-container/pv-home-card-container.component'
import { PvHomeCardInfoModalComponent } from '../pv-home-card-info-modal/pv-home-card-info-modal.component'
import { TranslateModule } from '@ngx-translate/core'
import { LinkComponent } from '@eliq/ui'
import { RouterLink } from '@angular/router'
import { PvTrendItemComponent } from '../pv-trend-item/pv-trend-item.component'
import { NgIf, TitleCasePipe } from '@angular/common'

@Component({
	selector: 'eliq-pv-home-card',
	templateUrl: './pv-home-card.component.html',
	styleUrls: ['./pv-home-card.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		PvTrendItemComponent,
		RouterLink,
		LinkComponent,
		TitleCasePipe,
		TranslateModule,
	],
})
export class PvHomeCardComponent implements OnInit {
	@Input() location!: Location
	@Input() activeDataStream!: false | 'consumption-production' | 'import-export'
	@Input() monthlyComparison!: ConsProdMonthlyComparison
	@Input() dailyComparison!: ConsProdDailyComparison
	@Input() unit!: 'energy' | 'cost' | 'm3'

	public month = ''
	public dayKey = ''
	public dayTranslation = ''
	constructor(
		private dateTranslator: DateTranslatorService,
		private modal: ModalService,
	) {}

	ngOnInit(): void {
		if (!this.dailyComparison) {
			return
		}

		const dd = this.dailyComparison?.day
		this.dayTranslation =
			dd.getDate() +
			' ' +
			this.dateTranslator.getTranslatedMonth(dd.getMonth(), false, true)

		this.month = this.dateTranslator.getTranslatedMonth(
			this.monthlyComparison.month,
		)
		if (this.dailyComparison) {
			if (isToday(this.dailyComparison.day)) {
				this.dayKey = 'home_pv_card.today'
			} else if (isYesterday(this.dailyComparison.day)) {
				this.dayKey = 'home_pv_card.yesterday'
			} else {
				//We should still show the component but display no data
				this.dayKey = 'home_pv_card.yesterday'
			}
		}
	}

	monthlyComparisonClicked() {
		this.modal.openModal(PvHomeCardInfoModalComponent, {
			data: { activeDataStream: 'consumption-production' || this.activeDataStream },
		})
	}
}
