import { Injectable } from '@angular/core'
import {
	TrackingEventName,
	EliqTrackingService,
	EliqTicketResultEvent,
	EliqNewTicketEvent,
	RequestTicketGeneratedEvent,
	ClaimTicketGeneratedEvent,
	WebTicketAlreadyUsedEvent,
	WebTicketInvalidEvent,
	WebTicketStatusCheckFailureEvent,
	WebTicketVerificationExpiredEvent,
	WebTicketVerificationFailureEvent,
	WebTicketVerificationStartedEvent,
	WebTicketVerificationSuccessEvent,
} from '@eliq/core/tracking'

@Injectable({
	providedIn: 'root',
})
export class TicketsTrackerService {
	constructor(private eliqTracker: EliqTrackingService) {}

	sendVerificationStartedEvent() {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketVerificationStarted, {
			v: 1,
		} as WebTicketVerificationStartedEvent)
	}

	sendTicketInvalidEvent() {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketInvalid, {
			v: 1,
		} as WebTicketInvalidEvent)
	}

	sendTicketAlreadyUsedEvent() {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketAlreadyUsed, {
			v: 1,
		} as WebTicketAlreadyUsedEvent)
	}

	sendTicketVerificationSuccessEvent() {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketVerificationSuccess, {
			v: 1,
		} as WebTicketVerificationSuccessEvent)
	}

	sendTicketVerificationExpiredEvent() {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketVerificationExpired, {
			v: 1,
		} as WebTicketVerificationExpiredEvent)
	}

	sendTicketVerificationFailureEvent(err) {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketVerificationFailure, {
			v: 1,
			url: err.url,
			transaction_id: err.error.transaction_id,
		} as WebTicketVerificationFailureEvent)
	}

	sendTicketStatusCheckFailureEvent(err) {
		this.eliqTracker.logEvent(TrackingEventName.WebTicketStatusCheckFailure, {
			v: 1,
			url: err.url,
			transaction_id: err.error.transaction_id,
		} as WebTicketStatusCheckFailureEvent)
	}
}
