import { Component, OnInit, Input } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'

@Component({
	selector: 'eliq-notification-item-claim-and-request',
	templateUrl: './notification-item-claim-and-request.component.html',
	styleUrls: ['./notification-item-claim-and-request.component.css'],
	standalone: true,
})
export class NotificationItemClaimAndRequestComponent implements OnInit {
	@Input() notification: NotificationData

	constructor() {}

	ngOnInit(): void {}
}
