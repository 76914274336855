import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
	AuthService,
	LoginService,
	Ticket,
	TicketService,
	TicketStatusType,
	TokenWrapper,
} from '@eliq/feature/auth'
import { TicketsTrackerService } from '../../tracking/tickets-tracker.service'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { NgIf } from '@angular/common'
import {
	BasicButtonComponent,
	HeaderSimpleComponent,
	SpinnerComponent,
} from '@eliq/ui'
import { ModalService } from '@eliq/ui/modal'
import { IlRegisterEmailBannerModalComponent } from '../../../integrationless/il-register-email-banner-modal/il-register-email-banner-modal.component'
import { EnvironmentService } from '@eliq/data-access'
import { LoginHelperService } from '../../../login/services/login-helper/login-helper.service'
import {
	Observable,
	ObservedValueOf,
	OperatorFunction,
	catchError,
	finalize,
	interval,
	map,
	of,
	switchMap,
	takeWhile,
} from 'rxjs'
import { LanguageService } from '@eliq/core'

export enum TicketValidationState {
	invalid,
	expired,
	used,
	completed,
}

@Component({
	selector: 'eliq-tickets',
	templateUrl: './tickets.component.html',
	styleUrls: ['./tickets.component.scss'],
	standalone: true,
	imports: [
		HeaderSimpleComponent,
		SpinnerComponent,
		NgIf,
		TranslateModule,
		BasicButtonComponent,
	],
})
export class TicketsComponent implements OnInit {
	public navigateToHomeAfterTicket = false
	public TicketValidationState = TicketValidationState
	public ticketState: TicketValidationState | null = null // invalid, expired, ok
	private ticketInfo!: string
	private ticketId!: string
	private ticketKey!: string

	public buttonToVerifyTicket = this.env.getFeatureFlag(
		'tickets-button-to-verify',
	)

	public errorObject = null
	public loading = false

	public type = ''
	constructor(
		private activatedRoute: ActivatedRoute,
		private ticketService: TicketService,
		private tracking: TicketsTrackerService,
		private modalService: ModalService,
		private router: Router,
		private env: EnvironmentService,
		private loginHelper: LoginHelperService,
		private authService: AuthService,
		private translate: TranslateService,
		private langService: LanguageService,
	) {}

	ngOnInit() {
		this.langService.fallbackMany({
			'sign_in_verify_magic_link.verify_button': 'Verify your email',
			'sign_in_verify_magic_link.verify_text':
				'Click the button below to verify and log in.',
			'sign_in_verify_magic_link.verify_title': 'Verify and log in',
		})

		this.buttonToVerifyTicket = this.env.getFeatureFlag(
			'tickets-button-to-verify',
		)

		if (this.env.isBrowser()) {
			this.navigateToHomeAfterTicket =
				this.navigateToHomeAfterTicket || this.env.isIntegrationless()
		}

		if (!this.buttonToVerifyTicket) {
			this.verifyTicketClicked()
		}

		const lang = this.activatedRoute.snapshot.queryParamMap.get('lang')
		//Language by query param (used for Mobile -> Email -> Verification page (web) language sync)
		if (lang && lang != '') {
			this.langService.setCurrentLanguage(lang).subscribe()
		}
	}

	verifyTicketClicked() {
		this.loading = true
		this.tracking.sendVerificationStartedEvent()
		this.verifyTicket(this.activatedRoute.snapshot.queryParamMap).subscribe(
			() => {
				this.postTicketVerified()
				this.loading = false
			},
		)
	}

	postTicketVerified() {
		if (
			this.navigateToHomeAfterTicket &&
			this.ticketState === TicketValidationState.completed
		) {
			this.router
				.navigateByUrl(this.env.isIntegrationless() ? '/welcome' : '/home')
				.then(() => {
					if (this.env.isIntegrationless() && this.type === 'set_email') {
						this.modalService.openModal(IlRegisterEmailBannerModalComponent, {
							data: { openedFromTicketsComponent: true },
						})
					}
				})
		}
	}

	verifyTicket(params: any): Observable<TicketValidationState> {
		this.ticketInfo = params.get('k')
		this.type = params.get('t')

		if (
			this.ticketInfo == null ||
			this.ticketInfo == '' ||
			this.type == null ||
			this.type == ''
		) {
			this.ticketState = TicketValidationState.invalid
			this.tracking.sendTicketInvalidEvent()
			return of(this.ticketState)
		}

		const infoArr = this.ticketInfo.split('_')
		if (infoArr.length != 2) {
			this.ticketState = TicketValidationState.invalid
			this.tracking.sendTicketInvalidEvent()
			return of(this.ticketState)
		}

		this.ticketId = this.ticketInfo.split('_')[0]
		this.ticketKey = this.ticketInfo.split('_')[1]

		// OBS! We might need to do updates here if more ticket types that magic link login is created
		// Until then, use this

		return this.ticketService
			.verifyTicket(this.ticketId, this.ticketKey)
			.pipe(takeWhile((res) => res.status === TicketStatusType.Completed))
			.pipe(
				switchMap((res: Ticket) => {
					if (res.status === TicketStatusType.Completed) {
						this.tracking.sendTicketVerificationSuccessEvent()
						this.ticketState = TicketValidationState.completed
						this.loading = false
						return of(this.ticketState)
					} else {
						this.ticketState = TicketValidationState.expired
						this.tracking.sendTicketVerificationExpiredEvent()
						this.loading = false
						return of(this.ticketState)
					}
				}),
				catchError((_err, _caught: Observable<TicketValidationState>) => {
					this.ticketState = TicketValidationState.expired
					this.tracking.sendTicketVerificationFailureEvent(_err)
					this.loading = false
					return of(this.ticketState)
				}),
			)
			.pipe(
				switchMap((res) =>
					interval(1000).pipe(
						takeWhile((_) => !this.authService.isLoggedIn()),
						map((_) => res),
					),
				),
			)
			.pipe(
				finalize(() => {
					this.loading = false
				}),
			)
	}
}
