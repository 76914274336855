// @ts-nocheck

import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import {
	ConfigAccount,
	ConfigPrivacyPolicy,
} from '../../models/config-models/account-config.model'

@Injectable({
	providedIn: 'root',
})
export class AccountConfigService {
	constructor(private config: JsonGetterService) {}

	isTesting(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map((config) => (config.is_testing === true ? true : false)),
		)
	}
	shouldDivideLocations(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			// default to false if the option isnt specified by the application
			map((config) =>
				config.divide_location_into_two_menu_items === undefined
					? false
					: config.divide_location_into_two_menu_items,
			),
		)
	}

	getAccountConfig(): Observable<ConfigAccount> {
		return this.config.getConfig('account')
	}

	getIsNicknameEditable(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map((config) => config.home_options.nickname_editable),
			catchError(() => of(false)),
		)
	}

	getIsEmailEditable(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map((config) => config.email_editable),
			catchError(() => of(false)),
		)
	}

	getAreNotificationSettingsDescriptionsVisible(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map((config) => config.notifications.show_settings_descriptions),
			catchError(() => of(false)),
		)
	}

	getHomeOptions() {
		return this.getAccountConfig().pipe(
			map((config) => config.home_options),
			catchError(() => of({})),
		)
	}

	getPersonalDetailsOptions() {
		return this.getAccountConfig().pipe(
			map((config) => {
				const retval = {}
				Object.keys(config.personal_details).map(
					(key) =>
						(retval[key] = {
							show_ext_ref: (val) => val ?? true,
							show_button: (val) => val ?? true,
							show_translations: (val) => val ?? true,
							edit_email: (val) => val ?? true,
						}[key](config.personal_details[key])),
				)
				return retval
			}),
			catchError(() => of({})),
		)
	}

	accountPageHeaderIsName(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map(
				// return, and default to false.
				(config) =>
					config.account_page_header_is_name
						? config.account_page_header_is_name
						: false,
			),
		)
	}

	//some clients do not have a Home Profile e.g. B2B Clients
	accountShouldHideHomeProfile(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map(
				// return, and default to false.
				(config) =>
					config.home_profile?.hide ? config.home_profile.hide : false,
			),
		)
	}

	personalDetailsHeaderIsName(): Observable<boolean> {
		return this.getAccountConfig().pipe(
			map(
				// return, and default to false.
				(config) =>
					config.personal_details_header_is_name
						? config.personal_details_header_is_name
						: false,
			),
		)
	}

	/**
	 * Gets the privacy policy config object. If there is none, returns undefined.
	 * @returns
	 */
	getPrivacyPolicyConfigObject(): Observable<ConfigPrivacyPolicy> {
		return this.getAccountConfig().pipe(
			map((response) => {
				// check that we have the good stuff
				if (response.consents) {
					if (response.consents.privacy_policy)
						return response.consents.privacy_policy
				}
				return undefined
			}),
		)
	}
}
