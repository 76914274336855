<eliq-card
	class="eliq-default-card-style card signin-card-sizing"
	[style.padding]="padding"
>
	<div *ngIf="showBackButton" (click)="backClicked.emit()" class="back-arrow">
		<img
			[src]="'/assets/images/Icon_arrow.svg' | imgSrc"
			[alt]="'common.button_back' | translate"
			class="back-arrow-img"
			height="20"
		/>
	</div>
	<h5 class="primary-h5 header" *ngIf="header">{{header | translate}}</h5>
	<ng-content></ng-content>
</eliq-card>
