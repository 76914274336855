import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { UserHttpService } from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { SpinnerComponent } from '@eliq/ui'
import { BasicButtonComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'
import { XButtonComponent } from '@eliq/ui'
import { NgIf } from '@angular/common'
import { AsyncPipe } from '@angular/common'
import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http'

@Component({
	selector: 'eliq-terms-accepted-dialog',
	templateUrl: './terms-accepted-dialog.component.html',
	styleUrls: ['./terms-accepted-dialog.component.css'],
	standalone: true,
	imports: [
		NgIf,
		XButtonComponent,
		TranslateModule,
		AsyncPipe,
		LinkComponent,
		BasicButtonComponent,
		SpinnerComponent,
	],
})
export class TermsAcceptedDialogComponent implements OnInit {
	public userId: number | null
	public cantClose = true
	public termsLink? = ''
	public privacyPolicyLink? = ''
	public state = 'waiting'
	public openNewTab = true
	public termsAndConditions$ = new Subject<string>()

	constructor(
		private http: HttpClient,
		private envS: EnvironmentService,
		public dialogRef: MatDialogRef<TermsAcceptedDialogComponent>,
		private translator: TranslateService,
		private userService: UserHttpService,
	) {}

	ngOnInit() {
		if (this.envS.isKBC()) {
			this.openNewTab = false
		}

		this.http
			.get('/assets/company/terms.txt', { responseType: 'text' })
			.subscribe((terms) => {
				// Expression to find lines starting with digits and wrap them with HTML tags.
				// This is used to style the terms and conditions headings in .txt.
				const styledHeader = terms.replace(/TERMS OF SERVICE/, '<strong>$&</strong>');
				const styledTerms = styledHeader.replace(/^(\d+\..+)/gm, '<strong>$1</strong>');
            this.termsAndConditions$.next(styledTerms);
			})

		//this.console.warn('The older terms accepted dialog component is used.')
	}

	confirm() {
		this.state = 'patching'
		if (this.userId !== -1) {
			this.userService.patchUserTerms(this.userId).subscribe(
				(result) => {
					this.dialogRef.close(true)
				},
				(error) => {
					this.state = 'error'
				},
			)
		} else {
			this.dialogRef.close(true)
		}
	}

	close() {
		this.dialogRef.close(false)
	}
}
