import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChildren,
	QueryList,
	ViewEncapsulation,
	inject,
} from '@angular/core'
import { ViewChild } from '@angular/core'
import { ElementRef } from '@angular/core'
import { Observable } from 'rxjs'
import { TranslateModule } from '@ngx-translate/core'
import { SpinnerComponent } from '../../spinner/spinner.component'
import {
	NgClass,
	NgStyle,
	NgIf,
	AsyncPipe,
	UpperCasePipe,
} from '@angular/common'
import { EliqThemeService } from '@eliq/theme'

@Component({
	selector: 'eliq-basic-button',
	templateUrl: './basic-button.component.html',
	styleUrls: ['./basic-button.component.scss'],
	standalone: true,
	imports: [
		NgClass,
		NgStyle,
		NgIf,
		SpinnerComponent,
		AsyncPipe,
		UpperCasePipe,
		TranslateModule,
	],
	//encapsulation: ViewEncapsulation.ShadowDom,
})
export class BasicButtonComponent implements OnInit {


	@Input() label: string | undefined
	@Input() useEntireWidth = false
	@Output() clicked = new EventEmitter<any>()
	@Input() disabled = false
	@Input() variant = 'primary'

	// Enables changing color by overloading css variable or by using only the variant or by using a variant in combination with a colorKey (valid themeService key)
	public themeService = inject(EliqThemeService)
	public _colorKey: string | null = null
	public get colorKey(): string | null {return this._colorKey ?? null}

	@Input() set colorKey(value: string) {
		if (typeof(value) !== "string" || value === "") {
			this._colorKey = this.variant
		}

		this.themeService.setComponentDefaultEventBasedProps(this.elRef.nativeElement,{
			name: 'button',
			parts: ['background', 'border', 'contrast', 'border-radius'],
			events: ['', 'hover', 'active', 'focus'],
			defaultName: `${this.variant}-button`
		})



		// this.themeService.setProps(this.elRef.nativeElement, {
		// 	'button-background': this.themeService.getProp(`${value}-button-background`, `${value}`),
		// 	'button-border': this.themeService.getProp(`${value}-button-border`, 'raw:transparent'),
		// 	'button-contrast': this.themeService.getProp(`${value}-button-contrast`, '${value}-contrast', 'primary-contrast'),
		// 	'button-background--hover': this.themeService.getProp(`${value}-button-background--hover`),
		// 	'button-border--hover': this.themeService.getProp(`${value}-button-border--hover`),
		// 	'button-contrast--hover': this.themeService.getProp(`${value}-button-contrast--hover`),
		// 	// unused here, for now:
		// 	'button-accent': this.themeService.getProp(`${value}-button-accent`, `${value}`), // <-- not used yet in this component but it exists in the theme (defaults.scss)
		// 	'button-accent--hover': this.themeService.getProp(`${value}-button-accent--hover`, `${value}`), // <-- not used yet in this component but it exists in the theme (defaults.scss)
		// })
	}

	@Input() custom: any
	@Input() iconSrc: string | undefined
	@Input() submit = false
	@Input() buttonStyle: Record<string, any>
	@Input() allCaps = false
	@Input() type = 'button'
	@Input() loading = false

	@ViewChildren('labelcontainer') labelContainer?: QueryList<ElementRef>

	constructor(private elRef: ElementRef) {}

	public width?: number
	public height?: number
	ngOnInit() {
		this.custom = Object.assign(
			{ minWidth: 'max-content' },
			this.buttonStyle ?? {},
			this.custom ?? {},
		)
		if (this.submit) this.type = 'submit'
		if (this.allCaps) this.label = this.label?.toUpperCase()
		if (this.colorKey === null) {
			this.colorKey = this.variant
		}
	}

	ngAfterViewInit() {
		this.labelContainer?.changes.subscribe({
			next: (c: QueryList<ElementRef>) => {
				this.width = c?.first?.nativeElement?.offsetWidth ?? this.width
				this.height = c?.first?.nativeElement?.offsetHeight ?? this.height
			},
			error: (err: any) => console.error(err),
		})
	}

	clickedButton(event: any) {
		if (!this.disabled) this.clicked.emit(event)
	}
}
