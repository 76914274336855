import {
	Inject,
	Injectable,
	makeStateKey,
	TransferState,
	StateKey,
	Optional,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import {
	catchError,
	concatAll,
	flatMap,
	concatMap,
	defaultIfEmpty,
	map,
	mergeMap,
	switchMap,
	switchMapTo,
	take,
	tap,
	mergeAll,
	takeLast,
} from 'rxjs/operators'
import {
	APIUser,
	ConfigLanguage,
	EliqApiHttpClient,
	JsonGetterService,
} from '../../public_api'
import { CoreDataStoreService } from '../../public_api'
import { EnvironmentService } from '@eliq/data-access'
import {
	EMPTY,
	MonoTypeOperatorFunction,
	Observable,
	OperatorFunction,
	iif,
	lastValueFrom,
	merge,
	of,
} from 'rxjs'
import { CookieService } from '@eliq/data-access/cookie'
import { ActivatedRoute, Router } from '@angular/router'
import { Request } from 'express'
import { REQUEST } from '@nguniversal/express-engine/tokens'

@Injectable({
	providedIn: 'root',
})
export class LanguageService {
	public languages$ = this.jsonGetter.getLanguages()
	public defaultLang!: string
	private readonly LANG_PREF = 'LANG_PREF'
	private userId?: number

	public cachedLanguage: string | null = null
	//public defaultLang!: string;

	constructor(
		private transferState: TransferState,
		private cookieService: CookieService,
		private env: EnvironmentService,
		private coreDS: CoreDataStoreService,
		private http: EliqApiHttpClient,
		private translator: TranslateService,
		@Inject('defaultLang') defaultLang: string,
		private jsonGetter: JsonGetterService,
		private router: Router,
		private route: ActivatedRoute,
		@Optional() @Inject(REQUEST) private request: Request,
	) {
		this.defaultLang = defaultLang
		const tempLang = this.getUsedLanguage()
		if (tempLang === null) {
			// no lang set in localStorage, use the best one we can find
			this.setCurrentLanguage()
		}
	}

	/**
	 * Function to find the closest match to a string (needle) in a list of strings (haystack).
	 *
	 * This function calculates a score for each string in the haystack, according to how closely
	 * it matches the needle. The match does not have to be exact and can have additional characters.
	 * The string with the highest score is returned as the best match.
	 *
	 * @param haystack The list of strings to search through. Must be a string array otherwise null is returned.
	 * @param needle The string to find a match for. Must be a string otherwise null is returned.
	 * @param caseSensitive (optional) Whether or not the search should be case sensitive. Defaults to false.
	 * @param exhaustive (optional) Whether or not the search should exhaustively calculate score for each string. It improves accuracy but reduces performance *slightly*. Defaults to true.
	 *
	 * ```
	 * fuzzy: 3615.18994140625 ms
	 * fuzzy exhaustive: 4576.52880859375 ms
	 * ```
	 *
	 * @returns The best match from the haystack or null if inputs are invalid.
	 */
	fuzzyStringSearch(
		haystack: string[],
		needle: string,
		caseSensitive = false,
		exhaustive = true,
	): string | null {
		// Verification of input types
		if (
			typeof needle !== 'string' ||
			!Array.isArray(haystack) ||
			!haystack.every((e) => typeof e === 'string')
		) {
			return null
		}

		/**
		 * Function to calculate a fuzzy score for given strings.
		 * Score increases with exact character matches and decreases with mismatches. Higher score is better.
		 * @returns the fuzzy similarity score between two strings.
		 */
		const fuzzyScore = (hay: string, needle: string): number => {
			let window: string,
				score = 0,
				step = needle.length,
				min = 0

			if (hay === needle) {
				return 10e6
			}

			if (!exhaustive) {
				min = Math.floor(step / 2)
			}

			// Iterate over the needle string, reducing its size with every iteration
			for (; step > min; step--) {
				for (let i = 0, n = -1; (window = needle.slice(i, step)); i++) {
					const old_n = n
					n = hay.indexOf(window, n + 1)
					// Score is increased by step if match is found, decreased by 1 if not
					if (n >= 0) {
						// the difference between the expected location and real location that
						// decreases based on how far from the start of the hay string it is.
						score += (old_n - n) / (i + 1)
					} else {
						// it's better if we find what we're looking for at an earlier step
						// so the score decreases more based on that
						score -= step
					}
					if (score > 0) {
						return score
					}
				}
			}

			return score
		}

		// 'scores' object stores each hay item along with their fuzzyScore.
		// Converting hay and needle to lowercase if caseSensitive is false.
		const scores = haystack.reduce<Record<string, number>>((acc, hay) => {
			const processedHay = caseSensitive ? hay : hay.toLowerCase()
			const processedNeedle = caseSensitive ? needle : needle.toLowerCase()

			acc[hay] = fuzzyScore(processedHay, processedNeedle)

			return acc
		}, {})

		const bestMatch = haystack.reduce((prev, curr) => {
			const _prev = caseSensitive ? prev : prev.toLowerCase()
			const _curr = caseSensitive ? curr : curr.toLowerCase()

			// Choose the string with the highest score. In case of tie, choose the shorter string
			if (
				scores[prev] > scores[curr] ||
				(scores[prev] > scores[curr] && _prev.length <= _curr.length)
			) {
				return prev
			}
			return curr
		})

		return bestMatch
	}

	getBestMatchLanguage(curLang: string | null): Observable<string | null> {
		return this.jsonGetter.getLanguages().pipe(
			switchMap((configLangs: ConfigLanguage[]) => {
				if (!curLang || curLang?.length === 0 || curLang === this.defaultLang) {
					return of(curLang)
				}

				const validLangs = configLangs.map((configLang) => configLang.code)
				const perfectMatch = validLangs.find(
					(validLang) => validLang === curLang,
				)
				if (perfectMatch) {
					return of(perfectMatch)
				}
				const bestMatchedLang: string | null = this.fuzzyStringSearch(
					validLangs,
					curLang,
				)

				return of(bestMatchedLang)
			}),
			catchError((err) => (console.error(err), of(null))),
		)
	}

	fallbackSingle = (key: string, fallback: string) => {
		const full_key = `${key}`
		const result = this.translator.instant(`${key}`)
		if (result == full_key) {
			console.warn(
				'Translation key was not found so the default was returned. Key:',
				key,
				'Fallback:',
				fallback,
			)
			return fallback
		}
		return result
	}

	fallbackMany = (keys_and_fallback: Record<string, string>) => {
		const values = {}
		for (const [key, fallback] of Object.entries(keys_and_fallback)) {
			const key_component = key.split('.')[0]
			const key_key = key.split('.')[1]
			if (!values[key_component]) {
				values[key_component] = {}
			}
			values[key_component][key_key] = this.fallbackSingle(key, fallback)
		}

		this.translator.setTranslation(this.translator.currentLang, values, true)
	}

	/**
  @param lang If `lang` is undefined then it checks the localStorage, defaultLanguage and API and syncs the values of them and uses the appropriate one.
  @returns the language used
  */
	setCurrentLanguage(lang?: string): Observable<string> {
		// If we have preferred language in the localStorage and no lang parameter then we want to use that
		if (this.env && !this.env.isBrowser()) {
			return this.getCurrentLanguage$().pipe(
				map((lang) => {
					this.useLanguage(lang)
					return lang
				}),
			)
		}

		const fallbackIfNull =
			(): OperatorFunction<string | null, string> =>
			(source: Observable<string | null>): Observable<string> =>
				source.pipe(
					switchMap((curLang) =>
						!curLang ? this.getCurrentLanguage$() : of(curLang),
					),
				) as Observable<string>

		const useAndReturn =
			(): OperatorFunction<string, string> =>
			(source: Observable<string>): Observable<string> =>
				source.pipe(
					map((curLang) => {
						this.useLanguage(curLang)
						return curLang
					}),
				)

		// If we're not logged in we can't get it from the API
		if (lang || this.cookieService.get('userId') == null) {
			return this.getBestMatchLanguage(lang ?? null).pipe(
				fallbackIfNull(),
				useAndReturn(),
			)
		}

		const getFromApi = () =>
			!(this.cookieService.get('userId') == null)
				? (x: any) => x // do nothing if we're not logged in
				: switchMap<string | null, Observable<string | null>>((curLang) =>
						this.coreDS.user.pipe(
							switchMap((user): Observable<string | null> => {
								if (user) {
									if (user.language_code === curLang) {
										return of(curLang)
									}

									if (!curLang) {
										return of(null)
									}

									// If we have a different language in the API then patch it
									this.userId = user.id
									const data = [
										{
											op: 'replace',
											path: '/language_code',
											value: curLang,
										},
									]
									if (!curLang) {
										return of(null)
									}
									return of(curLang).pipe(
										tap((_) =>
											this.http
												.patch('/v3/users/' + this.userId, data)
												.pipe(map((_) => curLang ?? this.defaultLang)),
										),
									)
								}
								return of(null)
							}),
							catchError((err) => (console.error(err), of(null))),
						),
				  )

		return this.getBestMatchLanguage(lang ?? null).pipe(
			getFromApi(),
			fallbackIfNull(),
			useAndReturn(),
		)
	}

	getCurrentLanguage(stale = true) {
		return this.cachedLanguage ?? this.getUsedLanguage() ?? this.defaultLang
	}

	getCurrentLanguage$(): Observable<string> {
		const allBrowserLanguages = this.request?.header('accept-language')

		if (allBrowserLanguages) {
			return this.languages$.pipe(
				map((langs) => {
					let browserPrefLang: string = this.defaultLang
					if (
						langs.some((lang) =>
							[lang.short, lang.long, lang.code].some((langCode) => {
								if (allBrowserLanguages?.includes(langCode)) {
									browserPrefLang = langCode
									return true
								}
								return false
							}),
						)
					) {
						this.defaultLang = browserPrefLang
					}
					return this.getCurrentLanguage(false)
				}),
			)
		}

		return of(this.getCurrentLanguage())
	}

	private useLanguage(lang: string): void {
		this.translator.use(lang).subscribe((_) => {
			this.cachedLanguage = lang
			if (this.env.isBrowser()) {
				this.cookieService.put(this.LANG_PREF, lang, 'necessary')
			}
		})
	}

	private getUsedLanguage(): string | null {
		let lang: string | null | undefined = this.cookieService.get(this.LANG_PREF)
		if (!lang || lang === 'undefined' || lang === 'null') {
			lang = null
		}
		return lang
	}
}
