import { Component, Inject, Input, OnInit, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { EliqApiHttpClient } from '@eliq/data-access'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ModalService } from '@eliq/ui/modal'
import {
	BasicButtonComponent,
	SnackbarService,
	LinkComponent,
	XButtonComponent,
} from '@eliq/ui'
import { Observable, catchError, interval } from 'rxjs'
@Component({
	selector: 'eliq-luna-register-email-banner-modal',
	standalone: true,
	imports: [
		CommonModule,
		BasicButtonComponent,
		LinkComponent,
		XButtonComponent,
	],
	templateUrl: './il-register-email-banner-modal.component.html',
	styleUrls: ['./il-register-email-banner-modal.component.scss'],
})
export class IlRegisterEmailBannerModalComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<IlRegisterEmailBannerModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			email: string
			emailIsVerified$: Observable<boolean>
			openedFromTicketsComponent: boolean
		},
	) {}

	snackbar = inject(SnackbarService)
	http = inject(EliqApiHttpClient)

	emailJustSent = false
	emailIsVerified = this.data?.openedFromTicketsComponent ?? false

	ngOnInit() {
		if (this.data?.openedFromTicketsComponent) {
			this.emailIsVerified = true
		} else {
			this.sendEmail()
			//this.emailIsVerified = true
			this.data.emailIsVerified$.subscribe((emailIsVerified) => {
				if (emailIsVerified) {
					this.emailIsVerified = true
				}
			})
		}
	}

	sendEmail() {
		if (this.emailJustSent) {
			return
		}
		this.emailJustSent = true

		this.http
			.post('/v3/authentication/tickets/setemail', {
				email: this.data.email,
			})
			.pipe(
				catchError((err, caught) => {
					console.error('Error when trying to set email:', err)
					this.snackbar.doErrorToast(5000)
					return caught
				}),
			)
			.subscribe((res) => {
				this.snackbar.doTextToast(
					"Sent verification email to '" + this.data.email + "'",
				)

				setTimeout(() => {
					this.emailJustSent = false
				}, 2000)
			})
	}

	closeModal() {
		this.dialogRef.close(false)
	}
}
