// @ts-nocheck
import { TranslateService } from '@ngx-translate/core'
import {
	APIProperty,
	APIPropertyDependency,
} from '../api-models/api-home-profile.model'

export class Property {
	private name: string
	private key: string
	private dataType: string
	private is_required: boolean
	private placeholder: any
	private dependentProperties: APIPropertyDependency[]

	constructor(apiProperty: APIProperty) {
		this.name = apiProperty.name
		this.key = apiProperty.key
		this.dataType = apiProperty.data_type
		this.is_required = apiProperty.is_required
		if (apiProperty.dependent_properties) {
			this.dependentProperties = apiProperty.dependent_properties
		}
	}

	/**
	 * Returns the dependent properties. This can be undefined as well, and probably will be
	 * most of the time.
	 */
	public getDependentProperties(): APIPropertyDependency[] {
		return this.dependentProperties
	}

	/**
	 * Getter $name
	 * @return {string}
	 */
	public get $name(): string {
		return this.name
	}

	/**
	 * Getter $key
	 * @return {string}
	 */
	public get $key(): string {
		return this.key
	}

	/**
	 * Getter $dataType
	 * @return {string}
	 */
	public get $dataType(): string {
		return this.dataType
	}

	/**
	 * Getter $is_required
	 * @return {boolean}
	 */
	public get $is_required(): boolean {
		return this.is_required
	}

	/**
	 * Getter $placeholder
	 * @return {any}
	 */
	public get $placeholder(): any {
		return this.placeholder
	}

	/**
	 * Setter $placeholder
	 */
	public set $placeholder(placeholder: any) {
		this.placeholder = placeholder
	}

	public getValue() {
		// to be overriden in extending class
	}

	public savePlaceholder() {
		// to be overrided in extending class
	}

	/**
	 * Will return the current selection. If there is placeholder data, it is returned.
	 * Used to show what the user has selected right now, even though it might not be confirmed / saved yet.
	 */
	public getCurrentSelection(): any {
		return // to be overridden
	}

	public getCurrentSelectionString(
		translator: TranslateService,
		maxChars?: number,
	): string {
		return // to be overridden in extending class
	}

	public hasChanges(): boolean {
		return // to be overridden
	}

	public hasValidSelection(): boolean {
		return // to be overridden
	}
}
