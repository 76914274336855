<eliq-card class="eliq-default-card-style container">
	<h6 class="primary-h6 header" translate>budget.title_budget</h6>

	<eliq-fuel-switch
		*ngIf="possibleFuelTypes.length > 1"
		[selectedFuel]="currentFuel.type"
		[fuels]="possibleFuelTypes"
		(newFuelSelected)="fuelChanged($event)"
	></eliq-fuel-switch>

	<div class="content">
		<div class="budgets">
			<div class="one-budget" *ngFor="let budget of currentFuelEnabledBudgets">
				<eliq-budget-home-card-item
					[budget]="budget"
					(budgetClicked)="budgetClicked.emit(budget)"
				></eliq-budget-home-card-item>
			</div>
			<div
				*ngIf="currentFuelEnabledBudgets && currentFuelEnabledBudgets.length === 0"
			>
				<p class="secondary-p1 side-padding" translate>
					budget.create_budget_description
				</p>
			</div>
		</div>

		<div
			*ngIf="currentFuelEnabledBudgets && currentFuelEnabledBudgets.length !== currentFuelBudgets.length"
			class="create-budget-area side-padding"
		>
			<eliq-flexible-button
				buttonId="home_budget_create_budget_button"
				label="budget.create_budget_title"
				[leftIcon]="false"
				(clicked)="createBudgetClicked.emit()"
			>
				<span style="margin-right: 4px;">+</span>
			</eliq-flexible-button>
		</div>
	</div>
</eliq-card>
