
import { Injectable, inject } from '@angular/core'
import { EliqApiHttpClient } from '@eliq/data-access'
import { CoreDataStoreService } from '@eliq/core'
import { Observable, of, ReplaySubject } from 'rxjs'
import { tap, map, catchError } from 'rxjs/operators'
import { Invoice } from '../models/Invoice.model'
import { BillingHandlerService } from './billing-handler.service'

export class APIInvoiceUrl {
	constructor(public invoice_id: string, public url: string) {}
}

@Injectable({
	providedIn: 'root',
})
export class BillViewerService {
	private url = '/v3/billingaccounts/'

	public http = inject(EliqApiHttpClient)
	public coreDS = inject(CoreDataStoreService)
	public billingHandler = inject(BillingHandlerService)

	public showBill(billingAccountId: string, invoiceId: string) {
		return this.http
			.get<APIInvoiceUrl>(
				this.url + billingAccountId + '/invoices/' + invoiceId,
			)
			.pipe(
				tap((res) => {
					window?.open(res.url, '_blank')
				}),
				map((res) => of(true)),
				catchError((err) => {
					console.error(err)
					return of(false)
				}),
			)
	}

	/**
	 * This function will get the billing account id via user id, then get the invoices for all billing
	 * accounts, then match the invoices id to be able to figure out which billing account it belongs to,
	 * then will try to show the invoice to user
	 * @param invoiceId the invoice to get url for and then show in new tab
	 */
	public showBillWithoutBillingAccountId(invoiceId: string) {
		const subject = new ReplaySubject<boolean>(1)

		this.coreDS.user.subscribe((user) => {
			this.billingHandler.getBillingAccounts(user.id).subscribe((accs) => {
				let billingId: string | undefined
				if (accs.length === 1) {
					// there is only one billing account so we know its this one.
					// this should happen pretty often so its a good if-statement to have
					billingId = accs[0].$id
				} else {
					// we have 2 or more billing accounts, so we need to find within which of these we have the desired invoice
					accs.forEach((acc) => {
						acc.$invoices.forEach((inv) => {
							if (inv.$id === invoiceId) {
								billingId = acc.$id
							}
						})
					})
				}

				this.showBill(billingId as string, invoiceId).subscribe((res) => {
					if (res) {
						// all good
						subject.next(true)
						subject.complete()
					} else {
						// not good
						subject.error('Not able to show invoice')
						subject.complete()
					}
				})
			})
		})

		return subject.asObservable()
	}

	/**
	 * This function will get the billing account id via user id, then get the invoices for all billing
	 * accounts, then match the invoices id to be able to figure out which billing account it belongs to
	 * @param invoiceId the invoice to get
	 */
	public getBillWithoutBillingAccountId(
		invoiceId: string,
	): Observable<Invoice> {
		const subject = new ReplaySubject<Invoice>(1)
		let invoice: Invoice | null = null

		this.coreDS.user.subscribe((user) => {
			this.billingHandler.getBillingAccounts(user.id).subscribe((accs) => {
				if (accs.length > 0) {
					for (const acc of accs) {
						if (invoice != null) {
							break
						}

						for (const inv of acc.$invoices) {
							if (inv.$id === invoiceId) {
								invoice = inv
								break
							}
						}
					}
				}

				if (invoice) {
					// all good
					subject.next(invoice)
					subject.complete()
				} else {
					// not good
					subject.error('Not able to get invoice')
					subject.complete()
				}
			})
		})

		return subject.asObservable()
	}
}
