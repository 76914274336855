<div class="container">
	<h6 class="primary-h6" style="margin: 10px 0;">
		{{'sign_in_start.find_account_number_title' | translate}}
	</h6>
	<p class="secondary-p2" style="margin-bottom: 25px;">
		{{'sign_in_start.find_account_number_desc' | translate}}
	</p>
	<eliq-basic-button
		label="common.ok"
		(clicked)="okClicked()"
	></eliq-basic-button>
</div>
