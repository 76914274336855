<div class="container">
	<div class="left">
		<div class="img">
			<img
				*ngIf="meter.type === 'elec' else notElec"
				[src]="'assets/images/pv_flash.svg' | imgSrc"
				alt="Flash"
				height="25"
				width="20"
			/>
		</div>
		<div>
			<div class="label">
				<span>{{'common.' + meter.type | translate}}</span>
			</div>
			<div class="secondary-p3" style="margin-bottom: 5px;">
				{{ 'submit_meter_read.id' | translate }} {{ meter.display_name }}
			</div>
		</div>
	</div>

	<div class="right">
		<div>
			<eliq-flexible-button
				buttonId="home_submit_meter_read_card_submit_read_button"
				(clicked)="showSubmitReadModal()"
				label="{{ 'submit_meter_read.submit' | translate }}"
			></eliq-flexible-button>
		</div>
	</div>
</div>

<ng-template #notElec>
	<img
		[src]="'assets/images/smr/Gas.svg' | imgSrc"
		alt="Flame"
		height="25"
		width="20"
	/>
</ng-template>
