import { Component, Input, OnInit, inject } from '@angular/core'
import { InfoLinkCardTrackingService } from '../../info-link-card/tracking/info-link-card-tracking.service'
import { PostmessageExternalSender } from '@eliq/external/kbc-post-message-api'
import { NgStyle, NgIf, NgClass } from '@angular/common'
import { CardComponent, FlexibleButtonComponent } from '@eliq/ui/common'
import { LinkComponent } from '@eliq/ui'
import { BasicButtonComponent } from '../../../../../common/components/buttons/basic-button/basic-button.component'
import { EliqThemeService } from '@eliq/theme'

@Component({
	selector: 'eliq-info-card-content',
	templateUrl: './info-card-content.component.html',
	styleUrls: ['./info-card-content.component.scss'],
	standalone: true,
	imports: [LinkComponent, CardComponent, NgStyle, NgIf, NgClass, BasicButtonComponent, FlexibleButtonComponent],
})
export class InfoCardContentComponent implements OnInit {
	@Input() title!: string
	@Input() text!: string
	@Input() imgSrc!: string
	@Input() link!: string
	@Input() linkText!: string
	@Input() cardStyle!: Record<string, string>
	@Input() imageStyle!: Record<string, string>
	@Input() imageLongShadow: boolean | string = false
	@Input() linkStyle: Record<string, string> = {} // variant or css record
	@Input() fullLink = false
	@Input() kbcPostMessageLink = false
	@Input() paddingBottom = false

	constructor(private tracking: InfoLinkCardTrackingService) {}

	public themeService = inject(EliqThemeService)
	ngOnInit(): void {
		// Defaults for container
		this.cardStyle = Object.assign(
			{
				'background': this.themeService.getProp("info-card-background", "consumption-card-indent"),
				'color': this.themeService.getProp("info-card-contrast", "consumption-card-indent-contrast"),
				'position': 'relative',
				'justify-content': 'center',
				'border-radius': this.themeService.getProp("card-border-radius")
			},
			this.cardStyle,
		)

		// Defaults for imageStyle
		this.imageStyle = Object.assign(
			{
				'align-self': 'flex-end',
				position: 'absolute',
			},
			this.imageStyle,
		)
		if (
			!Object.keys(this.imageStyle).includes('bottom') &&
			!Object.keys(this.imageStyle).includes('top')
		) {
			;(this.imageStyle as any)['top'] = '15px'
		}
	}

	_typeof(obj: any) {
		return typeof obj
	}
	onLinkClick(event: any, url: string) {
		event.preventDefault()

		if (this.kbcPostMessageLink) {
			const kbcSender = new PostmessageExternalSender()
			kbcSender.openUrl(url)
		} else {
			window?.open(url, '_blank')
		}

		this.tracking.cardOpened(url)
	}
}
