import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'eliq-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.css'],
	standalone: true,
})
export class CardComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
