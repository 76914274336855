import { Injectable, Inject } from '@angular/core'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { catchError, map, tap } from 'rxjs/operators'
import { EnvironmentService } from '../environment/environment.service'
import { makeStateKey, StateKey, TransferState } from '@angular/core'
import { TranslateLoader } from '@ngx-translate/core'
import { TranslateStore } from '@ngx-translate/core'

const ONLY_SERVER_TRANSLATE = false

@Injectable({
	providedIn: 'root',
})
export class TranslationLoaderService implements TranslateLoader {
	private clientId: string
	private baseUrl: string
	private assetsBaseUrl: string
	private loadedSubject: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false)
	public loadedObservable: Observable<boolean> =
		this.loadedSubject.asObservable()
	public serverStateKey!: StateKey<Record<string, Record<string, string>>>

	contentHeader = new HttpHeaders({
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	})
	constructor(
		private transferState: TransferState,
		private http: HttpClient,
		@Inject('clientId') clientId: string,
		@Inject('baseUrl') baseUrl: string,
		@Inject('ASSETS_BASE_URL') assetsBaseUrl: string,
		private env: EnvironmentService,
	) {
		this.clientId = clientId
		this.baseUrl = baseUrl
		this.assetsBaseUrl = assetsBaseUrl
	}

	getTranslation(
		lang: string,
	): Observable<Record<string, Record<string, string>>> {
		this.serverStateKey = makeStateKey<Record<string, Record<string, string>>>(
			'transfer-translate-' + lang,
		)

		if (
			ONLY_SERVER_TRANSLATE &&
			this.transferState.hasKey(this.serverStateKey)
		) {
			const serverState = this.transferState.get(this.serverStateKey, null)
			return serverState ? of(serverState) : of(null as any)
		}
		//else if (this.env && this.env.isBrowser()) {
		//return new TranslateHttpLoader(this.http)
		//}

		return this.http
			.get<Record<string, Record<string, string>>>(
				this.baseUrl +
					'/v3/translations?client_id=' +
					this.clientId +
					'&language_code=' +
					lang,
				//{ headers: this.contentHeader },
			)
			.pipe(
				map((result) => {
					if (ONLY_SERVER_TRANSLATE && result && !this.env.isBrowser()) {
						this.transferState.set(this.serverStateKey, result)
					}
					return result
				}),
				catchError((error) => {
					// lets fall back to our translations in our assets folder
					console.error(
						'Something went wrong so we returned a default translation',
					)
					console.error(error)
					console.trace()
					return this.http.get<Record<string, Record<string, string>>>(
						`${this.assetsBaseUrl}assets/translations/${lang}/translations.json`,
					)
				}),
			)
			.pipe(tap((res) => this.setAsLoaded()))
	}

	setAsLoaded() {
		this.loadedSubject.next(true)
	}
}

export function translationLoaderFactory(
	transferState: TransferState,
	http: HttpClient,
	clientId: string,
	baseUrl: string,
	assetsBaseUrl: string,
	env: EnvironmentService,
) {
	return new TranslationLoaderService(
		transferState,
		http,
		clientId,
		baseUrl,
		assetsBaseUrl,
		env,
	)
}
