import { Injectable } from '@angular/core'
import { HeaderObject } from '../models/HeaderObject.model'

import { Category } from '../models/Category.model'
import { CoreDataStoreService, JsonGetterService, Location } from '@eliq/core'
import { NotificationHandlerService } from '../../notifications'
import { HeaderConfigService } from './config/header-config.service'
import { take, tap } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class HeaderStateService {
	// the header is currently placed in several of the views at the top of the html,
	// and when we move from for example /home to /usage we need to keep consistency in the header
	// so this service is injected into header and acts as state manager for it

	private headers!: HeaderObject[]
	public accountSelected!: boolean
	public notificationsSelected!: boolean
	public nNotifications!: number

	private locations!: Location[]

	private accountCategories!: Category[]

	public headersChanged$ = new BehaviorSubject(this.headers)

	isHamburger$ = new BehaviorSubject(false)

	public set isHamburger(value: boolean) {
		this.isHamburger$.next(value)
	}

	public get isHamburger(): boolean {
		return this.isHamburger$.value
	}

	constructor(
		private notificationHandler: NotificationHandlerService,
		private coreDS: CoreDataStoreService,
		private config: HeaderConfigService,
	) {
		this.coreDS.locations.subscribe((locations) => {
			if (locations) {
				this.locations = locations
			}
		})

		this.config.getShowNotifications().pipe(
			take(1),
			tap((showNotifications) => {
				if (showNotifications) {
					this.notificationHandler.notifications.subscribe((nots) => {
						if (nots) {
							// null check
							this.nNotifications = nots.filter((not) => {
								return !not.$read
							}).length
						}
					})

					this.notificationHandler.startWatchingNotifications(60)
				}
			}),
		)
	}

	public getHeaders(): HeaderObject[] {
		return this.headers
	}

	public setNewSelectedHeader(name: string) {
		this.headers.forEach((header) => {
			if (header.link === name) {
				header.selected = true
			} else {
				header.selected = false
			}
		})
		this.headersChanged$.next(this.headers)
	}

	public setMenuStatus(name: string, isActive: boolean) {
		const menu = this.headers.find((x) => x.link === name)
		if (menu) {
			menu.isActive = isActive ?? true
		}
		this.headersChanged$.next(this.headers)
	}

	public setHeaders(headers: HeaderObject[]) {
		this.headers = headers
		this.headersChanged$.next(this.headers)
	}
}
