<div class="container">
	<div *ngIf="state === 'waiting'; else notWaitingBlock">
		<div *ngIf="!cantClose" class="x-button-container">
			<eliq-x-button (clicked)="close()"></eliq-x-button>
		</div>

		<h6 class="primary-h6 termsHeader" translate>
			terms_and_condition_prompt.title
		</h6>
		<p class=" primary-p2 termsText" translate>
			terms_and_condition_prompt.terms_and_condition_desc
		</p>
		<div class="embedded-terms" *ngIf="!termsLink || termsLink === 'embedded'" [innerHTML]="termsAndConditions$ | async"></div>

		<div class="link-container">
			<a
				*ngIf="termsLink && termsLink !== 'embedded'"
				[href]="termsLink"
				[target]="openNewTab ? '_blank' : ''"
				class="link"
			>
				<eliq-link
					label="terms_and_condition_prompt.terms_and_condition"
				></eliq-link>
			</a>
			<a
				*ngIf="privacyPolicyLink"
				[href]="privacyPolicyLink"
				[target]="openNewTab ? '_blank' : ''"
				class="link"
			>
				<eliq-link
					label="terms_and_condition_prompt.privacy_policy"
				></eliq-link>
			</a>
		</div>

		<div class="button-container">
			<eliq-basic-button
				[label]="'terms_and_condition_prompt.accept_and_continue' | translate"
				(clicked)="confirm()"
			></eliq-basic-button>
		</div>
	</div>

	<ng-template #notWaitingBlock>
		<div *ngIf="state === 'patching'; else errorBlock">
			<div class="loading-container">
				<eliq-spinner [size]="'medium'"></eliq-spinner>
			</div>
		</div>
	</ng-template>

	<ng-template #errorBlock>
		<h6 class="primary-h6 termsHeader" translate>
			terms_and_condition_prompt.terms_and_condition
		</h6>
		<p class=" primary-p2 termsText">
			<span translate>common.something_went_wrong</span>
			<span translate>common.please_try_again_later</span>
		</p>
		<div class="button-container">
			<eliq-basic-button
				[label]="'common.button_close' | translate"
				(clicked)="close()"
			></eliq-basic-button>
		</div>
	</ng-template>
</div>
