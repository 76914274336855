import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core'
import {
	FormControl,
	FormControlDirective,
	FormGroup,
	FormGroupDirective,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms'
import { SmrApiService } from '../../services/smr-api.service'
import { MatOption, MatOptionModule } from '@angular/material/core'
import { MatSelectModule } from '@angular/material/select'
import { NgFor, NgForOf, NgIf } from '@angular/common'
import { TranslateModule, TranslatePipe } from '@ngx-translate/core'

@Component({
	selector: 'eliq-smr-select-meter-dropdown',
	templateUrl: './smr-select-meter-dropdown.component.html',
	styleUrls: ['./smr-select-meter-dropdown.component.scss'],
	standalone: true,
	providers: [FormControlDirective],
	imports: [
		NgIf,
		NgFor,
		NgForOf,
		FormsModule,
		MatOptionModule,
		MatSelectModule,
		ReactiveFormsModule,
		TranslateModule,
	],
})
export class SmrSelectMeterDropdownComponent implements OnInit {
	//@Input() options: string[] = []
	// option setter & getter
	@Input() options: string[] = []

	private _labels: string[] = []

	@Input() set labels(value: string[]) {
		this._labels = value
	}
	get labels(): string[] {
		if (this._labels?.length !== this.options?.length) {
			return this.options
		}
		if (
			this._labels.some(
				(label) => typeof label !== 'string' || !(label?.length > 0),
			)
		) {
			return this.options
		}
		return this._labels
	}

	@Input() label = ''

	@Input() defaultText = ''
	@Input() defaultIndex = 0

	@Output() selected = new EventEmitter<string>()

	//@ViewChild('dropdown', { static: true }) dropdown: ElementRef

	@Input() selectedCtrl: FormControl = new FormControl('', Validators.required)

	constructor(private ref: ChangeDetectorRef, private api: SmrApiService) {}

	ngOnInit() {
		this.onSelected(this.options[this.defaultIndex])
	}

	onSelected(selection: any) {
		this.selectedCtrl.setValue(selection)
		this.selected.emit(selection)
		//this.ref.detectChanges()
	}
}
