import { Injectable } from '@angular/core'
import { CoreDataStoreService, JsonGetterService, Location } from '@eliq/core'
import { forkJoin, iif, Observable, of } from 'rxjs'
import {
	findIndex,
	map,
	mergeMap,
	shareReplay,
	switchMap,
	take,
	tap,
} from 'rxjs/operators'
import { Category } from '../../models/Category.model'
import { Item } from '../../models/Item.model'
import { AccountCategory } from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { EliqConnectService } from '@eliq/data-access/services/eliq-connect/eliq-connect.service'

@Injectable({
	providedIn: 'root',
})
export class HeaderHelperService {
	private prefix = 'settings_menu_options.'

	constructor(
		private jsonGetter: JsonGetterService,
		private coreDS: CoreDataStoreService,
		private envService: EnvironmentService,
		private connectService: EliqConnectService,
	) {}

	// This feels a bit iffy but it works
	// Basically category.items.push() works by reference and since this gets called twice
	// there was two meter_connection items showing.
	// Adding hasPushed made it so that it remembers if it has done it before because
	// services are singletons.
	public hasPushed = false
	public getAccountCategories(): Observable<AccountCategory[]> {
		return this.jsonGetter.getAccountStructureConfig().pipe(
			switchMap((categories) => {
				if (!this.hasPushed && this.envService.isIntegrationless()) {
					return this.connectService.getWebUri().pipe(
						map((webUri) =>
							categories.map((category) => {
								if (
									!this.hasPushed &&
									category.items.length &&
									category.id === 'general_settings'
								) {
									this.hasPushed = true
									const meterConnectionItem = category.items.find(
										(item) => item.id === 'meter_connection',
									)

									if (meterConnectionItem) {
										meterConnectionItem.href = webUri ?? '/'
									}
								}
								return category
							}),
						),
					)
				}
				return of(categories)
			}),
		)
	}

	private makeCategories(accountCategories: AccountCategory[]) {
		return accountCategories.map((accountCategory) => {
			return new Category(
				accountCategory.items.map(
					(accountItem) =>
						new Item(
							this.prefix + accountItem.id,
							false,
							accountItem.id,
							accountItem.href,
						),
				),
				accountCategory.id,
			)
		})
	}

	private shouldDivideLocations(): Observable<boolean> {
		return this.jsonGetter.getConfig('account').pipe(
			take(1),
			map((config) => config.divide_location_into_two_menu_items),
		)
	}

	public getCategories(): Observable<Category[]> {
		return this.getAccountCategories().pipe(
			map((accountCategories) => {
				return accountCategories.map((accountCategory) => {
					return new Category(
						accountCategory.items.map(
							(accountItem) =>
								new Item(
									this.prefix + accountItem.id,
									false,
									accountItem.id,
									accountItem.href,
								),
						),
						accountCategory.id,
					)
				})
			}),
			tap((categories) => {
				// this function here will run and update once the user logs in for example and the locations observable is updated
				this.coreDS.locations
					.pipe(
						switchMap((locations) => {
							return this.shouldDivideLocations().pipe(
								take(1),
								map((shouldDivide) => ({
									shouldDivide: shouldDivide,
									locations: locations,
								})),
							)
						}),
					)
					.subscribe(({ shouldDivide, locations }) => {
						const locationCategoryIndex = categories.findIndex(
							(c) => c.name === 'my_home',
						)
						if (categories[locationCategoryIndex]?.items) {
							categories[locationCategoryIndex].items =
								this.getItemsFromLocations(
									locations,
									shouldDivide,
									categories[locationCategoryIndex].items,
								)
						}

						/*if (categories[1].items.length === 0) {
            categories[1].items = locations.map(location => new Item(location.name, false, location.id.toString()))
          } else {
            categories[1].items = locations.map((location, index) => new Item(location.name, categories[1].items[index].selected, location.id.toString()))
          }*/
					})
			}),
		)
	}

	private getItemsFromLocations(
		locations: Location[],
		divide: boolean,
		previousItems: Item[],
	): Item[] {
		const arrayOfItemArrays: Item[][] = locations.map((location) => {
			const parts: { loc: Location; name: string; suffix: string }[] = []
			if (divide) {
				parts.push({
					loc: location,
					name: 'settings_menu_options.address',
					suffix: '-home_address',
				})
				parts.push({
					loc: location,
					name: 'settings_menu_options.home_profile',
					suffix: '-home_profile',
				})
			} else {
				parts.push({
					loc: location,
					name:
						location.name ||
						location.address.street_address ||
						(location.address.postal_code + ' ' || '') +
							(location.address.city || ''),
					suffix: '',
				})
			}

			return parts.map(
				(part) =>
					new Item(part.name, false, location.id.toString() + part.suffix),
			)
		})

		// flatten
		// eslint-disable-next-line prefer-spread
		const flattenedItems: Item[] = [].concat.apply([], arrayOfItemArrays as any)

		// if there are previous location-based menu items, one of them might be selected. So lets transfer that selection state over to us.
		// We know that the previousItems list's length === flattenedItems list length, so lets just apply it.
		if (previousItems.length > 0) {
			const selectedItemIndex = previousItems.findIndex((item) => item.selected)
			if (selectedItemIndex != -1) {
				// found it!
				flattenedItems[selectedItemIndex].selected = true
			}
		}

		return flattenedItems
	}
}
