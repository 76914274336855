import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
	ConsumptionHelperService,
	DataGetterService,
	Fuel,
	Location,
} from '@eliq/core'
import { DateHelper } from '@eliq/util'

import { Period } from '@eliq/core'
import {
	addDays,
	differenceInDays,
	isToday,
	startOfDay,
	subDays,
	subSeconds,
} from 'date-fns'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, take, tap } from 'rxjs/operators'
import { PvHomeCardComponent } from '../pv-home-card/pv-home-card.component'
import { NgIf } from '@angular/common'

export interface ConsProdMonthlyComparison {
	month: number
	consumption?: MonthlyComparison
	production?: MonthlyComparison
	import?: MonthlyComparison
	export?: MonthlyComparison
}

export interface MonthlyComparison {
	value: number
	diffPercentage: number
	days: number
}

export interface ConsProdDailyComparison {
	day: Date
	consumption: number
	production: number
	import: number
	export: number
}

@Component({
	selector: 'eliq-pv-home-card-container',
	templateUrl: './pv-home-card-container.component.html',
	styleUrls: ['./pv-home-card-container.component.scss'],
	standalone: true,
	imports: [NgIf, PvHomeCardComponent],
})
export class PvHomeCardContainerComponent implements OnInit {
	@Input() locId!: number
	@Input() period!: Period
	@Input() elecFuel!: Fuel
	@Input() location!: Location
	@Input() unit: 'energy' | 'cost' | 'm3' = 'cost'
	@Input() activeDataStream = true

	@Output() loading = new EventEmitter<boolean>()

	public monthlyComparison!: ConsProdMonthlyComparison
	public dailyComparison!: ConsProdDailyComparison

	private dateCalc: DateHelper
	private setupDone = false

	public loaded = false

	constructor(
		private data: DataGetterService,
		private consHelper: ConsumptionHelperService,
	) {
		this.dateCalc = DateHelper.getInstance()
	}

	ngOnInit() {
		this.setup()
		this.setupDone = true
	}

	ngOnChanges() {
		if (this.setupDone) {
			this.setup()
		}
	}

	private setup() {
		this.setLoaded(false)
		forkJoin({
			month: this.getMonthlyComparisons(this.period).pipe(
				tap((res) => (this.monthlyComparison = res)),
			),
			day: this.getDailyComparisons(this.elecFuel).pipe(
				tap((res) => (this.dailyComparison = res)),
			),
		})
			.pipe(take(1))
			.subscribe(
				(res) => {
					this.setLoaded(true)
				},
				(err) => {
					this.setLoaded(true)
				},
			)
	}

	private getMonthlyComparisons(
		period: Period,
	): Observable<ConsProdMonthlyComparison> {
		return forkJoin({
			cons: this.getMonthlyDataComparison('consumption', period),
			prod: this.getMonthlyDataComparison('production', period),
			import: this.getMonthlyDataComparison('import', period),
			export: this.getMonthlyDataComparison('export', period),
		}).pipe(
			map((res) => {
				return {
					month: period.getFirstDate().getMonth(),
					consumption: res.cons,
					production: res.prod,
					import: res.import,
					export: res.export,
				}
			}),
		)
	}

	private getDailyComparisons(
		elecFuel: Fuel,
	): Observable<ConsProdDailyComparison> {
		// if there is data for prod & cons TODAY, do TODAY
		// else, select the latest NON-TODAY DAY WITH DATA and go with this date FOR BOTH.
		// if there is no data for the PAST 5 DAYS, HIDE SECTION ALLTOGETHER

		const prodTo = this.activeDataStream
			? this.toDateToLastDateWithData(new Date(elecFuel.export.data_to))
			: this.toDateToLastDateWithData(new Date(elecFuel.production.data_to))
		const consTo = this.activeDataStream
			? this.toDateToLastDateWithData(new Date(elecFuel.import.data_to))
			: this.toDateToLastDateWithData(new Date(elecFuel.consumption.data_to))

		if (isToday(prodTo) && isToday(consTo)) {
			// DO TODAY STUFFS
			const fromDate = startOfDay(consTo)
			const toDate = addDays(fromDate, 1)

			return this.doDailyDataComparisonRequest(
				fromDate,
				toDate,
			) as Observable<ConsProdDailyComparison>
		} else {
			// DO SOME PREVIOUS DAY VALUES
			let latestDate = prodTo > consTo ? prodTo : consTo

			const dayDiff = Math.abs(differenceInDays(latestDate, new Date()))
			if (dayDiff >= 5) {
				return of(undefined) as unknown as Observable<ConsProdDailyComparison>
			}

			// we should go to yesterday if its a today-date!
			if (isToday(latestDate)) {
				latestDate = subDays(latestDate, 1)
			}

			const fromDate = startOfDay(latestDate)
			const toDate = addDays(fromDate, 1)

			return this.doDailyDataComparisonRequest(
				fromDate,
				toDate,
			) as Observable<ConsProdDailyComparison>
		}
	}

	private toDateToLastDateWithData(date: Date): Date {
		if (date.getHours() == 0 && date.getMinutes() == 0) {
			return subSeconds(date, 1)
		} else {
			return date
		}
	}

	private doDailyDataComparisonRequest(from: Date, to: Date): Observable<any> {
		let toReturn = of({})

		if (this.activeDataStream) {
			toReturn = forkJoin({
				import: this.data
					.getData(this.locId, 'import', 'elec', this.unit, 'day', from, to)
					.pipe(map((res) => res.data[0])),
				export: this.data
					.getData(this.locId, 'export', 'elec', this.unit, 'day', from, to)
					.pipe(map((res) => res.data[0])),
			})
		} else {
			toReturn = forkJoin({
				cons: this.data
					.getData(
						this.locId,
						'consumption',
						'elec',
						this.unit,
						'day',
						from,
						to,
					)
					.pipe(map((res) => res.data[0])),
				prod: this.data
					.getData(this.locId, 'production', 'elec', this.unit, 'day', from, to)
					.pipe(map((res) => res.data[0])),
			})
		}

		return toReturn.pipe(
			map((result: any) => {
				return {
					day: from,
					consumption: result?.cons ? result?.cons / 1000 : undefined,
					production: result?.prod ? result?.prod / 1000 : undefined,
					import: result?.import ? result?.import / 1000 : undefined,
					export: result?.export ? result?.export / 1000 : undefined,
				} as any
			}),
			catchError((_err) => of(undefined)),
		)
	}

	private getMonthlyDataComparison(
		type: string,
		currPer: Period,
	): Observable<MonthlyComparison | undefined> {
		const currMonthFrom = currPer.getFirstDate()
		const currMonthTo = this.dateCalc.addOfPeriod(
			currPer.getPeriodType(),
			1,
			currPer.getFirstDate(),
		)

		const prevMonthFrom = this.dateCalc.subOfPeriod(
			currPer.getPeriodType(),
			1,
			currMonthFrom,
		)
		const prevMonthTo = currMonthFrom

		return forkJoin({
			curr: this.data
				.getData(
					this.locId,
					type,
					'elec',
					this.unit,
					'day',
					currMonthFrom,
					currMonthTo,
				)
				.pipe(map((res) => res)),
			prev: this.data
				.getData(
					this.locId,
					type,
					'elec',
					this.unit,
					'day',
					prevMonthFrom,
					prevMonthTo,
				)
				.pipe(map((res) => res.data)),
		}).pipe(
			map((result) => {
				const currLength =
					this.consHelper.getIndexOfLastEntryWithValue(result.curr.data) ?? 0
				const prevLength =
					this.consHelper.getIndexOfLastEntryWithValue(result.prev) ?? 0
				const usedLength = Math.min(currLength ?? 0, prevLength ?? 0)

				const summaries = this.consHelper.summarizeIndexesInCommon([
					result.curr.data,
					result.prev,
				])
				const currSum = summaries[0]
				const prevSum = summaries[1]

				return {
					value: this.unit === 'cost' ? currSum : currSum / 1000,
					diffPercentage: Math.round((100 * (currSum - prevSum)) / prevSum),
					days: usedLength + 1,
				} as MonthlyComparison
			}),
			catchError((err) => of(undefined)),
		)
	}

	private setLoaded(loaded: boolean) {
		// Checking if load status has changed to avoid emiting same status multiple times
		if (loaded !== this.loaded) {
			this.loaded = loaded
			this.loading.emit(!loaded)
		}
	}
}
