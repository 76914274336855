import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/data-access'
import { ChatOptions } from '../models/chat-config.model'
import { Observable } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ChatConfigService {
	constructor(private config: JsonGetterService) {}

	getChatConfig(): Observable<ChatOptions> {
		return this.config.getConfig('chat')
	}
}
