<div class="main">
	<div class="primary-p2">
		<div class="text">
			<p translate [translateParams]="{email: userEmail}">
				sign_in_magic_link_not_working.check_email_address
			</p>
		</div>
		<div class="text">
			<p translate>
				sign_in_magic_link_not_working.check_junk_folder_request_new
			</p>
		</div>
	</div>
	<div class="submit-button">
		<eliq-basic-button
			label="sign_in_magic_link_not_working.send_email_again"
			(click)="sendNewEmail.emit()"
		></eliq-basic-button>
	</div>

	<div class="footer">
		<div class="footer-content">
			<div class="footer-contrast">
				<p class="primary-p2" translate>
					sign_in_magic_link_not_working.if_you_are_still_having_trouble_contact
				</p>
			</div>
			<div *ngIf="number | async as nr">
				<a class="contact" [href]="'tel:' + number">
					<img
						[src]="'/assets/images/account_phone.svg' | imgSrc"
						height="30"
						width="30"
						alt=""
						class="phone-icon"
					/>
					<eliq-link [label]="nr"></eliq-link>
				</a>
			</div>
		</div>
	</div>
</div>
