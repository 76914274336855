import { Component, Input, OnInit } from '@angular/core'
import { APITransaction } from '@eliq/feature/bills/models/api-models/api-transaction.model'
import { BlobHelper } from '@eliq/util'
import { BillingHandlerService } from '@eliq/feature/bills'
import { ModalService } from '@eliq/ui/modal'
import { PayModalComponent } from '../../pay-modal/pay-modal.component'
import { EliqDatePipe } from '@eliq/core'
import { DateTranslatorService } from '@eliq/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { EliqCurrencyPipe } from '@eliq/core/pipes/currency.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { SpinnerComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'
import { NgClass, NgIf, LowerCasePipe } from '@angular/common'
import { BasicButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-transaction-item',
	templateUrl: './transaction-item.component.html',
	styleUrls: ['./transaction-item.component.scss'],
	standalone: true,
	imports: [
		NgClass,
		NgIf,
		LinkComponent,
		SpinnerComponent,
		LowerCasePipe,
		TranslateModule,
		EliqCurrencyPipe,
		ImgSrcPipe,
		BasicButtonComponent,
	],
})
export class TransactionItemComponent {
	@Input() billingAccountId: string
	@Input() transaction: APITransaction
	@Input() options: any = {}
	@Input() isMini = false

	public downloadingTransaction = false

	constructor(
		private dateTranslator: DateTranslatorService,
		private datePipe: EliqDatePipe,
		private billingHandlerService: BillingHandlerService,
		private modal: ModalService,
	) {}

	showPayModal() {
		this.modal.openModal(PayModalComponent)
	}

	downloadTransaction() {
		this.downloadingTransaction = true
		this.billingHandlerService
			.getTransactionFile(this.billingAccountId, this.transaction.id)
			.subscribe((data) => {
				this.downloadingTransaction = false
				const url = URL.createObjectURL(data)
				window?.open(url, '_blank')
			})
	}

	transformDate(dateLike: Date | string): string {
		const date = new Date(dateLike)
		return this.dateTranslator.getDateTimeString(date, 'short', true)
	}
}
