import { Component, Input } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import {
	EliqTrackingService,
	TrackingEventName,
	EliqNotificationOpenedEvent,
	ViewNotificationEvent,
	TrackingScreen,
} from '@eliq/core/tracking'
import { TranslateModule } from '@ngx-translate/core'
import { BasicButtonComponent, LinkComponent } from '@eliq/ui'
import { RouterLink } from '@angular/router'

@Component({
	selector: 'eliq-notification-item-target',
	templateUrl: './notification-item-target.component.html',
	styleUrls: ['./notification-item-target.component.scss'],
	standalone: true,
	imports: [RouterLink, LinkComponent, TranslateModule],
})
export class NotificationItemTargetComponent {
	@Input() notification: NotificationData

	public routerParams: any
	public linkString = ''
	constructor(private tracking: EliqTrackingService) {}

	targetClicked() {
		const eventData: ViewNotificationEvent = {
			v: 2,
			from: TrackingScreen.Notifications,
			header: this.notification.$header,
			eventType: this.notification.$eventType,
		}
		this.tracking.logEvent(TrackingEventName.ViewNotification, eventData)
	}
}
