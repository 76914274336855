export enum FuelType {
	ELEC = 'elec',
	HEAT = 'district_heating',
	GAS = 'gas',
}

/**
 * Elec = 1, Heat = 2, Gas = 3.
 * If a is smaller than b, returns -1.
 * If a is equal to b, returns 0.
 * If a is larger than b, returns 1.
 * @param a
 * @param b
 */
export const fuelSorter = (a: any, b: any) => {
	const map = {}
	;(map as any)[FuelType.ELEC] = 1
	;(map as any)[FuelType.HEAT] = 2
	;(map as any)[FuelType.GAS] = 3

	if ((map as any)[a] < (map as any)[b]) {
		return -1
	}

	if ((map as any)[a] > (map as any)[b]) {
		return 1
	}
	return 0
}
