import {
	Directive,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	PipeTransform,
	inject,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Subscription } from 'rxjs'
import { EliqTranslatePipeArgs, TranslateOrFallbackPipe } from '../public_api'

@Directive({
	standalone: true,
	selector: '[eliqTranslatePreWrap]',
})
export class TranslatePreWrapDirective implements OnInit, OnDestroy {
	private args: { key: string } & EliqTranslatePipeArgs

	set setArgs(args: { key: string } & EliqTranslatePipeArgs) {
		this.args = args
		this.updateTranslation()
	}

	@Input() set eliqTranslatePreWrap(key: string) {
		this.setArgs = { ...this.args, key }
	}

	@Input() set eliqTranslatePreWrapValues(values: { [key: string]: any }) {
		this.setArgs = { ...this.args, values }
	}

	/// Pipes that run after the translation and pre-wrapping
	@Input() set eliqTranslatePreWrapPipes(
		pipes: ({ pipe: PipeTransform; args: any } | PipeTransform)[],
	) {
		this.setArgs = { ...this.args, pipes }
	}

	@Input() set eliqTranslatePreWrapFallbackKey(fallbackKey: string) {
		this.setArgs = { ...this.args, fallbackKey }
	}

	@Input() set eliqTranslatePreWrapFallbackString(fallbackString: string) {
		this.setArgs = { ...this.args, fallbackString }
	}

	constructor(private elem: ElementRef) {}

	private translatePipe = inject(TranslateOrFallbackPipe)
	private translate = inject(TranslateService)

	private subscription: Subscription

	ngOnDestroy() {
		this.subscription.unsubscribe()
	}

	updateTranslation() {
		const translated = this.translatePipe.transform(this.args.key, this.args)
		if (this.matchesHtml(translated)) {
			this.fixHtml()
		} else {
			this.fixPreWrapNewLines(translated)
		}
	}

	ngOnInit() {
		this.subscription = this.translate.onLangChange.subscribe(() => {
			this.updateTranslation()
		})
	}

	fixHtml() {
		this.elem.nativeElement.style.whiteSpace = 'normal'
		this.elem.nativeElement.innerHTML = this.translatePipe.transform(
			this.args.key,
			this.args,
		)
	}

	matchesHtml(str: string) {
		return /<[^>\n]{1,64}>/.test(str)
	}

	fixPreWrapNewLines(translated: string) {
		this.elem.nativeElement.style.whiteSpace = 'pre-wrap'
		const fixed = translated
			.replace(/\\\\n/g, '\n')
			.replace(/ {2}/g, '\n')
			.replace(/\u{200b}+/gu, '\n')
		this.elem.nativeElement.textContent = fixed
	}
}
