<div class="outer-container">
	<div class="container">
		<h6 class="primary-h6 header">{{header}}</h6>
		<div class="x" (click)="cancelDialog()">
			<eliq-x-button></eliq-x-button>
		</div>
		<div *ngIf="property; else applianceBlock">
			<eliq-homedetails-radiobuttons
				*ngIf="property.$dataType === 'single_select'"
				[property]="property"
				(formValueChange)="setNewValue($event)"
			></eliq-homedetails-radiobuttons>

			<eliq-homedetails-multiple-select
				*ngIf="property.$dataType === 'multiple_select'"
				[property]="property"
				(formValueChange)="setNewValue($event)"
			></eliq-homedetails-multiple-select>

			<eliq-homedetails-homesize
				*ngIf="property.$dataType === 'number' && shouldNumberPropHaveSlider(property)"
				(formValueChanged)="setNewValue($event)"
				(formStatusChange)="currentValidityChange($event)"
				[property]="property"
			></eliq-homedetails-homesize>

			<eliq-homedetails-amountof
				*ngIf="property.$dataType === 'number' && !shouldNumberPropHaveSlider(property)"
				[property]="property"
				(valueChange)="setNewValue($event)"
			></eliq-homedetails-amountof>
		</div>
		<ng-template #applianceBlock>
			<eliq-homedetails-appliances
				*ngIf="propertyGroup"
				[propertyGroup]="propertyGroup"
			></eliq-homedetails-appliances>
		</ng-template>
		<div class="footer">
			<div class="button-container">
				<eliq-basic-button
					label="common.button_save"
					(clicked)="saveClick()"
					[disabled]="!getCurrentlyValid()"
					[useEntireWidth]="true"
					[loading]="saveLoading"
				></eliq-basic-button>
			</div>
		</div>
	</div>
</div>
