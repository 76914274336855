<div class="container">
	<img [src]="'/assets/images/icon_language.svg' | imgSrc" alt="" />
	<div class="lang-box" *ngFor="let lang of languages">
		<div
			class="lang-text"
			[class.lang-text--selected]="selectedLang === lang.code"
			(click)="langClicked(lang.code)"
		>
			{{lang.short}}
		</div>
	</div>
</div>
