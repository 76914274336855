import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { BasicButtonComponent } from '@eliq/ui'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-smr-info-modal',
	templateUrl: './info-modal.component.html',
	styleUrls: ['./info-modal.component.scss'],
	standalone: true,
	imports: [BasicButtonComponent, TranslateModule],
})
export class InfoModalComponent {
	constructor(private dialogRef: MatDialogRef<InfoModalComponent>) {}

	close() {
		this.dialogRef.close()
	}
}
