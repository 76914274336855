import { Invoice } from './Invoice.model'
import { Agreement } from './Agreement.model'
import { APIBillingAccount } from './api-models/api-billing-account.model'
import { APIInvoice } from './api-models/api-invoice.model'
import { APIAgreement } from './api-models/api-agreement.model'

export class BillingAccount {
	private id: string
	private extRef: string
	private userRef: string
	private invoices: Invoice[]
	private agreements: Agreement[]

	constructor(
		apiBillingAccount: APIBillingAccount,
		apiInvoices: APIInvoice[],
		apiAgreements: APIAgreement[],
	) {
		this.id = apiBillingAccount.id
		this.extRef = apiBillingAccount.ext_ref
		this.userRef = apiBillingAccount.user_ref
		this.invoices = apiInvoices.map((apiInvoice) => {
			return new Invoice(apiInvoice, apiBillingAccount.id)
		})
		this.agreements = apiAgreements.map((apiAgreement) => {
			return new Agreement(apiAgreement)
		})
	}

	/**
	 * Getter $invoices
	 * @return {Invoice[]}
	 */
	public get $invoices(): Invoice[] {
		return this.invoices
	}

	/**
	 * Getter $agreements
	 * @return {Agreement[]}
	 */
	public get $agreements(): Agreement[] {
		return this.agreements
	}

	/**
	 * Setter $invoices
	 * @param {Invoice[]} value
	 */
	public set $invoices(value: Invoice[]) {
		this.invoices = value
	}

	/**
	 * Setter $agreements
	 * @param {Agreement[]} value
	 */
	public set $agreements(value: Agreement[]) {
		this.agreements = value
	}

	/**
	 * Getter $id
	 * @return {string}
	 */
	public get $id(): string {
		return this.id
	}

	/**
	 * Getter $extRef
	 * @return {string}
	 */
	public get $extRef(): string {
		return this.extRef
	}

	/**
	 * Getter $userRef
	 * @return {string}
	 */
	public get $userRef(): string {
		return this.userRef
	}

	/**
	 * Setter $id
	 * @param {string} value
	 */
	public set $id(value: string) {
		this.id = value
	}

	/**
	 * Setter $extRef
	 * @param {string} value
	 */
	public set $extRef(value: string) {
		this.extRef = value
	}

	/**
	 * Setter $userRef
	 * @param {string} value
	 */
	public set $userRef(value: string) {
		this.userRef = value
	}
}
