<div class="container">
	<h5 class="primary-h5 header">
		{{'my_bills.pay' | translate}} (placeholder)
	</h5>
	<p>
		Here you can display your payment provider of choice, like Stripe or Klarna.
	</p>
	<img
		src="https://docs.klarna.com/assets/media/bb791fe3-df86-4e6c-87fe-85f2cf3b3ac6/compressed/checkout-example-gb.png"
		style="border: 2px dashed black;
      height: 300px; width: auto;
      display: block; margin-left: auto; margin-right: auto;
      margin-bottom: 50px;"
	/>

	<eliq-basic-button
		(clicked)="close()"
		label="Close"
		[variant]="'secondary'"
	></eliq-basic-button>
</div>
