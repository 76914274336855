import { Component, Input, OnInit } from '@angular/core'
import { APIAccountBalance } from '../../models/api-models/api-account-balance.model'
import { EliqNumberPipe } from '@eliq/core/pipes/eliq-number.pipe'
import { EliqDatePipe } from '@eliq/core/pipes/date.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { NgIf, LowerCasePipe } from '@angular/common'

@Component({
	selector: 'eliq-mini-account-balance',
	templateUrl: './mini-account-balance.component.html',
	styleUrls: ['./mini-account-balance.component.scss'],
	standalone: true,
	imports: [NgIf, LowerCasePipe, TranslateModule, EliqDatePipe, EliqNumberPipe],
})
export class MiniAccountBalanceComponent implements OnInit {
	@Input() accountBalance: APIAccountBalance

	public updatedDate: Date

	constructor() {}

	ngOnInit(): void {
		if (this.accountBalance.updated_date) {
			this.updatedDate = new Date(this.accountBalance.updated_date)
		}
	}
}
