<eliq-signin-card-two
	[header]="currentHeader"
	[showBackButton]="showBackButton"
	(backClicked)="backClicked()"
>
	<ng-container *ngIf="state === 'started' || state === 'initiating' || state === 'initiated'">
		<div class="signin-area">
			<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="state !== 'initiated'">
				<label for="email" class="form-input-label textlabel">
					{{'sign_in_start.email_address' | translate}}
				</label>
				<mat-form-field appearance="outline">
					<input
						matInput
						type="text"
						id="email"
						class="email-input input-text primary-p3"
						[formControlName]="'email'"
						[errorStateMatcher]="signinErrorStateMatcher"
					/>
					<mat-error
						*ngIf="(form.get('email')?.hasError('email') || form.get('email')?.hasError('required')) && showErrors"
						translate
					>
						sign_in_start.provide_correct_email
					</mat-error>
				</mat-form-field>

				<div class="label-container">
					<label for="account_number" class="form-input-label textlabel">
						{{'sign_in_start.account_number' |
            translate}}
					</label>
					<div
						class="help-icon"
						[inlineSVG]="'/assets/images/information_circle_outline.svg' | imgSrc"
						[setSVGAttributes]="{style: 'width: 20px; height: 20px;'}"
						(click)="accountNumberHelpClicked()"
					></div>
				</div>
				<mat-form-field
					appearance="outline"
					class="input-field"
				>
					<input
						matInput
						type="text"
						id="account_number"
						class="email-input input-text primary-p3"
						[formControlName]="'account_number'"
						[errorStateMatcher]="signinErrorStateMatcher"
					/>
					<mat-error
						*ngIf="(form.get('account_number')?.hasError('minlength') || form.get('account_number')?.hasError('required')) && showErrors"
						translate
					>
						sign_in_start.provide_correct_account_number
					</mat-error>
				</mat-form-field>

				<div class="form-submit-button">
					<eliq-basic-button
						[disabled]="state === 'initiating'"
						type="submit"
						[loading]="state === 'initiating'"
						[label]="'sign_in_start.sign_in'"
						[useEntireWidth]="true"
					></eliq-basic-button>
				</div>
			</form>
		</div>
	</ng-container>

	<ng-container *ngIf="state === 'initiated'">
		<eliq-signin-magiclink-verification
			[userEmail]="emailControl.value"
			(havingTroubleClicked)="setState('trouble')"
		></eliq-signin-magiclink-verification>
	</ng-container>

	<ng-container *ngIf="state === 'trouble'">
		<eliq-signin-magiclink-having-trouble
			(sendNewEmail)="sendNewEmail()"
			[userEmail]="emailControl.value"
		></eliq-signin-magiclink-having-trouble>
	</ng-container>

	<ng-content
		*ngIf="state === 'started' || state === 'initiating'"
		select="[signin-types-footer]"
	></ng-content>
	<ng-content
		*ngIf="state === 'started' || state === 'initiating'"
		select="[having-trouble]"
	></ng-content>
</eliq-signin-card-two>
