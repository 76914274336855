import { Component, Input, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { InputHelperService } from '@eliq/core'
import { take } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui'
import { XButtonComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-annual-card-success-modal',
	templateUrl: './annual-card-success-modal.component.html',
	styleUrls: ['./annual-card-success-modal.component.scss'],
	standalone: true,
	imports: [XButtonComponent, BasicButtonComponent],
})
export class AnnualCardSuccessModalComponent implements OnInit {
	@Input() lastYear!: number
	@Input() greyBorder!: boolean
	@Input() estimatedEnergy!: number

	public valueSuffix = ''
	// random id used to stop the browser from auto-suggesting
	public randomId = ''
	public successTitleText = ''
	public thanks = ''
	public forWhat = ''
	public whatHappened = ''
	public closeButtonText = ''
	constructor(
		private dialogRef: MatDialogRef<AnnualCardSuccessModalComponent>,
		private inputHelper: InputHelperService,
		private translator: TranslateService,
	) {}

	ngOnInit(): void {
		this.randomId = this.inputHelper.makeid(10)
		//all our languages uses this so no need to translate (see common component)
		this.valueSuffix = 'kWh.'

		this.successTitleText = this.translator.instant(
			'annual_consumption.success_title',
		)
		if (this.successTitleText === 'annual_consumption.success_title') {
			this.successTitleText = 'Annual energy consumption set!'
		}
		this.thanks = this.translator.instant('annual_consumption.success_thanks')
		this.forWhat = this.translator.instant(
			'annual_consumption.success_for_what',
		)
		this.whatHappened = this.translator.instant(
			'annual_consumption.success_what_happened',
			{ value: this.lastYear },
		)
		this.closeButtonText = this.translator.instant('common.button_close')
		this.dialogRef
			.backdropClick()
			.pipe(take(1))
			.subscribe(() => this.closeClicked())
	}

	saveClicked() {
		this.dialogRef.close()
	}

	closeClicked() {
		this.dialogRef.close()
	}
}
