import { Component, EventEmitter, Input, Output, inject } from '@angular/core'
import { SpinnerComponent } from '@eliq/ui'
import { FlexibleButtonComponent } from '@eliq/ui/common/components/buttons/flexible-button/flexible-button.component'
import { NgIf } from '@angular/common'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'
import { EliqThemeService } from '@eliq/theme'
import { InlineSVGModule } from 'ng-inline-svg-2'

@Component({
	selector: 'eliq-energy-advisor-card',
	templateUrl: './energy-advisor-card.component.html',
	styleUrls: ['./energy-advisor-card.component.css'],
	standalone: true,
	imports: [
		InlineSVGModule,
		CardComponent,
		NgIf,
		FlexibleButtonComponent,
		SpinnerComponent,
	],
})
export class EnergyAdvisorCardComponent {
	@Input() loaded: boolean
	@Input() header: string
	@Input() text: string
	@Input() estimatedSavings: string
	@Input() imgSrc: string
	@Input() buttonLabel: string
	@Input() buttonRoute: string
	@Output() cardClicked = new EventEmitter()
	@Input() comingSoon = false

	public themeService = inject(EliqThemeService)
}
