import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import {
	APIConsent,
	APIHomeProfile,
	CoreDataStoreService,
	HomeProfile,
	JsonGetterService,
	Location,
	LocationHttpService,
	Property,
	PropertyGroup,
	UserHttpService,
	WizardStep,
} from '@eliq/core'
import { ConsentService } from '@eliq/feature/consents/consent.service'

import { EliqTrackingService, TrackingScreen } from '@eliq/core/tracking'
import { forkJoin, Observable, of } from 'rxjs'
import {
	catchError,
	delay,
	map,
	retry,
	retryWhen,
	take,
	tap,
} from 'rxjs/operators'
import { EnvironmentService } from '@eliq/data-access'
import { SpinnerComponent } from '@eliq/ui'
import { SigninConsentComponent } from '../signin-consent/signin-consent.component'
import { HomedetailsSetupComponent } from '@eliq/feature/homeprofile/components/homedetails-setup/homedetails-setup.component'
import { NgIf } from '@angular/common'
import { SigninCardTwoComponent } from '../signin-card-two/signin-card-two.component'
import { CacheService } from '@eliq/data-access'
@Component({
	selector: 'eliq-signin-setup-wizard',
	templateUrl: './signin-setup-wizard.component.html',
	styleUrls: ['./signin-setup-wizard.component.css'],
	standalone: true,
	imports: [
		SigninCardTwoComponent,
		NgIf,
		HomedetailsSetupComponent,
		SigninConsentComponent,
		SpinnerComponent,
	],
})
export class SigninSetupWizardComponent implements OnInit {
	@Output() done = new EventEmitter()

	public state = 'loading'

	public locName = ''
	public loc: Location

	// list of setups to perform
	public steps: string[] = []
	public currentStep = 0

	// home profile setup
	public properties?: Property[]
	public propertyGroups?: PropertyGroup[]

	// consents setup
	public consents?: APIConsent[]

	constructor(
		private env: EnvironmentService,
		private config: JsonGetterService,
		private coreDS: CoreDataStoreService,
		private consentsService: ConsentService,
		private locHttp: LocationHttpService,
		private tracking: EliqTrackingService,
		private cacheService: CacheService,
	) {}

	ngOnInit(): void {
		this.state = 'loading'

		const userInfo$ = forkJoin({
			locations: this.coreDS.locations.pipe(take(1)),
			location: this.coreDS.getActiveLocation().pipe(take(1)),
			config: this.config.getLoginConfig().pipe(take(1)),
		}).pipe(
			retry({ count: 3, delay: 3000 }),
			take(1),
			catchError((err, caught) => {
				console.error(
					`Error prevented us from signing in: ${err},\n... Retrying ...`,
				)
				return caught
			}),
		)

		userInfo$.subscribe((res) => {
			if (res.locations.length > 1) this.locName = res?.location?.name ?? ''
			if (!res.location ?? res.locations[0]) {
				return
			}
			this.loc = res.location ?? res.locations[0]
			this.prepareSteps(res.config.wizardSteps)
		})
	}

	private prepareSteps(wizardSteps: WizardStep[]) {
		const prepRequests: Observable<
			APIHomeProfile | APIConsent[] | undefined
		>[] = []

		wizardSteps.forEach((step) => {
			if (step.id === 'home_profile_setup') {
				prepRequests.push(
					this.prepareHomeProfileSetup(this.loc, step).pipe(take(1)),
				)
			} else if (step.id === 'consents') {
				prepRequests.push(this.prepareConsentsSetup(step).pipe(take(1)))
			}
		})

		forkJoin(prepRequests).subscribe((res) => {
			// errors are handled in the children requests, so should be good!
			if (this.consents) this.steps.push('consents')
			if (this.properties) this.steps.push('home_profile_setup')

			if (this.steps.length === 0) {
				// all done!
				this.done.emit()
				return
			} else {
				// we have some setup to do!
				this.tracking.logScreenViewEvent(TrackingScreen.LoginSetup)
			}

			this.state = 'setup'
			this.currentStep = 0
		})
	}

	stepCompleted() {
		if (this.currentStep >= this.steps.length - 1) {
			this.done.emit()
		} else {
			this.currentStep += 1
		}
	}

	/**
	 *
	 * @param location
	 * @param wizardStep
	 */
	private prepareHomeProfileSetup(location: Location, wizardStep: WizardStep) {
		forkJoin({
			hpRes: this.locHttp.getLocationHomeProfile(location.id).pipe(
				map((res) => res),
				catchError((e) => of(undefined)),
			),
		}).pipe(
			map(
				(res) =>
					new HomeProfile(this.env, res?.hpRes ?? ({} as APIHomeProfile)),
			),
			tap((hp) => {
				const neededPropertyUpdates = hp.$properties.filter(
					(p) => p.getCurrentSelection() === undefined,
				)
				const neededPropertyGroupUpdates = hp.$propertyGroups.filter(
					(p) => p.getCurrentSelection() === undefined,
				)
				this.properties =
					neededPropertyUpdates.length > 0 ? neededPropertyUpdates : []
				this.propertyGroups =
					neededPropertyGroupUpdates.length > 0
						? neededPropertyGroupUpdates
						: []
				if (this.properties.length === 0 && this.propertyGroups.length === 0) {
					this.properties = undefined
					this.propertyGroups = undefined
				}
			}),
		)
		return this.locHttp.getLocationHomeProfile(location.id)
	}

	private prepareConsentsSetup(wizardStep: WizardStep) {
		return this.consentsService.getConsents().pipe(
			tap((consents) => {
				const consentsToGive =
					this.consentsService.getLoginConsentsNotGiven(consents)

				this.consents = consentsToGive.length > 0 ? consentsToGive : undefined
			}),
			catchError((err) => of(undefined)),
		)
	}
}
