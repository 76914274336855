import { Injectable } from '@angular/core'
import { HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { EliqApiHttpClient } from '@eliq/data-access'

export class Ticket {
	id!: string
	status!: TicketStatusType
}

export class TokenWrapper {
	user_id?: number
	token?: Token
}
export class Token {
	access_token?: string
	token_type?: string
	expires_in?: number
	refresh_token?: string
	refresh_token_expires_in?: number
}

export class Oauth {
	tokenId!: string
}

export enum TicketStatusType {
	Initiated = 'initiated',
	Aborted = 'aborted',
	Syncing = 'syncing',
	Completed = 'completed',
	Error = 'error',
}

@Injectable({
	providedIn: 'root',
})
export class TicketService {
	private url = '/v3'

	constructor(private http: EliqApiHttpClient) {}

	public getTicketStatus(ticketId: string): Observable<Ticket> {
		return this.http.get<Ticket>(
			`${this.url}/authentication/tickets/${ticketId}`,
		)
	}

	public verifyTicket(ticketId: string, ticketKey: string): Observable<Ticket> {
		const header = new HttpHeaders({ 'content-type': 'application/json' })
		return this.http.post<Ticket>(
			`${this.url}/authentication/tickets/${ticketId}/verify`,
			{ key: ticketKey },
			{ headers: header, body: undefined },
		)
	}

	public getTokenForTicket(ticketId: string): Observable<TokenWrapper> {
		return this.http.get<TokenWrapper>(
			`${this.url}/authentication/tickets/${ticketId}/token`,
		)
	}
}
