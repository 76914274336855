import { NgForOf, NgIf } from '@angular/common'
import {
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnInit,
	QueryList,
	ViewChildren,
} from '@angular/core'
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms'
import { EliqDatePipe } from '@eliq/core'
import { Register } from '@eliq/feature/smr-card/models/Register.model'
import { SmrMeter } from '@eliq/feature/smr-card/models/SmrMeter.model'
import { TranslateModule } from '@ngx-translate/core'

@Component({
	selector: 'eliq-submit-modal-input',
	templateUrl: './submit-modal-input.component.html',
	styleUrls: ['./submit-modal-input.component.scss'],
	standalone: true,
	imports: [NgIf, NgForOf, ReactiveFormsModule, TranslateModule, EliqDatePipe],
})
export class SubmitReadInputComponent implements OnChanges {
	@Input() meter: SmrMeter
	@Input() registersForm: FormGroup
	@Input() registers: Register[]

	@ViewChildren('inputFieldsReference') inputFieldsReference: QueryList<
		ElementRef<HTMLInputElement>
	>

	ngOnChanges(): void {
		// We want to parse the meter register values here incase they're already set so they show up in the form (reverse of the below logic)

		const inputs = this.inputFieldsReference
			?.toArray()
			?.map((el: ElementRef<HTMLInputElement>) => el.nativeElement)

		if (!inputs) {
			setTimeout(() => this.ngOnChanges(), 100)
			return
		}

		for (const [i, register] of Object.entries(this.registers)) {
			if (!register?.value?.length) {
				continue
			}
			if (register.digits < 10) {
				// Short read

				for (let j = 0; j < register.digits; j += 1) {
					inputs[j + parseInt(i)]!.value = register.value![j] || ''
				}
			} else {
				// Long read
				inputs[0].value = register.value!
			}
		}
		//
		// for (const [i, register] of Object.entries(this.registers)) {
		//
		// 	if ((!register?.value?.length || 0) !== (register?.digits || 0)) {
		//
		// 		continue
		// 	}

		// 	const theControl = this.registersForm.controls[register.id] as FormControl
		// 	theControl.setValue(register.value)
		// 	const value = register.value!
		//
		//

		//
		//}
	}

	shortReadDigitsChanged(event: KeyboardEvent) {
		const inputIsEnter = event.key === 'Enter' || event.keyCode === 13

		if (inputIsEnter) {
			return true
		}

		const target = event.target as HTMLInputElement
		const curIndex = parseInt(target.dataset['index'] as string)

		const inputs = this.inputFieldsReference
			.toArray()
			.map((el: ElementRef<HTMLInputElement>) => el.nativeElement)

		const inputIsNumber =
			(parseInt(event.key) <= 9 && parseInt(event.key) >= 0) ||
			(event.keyCode >= 48 && event.keyCode <= 57)

		const inputIsBackspace = event.key === 'Backspace' || event.keyCode === 8

		const inputIsTab = event.key === 'Tab' || event.keyCode === 9

		const inputIsRight = event.key === 'Right' || event.key === 'ArrowRight'
		const inputIsLeft = event.key === 'Left' || event.key === 'ArrowLeft'

		let nextInput: HTMLInputElement | null = null

		if (inputIsBackspace) {
			if (curIndex > 0 && target.value.length === 0) {
				nextInput = inputs[curIndex - 1]
			}
			target.value = ''
		}

		if (inputIsNumber) {
			target.value = event.key
			if (curIndex < inputs.length - 1) {
				nextInput = inputs[curIndex + 1]
			}
		}

		if (inputIsTab || inputIsLeft || inputIsRight) {
			if ((event.shiftKey || inputIsLeft) && curIndex < inputs.length - 1) {
				nextInput = inputs[curIndex - 1]
			} else if (curIndex + 1 < inputs.length) {
				nextInput = inputs[curIndex + 1]
			} else {
				return true
			}
		}

		if (nextInput !== null) {
			nextInput.focus()
		}

		const inputValues = inputs.map((el: HTMLInputElement) => el.value)

		for (let i = 0; i < this.registers.length; i += 1) {
			this.registers[i].value = inputValues
				.slice(
					i * this.registers[i].digits,
					i * this.registers[i].digits + this.registers[i].digits,
				)
				.join('')
		}

		const errors: Record<string, undefined | boolean>[] = []

		for (const register of this.registers) {
			const theControl = this.registersForm.controls[register.id] as FormControl
			theControl.setValue(register.value)
			const regError = {
				...((register.value?.length ?? 0) < register.digits
					? { minLength: true }
					: null),
			}
			if (Object.keys(regError).length) {
				theControl.setErrors(regError)
				errors.push(regError)
			} else {
				theControl.setErrors(null)
			}
			//
			//
		}

		let summarizedErrors: Record<string, any> | null = {
			...(errors.some((reg) => reg['minLength']) ? { minLength: true } : {}),
		}
		if (Object.keys(summarizedErrors).length === 0) {
			summarizedErrors = null
		}

		this.registersForm.setErrors(summarizedErrors)
		this.registersForm.value

		//

		return false
	}

	longReadDigitsChanged(event: KeyboardEvent) {
		const target = event.target as HTMLInputElement
		const inputValue = target?.value
		const regIndex = target?.dataset?.['regIndex']
		const register =
			regIndex !== undefined ? this.registers[regIndex] : undefined

		let regFormControl: FormControl<any> | null = null

		if (register !== undefined) {
			register.value = inputValue

			regFormControl = this.registersForm.controls[register.id] as FormControl

			regFormControl.setValue(register.value)
			const value = register.value
		}

		// Validation
		let errors = {}
		if (register.value.length > register.digits) {
			errors['maxLength'] = true
		} else if (register.value.length < register.digits) {
			errors['minLength'] = true
		}
		if (!Object.keys(errors).length) {
			errors = {}
		}

		regFormControl?.setErrors(errors)
	}
}
