import { Component, OnInit, Input } from '@angular/core'
import { PropertyGroup, PropertyNumber } from '@eliq/core'
import { TranslateModule } from '@ngx-translate/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { NumberSpinnerCompactComponent } from '@eliq/ui/common/components/forms/number-spinner-compact/number-spinner-compact.component'
import { NgFor } from '@angular/common'
import { PropertyIconComponent } from '@eliq/ui/common/components/property-icon/property-icon.component'

@Component({
	selector: 'eliq-homedetails-appliances',
	templateUrl: './homedetails-appliances.component.html',
	styleUrls: ['./homedetails-appliances.component.css'],
	standalone: true,
	imports: [
		PropertyIconComponent,
		NgFor,
		NumberSpinnerCompactComponent,
		ImgSrcPipe,
		TranslateModule,
	],
})
export class HomedetailsAppliancesComponent {
	@Input() propertyGroup: PropertyGroup

	checkChange(property: PropertyNumber, event) {
		//isnt used right now as there are no checkboxes at the moment.
		property.$placeholder = event ? property.$limits.min : undefined
	}

	propertyValChange(property: PropertyNumber, value: number | null) {
		property.$placeholder = value

		// Saving placeholder so that the changed input's value is not reset when user navigates between the home profile steps
		property.savePlaceholder()

		// Workaround: setting placeholder to the changed value, so that when we're saving home profile changes this change is detected
		property.$placeholder = value
	}
}
