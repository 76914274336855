import { Component } from '@angular/core'

@Component({
	selector: 'eliq-session-expired',
	templateUrl: './session-expired.component.html',
	styles: [''],
})
export class SessionExpiredComponent {
	constructor() {}
}
