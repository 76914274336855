import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core'

@Injectable({
	providedIn: 'root',
})
export class SnackbarService {
	// simple little service that can create a snackbar with text on the screen!

	constructor(
		private snackBar: MatSnackBar,
		private translator: TranslateService,
	) {}

	public doTextToast(message: string, duration?: number) {
		this.snackBar.open(this.translator.instant(message), undefined, {
			duration: duration ? duration : 2000,
			panelClass: ['snackbar'],
		})
	}

	public doErrorToast(duration = 5000) {
		const errorMsg =
			this.translator.instant('common.something_went_wrong') +
			' ' +
			this.translator.instant('common.please_try_again_later')
		this.doTextToast(errorMsg, duration)
	}
}
