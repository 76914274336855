// @ts-nocheck
import { Injectable } from '@angular/core'
import { CoreDataStoreService } from '@eliq/core'
import { Observable, ReplaySubject, interval, Subscription } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { NotificationData } from '../models/NotificationData.model'
import { NotificationsApiService } from './notifications-api.service'

@Injectable({
	providedIn: 'root',
})
export class NotificationHandlerService {
	// a simple notification handler which is ment to be handled as a singleton and keeps track of notifications.
	// will also later have functions such as notificationRead(notificationId) that uses user-http-service or something
	// to post to the server that we have received and read a notification

	private subject: ReplaySubject<NotificationData[]>
	private $notifications: Observable<NotificationData[]>

	private currentNots: Map<number, NotificationData> = new Map()

	private initDone = false
	private watching = false
	private intervalSubscription: Subscription

	constructor(
		private api: NotificationsApiService,
		private coreDS: CoreDataStoreService,
	) {}

	public setNotifications(notifications: NotificationData[]) {
		this.subject.next(notifications)
	}

	public get notifications(): Observable<NotificationData[]> {
		if (!this.subject) {
			this.subject = new ReplaySubject(1)
			this.$notifications = this.subject.asObservable()
		}

		if (!this.initDone) {
			this.initDone = true

			this.coreDS.user.subscribe((user) => {
				this.api.getNotifications(user.id).subscribe((nots) => {
					const arrNots = nots?.map((not) => new NotificationData(not))
					this.subject.next(arrNots)
					this.currentNots = this.arrayToMap(arrNots)
				})
			})
		}

		return this.$notifications
	}

	/**
	 * Starts making api request for notifications every n seconds,
	 * updating the get notifications observable if there are additions.
	 * @param interval the time between each request in seconds. default 60
	 */
	public startWatchingNotifications(intervalBetweenReqs = 60) {
		if (!this.watching) {
			// only run this stuff once.
			this.watching = true

			const firstSub = this.$notifications.pipe(take(1)).subscribe(() => {
				// make sure that this code above runs first. then we can start polling for updates.
				// the user should have called get first anyway, but this way we ensure we dont face any race conditions.
				this.coreDS.user.subscribe((user) => {
					this.intervalSubscription = interval(
						intervalBetweenReqs * 1000,
					).subscribe(() => {
						this.api.getNotifications(user.id).subscribe((nots) => {
							const newNots: Map<number, NotificationData> = new Map()
							nots.forEach((not) =>
								newNots.set(not.id, new NotificationData(not)),
							)
							if (newNots.size > this.currentNots.size) {
								this.currentNots = newNots
								this.subject.next(this.mapToArray(this.currentNots))
							}
						})
					})
				})
			})
		}
	}

	/**
	 * Stops the current intervalSubscription, if there is one.
	 */
	public stopWatchingNotifications() {
		if (this.intervalSubscription) {
			this.intervalSubscription?.unsubscribe()
		}
	}

	private mapToArray(map: Map<number, NotificationData>): NotificationData[] {
		const arr: NotificationData[] = []
		map.forEach((val, _) => arr.push(val))
		return arr
	}

	private arrayToMap(arr: NotificationData[]): Map<number, NotificationData> {
		const myMap: Map<number, NotificationData> = new Map()
		arr?.forEach((not) => myMap.set(not?.$id, not))
		return myMap
	}

	public patchNotificationReads(
		userId: number | null,
		notifications: NotificationData[],
		value = true,
	): Observable<boolean> {
		return this.api.patchNotificationReads(userId, notifications, value).pipe(
			map(
				(res) => {
					// all good. We dont receive any result though, just OK message from api.
					notifications.forEach(
						(not) => (this.currentNots.get(not.$id).$read = value),
					)
					this.subject.next(this.mapToArray(this.currentNots))
					return true
				},
				(err) => {
					return false
				},
			),
		)
	}
}
