export enum TrackingScreen {
	Login = 'Login',
	WelcomeSignupLogin = 'WelcomeSignupLogin',
	Home = 'Home',
	Bills = 'Bills',
	AccountBills = 'AccountBills',
	SignUpLinkOut = 'SignUpLinkOut',
	AccountMenu = 'AccountMenu',
	AccountLanguage = 'AccountLanguage',
	LoginSetup = 'LoginSetup',
	AccountHomeProfile = 'AccountHomeProfile',
	HomeProfileDialog = 'HomeProfileDialog',
	Notifications = 'Notifications',
	AccountNotificationsSettings = 'AccountNotificationsSettings',
	AccountContact = 'AccountContact',
	BillSetting = 'BillSetting',
	AccountHomeAddress = 'AccountHomeAddress',
	AccountPersonalDetails = 'AccountPersonalDetails',
	AccountCustomerInformation = 'AccountCustomerInformation',
	DebugSettings = 'DebugSettings',
	SplashScreen = 'SplashScreen',
	AccountTariff = 'AccountTariff',
	InsightsDay = 'InsightsDay',
	InsightsWeek = 'InsightsWeek',
	InsightsMonth = 'InsightsMonth',
	InsightsYear = 'InsightsYear',
	InsightsMinute = 'InsightsMinute',
	Consent = 'Consent',
	ConsentLearnMore = 'ConsentLearnMore',
	AccountConsents = 'AccountConsents',
	Budget = 'Budget',
	CreateBudget = 'CreateBudget',
	EditBudget = 'EditBudget',
	BudgetWeek = 'BudgetWeek',
	BudgetMonth = 'BudgetMonth',
	AccountPV = 'AccountPV',
	AccountEmergencyPhone = 'AccountEmergencyPhone',
	AccountBillingDetail = 'AccountBillingDetail',
	AccountMarketingPreferences = 'AccountMarketingPreferences',
	Chat = 'Chat',
	ClaimAndRequest = 'ClaimAndRequest',
	ReportOutage = 'ReportOutage',
	PV = 'PV',
	HomeDetails = 'HomeDetails',
	WebMonitors = 'WebMonitors',
	WebTips = 'WebTips',
	WebTickets = 'WebTickets',
	WebViewAgreements = 'WebViewAgreements',
}
