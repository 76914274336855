import { Component, Input, OnInit } from '@angular/core'
import { APIAccountBalance } from '../../models/api-models/api-account-balance.model'
import { EliqDatePipe } from '@eliq/core'
import { DateTranslatorService } from '@eliq/core'
import { EliqNumberPipe } from '@eliq/core/pipes/eliq-number.pipe'
import { TranslateModule } from '@ngx-translate/core'
import { NgIf, LowerCasePipe } from '@angular/common'

@Component({
	selector: 'eliq-account-balance',
	templateUrl: './account-balance.component.html',
	styleUrls: ['./account-balance.component.scss'],
	standalone: true,
	imports: [NgIf, LowerCasePipe, TranslateModule, EliqNumberPipe],
})
export class AccountBalanceComponent implements OnInit {
	@Input() accountBalance: APIAccountBalance

	public updatedDate: Date
	public updatedDateString = ''
	constructor(
		private dateTranslator: DateTranslatorService,
		private datePipe: EliqDatePipe,
	) {}

	ngOnInit(): void {
		if (this.accountBalance.updated_date) {
			this.updatedDate = new Date(this.accountBalance.updated_date)
			this.updatedDateString = this.dateTranslator.getDateTimeString(
				this.updatedDate,
				'short',
				true,
			)
		}
	}
}
