import { Component, OnInit, Input, inject } from '@angular/core'
import { NotificationData } from '../../models/NotificationData.model'
import { NotificationDataSourceReport } from '../../models/NotificationDataSourceReport.model'
import { DateTranslatorService, Period } from '@eliq/core'
import { PeriodType } from '@eliq/core'
import { DateHelper } from '@eliq/util'

import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { EliqNumberPipe } from '@eliq/core/pipes/eliq-number.pipe'
import { LinkComponent } from '@eliq/ui'
import { RouterLink } from '@angular/router'
import { DOCUMENT, NgIf } from '@angular/common'
import { EliqThemeService } from '@eliq/theme'

@Component({
	selector: 'eliq-notification-item-report',
	templateUrl: './notification-item-report.component.html',
	styleUrls: ['./notification-item-report.component.css'],
	standalone: true,
	imports: [TranslateModule, NgIf, RouterLink, LinkComponent, EliqNumberPipe],
})
export class NotificationItemReportComponent implements OnInit {
	@Input() chartId: string
	@Input() notification: NotificationData

	public document = inject(DOCUMENT)
	public source: NotificationDataSourceReport
	public totalCosts = 0
	public totalConsumption = 0
	public loaded = false
	public routerParams: any

	public showChart = false

	private consumptionArray: number[]

	public dateString = ''
	public dateStringCapitalized = ''
	public linkString = ''
	private myChartOptions: any
	private myChart: any

	private colors: any

	private dateCalc: DateHelper

	constructor(
		private dateTranslator: DateTranslatorService,
		private translator: TranslateService,
		private themeService: EliqThemeService
	) {
		this.dateCalc = DateHelper.getInstance()
	}

	ngOnInit() {
		const colorEl1 = this.document.querySelector('.notificationReportColor1')
		const colorEl2 = this.document.querySelector('.notificationReportColor2')

		this.colors = {
			color1: colorEl1 ? getComputedStyle(colorEl1).color : '#FF0000',
			color2: colorEl2 ? getComputedStyle(colorEl2).color : '#0000FF',
		}

		this.source = <NotificationDataSourceReport>this.notification.$source
		this.totalCosts = this.source.getCost()
		this.totalConsumption = this.source.getEnergy() / 1000
		this.routerParams = this.getRouterParams()

		this.dateString = this.dateTranslator.getTranslatedPeriodString(
			new Period(<PeriodType>this.source.$period, this.source.$date),
		)
		this.dateStringCapitalized = this.capitalize(this.dateString)
		this.linkString =
			this.translator.instant('notifications_view.view_insights') +
			' (' +
			this.dateString +
			')'
	}

	private capitalize(s: string): string {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}

	private getRouterParams() {
		const params = {
			period: this.source.$period,
			date: this.dateCalc.getApiCompliantSingleDate(this.source.$date),
		}
		return params
	}

	getSeries() {
		const myColor = {
			linearGradient: {
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 1,
			},
			stops: [
				[0, this.colors.color1],
				[1, this.colors.color2],
			],
		}

		const rounding = 7

		return [
			{
				data: this.consumptionArray,
				color: myColor,
				//borderRadiusTopLeft: rounding,
				//borderRadiusTopRight: rounding,
				enableMouseTracking: false,
				groupPadding: 0,
				pointPadding: 0.15,
			},
		]
	}

	private getCategories() {
		if (this.source.$period === 'month') {
			return this.consumptionArray.map((val, index) => {
				return index + 1
			})
		} else if (this.source.$period === 'week') {
			return this.dateTranslator.getWeekdays()
		}
		return []
	}

	private setChartOptions() {
		this.myChartOptions = {
			chart: {
				type: 'column',
				height: 125,
				style: {
					fontFamily: 'inherit',
				},
				backgroundColor: 'transparent',
				margin: [0, 0, 20, 0],
				spacing: [0, 0, 0, 0],
			},
			title: {
				text: undefined,
			},
			subtitle: {
				text: undefined,
			},
			credits: {
				enabled: false,
			},
			legend: {
				enabled: false,
			},
			xAxis: {
				lineWidth: 0,
				gridLineWidth: 0,
				tickWidth: 0,
				tickInterval: 1,
				// these two values are dependent on the padding in the chart. they set the leftmost column to the left edge of the chart, and the same
				// with the rightmost column to the right edge of the chart.
				min: this.source.$period === 'month' ? 0.1 : 0.15,
				max:
					this.consumptionArray.length -
					(this.source.$period === 'month' ? 1.1 : 1.15),
				categories: this.getCategories(),
				labels: {
					step: this.source.$period === 'month' ? 5 : 1,
					y: 15,
					style: { 'font-family': this.themeService.getProp('font')},
					overflow: 'justify',
				},
			},
			yAxis: {
				gridLineWidth: 0,
				title: {
					text: undefined,
				},
				labels: {
					enabled: false,
				},
				max: Math.max(...this.consumptionArray),
				min: 0,
				endOnTick: false,
			},
			series: this.getSeries(),
		}
	}

	private setChart() {
		//this.myChart = Highcharts.chart(this.chartId, this.myChartOptions);
	}
}
