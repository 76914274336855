import { Injectable } from '@angular/core'
import { LocationHttpService, ResolutionType } from '@eliq/core'
import { forkJoin, Observable, of } from 'rxjs'
import { catchError, map, switchMap, tap } from 'rxjs/operators'
import { CommonConfigService } from '@eliq/core/config'
import { Fuel, FuelType } from '@eliq/core'
import { APIConsumption } from '@eliq/core/models/src/api-models/api-consumption.model'

@Injectable({
	providedIn: 'root',
})
export class UsagechartApiService {
	constructor(
		private commonConfig: CommonConfigService,
		private locHttp: LocationHttpService,
	) {}

	getUsageData(
		locId: number,
		unit: string,
		resolutionType: ResolutionType,
		fuelType: string,
		dataStreams: string[],
		from: Date,
		to: Date,
	): Observable<Map<string, number[]>> {
		const request = forkJoin(
			dataStreams.map((dataStream) =>
				this.locHttp
					.getLocationData(
						locId,
						dataStream,
						fuelType,
						unit,
						resolutionType,
						from,
						to,
					)
					.pipe(
						map((response) => ({ key: dataStream, data: response.data })),
						catchError(() => of({ key: dataStream, data: undefined })),
					),
			),
		)

		return request.pipe(
			map((response) => {
				const myMap = new Map<string, number[]>()
				this.commonConfig.dailyGraphShiftRight().subscribe((shiftRight) => {
					response.forEach((dataStream) => {
						if (dataStream.data) {
							if (resolutionType === ResolutionType.Hour) {
								for (
									let i = 0;
									shiftRight !== false && i < Number(shiftRight);
									i++
								) {
									dataStream.data.unshift(dataStream?.data?.pop() ?? 0)
								}
							}

							myMap.set(dataStream.key, dataStream.data)
						}
					})
				})

				return myMap
			}),
		)
	}

	getForecastData(
		locId: number,
		unit: string,
		resolutionType: ResolutionType,
		fuelType: string,
		dataStreams: string[],
		from: Date,
		to: Date,
	): Observable<Map<string, number[]>> {
		// note that for now the dataStreams array will ONLY EVER BE EXACTLY THIS: ["consumption"].
		const request = forkJoin(
			dataStreams.map((dataStream) =>
				this.locHttp
					.getLocationForecast(locId, fuelType, unit, resolutionType, from, to)
					.pipe(
						map((response: { forecast: number[] }) => {
							return {
								key: dataStream,
								data: response.forecast.map(
									(f) => f, // TODO I think this map can be removed (and instead just `data: response.forecast`) but I haven't tested yet and rxjs is sometimes weird.
								),
							}
						}),
						catchError(() => of({ key: dataStream, data: undefined })),
					),
			),
		)

		return request.pipe(
			map((response) => {
				const myMap = new Map<string, number[]>()
				response.forEach((dataStream) => {
					// as long as data is not undefined, insert into map.
					if (dataStream.data) {
						myMap.set(dataStream.key, dataStream.data)
					}
				})
				return myMap
			}),
		)
	}
}
