import { NotificationDataSource } from './NotificationDataSources.model'

export class NotificationDataSourceReport extends NotificationDataSource {
	private date: Date
	private fuel: string
	private period: string
	private cost: number
	private energy: number

	constructor(source: any) {
		super(source)
		this.date = new Date(source.date) // source.date is string
		this.fuel = source.fuel
		this.period = source.type // source.type is like month, week
		this.cost = source.cost
		this.energy = source.energy
	}

	public getCost() {
		return this.cost
	}

	public getEnergy() {
		return this.energy
	}

	/**
	 * Getter $date
	 * @return {Date}
	 */
	public get $date(): Date {
		return this.date
	}

	/**
	 * Getter $fuel
	 * @return {string}
	 */
	public get $fuel(): string {
		return this.fuel
	}

	/**
	 * Getter $period
	 * @return {string}
	 */
	public get $period(): string {
		return this.period
	}

	/**
	 * Setter $date
	 * @param {Date} value
	 */
	public set $date(value: Date) {
		this.date = value
	}

	/**
	 * Setter $fuel
	 * @param {string} value
	 */
	public set $fuel(value: string) {
		this.fuel = value
	}

	/**
	 * Setter $period
	 * @param {string} value
	 */
	public set $period(value: string) {
		this.period = value
	}
}
