<eliq-signin-card-two
	[header]="currentHeader"
	[showBackButton]="showBackButton"
	(backClicked)="backClicked()"
>
	<ng-container *ngIf="state === 'started' || state === 'initiating'">
		<div class="signin-area">
			<form [formGroup]="form" (ngSubmit)="onSubmit()">
				<label for="email" class="form-input-label textlabel">
					{{
          'sign_in_start.email_address' | translate
					}}
				</label>
				<mat-form-field
					style="margin-bottom: 0"
					floatLabel="auto"
					appearance="outline"
					class="input-field"
				>
					<input
						matInput
						type="text"
						id="email"
						name="email"
						class="email-input input-text primary-p3"
						formControlName="email"
						[errorStateMatcher]="signinErrorStateMatcher"
					/>
					<mat-error
						*ngIf="
              (form.get('email')?.hasError('email') ||
                form.get('email')?.hasError('required')) &&
              showErrors
            "
						translate
					>
						sign_in_start.provide_correct_email
					</mat-error>
				</mat-form-field>
				<div class="form-submit-button">
					<eliq-basic-button
						[disabled]="form.get('email')?.hasError('email') || state === 'initiating'"
						[submit]="true"
						(clicked)="onSubmit()"
						[loading]=" state === 'initiating'"
						[label]="'sign_in_start.sign_in'"
						[useEntireWidth]="true"
					></eliq-basic-button>
				</div>

				<div *ngIf="!!signUpUrl" class="form-submit-button sign-up-button">
					<div
						style="margin-top: 50px; margin-bottom: -50px;display: flex; justify-content: center;"
					>
						<eliq-link
							[disabled]="state === 'initiating'"
							[label]="'sign_in_start.sign_up' | translate"
							(clicked)="openSignUpPage()"
						></eliq-link>
					</div>
				</div>
			</form>
		</div>
	</ng-container>

	<ng-container *ngIf="state === 'initiated'">
		<eliq-signin-magiclink-verification
			[userEmail]="emailControl.value"
			(havingTroubleClicked)="setState('trouble')"
		></eliq-signin-magiclink-verification>
	</ng-container>

	<ng-container *ngIf="state === 'trouble'">
		<eliq-signin-magiclink-having-trouble
			(sendNewEmail)="sendNewEmail()"
			[userEmail]="emailControl.value"
		></eliq-signin-magiclink-having-trouble>
	</ng-container>

	<ng-content></ng-content>
</eliq-signin-card-two>
