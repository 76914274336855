import { PeriodType } from '@eliq/core'

export interface DozenalYear {
	dates: Date[]
	periodType: PeriodType
}

export class SelectableYear {
	public nextYear = this.year + 1

	constructor(public year: number) {}
}
