<div class="container">
	<div class="x" (click)="closeClicked()">
		<eliq-x-button></eliq-x-button>
	</div>
	<div class="text-container">
		<h6 class="primary-h6" style="margin: 0;">
			{{((data.activeDataStream ? 'home_pv_card.trend_dialog_title_import_export' : 'home_pv_card.trend_dialog_title') | translate)}}
		</h6>
		<p
			class="secondary-p2"
			[eliqTranslatePreWrap]="(data.activeDataStream ? 'home_pv_card.trend_dialog_import_export' : 'home_pv_card.trend_dialog')"
		></p>
	</div>
</div>
