import {
	endOfMonth,
	addMonths,
	startOfMonth,
	subDays,
	subMonths,
	subYears,
	startOfWeek,
	endOfWeek,
	formatISO,
	startOfDay,
	startOfYear,
	endOfDay,
	endOfYear,
	addDays,
	addWeeks,
	addYears,
	subWeeks,
	subHours,
	addHours,
	endOfMinute,
	subMinutes,
} from 'date-fns'
import { PeriodType } from '@eliq/models'
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class DateHelper {
	private static instance: DateHelper

	/**
	 *   converts the time to GMT+0
	 */
	public removeTimezone(date: Date): Date {
		return new Date(
			(date as Date).getTime() - (date as Date).getTimezoneOffset() * 60000,
		) // this line removes timezone offset
	}

	public makeApiDate(date: unknown): Date {
		if (typeof (date as any)['toISOString'] === 'function') {
			return date as Date
		} else {
			return new Date()
		}
	}

	public static getInstance(): DateHelper {
		if (DateHelper.instance === undefined) {
			DateHelper.instance = new DateHelper()
		}

		return DateHelper.instance
	}

	/**
	 *
	 * @param monthNr 0-based month number!
	 * @returns date for last day of specified month
	 */
	public getLastDayOfMonth(date: Date) {
		return endOfMonth(date)
	}

	public getStartOfMonth(date: Date) {
		return startOfMonth(date)
	}

	public getFirstDayOfNextMonth(date: Date) {
		return startOfMonth(addMonths(date, 1))
	}

	public getDayBeforeDate(date: Date) {
		return subDays(date, 1)
	}

	public getSameDatePreviousMonth(date: Date) {
		return subMonths(date, 1)
	}

	public getLastDatePreviousMonth(date: Date) {
		return endOfMonth(subMonths(date, 1))
	}

	public getDateXPreviousMonth(date: Date, number: number) {
		return subMonths(date, number)
	}

	public getSameDatePreviousYear(date: Date) {
		return subYears(date, 1)
	}

	public getResStringFromPerString(periodString: string) {
		if (periodString === 'day') return 'hour'
		else if (periodString === 'week' || periodString === 'month') return 'day'
		else if (periodString === 'year') return 'month'
		return 'month'
	}

	public getPeriodTypeFromString(string: string): PeriodType {
		let toReturn: PeriodType = PeriodType.Year
		Object.keys(PeriodType).forEach((key) => {
			if (PeriodType[key] === string) {
				toReturn = PeriodType[key]
			}
		})
		return toReturn
	}

	/**
	 * @deprecated Use getShortDateString from DateTranslatorService instead
	 * @param {Date} date
	 * @returns something like 07/06/12
	 */
	public getDashSeperatedDateString(date: Date) {
		let day = date.getDate().toString()
		if (day.length === 1) day = '0' + day
		let month = (date.getMonth() + 1).toString()
		if (month.length === 1) month = '0' + month
		return date.getFullYear() + '-' + month + '-' + day
	}

	public daysInMonth(month: number, year: number) {
		return new Date(year, month, 0).getDate()
	}

	public getApiCompliantSingleDate(date: Date) {
		return formatISO(date)
	}

	public startOfPeriod(period: string, date: Date): Date {
		switch (period) {
			case 'day':
				return startOfDay(date)
			case 'week':
				return startOfWeek(date, { weekStartsOn: 1 })
			case 'month':
				return startOfMonth(date)
			case 'year':
				return startOfYear(date)
		}
		return date
	}

	public endOfPeriod(period: string, date: Date): Date {
		switch (period) {
			case 'minute':
				return endOfMinute(date)
			case 'day':
				return endOfDay(date)
			case 'week':
				return endOfWeek(date, { weekStartsOn: 1 })
			case 'month':
				return endOfMonth(date)
			case 'year':
				return endOfYear(date)
		}
		return date
	}

	public addOfPeriod(period: string, amount: number, date: Date): Date {
		switch (period) {
			case 'hour':
				return addHours(date, amount)
			case 'day':
				return addDays(date, amount)
			case 'week':
				return addWeeks(date, amount)
			case 'month':
				return addMonths(date, amount)
			case 'year':
				return addYears(date, amount)
			case 'thirteenmonth':
				return addMonths(date, 13 * amount)
		}
		return date
	}

	public subOfPeriod(period: string, amount: number, date: Date): Date {
		switch (period) {
			case 'hour':
				return subHours(date, amount)
			case 'day':
				return subDays(date, amount)
			case 'week':
				return subWeeks(date, amount)
			case 'month':
				return subMonths(date, amount)
			case 'year':
				return subYears(date, amount)
			case 'thirteenmonth':
				return subMonths(date, 13 * amount)
			case 'minute':
				return subMinutes(date, amount)
		}
		return date
	}

	public getApiCompliantFirstOrLastDateOfPeriod(
		inPeriodDate: Date,
		period: string,
		first: boolean,
	) {
		const debug = false
		if (debug) {
		}

		let toReturn

		// period either 'day', 'week', 'month', 'year'
		// day here means get hours in given day
		// week is get days in given week
		// month is get days in given month
		// year is get months in given year (trivial? single source of truth is good tho...)

		// first make sure that we are provided one of the allowed stuffs
		let isPeriodThirteenMonth = false
		if (
			['day', 'week', 'month', 'year', 'thirteenmonth'].indexOf(period) >= 0
		) {
			if (period === 'thirteenmonth') {
				period = 'year'
				isPeriodThirteenMonth = true
			}
			// if we had to add an iso here above we need to remove it when adding time. good thing none of day, week, month, year have an i in them, makes it easy.
			if (first) {
				if (isPeriodThirteenMonth) {
					toReturn = formatISO(startOfMonth(inPeriodDate))
				} else {
					toReturn = formatISO(this.startOfPeriod(period, inPeriodDate))
				}
			} else {
				if (isPeriodThirteenMonth) {
					toReturn = formatISO(startOfMonth(addMonths(inPeriodDate, 13)))
				} else {
					toReturn = formatISO(
						this.startOfPeriod(
							period,
							this.addOfPeriod(period, 1, inPeriodDate),
						),
					)
				}
			}
		} else {
			toReturn = undefined
		}

		if (debug) {
		}

		return toReturn
	}

	public getAllDatesBetween(
		start: Date,
		end: Date,
		resolution: string,
	): Date[] {
		// resolution is either hour, day
		// hour meaning get all hours between the two given dates
		// day meaning get all days between the two given dates

		if (['hour', 'day', 'month'].indexOf(resolution) >= 0) {
			const toReturn: Date[] = []

			while (start < end) {
				toReturn.push(start)
				start = this.addOfPeriod(resolution, 1, start)
			}
			return toReturn
		} else return []
	}
}
