// @ts-nocheck

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
	FormsModule,
	ReactiveFormsModule,
} from '@angular/forms'
import { MatDialogRef } from '@angular/material/dialog'
import { SnackbarService } from '@eliq/ui/snackbar'
import { InputHelperService } from '@eliq/core'
import { take } from 'rxjs/operators'
import { AnnualHttpService } from '../services/annual-http-service'
import { TranslateService } from '@ngx-translate/core'
import { SpinnerComponent } from '@eliq/ui'
import { BasicButtonComponent } from '@eliq/ui'
import { MatSliderModule } from '@angular/material/slider'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { XButtonComponent } from '@eliq/ui'
import { NgIf } from '@angular/common'

@Component({
	selector: 'eliq-annual-card-add-modal',
	templateUrl: './annual-card-add-modal.component.html',
	styleUrls: ['./annual-card-add-modal.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		XButtonComponent,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatSliderModule,
		BasicButtonComponent,
		SpinnerComponent,
	],
})
export class AnnualCardAddModalComponent implements OnInit {
	@Input() lastYear: number
	@Input() locId: number
	@Input() greyBorder: boolean
	@Output() energy = new EventEmitter<number>()
	public form: FormGroup
	public valueSuffix = ''
	public estimatedEnergy = 2950
	public upperBoundEnergy = 90000
	public updatedEnergy = ''
	// random id used to stop the browser from auto-suggesting
	public randomId = ''
	public saveLoading = false
	public loading = false

	public addTitleText = ''
	public addEstimationPrefixText = ''
	public addEstimationSuffixText = ''
	public addQuestionText = ''
	public saveButtonText = ''
	constructor(
		private dialogRef: MatDialogRef<AnnualCardAddModalComponent>,
		private fb: FormBuilder,
		private inputHelper: InputHelperService,
		private snackbar: SnackbarService,
		private http: AnnualHttpService,
		private translator: TranslateService,
	) {}

	ngOnInit(): void {
		this.randomId = this.inputHelper.makeid(10)

		this.getEstimatedAndUpperBound()

		this.form = this.fb.group({
			energy: new FormControl(undefined, [
				Validators.required,
				Validators.pattern('^[1-9]\\d*$'),
			]),
		})
		this.form.get('energy').setValue(this.estimatedEnergy)

		//all our languages uses this so no need to translate (see common component)
		this.valueSuffix = 'kWh'

		this.addTitleText = this.translator.instant(
			'annual_consumption.add_title',
			{ value: this.lastYear },
		)
		if (this.addTitleText === 'annual_consumption.add_title') {
			this.addTitleText = `Electricity consumption: ${this.lastYear}`
		}
		this.addEstimationPrefixText = this.translator.instant(
			'annual_consumption.add_estimation_prefix',
		)
		if (
			this.addEstimationPrefixText ===
			'annual_consumption.add_estimation_prefix'
		) {
			this.addEstimationPrefixText =
				'According to our estimations you should have a yearly energy consumption around '
		}
		this.addEstimationSuffixText = this.translator.instant(
			'annual_consumption.add_estimation_suffix',
		)
		if (
			this.addEstimationSuffixText ===
			'annual_consumption.add_estimation_suffix'
		) {
			this.addEstimationSuffixText =
				', please adjust this number if you know your annual consumption.'
		}
		this.addQuestionText = this.translator.instant(
			'annual_consumption.add_question',
			{ value: this.lastYear },
		)
		if (this.addQuestionText === 'annual_consumption.add_question') {
			this.addQuestionText = `How much energy in kWh did you consume in ${this.lastYear}?`
		}
		this.saveButtonText = this.translator.instant('common.button_save')
		if (this.saveButtonText === 'common.button_save') {
			this.saveButtonText = 'Save'
		}

		this.dialogRef
			.backdropClick()
			.pipe(take(1))
			.subscribe(() => this.closeClicked())
	}

	getEstimatedAndUpperBound() {
		this.loading = true
		this.http.getYearlyEstimateSuggestion(this.locId, 99).subscribe(
			(res: any) => {
				//unit is Wh but we display kWh
				this.estimatedEnergy = Number(
					(res.yearly_consumption.estimate / 1000).toPrecision(3),
				)
				this.upperBoundEnergy = Number(
					(res.yearly_consumption.upper_bound / 1000).toPrecision(3),
				)
				this.loading = false
			},
			(err: any) => {

				//use default values when any errors occurs for estimate and upper bound
				this.loading = false
			},
		)
	}

	saveClicked() {
		this.saveLoading = true
		this.loading = true
		this.http
			.addAnnualEnergy(this.locId, this.form.get('energy').value)
			.subscribe(
				(res: any) => {
					this.saveLoading = false
					this.loading = false
					this.energy.emit(this.form.get('energy').value)
					this.dialogRef.close()
				},
				(err: any) => {
					this.saveLoading = false
					this.loading = false
					this.snackbar.doErrorToast()
					this.energy.emit(-1)
					this.dialogRef.close()
				},
			)
	}

	closeClicked() {
		this.energy.emit(-1)
		this.dialogRef.close()
	}
}
