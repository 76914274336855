import { Component, Inject, OnChanges, OnInit } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { CookieService } from '../cookie.service'
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'
import { FormsModule } from '@angular/forms'
import {
	CookieConsentStatus,
	CookieConsentStatusGeneric,
	COOKIE_CONSENT_STATUS_KEYS,
	CookieConsentStatusKeyType,
} from '../cookie.service'
import { BasicButtonComponent } from '@eliq/ui'
@Component({
	selector: 'eliq-cookie-consent-modal-component',
	standalone: true,
	imports: [CommonModule, FormsModule, MatCheckboxModule, BasicButtonComponent],
	templateUrl: './cookie-consent-modal-component.component.html',
	styleUrls: ['./cookie-consent-modal-component.component.scss'],
})
export class CookieConsentModalComponent implements OnChanges {
	constructor(
		public dialogRef: MatDialogRef<CookieConsentModalComponent>,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			cookieService: CookieService
			cookieAcceptedEvent: (status: CookieConsentStatus) => void
		},
	) {}

	public optionalConsentKeys = COOKIE_CONSENT_STATUS_KEYS.filter(
		(x) => x !== 'necessary',
	)

	defaultConsentData = (defaultValue: Readonly<boolean>) => {
		const defaults = COOKIE_CONSENT_STATUS_KEYS.reduce(
			(acc: CookieConsentStatus, cur: CookieConsentStatusKeyType) => (
				(acc[cur] = defaultValue), acc
			),
			{} as CookieConsentStatus,
		)
		defaults.necessary = true
		return defaults
	}

	public cookieConsentFormData: CookieConsentStatus =
		this.defaultConsentData(false)

	mouseEnter() {
		this.cookieConsentFormData = this.defaultConsentData(true)
	}
	mouseLeave() {
		this.cookieConsentFormData = this.defaultConsentData(false)
	}
	ngOnChanges() {}

	accepted(value: 'all' | 'some' = 'some'): false {
		if (value === 'all') {
			this.cookieConsentFormData = this.defaultConsentData(true)
		}

		this.data.cookieAcceptedEvent(this.cookieConsentFormData)
		this.dialogRef.close()
		return false
	}
}
