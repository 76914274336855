import { APIMonitor, MonitorData } from '@eliq/core'
import { NotificationDataSource } from './NotificationDataSources.model'

export class NotificationDataSourceMonitor extends NotificationDataSource {
	private monitor: MonitorData

	constructor(source: any) {
		super(source)
		const monitorSource = <APIMonitor>source
		this.monitor = new MonitorData(monitorSource)
	}

	/**
	 * Getter $monitorData
	 * @return {MonitorData}
	 */
	public get $monitorData(): MonitorData {
		return this.monitor
	}

	/**
	 * Setter $monitorData
	 * @param {MonitorData} value
	 */
	public set $monitorData(value: MonitorData) {
		this.$monitorData = value
	}
}
