<button
	[class.btn-fullwidth]="useEntireWidth"
	[class.disabled]="disabled || loading"
	[ngClass]="variant"
	[ngStyle]="custom"
	[type]="type"
	(click)="clickedButton($event)"
>
	<div class="container">
		<div #labelcontainer class="button-content-container" *ngIf="!loading">
			<div *ngIf="label">
				<img *ngIf="iconSrc" [src]="iconSrc" height="20" alt="" />
				<ng-container *ngIf="allCaps; else lowerCaseBlock">
					<span>{{label | translate | uppercase}}</span>
				</ng-container>
				<ng-template #lowerCaseBlock>
					<span>{{label | translate}}</span>
				</ng-template>
			</div>
			<div *ngIf="!label">
				<ng-content></ng-content>
			</div>
		</div>
		<div
			*ngIf="loading"
			class="loading-container"
			[style.width.px]="width"
			[style.height.px]="height"
		>
			<eliq-spinner size="default" [contrast]="true"></eliq-spinner>
		</div>
	</div>
</button>
