import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
} from '@angular/core'
import {
	ControlContainer,
	FormBuilder,
	FormControl,
	FormGroup,
	FormGroupDirective,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms'
import { SmrApiService } from '@eliq/feature/smr-card/services/smr-api.service'
import { Register } from '@eliq/feature/smr-card/models/Register.model'
import { SmrMeter } from '@eliq/feature/smr-card/models/SmrMeter.model'
import { KeyValuePipe, NgForOf, NgIf } from '@angular/common'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import {
	BasicButtonComponent,
	FlexibleButtonComponent,
	SpinnerComponent,
} from '@eliq/ui'
import { ImgSrcPipe } from '@eliq/core'
import { SmrSelectMeterDropdownComponent } from '../../../../smr-select-meter-dropdown/smr-select-meter-dropdown.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { SubmitReadInputComponent } from '../submit-modal-input/submit-modal-input.component'
import { SupplyDatesDropdownComponent } from '../supply-dates-dropdown/supply-dates-dropdown.component'

@Component({
	selector: 'eliq-smr-submit-modal',
	templateUrl: './submit-modal.component.html',
	styleUrls: ['./submit-modal.component.scss'],
	viewProviders: [
		{ provide: ControlContainer, useExisting: FormGroupDirective },
	],
	standalone: true,
	imports: [
		NgIf,
		NgForOf,
		TranslateModule,
		FlexibleButtonComponent,
		ImgSrcPipe,
		BasicButtonComponent,
		FormsModule,
		SmrSelectMeterDropdownComponent,
		SubmitReadInputComponent,
		ReactiveFormsModule,
		SpinnerComponent,
		SupplyDatesDropdownComponent,
		KeyValuePipe,
	],
})
export class SmrSubmitModalComponent implements OnInit, OnChanges {
	@Input() meter: SmrMeter
	@Input() meters: SmrMeter[]

	@Output() titleChanged = new EventEmitter<string>()

	public parentForm: FormGroup & {
		controls: {
			meterId: FormControl
			supplyDate: FormControl
			registersForm: FormGroup
		}
	}
	public newRegisters: Register[]
	public registers: Register[]
	public isLoaded = false
	public formIsValid = false

	//public meters$ = this.api.getMeters()
	public selectedMeterId: string
	public toggleForm: boolean
	//public meterOptions = [] as string[]
	public meterIds = [] as string[]
	public meterLabels = [] as string[]

	showConfirmView = false
	showSubmitView = true
	showSuccessView = false

	constructor(
		private ref: ChangeDetectorRef,
		private translate: TranslateService,
		private api: SmrApiService,
		private fb: FormBuilder,
	) {}

	ngOnInit(): void {
		this.getRegisters()
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['meters'] && changes['meters']['currentValue']) {
			//
			this.meterIds = this.meters.map((meter) => {
				return meter?.id || (meter as unknown as string)
			})
			// make selected meter the first meter in the meterIds
			this.meterIds = [
				this.meter.id,
				...this.meterIds.filter((id) => id !== this.meter.id),
			]

			this.meterLabels = this.meterIds.map((id) => {
				const type = this.meters.find((meter) => meter.id === id)?.type || id
				return this.translate.instant('common.' + type)
			})
		}
		// this.meterOptions = this.meters.map((meter) => {
		// 	return meter?.id || (meter as unknown as string)
		// })

		// if this.selectedMeterId changes
		// if (changes['selectedMeterId'] && changes['selectedMeterId']['currentValue']) {
		// 	const meterId = changes['selectedMeterId']['currentValue']
		// 	this.meter =
		// 		this.meters.find((meter) => meter.id === meterId) || this.meter
		// 	//this.getRegisters()
		// }

		// if (changes['meters']) {
		// 	this.meterIds = this.meters.map((meter) => {
		// 		return meter?.id || (meter as unknown as string)
		// 	})
		//
		// }
	}

	// supplyDateSelected(supplyDate: Date) {
	//
	// 	this.parentForm?.controls['supplyDate']?.setValue(supplyDate)
	// }

	createForm() {
		const registersFormControls = {}
		for (const reg of this.registers) {
			registersFormControls[reg.id] = [
				'',
				[Validators.required, Validators.minLength(reg.digits)],
			]
		}
		const registersForm = this.fb.group(registersFormControls)

		this.parentForm = this.fb.group({
			meterId: ['', [Validators.required]],
			supplyDate: [null, [Validators.required]],
			registersForm: registersForm,
		})

		this.parentForm.valueChanges.subscribe((value) => {
			this.formIsValid = this.parentForm.valid

			//
			//
			//
			//
			//
			//
		})

		this.isLoaded = true
	}

	findRegisterById(id: string): Register | undefined {
		return this.registers.find((reg) => reg.id === id)
	}
	getRegisters() {
		this.api.getDetailsOfMeter(this.meter.id).subscribe({
			next: (res) => {
				this.registers = res.registers
				this.createForm()
			},
			error: (err) => console.error('ERROR HERE (71)', err),
		})
	}

	// getSelectedRegisters(id: string): any {
	// 	let newReg = []
	// 	this.api.getDetailsOfMeter(id).subscribe({
	// 		next: (res) => {
	// 			newReg = res.registers
	// 			this.createForm()
	// 		},
	// 		error: (err) => console.error('ERROR HERE (82)', err),
	// 	})
	// 	return newReg
	// }

	submitRead() {
		//this.parentForm.controls['meterId'].setValue('23')

		if (!this.isValid()) {
			return
		}

		const id = this.meter.id //this.parentForm?.get('meterId')?.value
		const date =
			'' +
			(this.parentForm?.get('supplyDate')?.value as Date)
				.toISOString()
				.split('.')[0]
		const value = this.parentForm?.get('registersForm')?.value

		const registersToSubmit: { id: string; type: string; value: string }[] = []
		for (const register of this.registers) {
			registersToSubmit.push({
				id: register['id'],
				type: register['type'],
				value: value[register['id']],
			})
		}

		this.api.submitMeterRead(id, date, registersToSubmit).subscribe({
			next: (response: any) => {},
			error: (error: any) => {},
		})
	}

	// getMeterTypes() {
	// 	let meters: SmrMeter[] = []
	// 	const keys: string[] = []
	// 	const values: string[] = []
	// 	this.api.getMeters().subscribe((res: SmrMeter[]) => {
	// 		meters = res
	// 	})

	// 	for (let i = 0; i < meters.length; i++) {
	// 		keys[i] = meters[i].id
	// 		values[i] = meters[i].type
	// 	}

	// 	for (let i = 0; i < keys.length; i++) {
	// 		const item = {}
	// 		item[keys[i]] = values[i]
	// 		this.meterTypes.push(item)
	// 	}
	// }

	meterIdSelected(meterId: string) {
		this.selectedMeterId = meterId
		this.toggleForm = true
		// if (this.selectedMeterId) {
		// 	this.toggleForm = true
		// } else {
		// 	this.toggleForm = false
		// }
		this.parentForm?.controls['meterId']?.setValue(meterId)
		this.meter = this.meters.find((meter) => meter.id === meterId) || this.meter
		this.getRegisters()
	}

	public updateButtonClicked(isClicked: boolean) {
		this.titleChanged.emit('submit_meter_read.title')
		if (isClicked == true) {
			this.showSubmitView = true
			this.showConfirmView = false
			this.showSuccessView = false
		}
	}

	public isValid(): boolean {
		if (this.parentForm.valid) {
			return true
		}

		if (
			this.parentForm.controls['meterId'].valid &&
			this.parentForm.controls['registersForm'].valid &&
			this.parentForm.controls['supplyDate'].valid
		) {
			return true
		} else {
			console.error(
				'submitRead registersForm.errors:',
				this.parentForm.controls['registersForm'].errors,
				'registersForm.valid:',
				this.parentForm.controls['registersForm'].valid,
				'parentForm.errors:',
				this.parentForm.errors,
				'this.parentForm.valid:',
				this.parentForm.valid,
				'meterId.valid:',
				this.parentForm.controls['meterId'].valid,
				'meterId.errors:',
				this.parentForm.controls['meterId'].errors,
			)
			for (const reg of this.registers) {
				console.error(
					'Registry: ',
					reg,
					'validation errors:',
					(this.parentForm.controls['registersForm'] as FormGroup)?.controls[
						reg.id
					]?.errors,
				)
			}

			return false
		}
	}
	public preSubmit(event: any) {
		//
		if (!this.isValid()) {
			return
		}

		this.titleChanged.emit('submit_meter_read.confirmation_dialog_title')
		this.showSubmitView = false
		this.showConfirmView = true
		this.showSuccessView = false
	}

	public confirmButtonClicked(isClicked: boolean) {
		this.titleChanged.emit('submit_meter_read.success_dialog_title')
		if (isClicked == true) {
			this.showSubmitView = false
			this.showConfirmView = false
			this.showSuccessView = true
		}
		this.submitRead()
	}
}
