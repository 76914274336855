import { Injectable } from '@angular/core'
import { APIConsent } from '@eliq/models'
import { forkJoin, Observable, of } from 'rxjs'
import { EliqApiHttpClient } from '@eliq/data-access'
import { JsonGetterService } from '@eliq/data-access'
import { map, take, switchMap } from 'rxjs/operators'
import { LanguageService } from '@eliq/core'

import { GiveConsentModalComponent } from './give-consent-modal/give-consent-modal.component'
import { ModalService } from '@eliq/ui/modal'

@Injectable({
	providedIn: 'root',
})
export class ConsentService {
	constructor(
		private http: EliqApiHttpClient,
		private config: JsonGetterService,
		private langService: LanguageService,
		private modalService: ModalService,
	) {}

	useNewConsents(): Observable<boolean> {
		return forkJoin({
			terms: this.config.getTermsAndConditionsLink(),
			pp: this.config.getPrivacyPolicyLink(),
		}).pipe(
			switchMap(({ terms, pp }) => {
				return of(!terms && !pp)
			}),
		)
	}

	public openGiveConsentModal(
		consent: APIConsent,
		modalOptions?: {
			disableClose?: boolean
			autoFocus?: boolean
			width?: string
		},
	) {
		const options = Object.assign(
			{ ...this.modalService.options, disableClose: true },
			modalOptions ?? {},
		)
		//if (width) {
		//  options.width = width;
		//} else if (smaller) {
		//  options.width = (this.defaultModalWidthNumber * 0.9).toString() + "px";
		//  options.maxWidth = (this.defaultModalMaxWidthNumber * 0.9).toString() + "vw";
		//}

		if (consent) {
			const dialogRef = this.modalService.openModal(
				GiveConsentModalComponent,
				options,
			)
			dialogRef.componentInstance.consent = consent

			// returns an observable which can be subscribed to in order to see the outcome of the user's actions
			return dialogRef.afterClosed()
		} else {
			console.error('openGiveConsentModal received undefined consent')
		}

		return of(null)
	}

	getConsents(): Observable<APIConsent[]> {
		return forkJoin({
			apiConsents: this.http
				.get<APIConsent[]>(`/v3/users/consents`)
				.pipe(take(1)),
			configConsents: this.getConfigConsents().pipe(take(1)),
		}).pipe(
			map((res) => {
				if (res.apiConsents) {
					// we have some response from the api, lets handle it
					return res.configConsents.map((configConsent) => {
						// if we find the consent in the API response, use it. Otherwise, use the config one.
						const consentFromApi = res.apiConsents.find(
							(apiConsent) => apiConsent.name == configConsent.name,
						)
						return consentFromApi ? consentFromApi : configConsent
					})
				}
				return new Array<APIConsent>()
			}),
		)
	}

	getInsightsConsents(consents: APIConsent[]) {
		return consents.filter((value) =>
			['insight_consent', 'insights_consent'].includes(value.type),
		)
	}

	getInsightsConsentsNotGiven(consents: APIConsent[]) {
		return this.getInsightsConsents(consents).filter(
			(val) => !val.given_consent,
		)
	}

	getLoginConsents(consents: APIConsent[]) {
		return consents.filter((value) => value.type === 'login_consent')
	}

	getLoginConsentsNotGiven(consents: APIConsent[]) {
		return this.getLoginConsents(consents).filter((val) => !val.given_consent)
	}

	putConsents(consents: APIConsent[]) {
		return this.http.put(`/v3/users/consents?include_versioning=true`, consents)
	}

	/**
	 * Gets the consents specified in the config.json file as APIConfig objects
	 */
	private getConfigConsents(): Observable<APIConsent[]> {
		const currLang = this.langService.getCurrentLanguage()
		return this.config.getConfig('consents').pipe(
			map((response: string[]) => {
				return response.map(
					(consent) =>
						({
							name: consent,
							given_consent: false,
							language_code: currLang,
						} as APIConsent),
				)
			}),
		)
	}
}
