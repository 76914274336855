import { hasField, whichIs, whichIsStringRecord } from '@eliq/util'
import { AuthService } from '@eliq/feature/auth'
import { Injectable, inject } from '@angular/core'
import { Observable, of, from } from 'rxjs'
import { switchMap, catchError, take, map } from 'rxjs/operators'
import { ValueOf } from '@eliq/core'
import { CoreDataStoreService } from '@eliq/core'
import { Router } from '@angular/router'
import typia from 'typia'
import { WindowRef } from '@eliq/core/WindowRef'
import { DOCUMENT } from '@angular/common'

// APP -> WEB
export const PostMessageMethodsInWeb = [
	'isLoggedIn',
	'logout',
	'query',
	'unknown', // unknown is sometimes used in AppCompleted if the method can not be deduced.
] as const

export type PostMessageMethodsInWeb = (typeof PostMessageMethodsInWeb)[number]

export interface PostMessageApiMessageToWeb {
	method: PostMessageMethodsInWeb
	value?: string | Record<string, string>
}

interface WebSetQueryParam extends PostMessageApiMessageToWeb {
	method: 'query'
	value: Record<string, string>
}

interface WebLogOut extends PostMessageApiMessageToWeb {
	method: 'logout'
}

interface WebIsLoggedIn extends PostMessageApiMessageToWeb {
	method: 'isLoggedIn'
}

// WEB -> APP

export const PostMessageErrorTypes = ['error', 'warning'] as const
export type PostMessageErrorTypes = (typeof PostMessageErrorTypes)[number]

export const PostMessageMethodsInApp = [
	'completed',
	'nativeBrowserURL',
] as const
export type PostMessageMethodsInApp = (typeof PostMessageMethodsInApp)[number]

interface AppCompletedValueBase {
	method: PostMessageMethodsInWeb
	success: boolean
	returnedValue?: unknown
}

export interface AppCompletedValueDetailedError extends AppCompletedValueBase {
	errorType: PostMessageErrorTypes
	code: string
	description?: string
}

export type AppCompletedValue =
	| AppCompletedValueBase
	| AppCompletedValueDetailedError
export interface PostMessageApiMessageToApp {
	method: PostMessageMethodsInApp
	value: AppCompletedValue | string
}

export interface AppCompleted {
	method: 'completed'
	value: AppCompletedValue
}

export interface AppNativeBrowserURL {
	method: 'nativeBrowserURL'
	value: string
}

export class PostMessageApi {
	constructor(
		protected authService: AuthService,
		protected coreDS: CoreDataStoreService,
		protected router: Router,
	) {}

	private window = inject(DOCUMENT).defaultView as Window

	addListener() {
		this.window?.addEventListener('message', this.handle.bind(this))
	}

	private appCompletedError(
		method: PostMessageMethodsInWeb,
		code: string,
		description: string,
		errorType: PostMessageErrorTypes = 'error',
	): AppCompleted {
		return {
			method: 'completed',
			value: {
				method: method,
				errorType: errorType,
				success: false,
				code: code,
				description: description,
			},
		}
	}
	handleMethod(data: PostMessageApiMessageToWeb): Observable<AppCompleted> {
		// logout
		if (hasField(data, 'method', whichIs('logout'))) {
			return this.logout().pipe(
				switchMap((v) =>
					of({
						method: 'completed',
						value: { method: 'logout', success: v },
					} as AppCompleted),
				),
			)
		}
		// isLoggedIn
		else if (hasField(data, 'method', whichIs('isLoggedIn'))) {
			return this.isLoggedIn().pipe(
				switchMap((v) =>
					of({
						method: 'completed',
						value: {
							method: 'isLoggedIn',
							success: true,
							returnedValue: v,
						},
					} as AppCompleted),
				),
			)
		}
		// query
		else if (hasField(data, 'method', whichIs('query'))) {
			if (hasField(data, 'value', whichIsStringRecord)) {
				return this.updateQueryParams(data.value as any)
			} else {
				return of(
					this.appCompletedError(
						'query',
						'400-1',
						'Error in field `value`: Query params must be of type Record<string, string>',
					),
				)
			}
		}
		// unknown method
		else {
			return of(
				this.appCompletedError(
					(data as any)['method'],
					'400-0',
					'Unknown method',
				),
			)
		}
	}

	handle(event: MessageEvent) {
		try {
			if (!event || !event?.data || typeof event?.data !== 'string') {
				return
			}

			const data = JSON.parse(event?.data) as PostMessageApiMessageToWeb

			//const validator =
			const webValidator = typia.validate<PostMessageApiMessageToWeb>(data)
			if (!webValidator.success) {
				const isMessageToApp = typia.is<PostMessageApiMessageToApp>(data)
				if (isMessageToApp) {
					console.warn(
						'Received postMessage that is only valid for the app:',
						JSON.stringify(data),
					)
				} else {
					console.warn('Received postMessage that was invalid', data)
					this.send(
						this.appCompletedError(
							'unknown',
							'400',
							'Invalid data format: ' +
								JSON.stringify(webValidator?.errors, null, 2),
						),
					)
				}

				return
			} else {
			}

			this.handleMethod(data).subscribe((v) => {
				if (typia.is<PostMessageApiMessageToApp>(v)) {
					this.send(v)
				} else {
					console.warn('Not a valid postMessage to App:', v)
				}
			})
		} catch (e) {
			console.warn('Error in postMessageApiService:', e)
			return
		}
	}

	send(event: PostMessageApiMessageToApp) {
		const validator = typia.validate<PostMessageApiMessageToApp>(event)
		if (validator.success) {
			window?.parent.postMessage(JSON.stringify(event), '*')
		} else {
			console.warn('Event is not a valid postMessageApiMessageToApp:', event)
		}
	}

	logout(): Observable<boolean> {
		this.authService.logout()
		// didLogout
		return this.isLoggedIn().pipe(switchMap((v) => (v ? of(false) : of(true))))
	}

	nativeBrowserURL(url: string) {
		this.send({ method: 'nativeBrowserURL', value: url })
	}

	updateQueryParams(params: Record<string, string>): Observable<AppCompleted> {
		const currentParams = this.router.parseUrl(this.router.url).queryParams
		const newParams = Object.assign({}, currentParams, params) as Record<
			string,
			string
		>

		return from(this.router.navigate([], { queryParams: newParams })).pipe(
			switchMap(() => {
				return of({
					method: 'completed',
					value: {
						method: 'query' as const,
						success: true,
						returnedValue: newParams,
					},
				} as AppCompleted)
			}),
			catchError((e) => {
				return of({
					method: 'completed',
					value: {
						method: 'query' as const,
						errorType: 'error' as const,
						success: false as const,
						code: '500-1',
						description: e.message + '\n\n' + JSON.stringify(e),
					},
				} as AppCompleted)
			}),
		)
	}

	/*
  switchLocation(location: number): Observable<boolean> {
    this.coreDS.setActiveLocation(location)
    localStorage.setItem('LOCATION_ID', location.toString())
    // didSwitchLocation
    return this.coreDS
      .getActiveLocation()
      .pipe(switchMap((v) => (v.id === location ? of(true) : of(false))))
  }
  */

	isLoggedIn(): Observable<boolean> {
		// Tries to refresh the access token, if this is successful then we are logged in.
		return this.authService.refreshAccessToken().pipe(
			switchMap((v) => (v ? of(true) : of(false))),
			catchError(() => of(false)),
		)
	}
}
