import { Injectable } from '@angular/core'
import { DatePipe } from '@angular/common'
import { forkJoin, Observable, of } from 'rxjs'
import { EliqApiHttpClient } from '../../../../../data-access/src/lib/services/http/eliq-api-http-client.service'
import {
	APILocation,
	IsValidFuel,
} from '../../models/src/api-models/api-location.model'
import { APIDevice } from '../../models/src/api-models/api-device.model'
import { APIConsumption } from '../../models/src/api-models/api-consumption.model'
import { APIForecast } from '../../models/src/api-models/api-forecast.model'
import { APITemperature } from '../../models/src/api-models/api-temperature.model'
import { APIResponseWrapper } from '../../models/src/api-models/api-response-wrapper.model'
import { APISHGroup } from '../../models/src/api-models/api-sh-group.model'
import { APIEuc } from '../../models/src/api-models/api-euc.model'
import {
	APIHomeProfile,
	APIYearlyConsumption,
	APIYearlyConsumptionToProperty,
} from '../../models/src/api-models/api-home-profile.model'
import { APICO2Emission } from '../../models/src/api-models/api-co2.model'
import { APIMonitor } from '../../models/src/api-models/api-monitor.model'
import { Property } from '../../models/src/homeprofile-models/Property.model'
import { HomeProfile } from '../../models/src/homeprofile-models/HomeProfile.model'
import { HttpResponse } from '@angular/common/http'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { PropertyGroup } from '../../models/src/homeprofile-models/PropertyGroup.model'
import { APIData } from '../../models/src/api-models/api-data.model'
import { APIMeter } from '../../models/src/api-models/api-meter.model'
import {
	DataStreamType,
	JsonGetterService,
	Resolution,
	ResolutionType,
} from '../../public_api'
import { FuelType } from '../../public_api'
import { PropertyNumber } from '../../public_api'
import { EnvironmentService } from '@eliq/data-access'
import { ActivatedRouteSnapshot } from '@angular/router'
import { APIFuelData } from '@eliq/core/models/src/api-models/api-data-raw.modal'

export class YearlyEstimateSuggestion {
	country_code: string
	yearly_consumption: {
		units: string
		estimate: number
		upper_bound: number
		lower_bound: number
		confidence_level: number
	}
}

@Injectable({
	providedIn: 'root',
})
export class LocationHttpService {
	constructor(
		private env: EnvironmentService,
		private datepipe: DatePipe,
		private http: EliqApiHttpClient,
		private config: JsonGetterService,
	) {}

	private formatStr = 'yyyy-MM-ddTHH:mm:ss'

	public getLocation(locationId: number): Observable<APILocation> {
		return this.http.get<APILocation>(`/v3/locations/${locationId}`).pipe(
			map((loc) => {
				const loc2 = loc
				loc2.fuels = {}
				const valid_fuels = Object.keys(loc.fuels).filter((fuelName) =>
					IsValidFuel(loc.fuels[fuelName]),
				)
				for (const valid_fuel of valid_fuels) {
					loc2.fuels[valid_fuel] = loc[valid_fuel]
				}
				return loc2
			}),
		)
	}

	public getYearlyEstimateSuggestion(
		locId: number,
		confidenceLevelPercentage: number,
	) {
		return this.http.get<YearlyEstimateSuggestion>(
			`/v3/locations/${locId}/yearly_estimates/suggestion?confidence_level_percentage=${confidenceLevelPercentage}`,
		)
	}

	public getLocationDevices(locationId: number): Observable<APIDevice[]> {
		return this.http.get<APIDevice[]>(`/v3/locations/${locationId}/devices`)
	}

	public getLocationMeters(locationId: number): Observable<APIMeter[]> {
		return this.http.get<APIMeter[]>(`/v3/locations/${locationId}/meters`)
	}

	public makeValidDate(date: unknown): Date {
		if (typeof (date as any)['toISOString'] === 'function') {
			date = new Date(
				(date as Date).getTime() - (date as Date).getTimezoneOffset() * 60000,
			) // this line removes timezone offset
			return date as Date
		} else {
			return new Date()
		}
	}

	makeValidFromDate(date: unknown): string {
		return this.makeValidDate(date).toISOString().split('.')[0]
	}
	makeValidToDate(date: unknown): string {
		return (this.makeValidDate(date) || new Date()).toISOString().split('.')[0]
	}

	public getLocationConsumption(
		locationId: number,
		fuel: string,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APIConsumption> {
		return this.getLocationFuelData<'consumption'>(
			locationId,
			fuel,
			'consumption',
			unit,
			resolution,
			fromDt,
			toDt,
		)
	}

	public getLocationFuelData<T extends Readonly<DataStreamType>>(
		locationId: number,
		fuel: string,
		dataStream: T = 'consumption' as T,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APIFuelData<T>> {
		const from = this.makeValidFromDate(fromDt)
		const to = this.makeValidToDate(toDt)

		return this.http.get<APIFuelData<T>>(
			`/v3/locations/${locationId}/${dataStream}?fuel=${fuel}&unit=${unit}&resolution=${resolution}&from=${from}&to=${to}`,
		)
	}

	public getLocationForecast(
		locationId: number,
		fuel: string,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APIForecast> {
		//if (
		//	resolution === ResolutionType.Day
		//	) {
		// TODO There's to much magic with the new Date() so should move this to somewhere else
		//fromDt = new Date(toDt.getFullYear(), fromDt.getMonth(), 1)
		//fromDt = new Date(fromDt.getTime() - fromDt.getTimezoneOffset()*60000);
		//toDt = new Date(toDt.getFullYear(), fromDt.getMonth()+1, 1)
		//toDt = new Date(toDt.getTime() - toDt.getTimezoneOffset()*60000);
		//}

		const from = this.makeValidFromDate(fromDt)
		const to = this.makeValidToDate(toDt)

		//ToDo [m3]
		//Remove if case below when m3 forecast has been built in backend
		//if (unit === 'm3') {
		//	unit = 'energy'
		//}
		return this.http
			.get<APIForecast>(
				`/v3/locations/${locationId}/forecast?fuel=${fuel}&unit=${unit}&resolution=${resolution}&from=${from}&to=${to}`,
			)
			.pipe(
				map((res) => {
					if (Object.keys(res).includes('forecast')) {
						const res2: typeof res & { forecast: number[] } = res
						res2.forecast = res.forecast.map((forecast) => {
							if (
								typeof forecast !== 'number' ||
								isNaN(forecast) ||
								forecast < 0
							) {
								return 0
							}
							return forecast
						})
						return res2
					}
					return res
				}),
				catchError(() => {
					const toReturn: APIForecast = {
						forecast: [],
						resolution: resolution,
						from: from,
						to: to,
						unit: unit,
						fuel: fuel,
					}
					return of(toReturn)
				}),
			)
	}

	public getLocationData(
		locationId: number,
		dataType: string,
		fuel: string,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APIData> {
		const from = this.makeValidFromDate(fromDt)
		const to = this.makeValidToDate(toDt)
		return this.http
			.get(
				`/v3/locations/${locationId}/${dataType}?fuel=${fuel}&unit=${unit}&resolution=${resolution}&from=${from}&to=${to}`,
			)
			.pipe(
				map((res: any) => {
					return {
						data: res[dataType],
						dataType: dataType,
						resolution: res.resolution,
						unit: res.unit,
						from: res.from,
						to: res.to,
						fuel: res.fuel,
					}
				}),
			)
	}

	public getLocationCO2(
		locationId: number,
		fromDt: Date,
		toDt: Date,
	): Observable<APICO2Emission | undefined> {
		const from = this.makeValidFromDate(fromDt)
		const to = this.makeValidToDate(toDt)
		return this.http
			.get<APIConsumption>(
				`/v3/locations/${locationId}/consumption?fuel=elec&unit=energy&resolution=month&from=${from}&to=${to}&include_co2=true`,
			)
			.pipe(map((apiConsumption) => apiConsumption.co2emission))
	}

	public getSimilarHomesGroup(
		locationId: number,
	): Observable<APIResponseWrapper<APISHGroup>> {
		return this.http.get<APIResponseWrapper<APISHGroup>>(
			`/v3/locations/${locationId}/similarhomes`,
		)
	}

	public getSimilarHomesConsumption(
		locationId: number,
		fuel: string,
		unit: string,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APIResponseWrapper<APIConsumption>> {
		resolution = 'month'

		const from = this.makeValidFromDate(fromDt)
		let to: string
		const now = new Date()
		if (toDt > now) {
			to = this.makeValidToDate(now)
		} else {
			to = this.makeValidToDate(toDt)
		}
		return this.http.get<APIResponseWrapper<APIConsumption>>(
			`/v3/locations/${locationId}/similarhomes/consumption?fuel=${fuel}&unit=${unit}&resolution=${resolution}&from=${from}&to=${to}`,
		)
	}

	public getLocationTemperature(
		locationId: number,
		resolution: string,
		fromDt: Date,
		toDt: Date,
	): Observable<APITemperature> {
		//if (
		//	resolution === ResolutionType.Day &&
		//	toDt.getTime() - fromDt.getTime() > 1000 * 60 * 60 * 24 * 31
		//) {
		// TODO There's to much magic with the new Date() so should move this to somewhere else
		//fromDt = new Date(toDt.getFullYear(), fromDt.getMonth(), 1)
		//fromDt = new Date(fromDt.getTime() - fromDt.getTimezoneOffset()*60000);
		//toDt = new Date(toDt.getFullYear(), fromDt.getMonth()+1, 1)
		//toDt = new Date(toDt.getTime() - toDt.getTimezoneOffset()*60000);
		//}

		const from = this.makeValidFromDate(fromDt)
		const to = this.makeValidToDate(toDt)
		return this.http.get<APITemperature>(
			`/v3/locations/${locationId}/temperature?resolution=${resolution}&from=${from}&to=${to}`,
		)
	}

	/**
	 * Always gets energy usage categories.
	 * @param locationId
	 * @param fuel
	 * @param unit
	 * @param fromDt
	 * @param toDt
	 * @returns
	 */
	public getLocationEuc(
		locationId: number,
		fuel: FuelType,
		unit: 'energy' | 'cost' | 'm3',
		fromDt: Date,
		toDt: Date,
	): Observable<APIEuc> {
		const from = this.makeValidFromDate(fromDt)
		const to = this.makeValidToDate(toDt)
		return this.http.get<APIEuc>(
			`/v3/locations/${locationId}/euc?fuel=${fuel}&unit=${unit}&from=${from}&to=${to}`,
		)
	}

	public getMonitors(locationId: number) {
		return this.http.get<APIMonitor[]>(
			'/v3/locations/' + locationId + '/monitors',
		)
	}

	public getLocationHomeProfile(
		locationId: number,
	): Observable<APIHomeProfile> {
		// get the option from config.json if we should filter based on is_required flag or not.
		return forkJoin({
			onlyIsRequiredProperties: this.config.getHomeProfileConfig().pipe(
				map((config) => (config ? config.only_is_required_properties : false)),
				take(1),
			),
			enableYearlyEstimates: this.config.getHomeProfileConfig().pipe(
				map((config) => (config ? config.enableYearlyEstimates : false)),
				take(1),
			),
			homeProfile: this.http.get<APIHomeProfile>(
				'/v3/locations/' + locationId + '/homeprofile',
			),
			yearlyEnergy: this.config.getHomeProfileConfig().pipe(
				switchMap((c) => {
					return c.enableYearlyEstimates || this.env.isKBC()
						? this.getLocationYearlyEnergy(locationId)
						: of([])
				}),
			),
		}).pipe(
			switchMap(
				({
					enableYearlyEstimates,
					onlyIsRequiredProperties,
					homeProfile,
					yearlyEnergy,
				}) => {
					if (!enableYearlyEstimates) {
						return of({ onlyIsRequiredProperties, homeProfile })
						// TODO: Make KBC specific code less all over the place...
					} else if (this.env.isKBC()) {
						return this.http.get<any[]>('/v3/clients/kbc/mandates/').pipe(
							map((mandates) => {
								if (!(mandates.length > 0)) {
									const lastYearCons = yearlyEnergy.find(
										(el) => el.year === new Date().getFullYear() - 1,
									)
									homeProfile?.properties?.push(
										APIYearlyConsumptionToProperty(lastYearCons),
									)
								}
								return { onlyIsRequiredProperties, homeProfile }
							}),
						)
					} else {
						// TODO add check if user is smart or non-smart here...
						// Preferably even split out this yearly-energy logic so it's nicer
						const lastYearCons = yearlyEnergy.find(
							(el) => el.year === new Date().getFullYear() - 1,
						)

						homeProfile?.properties?.push(
							APIYearlyConsumptionToProperty(lastYearCons),
						)
						return of({ onlyIsRequiredProperties, homeProfile })
					}
				},
			),
			map(({ onlyIsRequiredProperties, homeProfile }) => {
				if (onlyIsRequiredProperties) {
					return {
						properties: homeProfile.properties.filter(
							(prop) => prop.is_required,
						),
					}
				} else {
					return homeProfile
				}
			}),
		)
	}

	public getLocationYearlyEnergy(
		locationId: number,
	): Observable<APIYearlyConsumption[]> {
		// get the option from config.json if we should filter based on is_required flag or not.
		return this.http.get<APIYearlyConsumption[]>(
			'/v3/locations/' +
				locationId +
				'/yearly_estimates?fuel=elec&type=consumption',
		)
	}

	public patchYearlyEnergyValues(locId: any, kWh: any, year = -1) {
		return this.http
			.post('/v3/locations/' + locId + '/yearly_estimates', {
				fuel: 'elec',
				type: 'consumption',
				source: 'user',
				year: year == -1 ? new Date().getFullYear() - 1 : year,
				energy_wh: kWh * 1000,
			})
			.pipe(
				map((res) => {
					return res
				}),
			)
	}

	private patchYearlyEnergy(locId: any, pye: PropertyNumber) {
		return this.http
			.post('/v3/locations/' + locId + '/yearly_estimates', {
				fuel: 'elec',
				type: 'consumption',
				source: 'user',
				year: new Date().getFullYear() - 1,
				energy_wh: !isNaN(pye.$placeholder) ? pye.$placeholder * 1000 : 0,
			})
			.pipe(
				map((_res) => {
					return null
				}),
			)
	}

	public deletePreviousYearEnergy(locId: number): any {
		const year = new Date().getFullYear() - 1
		return this.http.delete<any>(
			`/v3/locations/${locId}/yearly_estimates?fuel=elec&direction=consumption&year=${year}`,
		)
	}

	public patchHomeProfile(locId: number, homeProfile: HomeProfile) {
		let allProps = homeProfile.$properties.map((prop) => {
			return { type: 'properties', item: prop }
		})
		homeProfile.$propertyGroups.forEach((propGroup) => {
			allProps = allProps.concat(
				propGroup.$properties.map((prop) => {
					return { type: propGroup.$type, item: prop }
				}),
			)
		})

		for (const prop of allProps) {
			if (prop.type === 'yearlyenergy') {
				this.patchYearlyEnergy(locId, prop.item as PropertyNumber).subscribe(
					(res) => {},
				)
			}
		}

		const changedProps = allProps.filter((prop) => {
			return (
				prop.type !== 'yearlyenergy' && prop.item.$placeholder !== undefined
			)
		})

		const changedPropsData = changedProps.map((prop) => {
			let numberValue: number | undefined = undefined

			const firstValue = prop.item.getCurrentSelection()
			let isNumber = false
			if (!isNaN(firstValue)) {
				// its a number
				isNumber = true

				numberValue = parseInt(firstValue)
			}

			return {
				op: 'replace',
				path: prop.type + '/' + prop.item.$key,
				value: numberValue ? numberValue : firstValue,
			}
		})

		return this.http.patch<APIHomeProfile>(
			'/v3/locations/' + locId + '/homeprofile',
			changedPropsData,
		)
	}

	public resetProperties(
		locId: number,
		properties: Property[],
		propertyGroups: PropertyGroup[],
	) {
		let allProps = properties.map((prop) => {
			return { type: 'properties', item: prop }
		})
		propertyGroups.forEach((propGroup) => {
			allProps = allProps.concat(
				propGroup.$properties.map((prop) => {
					return { type: propGroup.$type, item: prop }
				}),
			)
		})

		const changedProps = allProps.filter((prop) => {
			return (
				prop.item.$name !== 'yearlyenergy' && prop.item.getValue() !== undefined
			)
		})

		const data = changedProps.map((prop) => {
			return {
				op: 'replace',
				path: prop.type + '/' + prop.item.$key,
				value: undefined,
			}
		})

		return this.http
			.patch<APIHomeProfile>('/v3/locations/' + locId + '/homeprofile', data)
			.pipe(
				tap((res: any) => {
					this.getLocationYearlyEnergy(locId).subscribe((ycres) => {
						ycres.forEach((yc) => {
							if ((yc.energy_wh ?? 0) > 0) {
								this.patchYearlyEnergyValues(locId, 0, yc.year).subscribe(
									(setYcRes) => {},
								)
							}
						})
					})
				}),
			)
	}

	public patchProperties(
		locId: number,
		properties: Property[],
		propertyGroups?: PropertyGroup[],
	) {
		const propertiesClone = [...properties]

		const yearlyEnergyIndex = propertiesClone.findIndex(
			(el) => el.$name === 'yearlyenergy',
		)

		if (yearlyEnergyIndex >= 0) {
			this.patchYearlyEnergy(
				locId,
				properties[yearlyEnergyIndex] as PropertyNumber,
			).subscribe(
				(res) => {},
				(err) => {
					console.error('Could not patch yearly energy:', err)
				},
			)
			propertiesClone.splice(yearlyEnergyIndex, 1)
		}

		let changedProps = propertiesClone
			.filter((prop) => prop.$placeholder !== undefined)
			.map((prop) => {
				return { type: 'properties', item: prop }
			})
		if (propertyGroups) {
			// concat all the individual properties inside the property group structures to the changedProps list
			propertyGroups.forEach((propGroup) => {
				changedProps = changedProps.concat(
					propGroup.$properties
						.filter((prop) => prop.$placeholder !== undefined)
						.map((prop) => {
							return { type: propGroup.$type, item: prop }
						}),
				)
			})
		}
		const changedPropsData = this.mapPropertyToData(changedProps)

		return this.http
			.patch<APIHomeProfile>(
				'/v3/locations/' + locId + '/homeprofile',
				changedPropsData,
			)
			.pipe(
				take(1),
				switchMap((apiHp) => {
					// check if we are only accepting is_required. If we are, then only allow is_req properties
					return forkJoin({
						config: this.config.getHomeProfileConfig(),
						yearly_estimates: this.getLocationYearlyEnergy(locId),
					}).pipe(
						take(1),
						map((res) => {
							if (this.env.isKBC() || res.config.enableYearlyEstimates) {
								if (res?.yearly_estimates.length > 0) {
									const lastYearCons = res.yearly_estimates.find(
										(el) => el.year === new Date().getFullYear() - 1,
									)
									apiHp.properties.push(
										APIYearlyConsumptionToProperty(lastYearCons),
									)
								}
							}

							if (res.config.only_is_required_properties) {
								apiHp = {
									properties: apiHp.properties.filter(
										(property) => property.is_required,
									),
								}
							}
							return apiHp
						}),
					)
				}),
			)
	}

	public patchLocationName(locId: number, newName: string) {
		const data = [
			{
				op: 'replace',
				path: '/name',
				value: newName,
			},
		]

		return this.http.patch<APILocation>('/v3/locations/' + locId, data)
	}

	public createMonitor(
		locId: number,
		monType: string,
		unit1: string,
		fuel: string,
		period: string,
		limit: number,
	) {
		const data = {
			measuring_type: monType,
			unit: unit1,
			source_fuel: fuel,
			interval_period: period,
			limit_upper: limit,
		}
		return this.http.post<APIMonitor>(
			'/v3/locations/' + locId + '/monitors',
			data,
		)
	}

	public updateMonitor(locId: number, monId: number, newLimit: number) {
		const data = [
			{
				op: 'replace',
				path: '/limit_upper',
				value: newLimit,
			},
		]
		return this.http.patch<APIMonitor>(
			'/v3/locations/' + locId + '/monitors/' + monId,
			data,
		)
	}

	public deleteMonitor(
		locId: number,
		monId: number,
	): Observable<HttpResponse<object>> {
		return this.http.delete<HttpResponse<object>>(
			'/v3/locations/' + locId + '/monitors/' + monId,
		)
	}

	private mapPropertyToData(
		typedProperties: { type: string; item: Property }[],
	): { op: string; path: string; value: any }[] {
		return typedProperties.map((obj) => {
			let value: any = obj.item.getCurrentSelection()

			if (obj.item.$dataType === 'number') {
				value = !isNaN(parseInt(value + '')) ? parseInt(value + '') : null
			}

			return {
				op: 'replace',
				path: obj.type + '/' + obj.item.$key,
				//value: numberValue ? numberValue : firstValue,'
				value: value,
			}
		})
	}
}
