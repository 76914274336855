<div class="mini-account-balance-container">
  <h6 class="primary-h6" style="margin: 0;">{{'my_bills.account_balance' | translate}}</h6>
  <div class="values" *ngIf="accountBalance.amount">
    <span class="primary-h2 account-balance-value">{{accountBalance.amount | eliqNumber:'cost':'1.2-2'}}</span>
    <span class="secondary-p3 updated-text">{{'my_bills.updated_date' | translate: { date: updatedDate | eliqdate:'short' } | lowercase }}</span>
  </div>
  <div class="values" *ngIf="!accountBalance.amount">
    <p class="secondary-p2">{{'common.something_went_wrong' | translate}}</p>
  </div>
</div>
