<div class="container">
	<!--<p class="content secondary-p3">{{notification.$content}}</p>-->
	<p class="primary-p1" style="font-weight: bold; margin: 0;">
		{{dateStringCapitalized}}
	</p>
	<div class="notification-total-costs-container">
		<div class="total-container">
			<div class=" flex-member left">
				<p class="total-value primary-h5">{{totalCosts | eliqNumber:'cost'}}</p>
				<p class="total-label secondary-p3" translate>
					notifications_view.total_costs
				</p>
			</div>
			<div class="flex-member right">
				<p class="total-value total-value-consumption primary-h6">
					{{totalConsumption | eliqNumber:'energy'}}
				</p>
				<p class="total-label secondary-p3" translate>
					insights_comparison_month.total_consumption
				</p>
			</div>
		</div>
	</div>
	<div class="chart" *ngIf="showChart" id="{{chartId}}">
		<!--Chart goes here-->
	</div>
	<div>
		<a routerLink="/insights" [queryParams]="routerParams">
			<eliq-link [label]="linkString"></eliq-link>
		</a>
	</div>
</div>
<span class="notificationReportColor1"></span>
<span class="notificationReportColor2"></span>
