import { Component, Input, OnInit } from '@angular/core'
import { APITransaction } from '../../models/api-models/api-transaction.model'
import { TranslateModule } from '@ngx-translate/core'
import { TransactionItemComponent } from '../transaction-items/transaction-item/transaction-item.component'
import { NgIf, NgFor } from '@angular/common'

@Component({
	selector: 'eliq-transactions-list',
	templateUrl: './transactions-list.component.html',
	styleUrls: ['./transactions-list.component.scss'],
	standalone: true,
	imports: [NgIf, NgFor, TransactionItemComponent, TranslateModule],
})
export class TransactionsListComponent implements OnInit {
	@Input() billingAccountId: string
	@Input() transactions: APITransaction[]
	@Input() options: any = {}

	constructor() {}

	ngOnInit(): void {}
}
