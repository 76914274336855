export class ToggleButton {
	private id: string
	private label: string
	private selected: boolean
	private color: string

	constructor($id: string, $label: string, $selected: boolean, $color: string) {
		this.id = $id
		this.label = $label
		this.selected = $selected
		this.color = $color
	}

	/**
	 * Just toggles the button's select value
	 */
	public toggle() {
		this.selected = !this.selected
	}

	/**
	 * Getter $id
	 * @return {string}
	 */
	public get $id(): string {
		return this.id
	}

	/**
	 * Setter $id
	 * @param {string} value
	 */
	public set $id(value: string) {
		this.id = value
	}

	/**
	 * Getter $label
	 * @return {string}
	 */
	public get $label(): string {
		return this.label
	}

	/**
	 * Setter $label
	 * @param {string} value
	 */
	public set $label(value: string) {
		this.label = value
	}

	/**
	 * Getter $selected
	 * @return {boolean}
	 */
	public get $selected(): boolean {
		return this.selected
	}

	/**
	 * Setter $selected
	 * @param {boolean} value
	 */
	public set $selected(value: boolean) {
		this.selected = value
	}

	/**
	 * Getter $color
	 * @return {string}
	 */
	public get $color(): string {
		return this.color
	}

	/**
	 * Setter $color
	 * @param {string} value
	 */
	public set $color(value: string) {
		this.color = value
	}
}
