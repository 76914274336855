import { isPlatformBrowser } from '@angular/common'
import { FeatureFlagsType } from '../../../index'
import {
	Inject,
	Injectable,
	Optional,
	PLATFORM_ID,
	inject,
} from '@angular/core'

export interface IntegrationlessConfig {
	enabled?: boolean
	googleMapsApi?: string
	useWelcomePage?: boolean
	usePreLoginPage?: boolean
}

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	constructor(
		@Inject('GIT_REVISION_TAG') private GIT_REVISION_TAG: string,
		@Inject('defaultLang') private defaultLang: string,
		@Inject('isUAT') private uat: boolean,
		@Inject('clientId') private clientId: number,
		@Inject('isProd') private production: boolean,
		@Inject('clientName') private clientName: string,
		@Inject('featureFlags') private featureFlags: FeatureFlagsType,
		@Inject('baseUrl') private baseUrl: string,
		@Optional()
		@Inject('integrationless')
		public readonly integrationless: IntegrationlessConfig,
	) {}

	public PLATFORM_ID = inject(PLATFORM_ID)

	gitRevisionTag(): string {
		return this.GIT_REVISION_TAG ?? '<no tag>'
	}

	isBrowser(): boolean {
		return isPlatformBrowser(this.PLATFORM_ID)
	}

	isIntegrationless(): boolean {
		return this.integrationless?.enabled || false
	}

	useIntegrationlessWelcomePage(): boolean {
		return this.isIntegrationless() && this.integrationless?.useWelcomePage !== false;
	}

	useIntegrationlessPreLoginPage(): boolean {
		return this.isIntegrationless() && this.integrationless?.usePreLoginPage !== false
	}

	getFeatureFlags(): FeatureFlagsType {
		return this.featureFlags
	}

	getFeatureFlag(flag: string): boolean | null {
		const values = this.featureFlags[flag]

		if (typeof values === 'undefined') {
			return null
		}

		let isEnabled = false

		// enabledFor
		if (
			(typeof values['enabledFor'] === 'string' &&
				values['enabledFor'] === 'all') ||
			Array.isArray(values['enabledFor'] && values['enabledFor'][0] === 'all')
		) {
			isEnabled = true
		} else if (
			Array.isArray(values['enabledFor']) &&
			values['enabledFor'].every((el) => typeof el === 'string') &&
			(values['enabledFor'] as Array<string>)
				.map((el) => el.toLowerCase())
				.includes(this.clientName.toLowerCase())
		) {
			isEnabled = true
		}

		let isAllowed = false

		// manuallyTested
		if (this.production && isEnabled && values['manuallyTested'] === true) {
			isAllowed = true
		} else if ((!this.production || this.uat) && isEnabled) {
			isAllowed = true
		}

		// exceptions
		if (
			Array.isArray(values['exceptions']) &&
			values['exceptions'].every((el) => typeof el === 'string') &&
			(values['exceptions'] as Array<string>)
				.map((el) => el.toLowerCase())
				.includes(this.clientName.toLowerCase())
		) {
			isAllowed = false
		}

		return isAllowed
	}
	/**
	 *
	 * @returns a boolean representing whether or not the app is running in UAT mode.
	 */
	isUAT(): boolean {
		return this.uat
	}

	isKBC(): boolean {
		return this.clientId === 12834026392 || this.clientId === 12971469140
	}

	isEnergia(): boolean {
		return this.clientId === 18182021852 || this.clientId === 19465119024 || this.clientId === 18261440271
	}

	getClientId(): number {
		return this.clientId
	}

	getClientName(): string {
		return this.clientName
	}

	isProd(): boolean {
		return this.production
	}

	getDefaultLang(): string {
		return this.defaultLang
	}

	getBaseUrl(): string {
		return this.baseUrl
	}
}
