import { Injectable } from '@angular/core'
import { JsonGetterService } from '@eliq/core'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { HeaderConfig } from './header-config.model'

@Injectable({
	providedIn: 'root',
})
export class HeaderConfigService {
	constructor(private config: JsonGetterService) {}

	private headerConfig(): Observable<HeaderConfig> {
		return this.config.getConfig('header_options')
	}

	/**
	 * Returns a boolean representing whether or not we should be showing/"doing" notifications.
	 * Defaults to true if the option is not specified in the config file.
	 */
	getShowNotifications(): Observable<boolean> {
		return this.headerConfig().pipe(
			map((config) => config.showNotifications),
			catchError(() => of(true)), // default to true in the case the option isnt specified.
		)
	}

	getCompanyLogoUrl(): Observable<string> {
		return this.headerConfig().pipe(
			map((config) => (config.companyLogoUrl ? config.companyLogoUrl : '/')),
		)
	}
}
