import { ToggleButton } from './ToggleButton.model'

export class FilterToggleButton extends ToggleButton {
	private types: string[]

	constructor(
		$label: string,
		$selected: boolean,
		$color: string,
		$types: string[],
	) {
		super($label, $label, $selected, $color)
		this.types = $types
	}

	/**
	 * Getter $types
	 * @return {string[]}
	 */
	public get $types(): string[] {
		return this.types
	}

	/**
	 * Setter $types
	 * @param {string[]} value
	 */
	public set $types(value: string[]) {
		this.types = value
	}
}
