import { Injectable } from '@angular/core'
import { BillingHandlerService } from '@eliq/feature/bills'
import { CoreDataStoreService, EliqApiHttpClient } from '@eliq/core'
import { Observable, of, throwError } from 'rxjs'
import { catchError, map, switchMap, take, tap } from 'rxjs/operators'
import { SmrMeter } from '../models/SmrMeter.model'

@Injectable({
	providedIn: 'root',
})
export class SmrApiService {
	private billingAccountId: string
	private url = '/v3/billingaccounts/'

	constructor(
		private http: EliqApiHttpClient,
		private billingHandler: BillingHandlerService,
		private coreDS: CoreDataStoreService,
	) {}

	getBillingAccountId(): Observable<string> {
		return this.coreDS.user.pipe(
			take(1),
			switchMap(
				(
					userRes: any, // Add type annotation 'any' to userRes
				) =>
					this.billingHandler.getBillingAccounts(userRes.id).pipe(
						take(1),
						switchMap((billingRes: any) => {
							// Add type annotation 'any' to billingRes

							this.billingAccountId = billingRes[0].$id
							return of(this.billingAccountId)
						}),
					),
			),
		)
	}

	getMeters(): Observable<SmrMeter[]> {
		return this.getBillingAccountId().pipe(
			take(1),
			switchMap((billingAccountId) => {
				return this.http
					.get<SmrMeter[]>(this.url + billingAccountId + '/smr/meters')
					.pipe(
						take(1),
						catchError((error) => {
							if (error.status === 404) {
								return throwError(error)
							} else {
								return throwError(error)
							}
						}),
					)
			}),
		)
	}

	getDetailsOfMeter(meter: any): Observable<any> {
		//console.trace('DEBUG0123 getDetailsOfMeter')
		// eslint-disable-next-line no-debugger
		//debugger

		return this.getBillingAccountId().pipe(
			switchMap((billingAccountId) => {
				return this.http
					.get<any>(this.url + billingAccountId + '/smr/meters/' + meter)
					.pipe(
						catchError((error) => {
							console.error('getDetailsOfMeter error', error)
							if (error.status === 404) {
								console.warn(error)
								return throwError(() => error)
							} else {
								console.error(error)
								return throwError(() => error)
							}
						}),
					)
			}),
		)
	}

	submitMeterRead(
		meter: string,
		date: string,
		registers: { id: string; value: string; type: string }[],
	): Observable<any> {
		date = date.split('T')[0] + 'T00:00:00'
		return this.getBillingAccountId().pipe(
			take(1),
			switchMap((billingAccountId) => {
				return this.http
					.post<any>(
						this.url + billingAccountId + '/smr/meters/' + meter,
						{
							ignore_warnings: false,
							date,
							registers: registers,
						},
						{ observe: 'response' as 'body' },
					)
					.pipe(
						catchError((error, _c) => {
							console.error('submitMeterRead error', error)
							return throwError(() => error)
						}),
					)
			}),
		)
	}

	getReadHistory(meter: any): Observable<any> {
		return this.getBillingAccountId().pipe(
			switchMap((billingAccountId) => {
				return this.http
					.get<any>(
						this.url + billingAccountId + '/smr/meters/' + meter + '/reads',
					)
					.pipe(
						catchError((error) => {
							if (error.status === 404) {
								console.warn(error)
								return throwError(() => error)
							} else {
								console.error(error)
								return throwError(() => error)
							}
						}),
					)
			}),
		)
	}
}

// smr-api.service.spec.ts:
