import { APIInvoice } from './api-models/api-invoice.model'

export class Invoice {
	private amount: number
	private createdDate: Date
	private dueDate: Date
	private id: string
	private invoiceNumber: string
	private status: string
	private paid: boolean
	private billingAccountId: string
	private updatedDate: Date

	constructor(apiInvoice: APIInvoice, billingAccountId: string) {
		this.amount = apiInvoice.amount
		this.createdDate = new Date(apiInvoice.created_date)
		this.dueDate = new Date(apiInvoice.due_date)
		this.id = apiInvoice.id
		this.invoiceNumber = apiInvoice.invoice_number
		this.status = apiInvoice.status
		this.paid = apiInvoice.status === 'paid'
		this.billingAccountId = billingAccountId
		this.updatedDate = new Date(apiInvoice.updated_date)
	}

	/**
	 * Getter $billingAccountId
	 * @return {string}
	 */
	public get $billingAccountId(): string {
		return this.billingAccountId
	}

	/**
	 * Setter $billingAccountId
	 * @param {string} value
	 */
	public set $billingAccountId(value: string) {
		this.billingAccountId = value
	}

	/**
	 * Getter $paid
	 * @return {boolean}
	 */
	public get $paid(): boolean {
		return this.paid
	}

	/**
	 * Setter $paid
	 * @param {boolean} value
	 */
	public set $paid(value: boolean) {
		this.paid = value
	}

	/**
	 * Getter $amount
	 * @return {number}
	 */
	public get $amount(): number {
		return this.amount
	}

	/**
	 * Getter $createdDate
	 * @return {Date}
	 */
	public get $createdDate(): Date {
		return this.createdDate
	}

	/**
	 * Getter $updatedDate
	 * @return {Date}
	 */
	public get $updatedDate(): Date {
		return this.updatedDate
	}

	/**
	 * Getter $dueDate
	 * @return {Date}
	 */
	public get $dueDate(): Date {
		return this.dueDate
	}

	/**
	 * Getter $id
	 * @return {string}
	 */
	public get $id(): string {
		return this.id
	}

	/**
	 * Getter $invoiceNumber
	 * @return {string}
	 */
	public get $invoiceNumber(): string {
		return this.invoiceNumber
	}

	/**
	 * Getter $status
	 * @return {string}
	 */
	public get $status(): string {
		return this.status
	}

	/**
	 * Setter $amount
	 * @param {number} value
	 */
	public set $amount(value: number) {
		this.amount = value
	}

	/**
	 * Setter $createdDate
	 * @param {Date} value
	 */
	public set $createdDate(value: Date) {
		this.createdDate = value
	}

	/**
	 * Setter $updatedDate
	 * @param {Date} value
	 */
	public set $updatedDate(value: Date) {
		this.updatedDate = value
	}

	/**
	 * Setter $dueDate
	 * @param {Date} value
	 */
	public set $dueDate(value: Date) {
		this.dueDate = value
	}

	/**
	 * Setter $id
	 * @param {string} value
	 */
	public set $id(value: string) {
		this.id = value
	}

	/**
	 * Setter $invoiceNumber
	 * @param {string} value
	 */
	public set $invoiceNumber(value: string) {
		this.invoiceNumber = value
	}

	/**
	 * Setter $status
	 * @param {string} value
	 */
	public set $status(value: string) {
		this.status = value
	}
}
