import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core'
import { CoreDataStoreService, JsonGetterService } from '@eliq/core'
import { TermsCheckerService } from '@eliq/feature/auth'
import { SnackbarService } from '@eliq/ui/snackbar'
import { FilterToggleButton } from '@eliq/ui/common/models/FilterToggleButton.model'
import { NotificationData } from '../../models/NotificationData.model'
import { NotificationHandlerService } from '../../services/notification-handler.service'
import { NotificationSettingsService } from '../../../account'
import { take } from 'rxjs/operators'
import { forkJoin } from 'rxjs'
import { PagerComponent } from '@eliq/ui/common/components/pager/pager.component'
import { NotificationItemWrapperComponent } from '../notification-item-wrapper/notification-item-wrapper.component'
import { ToggleButtonComponent } from '@eliq/ui'
import { NgFor, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'

class NotificationType {
	constructor(public handledTypes: string[], public label: string) {}
}

@Component({
	selector: 'eliq-notifications-container',
	templateUrl: './notifications-container.component.html',
	styleUrls: ['./notifications-container.component.css'],
	standalone: true,
	imports: [
		TranslateModule,
		NgFor,
		ToggleButtonComponent,
		NotificationItemWrapperComponent,
		NgIf,
		PagerComponent,
	],
})
export class NotificationsContainerComponent implements OnInit, AfterViewInit {
	public toggleButtons: FilterToggleButton[]
	// array of the types of notifications we support, and how they relate to each other.
	private differentTypes: NotificationType[]

	private notifications: NotificationData[]
	private filteredNotifications: NotificationData[]
	public showedNotifications: NotificationData[]
	public nPages: number
	private notificationsPerPage = 10
	private currentPage: number

	private userId: number | null

	constructor(
		private elRef: ElementRef,
		private notificationHandler: NotificationHandlerService,
		private coreDS: CoreDataStoreService,
		private snackbar: SnackbarService,
		private termsChecker: TermsCheckerService,
		private configGetter: JsonGetterService,
		private notificationsSettingsApi: NotificationSettingsService,
	) {}

	ngOnInit() {
		// in order for the notification view to load properly the user data store service needs to have loaded. so someone needs to call subscribe on it.
		// we dont need to do anything with it though, just show it that it needs to load.
		this.currentPage = 1

		forkJoin({
			types: this.notificationsSettingsApi
				.getNotificationSettings()
				.pipe(take(1)),
			config: this.configGetter.getNotificationConfig().pipe(take(1)),
		})
			.pipe(take(1))
			.subscribe((res) => {
				this.differentTypes = []
				if (res.config.filters) {
					// we have a manual override. use this.
					this.differentTypes = res.config.filters.map(
						(filter) => new NotificationType(filter.allowedTypes, filter.label),
					)
				} else {
					this.differentTypes.push(new NotificationType(['all_created'], 'all'))
					this.differentTypes = this.differentTypes.concat(
						res.types[0].settings.map(
							(setting) => new NotificationType([setting.type], setting.type),
						),
					)
				}

				this.initToggleButtons()
				this.notificationsPerPage = res.config.notificationsPerPage

				this.coreDS.user.subscribe((user) => {
					if (user) {
						this.userId = user.id
						this.notificationHandler.notifications.subscribe(
							(notifications) => {
								if (notifications) {
									this.notifications = notifications.sort((a, b) => {
										return b.$createdDate.getTime() - a.$createdDate.getTime()
									})
									this.updateFilteredNotifications()
									this.updatePages(this.currentPage)
								}
							},
						)
					}
				})
			})
	}

	toggleScroll(event, target: HTMLElement | null = null) {
		if (event !== null) {
			target = event.target
		}
		if (!target || !target.parentNode) {
			return
		}
		const shadowRight = target.parentNode.querySelector('.shadow-right')
		const shadowLeft = target.parentNode.querySelector('.shadow-left')
		const scrollLeftMax = target.scrollWidth - target.clientWidth

		if (
			shadowRight === null ||
			shadowLeft === null ||
			typeof scrollLeftMax !== 'number'
		) {
			return
		}

		if ((target.scrollLeft ?? 0) < 5) {
			shadowLeft.classList.add('hidden')
		} else if (target.scrollLeft >= ((target as any).scrollLeftMax ?? 0) - 5) {
			shadowRight.classList.add('hidden')
		} else {
			shadowRight.classList.remove('hidden')
			shadowLeft.classList.remove('hidden')
		}
	}
	ngAfterViewInit() {
		this.termsChecker.checkTerms()
		const togglebuttons =
			this.elRef.nativeElement.querySelector('.togglebuttons')
		this.toggleScroll(null, togglebuttons)
	}

	/**
	 * Map the notification types to toggle buttons to filter out the notifications the user doesn't want etc
	 */
	initToggleButtons() {
		this.toggleButtons = this.differentTypes.map((type) => {
			let isToggleSelected = false
			if (type.handledTypes[0] === 'all_created') {
				isToggleSelected = true
			}
			return new FilterToggleButton(
				type.label,
				isToggleSelected,
				'',
				type.handledTypes,
			)
		})
	}

	/**
	 * Runs when user clicks one of the toggle buttons. First updates current showing notifications,
	 * and then also updates the pager to be in line with the current showing notifications.
	 * @param label the clicked button's label
	 */
	toggleButtonClicked(label: string) {
		this.toggleButtons.forEach((toggle) => {
			toggle.$label === label
				? (toggle.$selected = true)
				: (toggle.$selected = false)
		})
		this.updateFilteredNotifications()
		this.currentPage = 1
		this.updatePages(this.currentPage)
	}

	/**
	 * Reassigns filteredNotifications by looping through each notification and checking it against
	 * the currently selected togglebuttons
	 */
	updateFilteredNotifications() {
		this.filteredNotifications = this.notifications.filter((notification) => {
			return this.toggleButtons
				.filter((tgl) => {
					return tgl.$selected
				})
				.some((toggleButton) => {
					return toggleButton.$types.some((type) => {
						if (type === 'all_created') {
							return type != notification.$eventType
						}
						return type === notification.$eventType
					})
				})
		})
	}

	/**
	 * Some basic pager logic
	 */
	updatePages(page: number) {
		this.currentPage = page
		this.showedNotifications = this.filteredNotifications.slice(
			this.notificationsPerPage * (page - 1),
			this.notificationsPerPage * page,
		)

		this.nPages = Math.ceil(
			this.filteredNotifications.length / this.notificationsPerPage,
		)
		// these notifications above here were now shown to the user. we now set their status to read = true, and update the server.
		const nonReadNotifications = this.showedNotifications.filter((not) => {
			return !not.$read
		})
		if (nonReadNotifications.length > 0) {
			// only need to set the notifications that are read = false
			this.setNotificationsAsRead(nonReadNotifications)
		}
	}

	private setNotificationsAsRead(notifications: NotificationData[]) {
		this.notificationHandler
			.patchNotificationReads(this.userId, notifications)
			.subscribe((res) => {
				// res is boolean, true if success false if failure
				if (res) {
					notifications.forEach((notif) => {
						notif.$read = true
					})
				}
				if (res === false) {
					this.snackbar.doTextToast(
						'Unable to update notification status. If this persists, please contact support.',
					)
				}
			})
	}

	private appendDummyReportElementsForTesting(
		notifications: NotificationData[],
		n: number,
	): NotificationData[] {
		const apiNotData = {
			id: 123459,
			header: 'New monthly report',
			content: 'In November you used 217 kWh',
			read: true,
			created_date: '2019-12-01T07:44:29.753',
			event: {
				created_date: '2019-11-30T23:00:00',
				type: 'report_created_monthly',
				source: {
					id: 1234,
					type: 'month',
					location_id: 152476,
					date: '2019-11-01T00:00:00',
					fuel: 'elec',
				},
			},
		}

		const myList: NotificationData[] = []
		for (let i = 0; i < n; i++) {
			myList.push(new NotificationData(apiNotData))
		}

		return myList.concat(notifications)
	}
}
