import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { APIUser, CoreDataStoreService, JsonGetterService } from '@eliq/core'
import { forkJoin, Observable, ReplaySubject, Subscription, throwError } from 'rxjs'
import { catchError, switchMap, take, tap } from 'rxjs/operators'
import { APIAccountBalance } from '../../models/api-models/api-account-balance.model'
import {
	APITransaction,
	TransactionType,
} from '../../models/api-models/api-transaction.model'
import { BillingAccount2 } from '../../models/BillingAccount2.model'
import { BillingHandlerService } from '../../services/billing-handler.service'
import { BillsConfigService } from '../../services/bills-config.service'
import { MiniTransactionsComponent } from '../mini-transactions/mini-transactions.component'

@Component({
	selector: 'eliq-mini-transactions-container',
	templateUrl: './mini-transactions-container.component.html',
	styleUrls: ['./mini-transactions-container.component.scss'],
	standalone: true,
	imports: [MiniTransactionsComponent],
})
export class MiniTransactionsContainerComponent implements OnInit, OnDestroy {
	@Output() errored = new EventEmitter()

	private selectedBASubject: ReplaySubject<BillingAccount2> =
		new ReplaySubject()
	public selectedBAObservable: Observable<BillingAccount2> =
		this.selectedBASubject.asObservable()
	public currentBA: BillingAccount2

	public billingAccounts: BillingAccount2[]

	public topElementType: 'latest-transaction' | 'account-balance'
	public latestTransaction: APITransaction
	public accountBalance: APIAccountBalance

	private user: APIUser
	public options: any = {}

	public loaded = false
	private sub: Subscription

	constructor(
		private billsHandler: BillingHandlerService,
		private cds: CoreDataStoreService,
		private billsConfig: BillsConfigService,
	) {}

	ngOnInit(): void {
		const billingReq = this.cds.user.pipe(
			tap((user) => (this.user = user)),
			switchMap((user) => {
				return this.billsHandler.getBillingAccounts2(user.id).pipe(
					tap((BAs) => {
						this.billingAccounts = BAs
						this.selectedBASubject.next(this.billingAccounts[0])
					}),
				)
			}),
			take(1),
			catchError((err: any) => {
				console.error(err)
				this.errored.emit()
				return throwError(() => err)
			})
		)

		const configReq = this.billsConfig.getBillsConfig().pipe(
			take(1),
			tap((config) => {
				this.options = config
				this.topElementType = config.topElement.type
			}),
		)

		forkJoin({ billing: billingReq, config: configReq })
			.pipe(take(1))
			.subscribe({
				next: (res) => {
					this.sub = this.selectedBAObservable.subscribe((ba) => {
						this.currentBA = ba
						if (!this.currentBA) {
							console.error("No transactions found for current billing account")
							this.errored.emit()
							return
						}
						if (!this.currentBA.accountBalance) {
							console.error("No account balance found for current billing account")
							this.errored.emit()
							return
						}
						if (!this.currentBA.transactions) {
							this.currentBA.transactions = []
						}
						const latestTransaction = this.getLatestTransactionBasedOnConfig(
							this.currentBA.transactions,
							res.config.miniTransactionLastTransactionType,
						)
						if (latestTransaction) {
							this.latestTransaction = latestTransaction
						} else {
							console.error("No latest transaction found")
							this.errored.emit()
						}

						this.accountBalance = this.currentBA.accountBalance
					})

					this.loaded = true
				},
				error: (err) => {
					console.error("Error fetching billing accounts or config", err)
					this.errored.emit()
				},
		})
	}

	private getLatestTransactionBasedOnConfig(
		allTransactions: APITransaction[],
		wantedTransactionType: string,
	): APITransaction | undefined {
		return allTransactions.find((t) => t.type === wantedTransactionType)
	}

	ngOnDestroy() {
		if (this.sub) {
			this.sub?.unsubscribe()
		}
	}
}
