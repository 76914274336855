// @ts-nocheck

export class Item {
	name: string
	id: string
	selected: boolean
	href: string

	constructor(name: string, selected: boolean, id: string, href?: string) {
		this.name = name
		this.selected = selected
		this.id = id
		this.href = href
	}

	public isMatch(input: string) {
		return input === this.id
	}
}
