import {
	Component,
	OnInit,
	Input,
	ViewEncapsulation,
	AfterViewInit,
} from '@angular/core'
import { TranslateModule, TranslateService } from '@ngx-translate/core'
import { MatDialogRef } from '@angular/material/dialog'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { BasicButtonComponent } from '@eliq/ui'
import { InlineSVGModule } from 'ng-inline-svg-2'
import { EliqThemeService } from '@eliq/theme'
import { ErrorData } from '@eliq/ui/modal'

import { NgIf } from '@angular/common'
import { MatExpansionModule } from '@angular/material/expansion'

@Component({
	selector: 'eliq-error-modal',
	templateUrl: './error-modal.component.html',
	styleUrls: ['./error-modal.component.scss'],
	standalone: true,
	imports: [
		MatExpansionModule,
		TranslateModule,
		NgIf,
		BasicButtonComponent,
		ImgSrcPipe,
		InlineSVGModule,
	],
	//encapsulation: ViewEncapsulation.ShadowDom,
})
export class ErrorModalComponent implements OnInit {
	@Input() errorData!: ErrorData

	public translatedOk = 'Okay' // default

	constructor(
		public themeService: EliqThemeService,
		private translator: TranslateService,
		public dialogRef: MatDialogRef<ErrorModalComponent>,
	) {}

	ngOnInit() {
		this.translatedOk = this.translator.instant('common.okay')
		this.themeService.addGlobalStyle(
			'eliq-error-modal-style',
			`
		.eliq-error-modal .mat-mdc-dialog-surface.mdc-dialog__surface{
			padding: 0;
		}`,
		)
	}

	okClick() {
		this.dialogRef.close()
	}
}
