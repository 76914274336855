<div class="notification-bell-wrapper">
	<div
		class="parent"
		(click)="bellClicked()"
		[popper]="notificationsPopper"
		[popperTrigger]="popperTrigger"
		[popperPlacement]="popperPlacement"
		[popperApplyClass]="'myPopper'"
		[popperDisabled]="isPopperDisabled"
	>
		<div
			[inlineSVG]="'/assets/images/menu_notifications.svg' | imgSrc"
			[setSVGAttributes]="svgAttributes"
		></div>

		<div class="notification-bell-counter primary-p2" *ngIf="count > 0">
			{{count}}
		</div>

		<div class="notification-bell-counter primary-p2" *ngIf="count > 9">9+</div>
	</div>
	<popper-content #notificationsPopper>
		<eliq-notifications-popper
			(showAllClickEmitter)="showAllClick()"
		></eliq-notifications-popper>
	</popper-content>
</div>
