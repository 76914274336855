<eliq-card class="eliq-default-card-style" *ngIf="loaded">
	<div style="height: 100%">
		<div class="container" id="consumptionCardPopperLimiter">
			<h6 *ngIf="isEstimated" class="primary-h6 header">
				{{'home_consumption_summary_month.your_estimated_consumption_in_month' |
					translate:{month:currMonth} | capitalize }}
			</h6>
			<h6 *ngIf="!isEstimated" class="primary-h6 header">
				{{'home_consumption_summary_month.your_consumption_in_month' |
					translate:{month:currMonth} | capitalize }}
			</h6>

			<div class="consumption-indent">
				<div class="costtext">
					<ng-container *ngIf="configShowSoFar$ | async">
						<p *ngIf="showSoFar" class="ptext secondary-p2" translate>
							home_consumption_summary_month.so_far
						</p>
					</ng-container>
					<p class="sofarcosts">
						<span
							*ngIf="bigValue; else noDataBlock"
							class="big-values-container"
							[popper]="costsPopper"
							[popperDisabled]="!enableInfoPopper"
							[popperTrigger]="popperTrigger"
							[popperPlacement]="popperPlacement"
							[popperBoundaries]="'#consumptionCardPopperLimiter'"
							[popperApplyClass]="'myPopper'"
							[popperPreventOverflow]="true"
							[popperApplyArrowClass]="'popperArrow'"
						>
							<h1 class="spanbold primary-h1" style="margin-right: 16px;">
								{{getBigValueString()}}
							</h1>
							<h6 *ngIf="smallValue" class="spanthin primary-h5">
								{{getSmallValueString()}}
							</h6>
							<eliq-info-button
								*ngIf="enableInfoPopper"
								style="position: relative; left: 1rem; display: inline-block;"
							></eliq-info-button>
						</span>
					</p>
					<p
						*ngIf="showNextMonthForecast && (bigValueForecast || smallValueForecast)"
						class="ptext secondary-p2"
					>
						<span
							[innerText]="('home_consumption_summary_month.forecast_for_month' | translate:{month: nextMonth} | capitalize)+':'"
						></span>
						<span class="spanbold" style="margin-right: 8px;">
							{{bigValueForecast}}
						</span>
						<span *ngIf="smallValueForecast" class="spanthin">
							{{smallValueForecast}}
						</span>
					</p>
					<ng-container *ngIf="configShowSoFar$ | async">
						<p
							*ngIf="showSoFar && (bigValueForecast || smallValueForecast)"
							class="ptext secondary-p2 consumption-card-forecast-text"
						>
							<span translate>
								home_consumption_summary_month.forecast_entire_month
							</span>
							<span>:</span>
							<span class="spanbold" style="margin-right: 8px;">
								{{bigValueForecast}}
							</span>
							<span *ngIf="smallValueForecast" class="spanthin">
								<span>{{smallValueForecast}}</span>
							</span>
						</p>
					</ng-container>
				</div>
			</div>

			<div class="bottom-part">
				<div class="comparisons">
					<div class="flex-comparison" *ngIf="prevPeriodComparison">
						<div
							class="icon-comparison"
							[inlineSVG]="'/assets/images/home_comparison.svg' | imgSrc"
						></div>
						<p class="bottom-comparison-text hello secondary-p2">
							<span
								[innerHTML]="prevPeriodComparison.key | translate:prevPeriodComparison.params"
							></span>
							<span class="secondary-p3">&nbsp;({{ comparisonBounds }})</span>
						</p>
					</div>

					<div class="flex-comparison" *ngIf="simHomesComparison">
						<div
							class="icon-comparison"
							[inlineSVG]="'/assets/images/home_similar-homes.svg' | imgSrc"
						></div>
						<p
							class="bottom-comparison-text hello secondary-p2"
							[innerHTML]="simHomesComparison.key | translate:simHomesComparison.params"
						></p>
					</div>
				</div>

				<div class="button-container">
					<a routerLink="/insights">
						<eliq-flexible-button
							label="home_consumption_summary_month.view_monthly_insights"
							[buttonId]="'home_consumption_card_view_insights_button'"
						></eliq-flexible-button>
					</a>
				</div>
			</div>

			<popper-content #costsPopper>
				<eliq-costs-popper
					[cost]="consumptionCost"
					[energy]="consumptionEnergy"
				></eliq-costs-popper>
			</popper-content>
		</div>
	</div>
</eliq-card>

<ng-template #noDataBlock>
	<span class="primary-p2 no-data-text" translate>
		insights_comparison_month.not_enought_data
	</span>
</ng-template>
