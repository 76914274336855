<eliq-card class="card-style">
	<div style="padding: 24px; height: 100%; box-sizing: border-box;">
		<div style="height: 100%;" *ngIf="!comingSoon">
			<div class="container" *ngIf="loaded; else loadingBlock">
				<div class="top-part">
					<h6 class="header primary-h6">{{header}}</h6>
					<p class="primary-p1" [innerHTML]="text"></p>
					<p class="primary-p2">{{estimatedSavings}}</p>
				</div>
				<div class="bottom-part">
					<eliq-flexible-button
						[buttonStyle]="{'color': themeService.getProp('primary')}"
						buttonId="home_energy_tips_view_tips_button"
						[label]="buttonLabel"
						*ngIf="buttonLabel"
						(clicked)="cardClicked.emit()"
					></eliq-flexible-button>
					<ng-container *ngIf="imgSrc.includes('.svg')">
						<div
							[inlineSVG]="imgSrc"
							alt=""
							style="height:60px;width:auto;"
							class="my-img"
						></div>
					</ng-container>
					<ng-container *ngIf="!imgSrc.includes('.svg')">
						<img [src]="imgSrc" alt="" height="60" class="my-img" />
					</ng-container>
				</div>
			</div>
		</div>

		<div style="height: 100%;" *ngIf="comingSoon">
			<div class="container">
				<div class="top-part">
					<h6 class="header primary-h6">{{header}}</h6>
					<p class="primary-p1" [innerHTML]="text"></p>
				</div>
				<div class="bottom-part">
					<ng-container *ngIf="imgSrc.includes('.svg')">
						<div
							[inlineSVG]="imgSrc"
							alt=""
							style="height:60px;width:auto;"
							class="my-img"
						></div>
					</ng-container>
					<ng-container *ngIf="!imgSrc.includes('.svg')">
						<img [src]="imgSrc" alt="" height="60" class="my-img" />
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</eliq-card>

<ng-template #loadingBlock>
	<div class="loading-container">
		<eliq-spinner size="medium"></eliq-spinner>
	</div>
</ng-template>
