import { Injectable } from '@angular/core'
import { AngularFireAnalytics } from '@angular/fire/compat/analytics'
import {
	CategoryDiscardedClickedEvent,
	CategoryDoneClickedEvent,
	CategoryTodoClickedEvent,
	TipAddedToTodoEvent,
	TipAddedToTodoFrom,
	TipDiscardedEvent,
	TipDiscardedFrom,
	TipDoneEvent,
	TipDoneFrom,
	TipReadMoreClickedEvent,
	TipReadMoreClickedFrom,
	TipsEvent,
	ViewAllTipsEvent,
	ViewNewTipsCancelledEvent,
	ViewNewTipsEvent,
	ViewNewTipsFinishedEvent,
} from './tips-tracking-events.model'
import {
	EliqTrackingService,
	TrackingEventName,
	WebTipsCategoryDiscardedClickedEvent,
	WebTipsCategoryDoneClickedEvent,
	WebTipsCategoryTodoClickedEvent,
	WebTipsTipAddedToTodoEvent,
	WebTipsTipDiscardedEvent,
	WebTipsTipDoneEvent,
	WebTipsTipReadMoreClickedEvent,
	WebTipsViewAllTipsEvent,
	WebTipsViewNewTipsCancelledEvent,
	WebTipsViewNewTipsEvent,
	WebTipsViewNewTipsFinishedEvent,
} from '@eliq/core/tracking'

@Injectable({
	providedIn: 'root',
})
export class TipsTrackingService {
	constructor(private tracking: EliqTrackingService) {}

	viewNewTips() {
		const data: WebTipsViewNewTipsEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsViewNewTips, data)
	}

	viewNewTipsCancelled() {
		const data: WebTipsViewNewTipsCancelledEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsViewNewTipsCancelled, data)
	}

	viewNewTipsFinished() {
		const data: WebTipsViewNewTipsFinishedEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsViewNewTipsFinished, data)
	}

	viewAllTips() {
		const data: WebTipsViewAllTipsEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsViewAllTips, data)
	}

	categoryTodoClicked() {
		const data: WebTipsCategoryTodoClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsCategoryTodoClicked, data)
	}

	genericCategoryClicked(category: string) {
		const data: WebTipsCategoryTodoClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsCategoryTodoClicked.replace("_todo_", "_"+category+"_") as TrackingEventName, data)
	}

	categoryDoneClicked() {
		const data: WebTipsCategoryDoneClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsCategoryDoneClicked, data)
	}

	categoryDiscardedClicked() {
		const data: WebTipsCategoryDiscardedClickedEvent = {
			v: 1,
		}
		this.tracking.logEvent(
			TrackingEventName.WebTipsCategoryDiscardedClicked,
			data,
		)
	}

	tipAddedToTodo(from: TipAddedToTodoFrom) {
		const data: WebTipsTipAddedToTodoEvent = {
			v: 1,
			from: from,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsTipAddedToTodo, data)
	}

	tipDone(from: TipDoneFrom) {
		const data: WebTipsTipDoneEvent = {
			v: 1,
			from: from,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsTipDone, data)
	}

	tipDiscarded(from: TipDiscardedFrom) {
		const data: WebTipsTipDiscardedEvent = {
			v: 1,
			from: from,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsTipDiscarded, data)
	}

	tipReadMoreClicked(from: TipReadMoreClickedFrom) {
		const data: WebTipsTipReadMoreClickedEvent = {
			v: 1,
			from: from,
		}
		this.tracking.logEvent(TrackingEventName.WebTipsTipReadMoreClicked, data)
	}
}
