import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { APIConsent } from '@eliq/models'
import { ConsentService } from '@eliq/feature/consents/consent.service'

import { SigninConsentLearnMoreModalComponent } from '../signin-consent-learn-more-modal/signin-consent-learn-more-modal.component'
import { ModalService } from '@eliq/ui/modal'
import { TranslateService } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-signin-consent',
	templateUrl: './signin-consent.component.html',
	styleUrls: ['./signin-consent.component.css'],
	standalone: true,
	imports: [LinkComponent, BasicButtonComponent],
})
export class SigninConsentComponent implements OnInit {
	@Input() consents: APIConsent[]
	@Output() done = new EventEmitter()

	public index: number

	public loading = false

	constructor(
		private modal: ModalService,
		private translator: TranslateService,
		private consentsService: ConsentService,
	) {}

	ngOnInit(): void {
		this.index = 0
	}

	yesClicked() {
		this.loading = true
		this.consents[this.index].given_consent = true
		this.consents[this.index].language_code =
			this.consents[this.index].language_code ?? this.translator.currentLang
		this.consentsService.putConsents([this.consents[this.index]]).subscribe(
			(res) => {
				this.loading = false
				// on success!
				this.goToNextConsent()
			},
			(err) => {
				// we were unable to update the stuffs, say sorry and that this can be updated under Account.
				this.loading = false
				this.modal.openErrorModal(this.getErrorMessage()).subscribe((_) => {
					// closed
					this.goToNextConsent()
				})
			},
		)
	}

	private goToNextConsent() {
		if (this.index === this.consents.length - 1) {
			// done
			this.done.emit()
		} else {
			this.index += 1
		}
	}

	noClicked() {
		if (this.consents[this.index].is_mandatory) {
			localStorage.removeItem('userId')
			localStorage.removeItem('accessToken')
			window?.location.reload()
		} else {
			this.goToNextConsent()
		}
	}

	private getErrorMessage() {
		return (
			this.translator.instant('common.something_went_wrong') +
			' ' +
			this.translator.instant('common.please_try_again_later')
		)
	}

	learnMoreClicked() {
		const dialogRef = this.modal.openModal(SigninConsentLearnMoreModalComponent)
		const instance = <SigninConsentLearnMoreModalComponent>(
			dialogRef.componentInstance
		)
		const vars = this.getLearnMoreVars()
		instance.title = vars.title
		instance.text = vars.text
	}

	private getLearnMoreVars(): { title: string; text: string } {
		if (this.consents[this.index].name === 'consent_high_res_data') {
			return {
				title: this.translator.instant(
					'high_resolution_insights_data.learn_more_title',
				),
				text: this.translator.instant(
					'high_resolution_insights_data.learn_more_description',
				),
			}
		} else
			return {
				title: this.translator.instant(
					'user_consents.' +
						this.consents[this.index].name +
						'_learn_more_title',
				),
				text: this.translator.instant(
					'user_consents.' +
						this.consents[this.index].name +
						'_learn_more_text',
				),
			}
	}
}
