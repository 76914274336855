import { Component, OnInit, Input } from '@angular/core'
import { SpinnerComponent } from '@eliq/ui'

@Component({
	selector: 'eliq-loading-modal',
	templateUrl: './loading-modal.component.html',
	styleUrls: ['./loading-modal.component.css'],
	standalone: true,
	imports: [SpinnerComponent],
})
export class LoadingModalComponent implements OnInit {
	@Input() loadingMessage: string

	constructor() {}

	ngOnInit() {}
}
