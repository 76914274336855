// @ts-nocheck
import { TranslateService } from '@ngx-translate/core'
import { APIProperty } from '../api-models/api-home-profile.model'
import { PropertyNumber } from './PropertyNumber.model'

export class PropertyGroup {
	private type: string
	private properties: PropertyNumber[]

	constructor(type: string, items: APIProperty[]) {
		this.type = type
		this.properties = items.map((item) => {
			return new PropertyNumber(item)
		})
	}

	/**
	 * Get a list of the PropertyNumbers that have been assigned. Undefined if none assigned.
	 * @returns a list of assigned property numbers, or undefined if none available
	 */
	public getCurrentSelection(): PropertyNumber[] {
		const checkedProperties = this.properties.filter((property) => {
			return property.getCurrentSelection() !== undefined
		})

		if (checkedProperties.length === 0) {
			return undefined
		} else return checkedProperties
	}

	public hasChange(): boolean {
		return this.properties.some((property) => {
			return property.$placeholder !== undefined
		})
	}

	public getCurrentSelectionString(
		translator: TranslateService,
		maxChars?: number,
	): string {
		maxChars = maxChars ? maxChars : 20
		const assignedProperties = this.getCurrentSelection()

		// if no properties are assigned, then return undefined
		if (assignedProperties === undefined) return undefined

		// else, assemble all the names in the order they appear and cut off at maxChars after translating
		const assignedOptionNames = assignedProperties
			.filter((p) => p.getCurrentSelection() !== 0)
			.map((property) => {
				return translator.instant('home_profile_options.' + property.$key)
			})

		if (assignedOptionNames.length === 0) return '-'

		let toReturnStr = ''

		assignedOptionNames.forEach((name) => {
			if (toReturnStr !== '') {
				toReturnStr += ', '
			}

			toReturnStr += name
		})

		if (toReturnStr.length > maxChars) {
			toReturnStr = toReturnStr.substring(0, maxChars) + '...'
		}

		return toReturnStr
	}

	public undoChanges() {
		this.properties.forEach((prop) => {
			prop.$placeholder = undefined
		})
	}

	/**
	 * Getter $type
	 * @return {string}
	 */
	public get $type(): string {
		return this.type
	}

	/**
	 * Getter $properties
	 * @return {PropertyNumber[]}
	 */
	public get $properties(): PropertyNumber[] {
		return this.properties
	}
}
