import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslateModule } from '@ngx-translate/core'
import { BasicButtonComponent } from '@eliq/ui'

@Component({
	selector: 'pay-modal',
	templateUrl: './pay-modal.component.html',
	styleUrls: ['./pay-modal.component.css'],
	standalone: true,
	imports: [BasicButtonComponent, TranslateModule],
})
export class PayModalComponent implements OnInit {
	constructor(private dialogRef: MatDialogRef<PayModalComponent>) {}

	ngOnInit(): void {}

	close() {
		this.dialogRef.close()
	}
}
