<div class="transactions-list-container">
  <ng-container *ngIf="transactions.length > 0">
    <div class="bill-item" *ngFor="let transaction of transactions">
      <eliq-transaction-item [options]="options" [billingAccountId]="billingAccountId" [transaction]="transaction"></eliq-transaction-item>
    </div>
  </ng-container>

  <ng-container *ngIf="transactions.length == 0">
    <p class="secondary-p2">{{'my_bills.no_bills_yet' | translate}}</p>
  </ng-container>

</div>
