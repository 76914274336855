import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ModalService } from '@eliq/ui/modal'
import { SmrApiService } from './services/smr-api.service'
import { InfoModalComponent } from './components/modals/info-modal/info-modal.component'
import { SmrMeter } from './models/SmrMeter.model'
import { ViewHistoryModalContainerComponent } from './components/modals/view-history-modal-container/view-history-modal-container.component'
import { SmrCardContentComponent } from './components/smr-card-content/smr-card-content.component'
import {
	CardComponent,
	FlexibleButtonComponent,
	InfoButtonComponent,
	LoadingComponent,
	SpinnerComponent,
} from '@eliq/ui'
import { NgFor, NgIf } from '@angular/common'
import { TranslateModule } from '@ngx-translate/core'
import { retry, take } from 'rxjs'

@Component({
	selector: 'eliq-smr-card',
	templateUrl: './smr-card.component.html',
	styleUrls: ['./smr-card.component.scss'],
	standalone: true,
	imports: [
		InfoButtonComponent,
		FlexibleButtonComponent,
		CardComponent,
		TranslateModule,
		SpinnerComponent,
		SmrCardContentComponent,
		NgIf,
		NgFor,
	],
})
export class SmrCardComponent implements AfterViewInit {
	public loaded = false
	public meters: SmrMeter[] = []
	public meter: SmrMeter

	constructor(private api: SmrApiService, private modal: ModalService) {}

	ngAfterViewInit(): void {
		this.api
			.getMeters()
			.pipe(
				take(1),
				retry({
					count: 10,
					delay: 1000,
				}),
			)
			.subscribe({
				next: (res: SmrMeter[]) => {
					this.meters = res
					if (this.meters) {
						this.loaded = true
					} else {
						this.loaded = false
					}
				},
				error: (err) => {},
			})
	}

	public infoButtonClicked() {
		this.modal.openModal(InfoModalComponent)
	}

	viewHistoryButtonClicked() {
		if (!this.meters?.length) {
			alert('Could not use viewHistoryButton')
			return
		}
		this.meter = this.meters[0]
		const modalRef = this.modal.openModal(ViewHistoryModalContainerComponent)
		modalRef.componentInstance.meter = this.meter
		modalRef.componentInstance.meters = this.meters
	}
}
