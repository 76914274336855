<eliq-card class="eliq-default-card-style" *ngIf="loaded">
	<div class="bg" style="padding: 24px; height: 100%; box-sizing: border-box;">
		<div class="container">
			<h6 class="primary-h6 header">
				{{'home_dynamic_capacity_tariffs.header' | translate}}
			</h6>
			<div class="fragment">
				<div
					(click)="monthlyInsightsButtonClicked()"
					class="top_peak peak-tariffs-indent"
				>
					<div class="flex-items">
						<img class="icons" [src]="'/assets/images/top_peak.svg' | imgSrc" />
						<p class="fragment-bottom-text secondary-p2">
							{{'home_dynamic_capacity_tariffs.month_dct_history' | translate}}:
							<br />
							<b
								*ngIf="!noData && maxAmount; else noDataFound"
								class="underline"
							>
								{{maxAmount |  number:'1.2-2'}} kW - {{maxWeek}}
								{{trueDayOfMonth}} {{currentMonth}}
								{{'home_dynamic_capacity_tariffs.o_clock' | translate}}
								{{maxHour}}
							</b>
						</p>
					</div>
				</div>
			</div>
			<div class="fragment">
				<div
					(click)="annualAveragePeakButtonClicked()"
					class="peak-tariffs-indent"
				>
					<div class="peak-tariffs">
						<div class="flex-items">
							<img
								class="icons"
								[src]="'/assets/images/annual_average.svg' | imgSrc"
							/>
							<p class="fragment-bottom-text secondary-p2">
								{{'home_dynamic_capacity_tariffs.capacity_tariff_link_text' | translate




								}}:
								<br />
								<b
									*ngIf="!noData && yearlyAverage; else noDataFound"
									class="underline"
								>
									{{yearlyAverage |  number:'1.2-2'}} kW
								</b>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="button-container">
				<div>
					<eliq-link
						label="{{'home_dynamic_capacity_tariffs.view_more_details' | translate}}"
						(clicked)="annualAveragePeakButtonClicked()"
					></eliq-link>
				</div>
			</div>
		</div>
	</div>

	<ng-template #noDataFound>
		<b>--</b>
	</ng-template>
</eliq-card>
