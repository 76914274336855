import { FeatureFlags } from '@eliq/data-access'

const SecondUAT = false
const DisableUATLogin = false

export const environment = {
	// If second UAT
	featureFlags: FeatureFlags,

	disabledRoutes: DisableUATLogin ? ['login'] : [],

	production: false,
	uat: true,
	defaultLang: 'en-IE',
	locales: ['en-IE'], // for euro only

	clientName: 'energia',
	clientId: 19465119024,
	eliqApiBaseUrl: 'https://client-energia-ie-2-api-uat.eliq.io',

	firebase: {
		apiKey: 'AIzaSyB4D394-i3rHaz1SJtsKvfn1tlxKvXxG24',
		authDomain: 'energia-ireland.firebaseapp.com',
		databaseURL: 'https://energia-ireland.firebaseapp.com',
		projectId: 'energia-ireland',
		storageBucket: 'energia-ireland.appspot.com',
		messagingSenderId: '472167735385',
		appId: '1:472167735385:web:28a552f28a3e6545821148',
		measurementId: 'G-EWGQ8ECQTY',
		DEBUG_MODE: true,
	},
}

// AUTOMATICALLY ADDED GIT REVISION, DO NOT MODIFY
export const GIT_REVISION_TAG = 'd53bc3e3780f345e14a0251503c86032768891ea';
