// @ts-nocheck

import { Injectable } from '@angular/core'
import { Observable, forkJoin, ReplaySubject, of } from 'rxjs'
import { Invoice } from '../models/Invoice.model'
import { BillingAccount } from '../models/BillingAccount.model'
import { BillingAccount2 } from '../models/BillingAccount2.model'
import { Agreement } from '../models/Agreement.model'
import { BillsApiService } from './bills-api.service'
import { addWeeks } from 'date-fns'
import { APIRenewal } from '../models/api-models/api-agreement.model'
import { map, switchMap } from 'rxjs/operators'
import {
	APITransaction,
	TransactionType,
} from '../models/api-models/api-transaction.model'

@Injectable({
	providedIn: 'root',
})
export class BillingHandlerService {
	private billingAccontsSubject: ReplaySubject<BillingAccount[]>
	private billingAccounts: BillingAccount[]

	constructor(private api: BillsApiService) {}

	getBillingAccounts(
		userId: number | null,
		getAgreements = false,
		testing = false,
		nAccs = 1,
		nAgreements = 1,
	): Observable<BillingAccount[]> {
		if (this.billingAccontsSubject)
			return this.billingAccontsSubject.asObservable()
		this.billingAccontsSubject = new ReplaySubject(1)

		this.api.getBillingAccounts(userId).subscribe(
			(resBA) => {
				if (resBA === undefined) return

				const calls = resBA.map((ba) => {
					const arr: Observable<any>[] = [this.api.getInvoices(ba.id)]
					if (getAgreements) arr.push(this.api.getAgreements(ba.id))

					return forkJoin(arr)
				})

				forkJoin(calls).subscribe((res) => {
					let completeBAs = res.map((value, index) => {
						return new BillingAccount(
							resBA[index],
							value[0],
							value[1] ? value[1] : [],
						)
					})

					// just for testing, can be removed
					if (testing)
						completeBAs = this.appendTestingStuff(
							completeBAs,
							nAccs,
							nAgreements,
						)

					this.billingAccounts = completeBAs
					this.billingAccontsSubject.next(completeBAs)
				})
			},
			(error) => {
				// error when getting billing accounts
				if (testing) {
					let completeBAs = []
					if (testing)
						completeBAs = this.appendTestingStuff(
							completeBAs,
							nAccs,
							nAgreements,
						)

					this.billingAccounts = completeBAs
					this.billingAccontsSubject.next(completeBAs)
				} else {
					this.billingAccontsSubject.error('Failed to get billing accounts.')
				}
			},
		)

		return this.billingAccontsSubject.asObservable()
	}

	/**
	 *
	 * @param userId
	 * @param withTransactions defaults to true, if set to false will be undefined
	 * @param withAgreements defaults to true, if set to false will be undefined
	 * @returns
	 */
	getBillingAccounts2(
		userId: number | null,
		withTransactions = true,
		withAgreements = true,
		withAccountBalance = true,
	): Observable<BillingAccount2[]> {
		return this.api.getBillingAccounts(userId).pipe(
			switchMap((billingAccounts) => {
				const requests = billingAccounts.map((ba) => {
					return forkJoin({
						transactions: withTransactions
							? this.api.getTransactions(ba.id)
							: of(undefined),
						agreements: withAgreements
							? this.api.getAgreements(ba.id)
							: of(undefined),
						accountBalance: withAccountBalance
							? this.api.getAccountBalance(ba.id)
							: of(undefined),
					}).pipe(
						map((response) => {
							return new BillingAccount2(
								ba.id,
								ba.user_ref,
								ba.ext_ref,
								response.agreements,
								response.transactions,
								response.accountBalance,
							)
						}),
					)
				})

				return forkJoin(requests)
			}),
		)
	}

	/**
	 *
	 * @param userId
	 * @param withTransactions defaults to true, if set to false will be undefined
	 * @param withAgreements defaults to true, if set to false will be undefined
	 * @returns
	 */
	getBillingAccountsForLocation(
		locationId: number,
		withTransactions = true,
		withAgreements = true,
		withAccountBalance = true,
	): Observable<BillingAccount2[]> {
		return this.api.getBillingAccountsForLocation(locationId).pipe(
			switchMap((billingAccounts) => {
				const requests = billingAccounts.map((ba) => {
					return forkJoin({
						transactions: withTransactions
							? this.api.getTransactions(ba.id)
							: of(undefined),
						agreements: withAgreements
							? this.api.getAgreements(ba.id)
							: of(undefined),
						accountBalance: withAccountBalance
							? this.api.getAccountBalance(ba.id)
							: of(undefined),
					}).pipe(
						map((response) => {
							return new BillingAccount2(
								ba.id,
								ba.user_ref,
								ba.ext_ref,
								response.agreements,
								response.transactions,
								response.accountBalance,
							)
						}),
					)
				})

				return forkJoin(requests)
			}),
		)
	}

	getLatestTransaction(
		billingAccountId: string,
		transactionType: TransactionType,
	): Observable<APITransaction> {
		return this.api
			.getTransactions(billingAccountId, undefined, 1, transactionType)
			.pipe(
				map((result) => {
					if (result.length === 1) return result[0]
				}),
			)
	}

	getTransactions(billingAccountId: string): Observable<APITransaction[]> {
		return this.api.getTransactions(billingAccountId)
	}

	public getTransactionFile(
		billingAccountId: string,
		invoiceId: string,
	): Observable<Blob> {
		return this.api.getTransactionFile(billingAccountId, invoiceId)
	}

	// ---------------- testing functions for billing accounts and agreements ------------------
	/**
	 * A function that appends an arbitrary billing account to the list of billing accounts.
	 * The standard billing account observable will re-emit a new list of billing accounts.
	 */
	appendBillingAccount() {
		if (!this.billingAccounts || !this.billingAccontsSubject) return

		this.billingAccounts.push(this.getAnotherBillingAccountTesting())
		this.billingAccontsSubject.next(this.billingAccounts)
	}

	removeBillingAccount(billingAccountIdToDelete: string) {
		this.billingAccounts = this.billingAccounts.filter(
			(ba) => ba.$id !== billingAccountIdToDelete,
		)
		this.billingAccontsSubject.next(this.billingAccounts)
	}

	appendAgreementToBillingAccount(billingAccountId: string) {
		this.billingAccounts
			.find((ba) => ba.$id === billingAccountId)
			.$agreements.push(this.getDummyAgreement())
		this.billingAccontsSubject.next(this.billingAccounts)
	}

	removeAgreementFromBillingAccount(
		billingAccountId: string,
		agreementId: string,
	) {
		const ba = this.billingAccounts.find((ba) => ba.$id === billingAccountId)
		ba.$agreements = ba.$agreements.filter((a) => a.id !== agreementId)
		this.billingAccontsSubject.next(this.billingAccounts)
	}

	// -------------------------------------------------------------

	// TESTING STUFF FOR WHEN API DOESNT RETURN GOOD SHIT

	private appendTestingStuff(
		billingAccounts: BillingAccount[],
		nAccs: number,
		nAgreements: number,
	): BillingAccount[] {
		if (nAccs === undefined) nAccs = 1
		while (billingAccounts.length < nAccs) {
			billingAccounts.push(this.getAnotherBillingAccountTesting())
		}

		return this.appendDummyDataForTesting(billingAccounts, nAgreements)
	}

	private appendDummyDataForTesting(
		billingAccounts: BillingAccount[],
		nAgreements: number,
	): BillingAccount[] {
		return billingAccounts.map((billingAccount) => {
			billingAccount.$agreements.forEach((agreement) => {
				if (!agreement.renewal) agreement.renewal = this.getRenewalObject()
				if (!agreement.id)
					agreement.id = this.getRandomInt(999999999).toString()
			})

			while (billingAccount.$invoices.length < 30) {
				billingAccount.$invoices.push(this.getDummyInvoice(billingAccount.$id))
			}

			while (billingAccount.$agreements.length < nAgreements) {
				billingAccount.$agreements.push(this.getDummyAgreement())
			}

			return billingAccount
		})
	}

	/*private getRenewalObject(): APIRenewal {
    let status = localStorage.getItem("agreement_renewal_status");
    if (status === null) {
      localStorage.setItem("agreement_renewal_status", "time_to_renew");
    }

    if (status === 'time_to_renew') {
      return {
        status: "time_to_renew",
        time_to_renew: {
          date: addDays(new Date(), 7).toISOString()
        }
      }
    } else if (status === "renewed") {
      return {
        status: "renewed",
        renewed: {
          start_date: addDays(new Date(), 21).toISOString()
        }
      }
    } else {
      return {
        status: "normal"
      }
    }
  }*/

	private getRenewalObject(): APIRenewal {
		const status = localStorage.getItem('agreement_renewal_status')
		if (status === null) {
			localStorage.setItem('agreement_renewal_status', 'renewed')
		}

		if (status === 'time_to_renew') {
			return {
				time_to_renew: true,
			}
		} else if (status === 'renewed') {
			return {
				time_to_renew: false,
			}
		} else {
			return {
				time_to_renew: false,
			}
		}
	}

	private getDummyInvoice(billingAccountId: string): Invoice {
		const id = this.getRandomInt(999999999).toString()
		const invoiceNr = this.getRandomInt(999999999).toString()
		const amount = this.getRandomInt(9999)
		const createdDate = '2019-10-07T00:00:00'
		const dueDate = '2019-10-31T00:00:00'
		const updatedDate = '2019-10-07T00:00:00'
		const status: string = this.getRandomInt(2) === 0 ? 'paid' : 'unpaid' // ?????????????????

		return new Invoice(
			{
				id: id,
				invoice_number: invoiceNr,
				amount: amount,
				created_date: createdDate,
				updated_date: updatedDate,
				due_date: dueDate,
				status: status,
			},
			billingAccountId,
		)
	}

	public getDummyAgreement(): Agreement {
		// taken directly from postman documentation response examples
		const id = this.getRandomInt(999999999).toString()
		return new Agreement({
			id: id.toString(),
			name: 'Electric trading contract',
			from_date: new Date(2020, 1, 1).toISOString(),
			to_date: addWeeks(new Date(), 3).toISOString(),
			fuels: [
				{
					fuel: 'elec',
					devices: [
						{
							id: '6baa1e5eefb54004a6e2ae1fee0a6a1e',
							ext_ref: '10000',
							user_ref: '546487',
						},
					],
					details: [
						{
							name: 'Electric price',
							type: 'tariff',
							value: '55.91 öre/kWh',
						},
						{
							name: 'Standing charge',
							type: 'charge',
							value: '0 kr/month',
						},
					],
				},
			],
			renewal: this.getRenewalObject(),
		})
	}

	private getAnotherBillingAccountTesting(): BillingAccount {
		const random = this.getRandomInt(999999).toString()
		return new BillingAccount(
			{
				id: random.toString(),
				ext_ref: random.toString(),
				user_ref: random.toString(),
			},
			[],
			[],
		)
	}

	private getRandomInt(max: number) {
		return Math.floor(Math.random() * Math.floor(max))
	}
}
