// @ts-nocheck

import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '@eliq/ui/modal'
import { PeakTariffsDetailsModalComponent } from '../peak-tariffs-details-modal/peak-tariffs-details-modal.component'
import { Observable } from 'rxjs'
import { APIDynamicCapacityTariffsData } from '../../models/dynamic-capacity-tariffs-data.model'
import { DynamicCapacityTariffsApiService } from '../../services/dynamic-capacity-tariffs-api.service'
import { map } from 'rxjs/operators'
import { ArrayService } from '@eliq/core/services/array/array.service'
import { DateTranslatorService } from '@eliq/core'
import { DynamicCapacityTariffsTrackingService } from '../../tracking/dct-tracking.service'
import { Router } from '@angular/router'
import { SnackbarService } from '@eliq/ui/snackbar'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import { ImgSrcPipe } from '@eliq/core/pipes/img-src.pipe'
import { LinkComponent } from '@eliq/ui'
import { CardComponent } from '@eliq/ui/common/components/card/card.component'
import { NgIf, DecimalPipe } from '@angular/common'

@Component({
	selector: 'eliq-peak-tariffs-home-card',
	templateUrl: './peak-tariffs-home-card.component.html',
	styleUrls: ['./peak-tariffs-home-card.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		CardComponent,
		LinkComponent,
		DecimalPipe,
		TranslateModule,
		ImgSrcPipe,
	],
})
export class PeakTariffsHomeCardComponent implements OnInit {
	public data: number[]
	@Input() locId: number
	@Input() startYear: number
	@Input() pv: boolean
	public maxAmount: number
	private currentMonthYearIndex: number
	public maxWeek = ''
	public currentMonth = ''
	public trueDayOfMonth: number
	public trueMaxDay: Date
	public maxHour = ''
	public yearlyAverage: number
	public loaded = false
	public noData = false
	private now: Date
	private monthlyFromDate: Date

	constructor(
		private modal: ModalService,
		private api: DynamicCapacityTariffsApiService,
		private arrays: ArrayService,
		private dateTranslator: DateTranslatorService,
		private tracking: DynamicCapacityTariffsTrackingService,
		private router: Router,
		private snackbar: SnackbarService,
		private translator: TranslateService,
	) {}

	ngOnInit() {
		this.setup()
	}

	private setup() {
		this.getCurrentMonthlyData().subscribe(
			(res) => {
				if (this.pv) {
					this.data = res.import
				} else {
					this.data = res.consumption
				}
				const maxAmountIndexTuple: number[] =
					this.arrays.findMaximumAmountAndIndexTuple(this.data)
				this.maxAmount = maxAmountIndexTuple[0]
				this.currentMonthYearIndex = this.now.getMonth()
				if (this.maxAmount !== null && this.maxAmount > -1) {
					this.setMonthlyValues()
					this.loaded = true
					this.setDailyValues()
				} else {
					this.noData = true
					this.loaded = true
				}
			},
			(err) => {
				this.noData = true
				this.loaded = true
			},
		)
	}

	private setMonthlyValues() {
		this.yearlyAverage =
			this.arrays.getTotalFromLastIteration() / this.data.length
		this.currentMonth =
			this.dateTranslator.getMonthStringInSentenceFromZeroIndex(
				this.currentMonthYearIndex,
			)
		this.maxWeek = ''
		this.maxHour = ''
	}

	private setDailyValues() {
		this.getDailyData().subscribe(
			(res) => {
				let dailyData: number[]
				if (this.pv) {
					dailyData = res.import
				} else {
					dailyData = res.consumption
				}
				const dailyMaxAmountIndexTuple: number[] =
					this.arrays.findMaximumAmountAndIndexTuple(dailyData)
				this.maxAmount = dailyMaxAmountIndexTuple[0]
				const dailyMaxIndex = dailyMaxAmountIndexTuple[1]
				if (this.maxAmount !== null && this.maxAmount > -1) {
					this.trueDayOfMonth = dailyMaxIndex + 1
					this.trueMaxDay = new Date(
						this.now.getFullYear(),
						this.currentMonthYearIndex,
						this.trueDayOfMonth,
					)
					this.maxWeek = this.dateTranslator.getWeekdaysForDates(
						[this.trueMaxDay],
						false,
					)[0]
					if (this.maxWeek.length > 3) {
						this.maxWeek = this.maxWeek.substring(0, 3)
					}
					this.setHourlyValues()
				}
			},
			(err) => {},
		)
	}

	private setHourlyValues() {
		this.getHourlyData().subscribe(
			(res) => {
				let hourlyData: number[]
				if (this.pv) {
					hourlyData = res.import
				} else {
					hourlyData = res.consumption
				}
				const hourlyMaxAmountIndexTuple: number[] =
					this.arrays.findMaximumAmountAndIndexTuple(hourlyData)
				const hourlyMaxAmount = hourlyMaxAmountIndexTuple[0]
				const hourlyMaxIndex = hourlyMaxAmountIndexTuple[1]
				if (hourlyMaxAmount !== null && hourlyMaxAmount > -1) {
					const time = new Date(this.trueMaxDay.getTime())
					time.setHours(time.getHours() + hourlyMaxIndex)
					this.maxHour = this.dateTranslator.getDateTimeString(time, 'time')
				}
			},
			(err) => {},
		)
	}

	private getHourlyData(): Observable<APIDynamicCapacityTariffsData> {
		const dayAfterTrueMaxDay = new Date(this.trueMaxDay.getTime())
		dayAfterTrueMaxDay.setDate(dayAfterTrueMaxDay.getDate() + 1)
		const d = this.api.getDynamicCapacityTariffsData(
			this.locId,
			'hour',
			this.trueMaxDay,
			dayAfterTrueMaxDay,
			this.pv,
		)
		if (this.pv) {
			return d.pipe(
				map(
					(result) =>
						({
							...result,
							import: result.import.map((value) =>
								value === null ? value : value / 1000,
							),
						} as APIDynamicCapacityTariffsData),
				), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
			)
		} else {
			return d.pipe(
				map(
					(result) =>
						({
							...result,
							consumption: result.consumption.map((value) =>
								value === null ? value : value / 1000,
							),
						} as APIDynamicCapacityTariffsData),
				), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
			)
		}
	}

	private getDailyData(): Observable<APIDynamicCapacityTariffsData> {
		const fromCurrentMonth = new Date(
			this.now.getFullYear(),
			this.currentMonthYearIndex,
			1,
		)
		const toNextMonth = new Date(
			this.now.getFullYear(),
			this.currentMonthYearIndex + 1,
			1,
		)
		const d = this.api.getDynamicCapacityTariffsData(
			this.locId,
			'day',
			fromCurrentMonth,
			toNextMonth,
			this.pv,
		)
		if (this.pv) {
			return d.pipe(
				map(
					(result) =>
						({
							...result,
							import: result.import.map((value) =>
								value === null ? value : value / 1000,
							),
						} as APIDynamicCapacityTariffsData),
				), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
			)
		} else {
			return d.pipe(
				map(
					(result) =>
						({
							...result,
							consumption: result.consumption.map((value) =>
								value === null ? value : value / 1000,
							),
						} as APIDynamicCapacityTariffsData),
				), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
			)
		}
	}

	private getCurrentMonthlyData(): Observable<APIDynamicCapacityTariffsData> {
		this.now = new Date()
		this.monthlyFromDate = new Date(this.now.getFullYear(), 0)
		const d = this.api.getDynamicCapacityTariffsData(
			this.locId,
			'month',
			this.monthlyFromDate,
			this.now,
			this.pv,
		)
		if (this.pv) {
			return d.pipe(
				map(
					(result) =>
						({
							...result,
							import: result.import.map((value) =>
								value === null ? value : value / 1000,
							),
						} as APIDynamicCapacityTariffsData),
				), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
			)
		} else {
			return d.pipe(
				map(
					(result) =>
						({
							...result,
							consumption: result.consumption.map((value) =>
								value === null ? value : value / 1000,
							),
						} as APIDynamicCapacityTariffsData),
				), // Dividing by 1000, because API return data in W instead of kW, but Web displays in kW.
			)
		}
	}

	public annualAveragePeakButtonClicked() {
		this.tracking.homeCardPeakDetailsClicked()
		const dialogRef = this.modal.openModal(PeakTariffsDetailsModalComponent)
		const instance = <PeakTariffsDetailsModalComponent>(
			dialogRef.componentInstance
		)
		instance.data = this.data
		instance.locId = this.locId
		instance.startYear = this.startYear
		instance.pv = this.pv
	}

	public monthlyInsightsButtonClicked() {
		this.tracking.homeCardDctMonthlyInsightsClicked()
		const scrollSuggestion = this.translator.instant(
			'home_dynamic_capacity_tariffs.scroll_suggestion',
		)
		this.snackbar.doTextToast(scrollSuggestion, 3000)
		this.router.navigate(['/insights'])
	}
}
