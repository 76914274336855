import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatProgressBarModule } from '@angular/material/progress-bar'

@Component({
	selector: 'eliq-fullpage-linear-loading-bar',
	standalone: true,
	imports: [CommonModule, MatProgressBarModule],
	templateUrl: './fullpage-linear-loading-bar.component.html',
	styleUrls: ['./fullpage-linear-loading-bar.component.scss'],
})
export class FullpageLinearLoadingBarComponent {}
