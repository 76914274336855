import { DOCUMENT } from '@angular/common'
import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core'
import { EnvironmentService } from '@eliq/data-access'
@Injectable({
	providedIn: 'root',
})
export class EliqThemeService {
	private document = inject(DOCUMENT)

	private renderer: Renderer2

	private env = inject(EnvironmentService)

	constructor(private rendererFactory: RendererFactory2) {
		this.renderer = rendererFactory.createRenderer(null, null)
	}

	public getPrefix() {
		return this.env.getClientName()
	}

	public getPropVal(name: string) {
		return (
			this.getElementPropertyValue(this.document.body, name) ||
			this.getElementPropertyValue(this.document.documentElement, name)
		)
	}

	public getElementPropertyValue(element: HTMLElement, name: string): string {
		let currentElement: HTMLElement | null = element
		let currentValue: CSSStyleDeclaration | string | null =
			this.document?.defaultView?.getComputedStyle(element) ?? null

		while (
			currentValue &&
			typeof currentValue['getPropertyValue'] === 'function'
		) {
			currentValue = (currentValue as CSSStyleDeclaration).getPropertyValue(
				`--${this.getPrefix()}-${name}`,
			)
		}

		//currentValue = this.getElementPropertyValue(currentValue, name)

		while (
			(!currentValue || typeof currentValue !== 'string') &&
			element.parentElement &&
			currentElement !== this.document.documentElement
		) {
			currentElement = currentElement?.parentElement ?? null
			if (currentElement) {
				currentValue = this.getElementPropertyValue(currentElement, name)
			}
		}

		return typeof currentValue === 'string' && currentValue.length > 0
			? currentValue
			: ''
		//
	}

	public setPropVal(element: HTMLElement, key: string, value: string) {
		this.renderer.setProperty(
			// it can be this.HTMLElement.nativeElement
			// or this.document.documentElement or any similar
			element,

			// we will set the "style" property of the element
			'style',

			// the CSS variable you want to declare
			// be sure to encapsulate it in a back tick (`)
			(element.getAttribute('style') ?? '') +
				`--${this.getPrefix()}-${key}: ${value};`,
		)
	}

	/**
	Returns a string that can be used to get a css variable from the theme.
	# Example:
	```typescript
	const color = this.themeService.getProp('primary', 'color', 'raw:transparent')
	// color is now: "var(--prefix-primary, var(--prefix-color, transparent))"
	```*/
	public getProp(...names: string[]) {
		return `${names
			// If name starts with 'raw:' then we remove 'raw:\s*' from the name and do not add prefix or surround with var()
			.map((name) => (name.startsWith("raw:") ? name.replace(/^raw:\s*/,'') : `var(--${this.getPrefix()}-${name}`))
			.join(', ')}${names.reduce((ending, name) => (name.startsWith("raw:") ? ending : ending + ')'), '')}`
	}

	/// Adds the css in a style-tag to the head of the document.
	/// If a style tag with TYthe same id already exists then it is not added.
	/// Useful for overriding MDC styling per component without having to put more bloat in the styles.scss file.
	public addGlobalStyle(uniqueId: string, cssContent: string) {
		if (!document.head.querySelector('#eliq-error-modal-style')) {
			const styleTag = document.createElement('style') as HTMLStyleElement
			styleTag.id = uniqueId
			styleTag.innerHTML = cssContent
			document.head.appendChild(styleTag)
		}
	}

	public getComponentProperty(element: HTMLElement, key: string) {
		return element.style.getPropertyValue(`--${this.getPrefix()}-${key}`)
	}

	public setProps(element: HTMLElement, properties: Record<string, string>) {
		Object.entries(properties).forEach(([key, value]) => {
			if (typeof value !== 'string' || typeof key !== 'string') {
				console.error('Invalid css variable: (' + key + ' = ' + value + ')')
				return
			}
			this.setPropVal(element, key, value)
		})
	}

	/**
	 * @deprecated Not sure if I fixed this comment:
	 * TODO seems bugged, just use setProps for now instead of this special logic
	 * @param element
	 * @param defaults
	 */
	public setComponentDefaults(
		element: HTMLElement,
		defaults: Record<string, string>,
	) {
		Object.entries(defaults).forEach(([key, value]) => {
			if (typeof value !== 'string' || typeof key !== 'string') {
				console.error('Invalid css variable: (' + key + ' = ' + value + ')')
				return
			}

			if (!this.getPropVal(key) && !this.getComponentProperty(element, key)) {
				this.setPropVal(element, key, value)
				//element.style.setProperty(`--${this.getPrefix()}-${key}`, value)
				//element.style[`--${this.getPrefix()}-${key}`] = value
			}
		})
	}

	/**
	 * This function is used to set the *defaults* for css variables for a component based on the "events" ('hover', 'clicked', or something custom) that can happen to it and which "parts" (e.g. 'background', 'contrast', etc.) the component has.
	 * The resulting variable set follow the pattern: `--prefix-name-part--event`.
	 * name & part can contain an empty string for the default state. For example, if events is `['']` then the resulting variables will be `--prefix-name-part` instead of `--prefix-componentName-part--event`.
	 * @param options The options object
	 * @param options.element The element to set the variables on
	 * @param options.name The name of the component
	 * @param options.parts Can contain an empty string for the default state.
	 * @param options.events Can contain an empty string for the default state.
	 * @returns An object with the generated variables for the component. The returned value will usually not be used.
	 *
	 * @example

	 * ```typescript
	 * const button = document.querySelector('button')
	 * this.themeService.setComponentDefaultEventBasedProps(button, {
	 * 	name: 'button-component',
	 * 	parts: ['background', 'border', 'contrast'],
	 * 	events: ['hover', 'active', 'focus'],
	 * 	defaultname: 'button'
	 * })
	 * // The resulting css variables will be:
	 * // --prefix-button-component-background = prefix-button-background
	 * // --prefix-button-component-background--hover = prefix-button-background--hover
	 * // ...
	 * ```
	*/
	public setComponentDefaultEventBasedProps = (
		element: HTMLElement,
		options: {
			name: string,
			parts: string[],
			events: string[],
			defaultName?: string
			defaultAppended?: string,
		}
	) => {
		if (!options.name) {
			throw new Error("name is required")
		}
		if (!options.parts || options.parts.length === 0) {
			throw new Error("componentParts is required")
		}
		if (!options.events || options.events.length === 0) {
			throw new Error("events is required")
		}
		const [name, parts, events] = [options.name, options.parts, options.events]

		const opt = {
			...options,
			defaultAppended: options["defaultAppended"] ?? '',
			defaultName: options["defaultName"] ?? ''
		} as Required<typeof options>

		const makeColorPart = (string: string, separator = '--') => {
			if (!string) {
				return ''
			}
			if (!string.startsWith("-")) {
				return separator + string
			}
			return string
		}
		const makeColor = (...strings: string[]) => strings.map((string) => makeColorPart(string)).join('')

		const componentColors = events.reduce((colors, event) => {
			parts.forEach((part) => {
				colors[name + makeColor('-'+part, event)] = this.getProp(
					(opt.defaultName || name) + makeColor('-'+part, event, opt.defaultAppended),
					(opt.defaultName || name) + makeColor('-'+part, opt.defaultAppended),
				)
			})
			return colors
		}, {})

		this.setProps(element, componentColors)

		return componentColors
	}



	public _unused_setComponentDefaults(
		element: HTMLElement,
		defaults: Record<string, string>,
	) {
		Object.entries(defaults).forEach(([key, value]) => {
			if (typeof value !== 'string' || typeof key !== 'string') {
				console.error('Invalid css variable: (' + key + ' = ' + value + ')')
				return
			}
			const retValue = this.getPropVal(key) ?? ''
			// global style overrides, otherwise set the default variables (inside of the component so shadowdom can access it??? maybe this should be global instead? <-- TODO)
			if (!retValue) element.style.setProperty(key, retValue)
		})
	}
}
