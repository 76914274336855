import { APIAccountBalance } from './api-models/api-account-balance.model'
import { APIAgreement } from './api-models/api-agreement.model'
import { APITransaction } from './api-models/api-transaction.model'

export class BillingAccount2 {
	constructor(
		public id: string,
		public userRef: string,
		public extRef: string,
		public agreements?: APIAgreement[],
		public transactions?: APITransaction[],
		public accountBalance?: APIAccountBalance,
	) {}
}
