import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core'
import { TranslateService, TranslateModule } from '@ngx-translate/core'
import {
	APIPropertyDependency,
	HomeProfile,
	LocationHttpService,
	Property,
	PropertyGroup,
	PropertySingleSelect,
} from '@eliq/core'
import { EnvironmentService } from '@eliq/data-access'
import { SpinnerComponent } from '@eliq/ui'
import { BasicButtonComponent } from '@eliq/ui'
import { LinkComponent } from '@eliq/ui'
import { HomedetailsAppliancesComponent } from '../homedetails-appliances/homedetails-appliances.component'
import { HomedetailsAmountofComponent } from '../homedetails-amountof/homedetails-amountof.component'
import { HomedetailsHomesizeComponent } from '../homedetails-homesize/homedetails-homesize.component'
import { HomedetailsMultipleSelectComponent } from '../homedetails-multiple-select/homedetails-multiple-select.component'
import { HomedetailsRadiobuttonsComponent } from '../homedetails-radiobuttons/homedetails-radiobuttons.component'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { XButtonComponent } from '@eliq/ui'
import { NgIf, NgClass, NgStyle } from '@angular/common'
import { catchError, map, take, tap } from 'rxjs'
import { th } from 'date-fns/locale'

@Component({
	selector: 'eliq-homedetails-setup-stepper',
	templateUrl: './homedetails-setup-stepper.component.html',
	styleUrls: ['./homedetails-setup-stepper.component.scss'],
	standalone: true,
	imports: [
		NgIf,
		NgClass,
		XButtonComponent,
		NgStyle,
		MatProgressBarModule,
		HomedetailsRadiobuttonsComponent,
		HomedetailsMultipleSelectComponent,
		HomedetailsHomesizeComponent,
		HomedetailsAmountofComponent,
		HomedetailsAppliancesComponent,
		LinkComponent,
		BasicButtonComponent,
		TranslateModule,
		SpinnerComponent,
	],
})
export class HomedetailsSetupStepperComponent implements OnInit {
	@Input() locationName: string
	@Input() locationId: number
	@Input() properties: Property[]
	@Input() propertyGroups: PropertyGroup[] = []
	@Input() noX = false

	@Output() done = new EventEmitter<string>()
	@Output() loading = new EventEmitter<boolean>()

	public currentIndex = 0
	public maxIndex: number
	public currentlyValid = false
	public property: any // either Property or PropertyGroup
	public propertyType: 'property' | 'propertyGroup'

	public hasYearlyEnergy = false
	private yearlyEnergyIndex = 0

	public allProps: any[]
	private allPropsOriginal: any[]

	// a percentage number to be passed to the progress bar
	public progressMade: number

	public continueString = ''

	public header = ''
	constructor(
		private env: EnvironmentService,
		private locHttp: LocationHttpService,
		private translator: TranslateService,
		private locationHttp: LocationHttpService,
	) {}

	ngOnInit(): void {
		let ye = null as Property | null
		const yeIndex = this.properties.findIndex(
			(el) => el.$name === 'yearlyenergy',
		)
		if (yeIndex > -1) {
			this.hasYearlyEnergy = true
			ye = this.properties.splice(yeIndex, 1)[0] // we delete yearly energy from the original properties so we can insert it where we want it.
		}

		this.allProps = this.properties

		if (this.propertyGroups)
			this.allProps = [
				...this.allProps,
				this.propertyGroups,
				...(ye ? [ye] : []),
			]

		if (ye) {
			this.yearlyEnergyIndex = this.allProps.length - 1
			this.properties.push(ye) // we add back the yearly energy object
		}

		this.hideXIfYearlyEnergy()

		// create the original, which we wont be filtering based on possible dependencies
		this.allPropsOriginal = Object.assign([], this.allProps)

		this.maxIndex = this.allProps.length - 1
		this.assignVariables()
	}

	hideXIfYearlyEnergy() {
		this.noX = true
		return
		if (this.hasYearlyEnergy && this.currentIndex === this.yearlyEnergyIndex) {
			this.noX = true
		} else {
			this.noX = false
		}
	}

	// only used for Property object
	setNewValue(value: any) {
		if (value === undefined) {
			value = null
		}

		if (this.property instanceof Property) {
			this.property.$placeholder = value
			this.property.savePlaceholder()
			this.property.$placeholder = value
		}
		//this.property.value = value
	}

	// only used for Property object
	validityChange(valid: boolean) {
		this.currentlyValid = valid
	}

	backClick() {
		if (this.currentIndex > 0) {
			this.currentIndex--
			this.assignVariables()
		}
		this.hideXIfYearlyEnergy()
	}

	continueClick() {
		if (!this.currentlyValid) {
			return
		}

		this.saveOne(this.allProps[this.currentIndex]).subscribe({
			next: (res) => {
				if (res !== null) {
					this.properties = new HomeProfile(this.env, res).$properties
				}
			},
		})

		if (this.currentIndex < this.maxIndex) {
			// Save current property or prop group before going to next one

			// alright, we've made a change. let's see if we need to update the array, taking dependencies into account.
			this.checkDependencies()

			this.currentIndex++
			this.assignVariables()
		} else {
			this.loading.emit(true)
			this.done.emit('success')

			this.saveAll()
				.pipe(take(1))
				.pipe(
					take(1),
					tap((res) => {
						if (res !== null) {
							this.done.emit('success')
							this.loading.emit(false)
							this.properties = new HomeProfile(this.env, res).$properties
						} else {
							this.done.emit('failure')
						}
					}),
					catchError((c, err) => {
						console.error(
							"homedetails-setup-stepper emitted 'failure'",
							{ currentIndex: this.currentIndex, properties: this.properties },
							err,
						)
						this.loading.emit(false)
						this.done.emit('failure')
						console.error(err)
						return c
					}),
				)
				.subscribe()
		}

		//this.assignVariables(this.maxIndex - 1)
		// user clicked complete we are now done

		this.hideXIfYearlyEnergy()
	}

	/**
	 * Some properties or dependent on the selected value of other properties.
	 * This is specified i its getDependentProperties field.
	 * So we go through all properties, and for each we check if it has a dependency.
	 * If it has a dependency, we only include it if the dependency is satisfied. Otherwise filter out.
	 */
	private checkDependencies() {
		const initLength = this.allProps.length
		this.allProps = this.allPropsOriginal
			.filter((prop) => prop instanceof Property)
			.filter((prop: Property) => {
				if (prop.getDependentProperties() === undefined) return true
				else
					return prop
						.getDependentProperties()
						.every((dependency) =>
							this.isPropertyDependencySatisfied(dependency),
						)
			})
			.concat(this.propertyGroups)
		// Update so yearly energy is positioned correctly after property groups are added
		if (this.hasYearlyEnergy) {
			const curYearlyIndex = this.allProps.findIndex(
				(el) => el.$key === 'yearlyenergy',
			)
			if (this.yearlyEnergyIndex === this.allPropsOriginal.length - 1) {
				this.allProps.splice(
					this.allProps.length,
					0,
					this.allProps.splice(curYearlyIndex, 1)[0],
				)
			} else {
				this.allProps.splice(
					this.yearlyEnergyIndex,
					0,
					this.allProps.splice(curYearlyIndex, 1)[0],
				)
			}
		}

		if (
			this.hasYearlyEnergy &&
			this.yearlyEnergyIndex === this.allPropsOriginal.length - 1
		) {
			this.yearlyEnergyIndex = this.allProps.length - 1
		}
		if (initLength !== this.allProps.length) {
			// a change was made, lets update some stuffs!
			// okay actualy only the maxIndex needs to be changed, the progressMade var will be updated in assignVariables()
			if (this.yearlyEnergyIndex === this.maxIndex) {
				this.yearlyEnergyIndex = this.allProps.length - 1
			}
			this.maxIndex = this.allProps.length - 1
		}
	}

	/**
	 * Returns a property if it exists in this.allPropsOriginal, or undefined if it doesn't
	 * @param path something like "properties/house_type"
	 */
	private getPropertyFromDependencyPath(path: string): Property | undefined {
		// currently we only support properties, so...
		if (!path.includes('properties/')) return undefined // shouldnt happen, return undefined!

		path = path.replace('properties/', '')
		return this.properties.find((prop) => prop.$key === path)
	}

	private isPropertyDependencySatisfied(
		dependency: APIPropertyDependency,
	): boolean {
		const property = this.getPropertyFromDependencyPath(dependency.property)
		if (property === undefined) return true // if its not even here, then we know its satisfied.

		const currSelection: string = property.getCurrentSelection()
		return dependency.accepted_values.includes(currSelection)
	}

	private saveOne(property: Property | PropertyGroup) {
		if (property instanceof Property) {
			return this.locHttp.patchProperties(this.locationId, [property], [])
		} else {
			return this.locHttp.patchProperties(this.locationId, [], [property])
		}
	}
	private saveAll() {
		// user clicked complete we are now done
		return this.locHttp.patchProperties(
			this.locationId,
			this.properties,
			this.propertyGroups,
		)
	}

	skipClick() {
		// the user chose to close the modal, we want to save their progress first.
		if (this.currentIndex > this.yearlyEnergyIndex) {
			this.saveAll()
				.pipe(take(1))
				.subscribe(
					(res) => {
						this.properties = new HomeProfile(this.env, res).$properties
						this.done.emit('success-progress-saved')
					},
					(err) => {
						this.done.emit('failure')
					},
				)
		} else {
			// alright, we've made a change. let's see if we need to update the array, taking dependencies into account.
			this.checkDependencies()
			this.currentIndex = this.yearlyEnergyIndex
			this.assignVariables()
			this.hideXIfYearlyEnergy()
			this.noX = true
		}
	}

	shouldNumberPropHaveSlider(property: Property) {
		// just match against different keys such
		const sliderKeys = ['living_area', 'yearlyenergy']

		return sliderKeys.some((key) => {
			return key === property.$key
		})
	}

	// assumes that index is 0-based (index 0 is the first step)
	private getContinueString(currentIndex: number, maxIndex: number) {
		if (currentIndex === maxIndex) {
			// if we are currently on the last home profile setup step
			return 'common.button_done'
		} else {
			return 'common.button_continue'
		}
	}

	private getProgress(index: number, maxIndex: number) {
		if (index === 0) return 0
		const value = (index / maxIndex) * 100
		return value
	}

	private assignVariables(indexOverride: number | null = null) {
		//this.property.$placeholder = undefined
		this.property = this.allProps[indexOverride ?? this.currentIndex]

		if (this.property instanceof Property) {
			this.propertyType = 'property'
			this.currentlyValid =
				this.property.hasValidSelection() || !this.property.$is_required
			if (!this.currentlyValid) {
			} else {
			}
			this.header = this.translator.instant(
				`home_profile_options.${this.property.$key}`,
			)
		} else if (this.property instanceof PropertyGroup) {
			this.propertyType = 'propertyGroup'
			const propGroup = <PropertyGroup>this.property
			this.header = this.translator.instant(
				`home_profile_options.${propGroup.$type}`,
			)
			if (propGroup.getCurrentSelection() === undefined) {
				// nothing is set, so here we should set them all to 0.
				propGroup.$properties.forEach((prop) => (prop.$placeholder = 0))
			}
			this.currentlyValid = true //propGroups are always valid.
		}

		this.continueString = this.getContinueString(
			this.currentIndex,
			this.maxIndex,
		)
		this.progressMade = this.getProgress(this.currentIndex, this.maxIndex)
	}
}
